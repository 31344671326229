import React, { useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsChipSelectField } from '@plexus-ui/index';

export default () => {
  const [bugs, setBugs] = useState(['Ant', 'Wasp', 'Bee']);
  const [forbiddenBugs, setForbiddenBugs] = useState(['Wasp', 'Spider']);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Interactive Example</DocHeading>
        <PlxsChipSelectField
          autofocus
          dataTestId="bugs-input"
          label="Bugs"
          items={bugs}
          onItemsChanged={setBugs}
          itemValidity={(bug) => !forbiddenBugs.includes(bug)}
        />
        <PlxsChipSelectField
          dataTestId="forbidden-bugs-input"
          label="Forbidden Bugs"
          items={forbiddenBugs}
          onItemsChanged={setForbiddenBugs}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Fixed Height (rows=3, lowResolutionRows=5)</DocHeading>
        <PlxsChipSelectField
          dataTestId="fixed-height-input"
          items={bugs}
          onItemsChanged={setBugs}
          itemValidity={(bug) => !forbiddenBugs.includes(bug)}
          rows={3}
          lowResolutionRows={5}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Invalid</DocHeading>
        <PlxsChipSelectField
          dataTestId="disabled-input"
          label="Invalid"
          items={bugs}
          onItemsChanged={setBugs}
          itemValidity={(bug) => !forbiddenBugs.includes(bug)}
          hasError
          errorMessage="Invalid State"
        />
      </DocRow>
    </DocRow>
  );
};
