import React, { FC } from 'react';

import { ComponentProps } from './Spinner.types';
import { useStyles } from './Spinner.styles';
import Icon from './Icon';

const Spinner: FC<ComponentProps> = ({ size }) => {
  const classes = useStyles({ size });
  return (
    <span className={`${classes.root} ${classes.size}`}>
      <Icon />
    </span>
  );
};

export default Spinner;
