import React, { ChangeEvent, FC, useState, KeyboardEvent } from 'react';
import { useStyles } from './styles';
import { ComponentProps } from './types';

import { PlxsChip, PlxsTextField } from '@plexus-ui/index';
import LockedCell from './LockedCell';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const TextFieldCell: FC<ComponentProps> = ({
  label,
  value,
  editIcon,
  isEditable,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [cellValue, setCellValue] = useState(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCellValue(event.target.value as string);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditMode(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsEditMode(false)}>
      <div className={classes.editableCell}>
        {!isEditable ? (
          <LockedCell value={cellValue} />
        ) : isEditMode ? (
          <PlxsTextField
            autoFocus={true}
            dataTestId={label}
            isInline
            isDefaultActive={true}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={cellValue}
          />
        ) : (
          <PlxsChip
            label={cellValue}
            hasHover={true}
            functionalIcon={editIcon}
            onFunctionalIconClick={() => {
              setIsEditMode(true);
            }}
            onChipClick={() => {
              setIsEditMode(true);
            }}
            tone="brand"
            variant="plainText"
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TextFieldCell;
