import React, { FC, ReactNode } from 'react';
import './index.css';

interface ComponentProps {
  children?: ReactNode;
  hasDarkBg?: boolean;
}

const Box: FC<ComponentProps> = ({ children, hasDarkBg }) => {
  const classes = hasDarkBg ? 'box box-dark' : 'box';
  return <div className={classes}>{children}</div>;
};

export default Box;
