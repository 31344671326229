import { makeStyles } from '@material-ui/core/styles';
import { brand } from '../../../styles/color';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  option: {
    '&:hover': {
      backgroundColor: brand[900],
    },
  },
});
