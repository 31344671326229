import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { brand, urgent, neutral } from './color';

const fontFamily =
  '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif';

export type TextSize = 'xs' | 'sm' | 'md' | 'inherit';

const sizeStyles: Record<TextSize, CSSProperties> = {
  xs: {
    fontSize: '10px',
    lineHeight: '14px',
  },
  sm: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  md: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  inherit: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
};

export type TextColor =
  | 'brand'
  | 'dark'
  | 'white'
  | 'urgent'
  | 'inherit'
  | 'disabled';

const textColors: Record<TextColor, string> = {
  brand: brand[400],
  dark: brand[100],
  white: 'white',
  urgent: urgent[400],
  inherit: 'inherit',
  disabled: neutral[400],
};

export type TextWeight = 'regular' | 'bold' | 'semiBold' | 'inherit';

const textWeights: Record<TextWeight, 'inherit' | number> = {
  regular: 400,
  bold: 700,
  semiBold: 600,
  inherit: 'inherit',
};

export type TextAlignment = 'left' | 'center' | 'right' | 'inherit';

export interface TextProps {
  size?: TextSize;
  color?: TextColor;
  align?: TextAlignment;
  weight?: TextWeight;
}

export const getTextStyle = ({
  size,
  color,
  weight,
  align,
}: TextProps): CSSProperties => ({
  fontFamily,
  letterSpacing: 'normal',
  ...(size ? { ...sizeStyles[size] } : {}),
  ...(color ? { color: textColors[color] } : {}),
  ...(weight ? { fontWeight: textWeights[weight] } : {}),
  ...(align ? { textAlign: align } : {}),
});

export const inheritedTextStyle = getTextStyle({
  size: 'inherit',
  color: 'inherit',
  weight: 'inherit',
  align: 'inherit',
});

export const defaultTextStyle: CSSProperties = {
  marginTop: 0,
  marginBottom: 0,
  ...getTextStyle({
    size: 'sm',
    color: 'dark',
    weight: 'regular',
    align: 'left',
  }),
};
