import React, { FC, useState } from 'react';

import { DocRow } from '@dev/components';
import { PlxsAutoComplete, PlxsText } from '@plexus-ui/index';
import { users } from '@dev/mocks/users';

interface User {
  email: string;
  id?: number;
  name: string;
}

const SingleSelectComplex: FC = () => {
  const [selection, setSelection] = useState('');

  const getOptionValue = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  const handleSelect = (option: User) => {
    setSelection(JSON.stringify(option));
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  return (
    <DocRow margin="md">
      <PlxsAutoComplete
        dataTestId="single-select-complex"
        getOptionValue={getOptionValue}
        label="Recipients"
        matchKeys={['email', 'name']}
        onSelect={handleSelect}
        optionList={users}
        renderOption={renderOption}
      />
      <PlxsText>Selected option: {selection}</PlxsText>
    </DocRow>
  );
};

export default SingleSelectComplex;

export const SingleSelectComplexSnippet = `import React, { FC } from 'react';

import { PlxsAutoComplete } from '@plexus-ui/index';

interface User {
  email: string;
  id?: number;
  name: string;
}

const users = [
  {
    id: 1,
    name: 'Tabby Petrussi',
    email: 'tpetrussi0@netvibes.com',
  },
  {
    id: 2,
    name: 'Morgun Vasilik',
    email: 'mvasilik1@usa.gov',
  },
  {
    id: null,
    name: 'Cos Gunter',
    email: 'cgunter2@gov.uk',
  },
  {
    id: null,
    name: 'Claudia Tousy',
    email: 'ctousy3@jimdo.com',
  },
  {
    id: 5,
    name: 'Pooh Fountaine',
    email: 'pfountaine4@amazon.de',
  },
];

const SingleSelectComplex: FC = () => {

  const getOptionValue = (option: User) => {
    const { email, id, name } = option;
    return \`\${name} (\${id ? email : 'External'})\`;
  };

  const handleSelect = (option: User) => {
    console.log('handleSelect', JSON.stringify(option));
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return \`\${name} (\${id ? email : 'External'})\`;
  };

  return (
    <PlxsAutoComplete
      dataTestId="single-select-complex"
      getOptionValue={getOptionValue}
      label="Recipients"
      matchKeys={['email', 'name']}
      onSelect={handleSelect}
      optionList={users}
      renderOption={renderOption}
    />
  );
};

export default SingleSelectComplex;`;
