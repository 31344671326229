import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../styles/text';
import { neutral } from '../../styles/color';

import { StyleProps } from './TextEditor.types';

const paddingStyles = (props: StyleProps) => {
  if (props.padding === 'sm') {
    return {
      padding: '7px',
    };
  }
  return {
    padding: '10px 15px',
  };
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    ...defaultTextStyle,
    ...paddingStyles(props),
    border: '1px solid transparent',
    borderRadius: '3px',
    color: neutral[100],
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    '&:hover': {
      borderColor: neutral[500],
      cursor: 'pointer',
    },
  }),
}));
