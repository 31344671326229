import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import PlxsButton from '../../../Button';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const ScrollButton: FC<ComponentProps> = ({ dataTestId, position }) => {
  const {
    initComplete,
    isFirstItemVisible,
    isLastItemVisible,
    scrollNext,
    scrollPrev,
  } = useContext(VisibilityContext);

  const classes = useStyles();

  const [isVisible, setVisible] = useState(false);

  const isLeft = position === 'left';
  const isRight = position === 'right';

  const className = classNames(classes.scrollButton, {
    [classes.scrollButtonLeft]: isLeft,
    [classes.scrollButtonRight]: isRight,
    [classes.scrollButtonShow]: isVisible,
  });
  const functionalIcon = isLeft ? 'arrowLeft' : 'arrowRight';

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    return isLeft ? scrollPrev() : scrollNext();
  };

  useEffect(() => {
    if (initComplete) {
      setVisible(isLeft ? !isFirstItemVisible : !isLastItemVisible);
    }
  }, [initComplete, isFirstItemVisible, isLastItemVisible, isLeft]);

  return (
    <div className={className}>
      {isVisible && (
        <PlxsButton
          dataTestId={dataTestId}
          functionalIcon={functionalIcon}
          isIconOnly={true}
          label=""
          onClick={handleClick}
          size="sm"
          tone="brand"
          variant="link"
        />
      )}
    </div>
  );
};

export default ScrollButton;
