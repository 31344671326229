import React, { ChangeEvent, Fragment, MouseEvent, useState } from 'react';
import { PlxsTextarea, PlxsTextEditor } from '@plexus-ui/index';

const initialContent =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quaerat quibusdam earum maxime cum pariatur, eos omnis veniam, atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!';

const WithTextarea = () => {
  const [isEditMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialContent);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    setEditMode(false);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setEditMode(true);
  };

  return (
    <Fragment>
      {isEditMode ? (
        <PlxsTextarea
          autoFocus={true}
          dataTestId="textarea"
          isAutoResizing={true}
          isInline={true}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
        />
      ) : (
        <PlxsTextEditor
          dataTestId="text-content-edit"
          onClick={handleClick}
          value={value}
        />
      )}
    </Fragment>
  );
};

export default WithTextarea;
