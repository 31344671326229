import React, { ChangeEvent, createRef, FC, useEffect, useState } from 'react';

import PlxsChip from '../Chip';

import { ComponentProps } from './Input.types';
import { useStyles } from './Input.styles';

const Input: FC<ComponentProps> = ({
  autoFocus,
  dataTestId,
  id,
  inputRef,
  maxLength,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  type = 'text',
  value,
}) => {
  const hasMaxLength = Boolean(maxLength);
  const initialWidth = 320;
  const valueLength = value ? value.length : 0;
  const initialCharacterCountdown = hasMaxLength
    ? maxLength - valueLength
    : null;

  const [characterCountdown, setCharacterCountdown] = useState(
    initialCharacterCountdown
  );
  const [inputMaxWidth, setInputMaxWidth] = useState(initialWidth);
  const [inputWidth, setInputWidth] = useState(initialWidth);

  const textRef = createRef<HTMLDivElement>();
  const wrapperRef = createRef<HTMLDivElement>();

  const classes = useStyles({
    maxLength,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    if (maxLength && newValue.length > maxLength) {
      event.currentTarget.value = newValue.substring(0, maxLength);
    }
    onChange(event);
  };

  useEffect(() => {
    if (hasMaxLength) {
      setCharacterCountdown(maxLength - value.length);
    }
  }, [characterCountdown, hasMaxLength, maxLength, value]);

  useEffect(() => {
    const hasLoaded = textRef && textRef.current && textRef.current.offsetWidth;
    if (hasLoaded) {
      setInputWidth(textRef.current.offsetWidth);
    }
  }, [value, textRef]);

  useEffect(() => {
    const hasLoaded =
      wrapperRef && wrapperRef.current && wrapperRef.current.offsetWidth;
    if (hasLoaded) {
      const width = wrapperRef.current.offsetWidth;
      if (inputMaxWidth !== width) {
        setInputMaxWidth(width);
      }
    }
  }, [inputMaxWidth, wrapperRef]);

  const inputStyle = {
    maxWidth: `${inputMaxWidth}px`,
    width: `${inputWidth}px`,
  };

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <div ref={textRef} className={classes.text}>
        {value}
      </div>
      <div className={classes.inputWrapper}>
        <input
          autoFocus={autoFocus}
          className={classes.input}
          data-testid={dataTestId}
          id={id}
          onBlur={onBlur}
          onChange={handleChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={inputRef}
          style={inputStyle}
          type={type}
          value={value}
        />
        {hasMaxLength && (
          <div className={classes.counter}>
            <PlxsChip label={String(characterCountdown)} tone="brand" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
