import React, { FC, useContext } from 'react';

import { useStyles } from './Dropdown.styles';
import { ComponentProps } from './Dropdown.types';
import { FieldContext } from '../../Field/Field.context';

const Dropdown: FC<ComponentProps> = ({ children, isOpen }) => {
  const { hasError } = useContext(FieldContext);
  const classes = useStyles({ isOpen, hasError });

  return <div className={classes.root}>{children}</div>;
};

export default Dropdown;
