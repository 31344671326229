import React, { FC } from 'react';

import PlxsText from '../../../../Text';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const InstructionText: FC<ComponentProps> = ({ children }) => {
  const classes = useStyles({});

  return children ? (
    <div className={classes.wrapper}>
      <PlxsText size="xs">{children}</PlxsText>
    </div>
  ) : null;
};

export default InstructionText;
