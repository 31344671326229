import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsCollapsePanel, PlxsRow, PlxsText } from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Basic</DocHeading>
      <PlxsCollapsePanel dataTestId="test" title="Details">
        <PlxsRow spaceBelow="lg">
          <PlxsText size="sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet morbi
            porttitor turpis hendrerit lacinia nunc mi neque ultrices. Fusce
            ornare senectus tellus sit consectetur. Adipiscing aliquet nibh
            convallis nunc augue proin consequat eleifend vitae. Diam quis urna
            vel sed quis ac sed turpis. Vulputate sed est eget et volutpat
            pellentesque faucibus. Venenatis nibh cras ornare tortor libero, ut
            pellentesque sem pulvinar. Consequat dolor auctor luctus vel.
          </PlxsText>
        </PlxsRow>
      </PlxsCollapsePanel>
    </DocRow>
  </DocRow>
);
