import React, { FC, Fragment } from 'react';

import { useStyles } from './styles';

const Footer: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Fragment>
      {children && <div className={classes.footer}>{children}</div>}
    </Fragment>
  );
};

export default Footer;
