import React, { FC } from 'react';

import { PlxsRow } from '@plexus-ui/index';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import Label from './_internal/Label';

const BestPractice: FC<ComponentProps> = ({ lists }) => {
  const classes = useStyles();

  if (!lists) {
    return null;
  }

  const { doList, doNotList } = lists;

  return (
    <PlxsRow isFlex={false} spaceBelow="md">
      <h2>Best Practice</h2>

      <div className="row">
        <div className="col-lg-6">
          <ul>
            {doList.map((good: string, index: number) => (
              <li className={classes.listItem} key={index}>
                <Label icon="check" tone="positive">
                  {good}
                </Label>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-6">
          <ul>
            {doNotList.map((bad: string, index: number) => (
              <li className={classes.listItem} key={index}>
                <Label icon="cross" tone="urgent">
                  {bad}
                </Label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </PlxsRow>
  );
};

export default BestPractice;
