import React, { FC } from 'react';

import InternalAnchor from '../../../Anchor';
import InternalLabel from '../../../Label';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const StaticLabel: FC<ComponentProps> = ({
  hasError,
  label,
  secondaryLabel,
  secondaryLabelUrl,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InternalLabel hasError={hasError} content={label} />
      {secondaryLabel && secondaryLabelUrl && (
        <InternalAnchor isExternal={true} url={secondaryLabelUrl}>
          <InternalLabel content={secondaryLabel} variant="help" />
        </InternalAnchor>
      )}
    </div>
  );
};

export default StaticLabel;
