import React, { FC } from 'react';

import { useStyles } from '../../styles/header';

const PropGroupHeading: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <span className={classes.heading}>{children}</span>
    </div>
  );
};

export default PropGroupHeading;
