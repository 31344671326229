import React, { FC } from 'react';

import { PlxsIcon } from '@plexus-ui/index';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Label: FC<ComponentProps> = ({ icon, children, tone }) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <PlxsIcon name={icon} tone={tone} />
      <span>{children}</span>
    </span>
  );
};

export default Label;
