import { makeStyles } from '@material-ui/core/styles';
import { defaultTextStyle } from '../../styles/text';
import { fontColor } from '../../styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  content: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    padding: '0 0 7px 7px',
    '& > *': {
      margin: '7px 7px 0 0',
    },
  },
  placeholder: {
    ...defaultTextStyle,
    color: fontColor.disabled,
    width: '100%',
    margin: '10px',
    userSelect: 'none',
  },
});
