import React, { FC, FocusEvent, useCallback, useState } from 'react';

import InternalFieldWrapper from '../_internal/FieldWrapper';

import { ComponentProps } from './TextField.types';
import { useStyles } from './TextField.styles';
import ResetButton from './ResetButton';

const TextField: FC<ComponentProps> = ({
  autoFocus,
  button,
  dataTestId,
  errorMessage,
  hasError,
  iconRight,
  id,
  inputRef,
  instructionText,
  isDefaultActive = false,
  isDisabled,
  isInline,
  label,
  labelVariant = 'static',
  maxLength,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onReset,
  onKeyDown,
  placeholder,
  readOnly,
  secondaryLabel,
  secondaryLabelUrl,
  styleOverrides,
  type = 'text',
  value,
}) => {
  const [isActive, setIsActive] = useState(isDefaultActive);

  const iconRightComponent =
    Boolean(onReset) && !isDisabled && Boolean(value) ? (
      <ResetButton isDisabled={isDisabled} onClick={onReset} />
    ) : (
      iconRight
    );
  const hasIconRight = Boolean(iconRightComponent);
  const isDynamicLabelMinimised = isActive || Boolean(value);

  const classes = useStyles({
    hasIconRight,
    isDisabled,
  });

  const handleBlurCapture = useCallback(() => {
    setIsActive(false);
  }, []);

  const handleFocusCapture = useCallback(() => {
    setIsActive(true);
  }, []);

  return (
    <InternalFieldWrapper
      button={button}
      errorMessage={errorMessage}
      hasError={hasError}
      iconRight={iconRightComponent}
      instructionText={instructionText}
      isActive={isActive}
      isDisabled={isDisabled}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      onBlurCapture={handleBlurCapture}
      onFocusCapture={handleFocusCapture}
      label={label}
      labelVariant={labelVariant}
      placeholder={!value && placeholder}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
      styleOverrides={styleOverrides}
    >
      <input
        autoFocus={autoFocus}
        className={classes.root}
        data-testid={dataTestId}
        disabled={isDisabled}
        id={id}
        maxLength={maxLength}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        ref={inputRef}
        type={type}
        value={value}
      />
    </InternalFieldWrapper>
  );
};

export default TextField;
