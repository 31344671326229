import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Ai: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    width="80"
    height="77"
    viewBox="0 0 80 77"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="49.5" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M27.8047 32.2344L32.8828 34.3672L35.0156 39.4453C35.1172 39.6484 35.3203 39.8516 35.625 39.8516C35.8281 39.8516 36.0312 39.6484 36.1328 39.4453L38.2656 34.3672L43.3438 32.2344C43.5469 32.1328 43.75 31.9297 43.75 31.625C43.75 31.4219 43.5469 31.2188 43.3438 31.1172L38.2656 28.9844L36.1328 23.9062C36.0312 23.7031 35.8281 23.5 35.5234 23.5C35.3203 23.5 35.1172 23.7031 35.0156 23.9062L32.8828 28.9844L27.8047 31.1172C27.6016 31.2188 27.3984 31.4219 27.3984 31.7266C27.3984 31.9297 27.6016 32.1328 27.8047 32.2344ZM38.4688 50.7188L27.8047 49.0938L23.0312 39.4453C22.2188 37.7188 19.6797 37.7188 18.8672 39.4453L14.0938 49.0938L3.42969 50.7188C1.5 50.9219 0.789062 53.3594 2.10938 54.6797L9.82812 62.1953L8 72.8594C7.69531 74.6875 9.72656 76.2109 11.4531 75.2969L20.8984 70.2188L30.4453 75.2969C30.75 75.3984 31.1562 75.5 31.5625 75.5C32.8828 75.5 33.8984 74.4844 33.8984 73.1641C33.8984 73.0625 33.8984 72.9609 33.8984 72.8594L32.0703 62.1953L39.7891 54.6797C41.1094 53.3594 40.3984 50.9219 38.4688 50.7188ZM53.1953 45.8438L49.1328 44.1172L47.4062 40.0547C47.3047 39.9531 47.2031 39.75 47 39.75C46.6953 39.75 46.5938 39.9531 46.4922 40.0547L44.7656 44.1172L40.7031 45.8438C40.6016 45.9453 40.5 46.0469 40.5 46.3516C40.5 46.5547 40.6016 46.6562 40.7031 46.7578L44.7656 48.4844L46.4922 52.5469C46.5938 52.6484 46.6953 52.75 47 52.75C47.2031 52.75 47.3047 52.6484 47.4062 52.5469L49.1328 48.4844L53.1953 46.7578C53.2969 46.6562 53.5 46.5547 53.5 46.25C53.5 46.0469 53.2969 45.9453 53.1953 45.8438Z"
    />
  </svg>
);

export default Ai;
