import React, { FC } from 'react';
import { DocBox, DocHeading, DocRow } from '@dev/components';
import { PlxsText, PlxsSpinner } from '@plexus-ui/index';

const SpinnerDocs: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <PlxsSpinner size={48} />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <DocBox hasDarkBg={true}>
          <PlxsText color="white" element="span">
            <PlxsSpinner size={48} />
          </PlxsText>
        </DocBox>
      </DocRow>
    </DocRow>
  );
};

export default SpinnerDocs;
