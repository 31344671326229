import { makeStyles } from '@material-ui/core/styles';
import { neutral } from '../../styles/color';

export const useStyles = makeStyles({
  inlineRoot: {
    display: 'inline-block',
  },
  blockRoot: {
    display: 'block',
  },
  menuListWrapper: {
    background: 'white',
    border: `1px solid ${neutral[800]}`,
    borderRadius: '3px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    minWidth: '160px',
    padding: '4px',
  },
});
