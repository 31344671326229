import React, { FC, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import ScrollButton from './_internal/ScrollButton';
import Tab from './_internal/Tab';

const Navigation: FC<ComponentProps> = ({ list, onClick }) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(list[0].id);

  const handleClick = (id: string) => {
    setActiveTab(id);
    onClick(id);
  };

  const renderTabs = list.map((item, index) => {
    const { id, label } = item;
    return (
      <Tab
        index={index}
        isActive={activeTab === id}
        itemId={id}
        key={index}
        label={label}
        onClick={handleClick}
      />
    );
  });

  return (
    <div className={classes.navigation}>
      <ScrollMenu
        LeftArrow={<ScrollButton position="left" />}
        RightArrow={<ScrollButton position="right" />}
      >
        {renderTabs}
      </ScrollMenu>
    </div>
  );
};

export default Navigation;
