import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const RadioButton: FC<ComponentProps> = ({
  autoFocus,
  checked,
  dataTestId,
  hasError,
  id,
  name,
  onChange,
  required,
  value,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(event.target.value);
    }
  };

  return (
    <input
      autoFocus={autoFocus}
      checked={checked}
      className={classes.root}
      data-has-error={hasError}
      data-testid={dataTestId}
      id={id}
      name={name}
      onChange={handleChange}
      required={required}
      type="radio"
      value={value}
    />
  );
};

export default RadioButton;
