import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { FormControlLabel } from '@material-ui/core';

import { DocHeading, DocRow } from '@dev/components';
import {
  PlxsButton,
  PlxsHeading,
  PlxsPopover,
  PlxsRow,
  PlxsSwitch,
  PlxsText,
} from '@plexus-ui/index';

const WithSwitches: FC = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [toggleOne, setToggleOne] = useState<boolean>(false);
  const [toggleTwo, setToggleTwo] = useState<boolean>(false);
  const [toggleThree, setToggleThree] = useState<boolean>(false);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleToggleOne = () => {
    setToggleOne(!toggleOne);
  };

  const handleToggleTwo = () => {
    setToggleTwo(!toggleTwo);
  };

  const handleToggleThree = () => {
    setToggleThree(!toggleThree);
  };

  const isOpen = Boolean(anchorElement);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Dropdown with switches</DocHeading>
        <PlxsButton
          dataTestId="default-menu--button"
          onClick={handleOpen}
          id="default-menu-button"
          isActive={isOpen}
          label="Open"
        />
        <PlxsPopover
          anchorElement={anchorElement}
          dataTestId="default-menu"
          isOpen={isOpen}
          onClickAway={handleClose}
        >
          <Fragment>
            <PlxsRow spaceBelow="md">
              <PlxsHeading element="h2">Heading text</PlxsHeading>
            </PlxsRow>
            <PlxsRow spaceBelow="md">
              <PlxsText element="span">Sub heading text</PlxsText>
            </PlxsRow>
            <PlxsRow spaceBelow="md">
              <FormControlLabel
                control={
                  <PlxsSwitch checked={toggleOne} onChange={handleToggleOne} />
                }
                label="Toggle one"
              />
            </PlxsRow>
            <PlxsRow spaceBelow="md">
              <FormControlLabel
                control={
                  <PlxsSwitch checked={toggleTwo} onChange={handleToggleTwo} />
                }
                label="Toggle two"
              />
            </PlxsRow>
            <PlxsRow>
              <FormControlLabel
                control={
                  <PlxsSwitch
                    checked={toggleThree}
                    onChange={handleToggleThree}
                  />
                }
                label="Toggle three"
              />
            </PlxsRow>
          </Fragment>
        </PlxsPopover>
      </DocRow>
    </DocRow>
  );
};

export default WithSwitches;
