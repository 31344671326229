import React, { FC, useState } from 'react';

import { DocHeading, DocRow } from '@dev/components';
import { PlxsSearch } from '@plexus-ui/index';

interface User {
  email: string;
  id?: number;
  name: string;
}

export const EmptyState: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const renderSelectedOptionValue = (option: User) => option.name;

  const handleSelect = () => {};

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  const handleChange = async (value: string, selected: User[]) => {
    setIsLoading(true);
    setIsLoading(false);
  };

  return (
    <DocRow margin="md">
      <DocHeading>Empty Search</DocHeading>
      <PlxsSearch
        isDefaultActive
        dataTestId="empty-state-search"
        renderSelectedOptionValue={renderSelectedOptionValue}
        label="Empty state"
        labelVariant="dynamic"
        onSelect={handleSelect}
        optionList={[]}
        renderOption={renderOption}
        tooltipTextKey="email"
        onChange={handleChange}
        isLoading={isLoading}
        placeholder="Type user name here"
        emptyOptionText={'No Selection Found'}
      />
    </DocRow>
  );
};
