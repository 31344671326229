import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
  DocText,
} from '@dev/components';

import Tone from '@plexus-ui/components/_internal/Tone';

import { useStyles } from '../../../styles/panelList';

const props = `interface ComponentProps {
  children: ReactNode;
  hasHover?: boolean;
  tone: Tone; // 'brand' | 'dark' | 'disabled' | 'caution' | 'neutral' | 'positive' | 'urgent' | 'white'
}`;

export default () => {
  const classes = useStyles();
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The Tone component provides tone color by wrapping the children in a
            span element. A hover state can be applied to the base tone.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Default</DocSubHeading>
            <DocParagraph>
              In the default state tone colour is applied to the color css
              property.
            </DocParagraph>
          </DocRow>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Brand</div>
              <div className={classes.icons}>
                <Tone tone="brand">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="brand">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Dark</div>
              <div className={classes.icons}>
                <Tone tone="dark">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="dark">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Disabled</div>
              <div className={classes.icons}>
                <Tone tone="disabled">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="disabled">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Caution</div>
              <div className={classes.icons}>
                <Tone tone="caution">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="caution">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Neutral</div>
              <div className={classes.icons}>
                <Tone tone="neutral">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="neutral">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Positive</div>
              <div className={classes.icons}>
                <Tone tone="positive">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="positive">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Urgent</div>
              <div className={classes.icons}>
                <Tone tone="urgent">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="urgent">{children}</Tone>'}</DocCode>
              </div>
            </li>
            <li className={`${classes.listItem} ${classes.listItemDark}`}>
              <div
                className={`${classes.displayName} ${classes.displayNameLight}`}
              >
                White
              </div>
              <div className={classes.icons}>
                <Tone tone="white">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>{'<Tone tone="white">{children}</Tone>'}</DocCode>
              </div>
            </li>
          </ul>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Hover</DocSubHeading>
            <DocParagraph>
              The hasHover prop, applies a hover state to the color CSS property
              and turns the cursor into a pointer.
            </DocParagraph>
          </DocRow>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Brand with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="brand">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="brand">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Dark with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="dark">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="dark">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Disabled with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="disabled">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="disabled">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Caution with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="caution">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="caution">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Neutral with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="neutral">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="neutral">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Positive with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="positive">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="positive">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Urgent with hover</div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="urgent">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="urgent">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
            <li className={`${classes.listItem} ${classes.listItemDark}`}>
              <div
                className={`${classes.displayName} ${classes.displayNameLight}`}
              >
                White with hover
              </div>
              <div className={classes.icons}>
                <Tone hasHover={true} tone="white">
                  <DocText isBold={true}>Sample text in tone colour.</DocText>
                </Tone>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<Tone hasHover={true} tone="white">{children}</Tone>'}
                </DocCode>
              </div>
            </li>
          </ul>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
