import React, { PropsWithChildren, forwardRef, Children } from 'react';
import { ComponentProps } from './types';

const CustomMenuItem = forwardRef<
  HTMLLIElement,
  PropsWithChildren<ComponentProps>
>(({ value, children, onClick, onMouseEnter, className }, ref) => {
  return (
    <li
      ref={ref}
      className={className}
      value={value}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      tabIndex={-1}
    >
      {children}
    </li>
  );
});

export default CustomMenuItem;
