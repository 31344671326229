import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '../../styles';
import { StyleProps } from './Row.types';

const alignItems = (alignY: string) => {
  const styles = {
    bottom: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    top: 'flex-start',
  };
  return styles[alignY];
};

const spaceBelowStyles = (spaceBelow: string | undefined) => {
  if (!spaceBelow) {
    return;
  }
  return {
    marginBottom: spacing[spaceBelow],
  };
};

const spaceBetweenStyles = (spaceBetween: string | undefined) => {
  if (!spaceBetween) {
    return;
  }
  return {
    '& > *': {
      marginRight: spacing[spaceBetween],
      '&:last-child': {
        marginRight: '0',
      },
    },
  };
};

const justifyContent = (alignX: string) => {
  const styles = {
    center: 'center',
    left: 'flex-start',
    right: 'flex-end',
    spaceBetween: 'space-between',
  };
  return styles[alignX];
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => {
    const { alignX, alignY, isFlex, spaceBelow, spaceBetween } = props;
    return {
      alignItems: isFlex ? alignItems(alignY) : undefined,
      display: isFlex ? 'flex' : undefined,
      justifyContent: isFlex ? justifyContent(alignX) : undefined,
      ...spaceBelowStyles(spaceBelow),
      ...spaceBetweenStyles(spaceBetween),
    };
  },
}));
