import React from 'react';
import {
  DocCode,
  DocHeading,
  DocPadding,
  DocParagraph,
  DocRow,
  DocSubHeading,
  DocText,
} from '@dev/components';

import ToneBg from '@plexus-ui/components/_internal/ToneBg';

import { useStyles } from '../../../styles/panelList';

const props = `interface ComponentProps {
  children: ReactNode;
  hasHover?: boolean;
  tone: Tone; // 'brand' | 'disabled' | 'caution' | 'neutral' | 'positive' | 'urgent'
}`;

export default () => {
  const classes = useStyles();
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The ToneBg component provides tone background color by wrapping the
            children in a span element. A hover state can be applied to the base
            tone.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Default</DocSubHeading>
            <DocParagraph>
              In the default state tone colour is applied to the backgroundColor
              CSS property.
            </DocParagraph>
          </DocRow>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Brand</div>
              <div className={classes.icons}>
                <ToneBg tone="brand">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>{'<ToneBg tone="brand">{children}</ToneBg>'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Disabled</div>
              <div className={classes.icons}>
                <ToneBg tone="disabled">
                  {' '}
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg tone="disabled">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Caution</div>
              <div className={classes.icons}>
                <ToneBg tone="caution">
                  {' '}
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg tone="caution">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Neutral</div>
              <div className={classes.icons}>
                <ToneBg tone="neutral">
                  {' '}
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg tone="neutral">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Positive</div>
              <div className={classes.icons}>
                <ToneBg tone="positive">
                  {' '}
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg tone="positive">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Urgent</div>
              <div className={classes.icons}>
                <ToneBg tone="urgent">
                  {' '}
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>{'<ToneBg tone="urgent">{children}</ToneBg>'}</DocCode>
              </div>
            </li>
          </ul>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Hover</DocSubHeading>
            <DocParagraph>
              The hasHover prop, applies a hover state to the color css property
              and turns the cursor into a pointer.
            </DocParagraph>
          </DocRow>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Brand with hover</div>
              <div className={classes.icons}>
                <ToneBg hasHover={true} tone="brand">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg hasHover={true} tone="brand">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Disabled with hover</div>
              <div className={classes.icons}>
                <ToneBg hasHover={true} tone="disabled">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {
                    '<ToneBg hasHover={true} tone="disabled">{children}</ToneBg>'
                  }
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Caution with hover</div>
              <div className={classes.icons}>
                <ToneBg hasHover={true} tone="caution">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg hasHover={true} tone="caution">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Neutral with hover</div>
              <div className={classes.icons}>
                <ToneBg hasHover={true} tone="neutral">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg hasHover={true} tone="neutral">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Positive with hover</div>
              <div className={classes.icons}>
                <ToneBg hasHover={true} tone="positive">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {
                    '<ToneBg hasHover={true} tone="positive">{children}</ToneBg>'
                  }
                </DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.displayName}>Urgent with hover</div>
              <div className={classes.icons}>
                <ToneBg hasHover={true} tone="urgent">
                  <DocPadding>
                    <DocText isBold={true}>Sample text...</DocText>
                  </DocPadding>
                </ToneBg>
              </div>
              <div className={classes.name}>
                <DocCode>
                  {'<ToneBg hasHover={true} tone="urgent">{children}</ToneBg>'}
                </DocCode>
              </div>
            </li>
          </ul>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
