import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const PanelContent: FC<ComponentProps> = ({ children, hasBorder }) => {
  const classes = useStyles({ hasBorder });

  return <div className={classes.panelContent}>{children}</div>;
};

export default PanelContent;
