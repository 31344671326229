import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import SingleDate from './SingleDate';

const props = `interface ComponentProps {
  autoFocus?: boolean;
  dataTestId: string;
  errorMessage?: string;
  hasError?: boolean;
  id?: string;
  instructionText?: string;
  isDefaultActive?: boolean;
  isDisabled?: boolean;
  label?: string;
  labelVariant?: 'dynamic' | 'hidden' | 'static';
  maxDate?: Date | null;
  minDate?: Date | null;
  onSelect: (date: Date | null) => void;
  secondaryLabel?: string;
  secondaryLabelUrl?: string;
  selected: Date | null;
  isInline?: boolean;
  locale?: Locale;
}`;

const usage = `<PlxsDatePicker
  dataTestId="date-picker"
  onSelect={() => {}}
  selected={new Date()}
/>
`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>The DatePicker component.</DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocRow margin="sm">
            <DocSubHeading>Default</DocSubHeading>
            <DocParagraph>
              The date format is in dd/MM/yyyy and calendar by default has no
              limit to the min and max date selectable
            </DocParagraph>
            <DocCode>{usage}</DocCode>
          </DocRow>
        </DocRow>

        <DocRow margin="sm">
          <DocHeading>Examples</DocHeading>
          <DocParagraph>
            This component renders a TextField and Calendar.
          </DocParagraph>
          <SingleDate />
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
