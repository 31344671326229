import React, { FC, useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import classNames from 'classnames';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Tab: FC<ComponentProps> = ({
  index,
  isActive,
  itemId,
  label,
  onClick,
}) => {
  const classes = useStyles({ isActive });
  const visibility = useContext(VisibilityContext);

  const isFirstTab = index === 0;
  const isLastTab = visibility.isLastItem(itemId);
  const tabClasses = classNames(classes.tab, {
    [classes.firstTab]: isFirstTab,
    [classes.lastTab]: isLastTab,
    [classes.otherTab]: !isFirstTab && !isLastTab,
  });

  const handleClick = () => {
    onClick(itemId, visibility);
  };

  return (
    <div className={tabClasses} onClick={handleClick}>
      <div className={classes.tabLabel}>{label}</div>
      <span className={classes.tabBorder} />
    </div>
  );
};

export default Tab;
