import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import KebabButton from './KebabButton';
import DropdownButton from './DropdownButton';

const props = `interface ComponentProps {
  dataTestId: string;
  id?: string;
  isCloseOnSelect?: boolean;
  isDisabled?: boolean;
  isUnmountOnTransitionedOut?: boolean;
  getOptionState?: (option: Option) => MenuItemState;
  onSelect: (option: Option) => void;
  options: Option[];
  placement?: MenuPlacement;
  renderOption: (option: Option) => ReactNode;
  triggerButton?: ReactNode;
}`;

const usage = `<PlxsActionMenu
  dataTestId="action-menu"
  onSelect={() => {}}
  options={[{id: 1, name: 'foo'}, {id: 2, name: 'bar'}]}
  renderOption={(option) => <div>{option.name}</div>}
/>
`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>The ActionMenu component.</DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocRow margin="sm">
            <DocSubHeading>Default</DocSubHeading>
            <DocParagraph>
              ActionMenu rendered with a default "kebab" style trigger button.
              To use a "dropdown" style trigger, supply a PlxsButton via the
              triggerButton prop - see example below.
            </DocParagraph>
            <DocCode>{usage}</DocCode>
          </DocRow>
        </DocRow>

        <DocRow margin="sm">
          <DocHeading>Examples</DocHeading>
          <DocParagraph>
            Scroll the element in all axis within the viewport to experiment
            with menu overflow prevention.
          </DocParagraph>
          <KebabButton />
          <DropdownButton />
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
