import React, { FC } from 'react';

import { BRAND } from '../../_constants';

import { ComponentProps } from './ProgressBar.types';
import { useStyles } from './ProgressBar.styles';

const ProgressBar: FC<ComponentProps> = ({
  label,
  labelWidth,
  progress,
  tone = BRAND,
}) => {
  const styles = useStyles({ labelWidth, progress, tone });

  return (
    <div className={styles.root}>
      <div className={styles.progressBar}>
        <div className={styles.progressBarFill} />
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default ProgressBar;
