import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-4px',
    '& > div': {
      margin: '4px',
    },
  },
});
