import Form from '@dev/docs/sandbox/Form';
import Test from '@dev/docs/sandbox/Test';

const sandbox = [
  {
    component: Form,
    name: 'Form',
    path: '/sandboxForm',
  },
  {
    component: Test,
    name: 'Test',
    path: '/sandboxTest',
  },
];

export default sandbox;
