import React, { FC, MouseEvent } from 'react';
import { PlxsButton } from '@plexus-ui/index';

interface ComponentProps {
  onClick: (event: MouseEvent) => void;
}

const SecondaryButton: FC<ComponentProps> = ({ onClick }) => {
  return (
    <PlxsButton
      dataTestId="secondary-action--button"
      functionalIcon="cross"
      onClick={onClick}
      label="Secondary action button"
      tone="neutral"
      variant="secondary"
    />
  );
};

export default SecondaryButton;
