import React, { FC, Fragment, useState } from 'react';
import { DocHeading, DocRow, DocParagraph } from '@dev/components';
import { PlxsButton, PlxsButtonGroup, PlxsModal } from '@plexus-ui/index';

import PrimaryButton from './_internal/PrimaryButton';

const Basic: FC = () => {
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <DocRow margin="xxl">
        <DocRow margin="lg">
          <DocHeading>Basic</DocHeading>
          <DocParagraph>
            This example renders a modal with minimal default props.
          </DocParagraph>
          <PlxsButtonGroup>
            <PlxsButton
              dataTestId="basic-modal--button"
              onClick={handleOpen}
              label="Open"
            />
          </PlxsButtonGroup>
          <PlxsModal
            dataTestId="basic-modal"
            heading="Modal heading"
            isOpen={isOpen}
            onClose={handleClose}
            primaryButton={<PrimaryButton onClick={handleClose} />}
          >
            <p>React node with modal content</p>
          </PlxsModal>
        </DocRow>
      </DocRow>
    </Fragment>
  );
};

export default Basic;
