import React, { FC } from 'react';

import { DocHeading, DocRow } from '@dev/components';
import { PlxsSearch } from '@plexus-ui/index';

export const SearchWithError: FC = () => {
  return (
    <DocRow margin="md">
      <DocHeading>Error state</DocHeading>
      <PlxsSearch
        dataTestId="search-with-error"
        renderSelectedOptionValue={(_) => ''}
        initialValue={[]}
        onSelect={() => {}}
        optionList={[]}
        renderOption={(_) => null}
        tooltipTextKey="email"
        onChange={() => {}}
        isLoading={false}
        label="Search User name"
        placeholder="Type user name here"
        labelVariant="static"
        errorMessage="something went wrong"
        hasError
      />
    </DocRow>
  );
};
