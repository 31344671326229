import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    borderTop: '1px solid #e0e0e3',
    bottom: 0,
    left: 0,
    padding: '20px',
    position: 'absolute',
    right: 0,
  },
  text: {
    fontSize: '13px',
  },
});
