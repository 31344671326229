import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Text: FC<ComponentProps> = ({ children, isBold }) => {
  const classes = useStyles({ isBold });
  return <span className={classes.root}>{children}</span>;
};

export default Text;
