import { makeStyles } from '@material-ui/core/styles';

import { StyleProps } from './ContentSplash.types';

export const useStyles = makeStyles({
  root: ({ alignCenter, maxWidth }: StyleProps) => ({
    marginLeft: alignCenter ? 'auto' : undefined,
    marginRight: alignCenter ? 'auto' : undefined,
    maxWidth,
  }),
});
