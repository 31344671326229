import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

import {
  BasicContent1,
  BasicContent2,
  BasicContent3,
} from './_internal/BasicContent';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Basic</DocHeading>
      <PlxsTabProvider ariaLabel="Example" dataTestId="basic" height={250}>
        <PlxsTabContent id="basic-tab-1" tabLabel="Tab one">
          <BasicContent1 />
        </PlxsTabContent>
        <PlxsTabContent id="basic-tab-2" tabLabel="Tab two">
          <BasicContent2 />
        </PlxsTabContent>
        <PlxsTabContent id="basic-tab-3" tabLabel="Tab three">
          <BasicContent3 />
        </PlxsTabContent>
        { null }
      </PlxsTabProvider>
    </DocRow>
  </DocRow>
);
