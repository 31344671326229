import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../styles/text';

import { StyleProps } from './LegacyButton.types';

const activeStyles = {
  backgroundColor: '#e6e6e6',
  border: '1px solid #9c9c9c',
  boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 13%)',
};

const baseStyles = {
  backgroundColor: '#ffffff',
  border: '1px solid #bbbbbb',
  boxShadow: 'none',
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    '-webkit-font-smoothing': 'inherit',
    '-moz-osx-font-smoothing': 'inherit',
    '-webkit-appearance': 'none',
    borderRadius: 3,
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    margin: 0,
    overflow: 'visible',
    padding: '5px 10px',
    position: 'relative',
    width: 'auto',
    // Maintain order below for inheritance
    ...defaultTextStyle,
    ...(props.isActive ? activeStyles : baseStyles),
    color: 'inherit',
    lineHeight: '1.5',
    '&:active': {
      ...activeStyles,
    },
    '&:focus': {
      outline: '0',
    },
    '&:hover': {
      backgroundColor: '#e6e6e6',
      borderColor: '#9c9c9c',
    },
  }),
  caret: {
    borderTop: '4px dashed',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    display: 'inline-block',
    height: 0,
    marginLeft: '4px',
    verticalAlign: 'middle',
    width: 0,
  },
}));
