import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

import { getTextStyle, inheritedTextStyle } from '../../styles/text';

import { StyleProps, Variant } from './Heading.types';

const variantStyles: Record<Variant, CSSProperties> = {
  h1: {
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 400,
  },
  h2: {
    fontSize: '26px',
    lineHeight: '36px',
    fontWeight: 600,
  },
  h3: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  h4: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  h5: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  h6: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  legacy: {
    fontSize: '21px',
    lineHeight: '29px',
    fontWeight: 600,
  },
  inherit: inheritedTextStyle,
};

export const useStyles = makeStyles(() => ({
  root: ({ align, color, variant, weight }: StyleProps) => ({
    marginTop: 0,
    marginBottom: 0,
    ...variantStyles[variant],
    ...getTextStyle({ align, color, weight }),
  }),
}));
