import React, { FC, Fragment } from 'react';
import {
  DocBox,
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
} from '@dev/components';
import {
  PlxsContentSplash,
  PlxsHeading,
  PlxsRow,
  PlxsText,
  PlxsTextLink,
} from '@plexus-ui/index';

const props = `interface ComponentProps {
  alignCenter?: boolean;
  children?: ReactNode;
  heading?: string;
  maxWidth?: number;
  spotlightIcon?: SpotlightIcon;
}`;

const ParentComponent: FC = ({ children }) => {
  return (
    <DocBox>
      <PlxsHeading element="h2">Page heading</PlxsHeading>
      <PlxsRow spaceBelow="lg">
        <PlxsText size="md">Content above content splash area</PlxsText>
      </PlxsRow>
      <PlxsRow spaceBelow="lg">{children}</PlxsRow>
      <PlxsText size="md">Content below content splash area</PlxsText>
    </DocBox>
  );
};

const ContentSplash: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocParagraph margin="lg">
        The ContentSplash component renders a styled message. To be used for
        empty content areas or when content has failed to load.
      </DocParagraph>

      <DocRow margin="lg">
        <DocHeading>Props</DocHeading>
        <DocCode>{props}</DocCode>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <DocParagraph margin="md">
          If no props are specified the component will display default
          messaging.
        </DocParagraph>
        <ParentComponent>
          <PlxsContentSplash />
        </ParentComponent>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Center aligned</DocHeading>
        <DocParagraph margin="md">
          Centers the component within the parent container.
        </DocParagraph>
        <ParentComponent>
          <PlxsContentSplash alignCenter={true} />
        </ParentComponent>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Custom messaging</DocHeading>
        <DocParagraph margin="md">
          The message can be customised independently. In the example below; a
          message with retry link has been provided as children.
        </DocParagraph>
        <ParentComponent>
          <PlxsContentSplash>
            <Fragment>
              We are unable to complete your request{' '}
              <PlxsTextLink>please retry.</PlxsTextLink>
            </Fragment>
          </PlxsContentSplash>
        </ParentComponent>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Fully customised</DocHeading>
        <DocParagraph margin="md">
          Each component element can be customised. In the example below; the
          heading, spotlight icon and message have all been customised.
        </DocParagraph>
        <ParentComponent>
          <PlxsContentSplash heading="No users" spotlightIcon="user">
            <Fragment>
              You currently have no users assigned to this role. Search and add
              users from you contact list above.
            </Fragment>
          </PlxsContentSplash>
        </ParentComponent>
      </DocRow>
    </DocRow>
  );
};

export default ContentSplash;
