import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Row: FC<ComponentProps> = ({ children, margin }) => {
  const classes = useStyles({ margin });
  return <div className={classes.root}>{children}</div>;
};

export default Row;
