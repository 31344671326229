import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import {
  PlxsButton,
  PlxsButtonGroup,
  PlxsHeading,
  PlxsRadioButtonGroup,
  PlxsRow,
  PlxsTextField,
} from '@plexus-ui/index';

import AutoCompleteExample from './_internal/AutoCompleteExample';
import CheckboxExample from './_internal/CheckboxExample';
import SelectExample from './_internal/SelectExample';
import TextareaExample from './_internal/TextareaExample';

const SandboxForm: FC = () => {
  const [value, setValue] = useState('');

  const [checkedSeason, setCheckedSeason] = useState('summer');
  const [hasError, setHasError] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleErrorToggle = () => {
    setHasError(!hasError);
  };

  return (
    <Fragment>
      <PlxsRow spaceBelow="lg">
        <PlxsHeading element="h2">States</PlxsHeading>
      </PlxsRow>

      <PlxsRow spaceBelow="lg">
        <PlxsButton
          dataTestId="toggle-error"
          label="Toggle error"
          onClick={handleErrorToggle}
          tone="neutral"
          variant="secondary"
        />
      </PlxsRow>

      <PlxsRow spaceBelow="lg">
        <PlxsHeading element="h2">Example form</PlxsHeading>
      </PlxsRow>

      <PlxsTextField
        dataTestId="dynamic-label-text-field"
        errorMessage="Error text to go here"
        hasError={hasError}
        label="Input name to go here"
        onChange={handleChange}
        placeholder="Field helper text"
        value={value}
      />

      <AutoCompleteExample hasError={hasError} />

      <SelectExample hasError={hasError} />

      <TextareaExample hasError={hasError} />

      <PlxsRow spaceBetween="xl">
        <div style={{ width: '50%' }}>
          <PlxsTextField
            dataTestId="dynamic-label-text-field"
            errorMessage="Error text to go here"
            hasError={hasError}
            label="Input name to go here"
            onChange={handleChange}
            placeholder="Field helper text"
            value={value}
          />
        </div>

        <div style={{ width: '50%' }}>
          <PlxsTextField
            dataTestId="dynamic-label-text-field"
            errorMessage="Error text to go here"
            hasError={hasError}
            instructionText="Additional instruction text to go here."
            label="Input name to go here"
            onChange={handleChange}
            placeholder="Field helper text"
            secondaryLabel="View help article"
            secondaryLabelUrl="#"
            value={value}
          />
        </div>
      </PlxsRow>

      <PlxsRow spaceBetween="xl">
        <div style={{ width: '50%' }}>
          <PlxsTextField
            dataTestId="dynamic-label-text-field"
            errorMessage="Error text to go here"
            hasError={hasError}
            instructionText="Additional instruction text to go here."
            label="Input name to go here"
            labelVariant="dynamic"
            onChange={handleChange}
            placeholder="Field helper text"
            value={value}
          />
        </div>

        <div style={{ width: '50%' }}>
          <PlxsTextField
            dataTestId="dynamic-label-text-field"
            errorMessage="Error text to go here"
            hasError={hasError}
            label="Input name to go here"
            labelVariant="dynamic"
            onChange={handleChange}
            placeholder="Field helper text"
            value={value}
          />
        </div>
      </PlxsRow>

      <PlxsRadioButtonGroup
        autoFocus={true}
        value={checkedSeason}
        dataTestId="radio-button-default"
        errorMessage="Error text to go here"
        hasError={hasError}
        label="What is your favourite season?"
        name="season"
        onChange={setCheckedSeason}
        options={[
          { value: 'spring', label: 'Spring' },
          { value: 'summer', label: 'Summer' },
          { value: 'autumn', label: 'Autumn' },
          { value: 'winter', label: 'Winter' },
        ]}
      />

      <PlxsRow spaceBelow="md">
        <CheckboxExample hasError={hasError} />
      </PlxsRow>

      <PlxsTextField
        dataTestId="dynamic-label-text-field"
        errorMessage="Error text to go here"
        hasError={hasError}
        label="Input name to go here"
        labelVariant="dynamic"
        onChange={handleChange}
        placeholder="Field helper text"
        value={value}
      />

      <PlxsButtonGroup align="right">
        <PlxsButton
          dataTestId="large-button"
          label="Cancel"
          tone="neutral"
          variant="secondary"
        />
        <PlxsButton dataTestId="large-button" label="Submit" />
      </PlxsButtonGroup>
    </Fragment>
  );
};

export default SandboxForm;
