import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import SimpleItems from './SimpleItems';
import ComplexItems from '@dev/docs/_internal/MenuList/ComplexItems';

const menuListProps = `interface ComponentProps {
  autoFocus?: boolean;
  children: ReactNode;
  dataTestId?: string;
  id?: string;
  onKeyExit: () => void;
  onSelect: (itemValue: number) => void;
}`;

const menuItemProps = `interface ComponentProps {
  children?: ReactNode;
  hasBorderRadius?: boolean;
  isDisabled?: boolean;
  isDivider?: boolean;
  isHighlighted?: boolean;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  tone?: Tone;
  value?: number;
}`;

const usage = `<MenuList onKeyExit={handleKeyExit} onSelect={handleSelect}>
  <MenuItem key={1} value={1}>item1</MenuItem>
  <MenuItem key={2} value={2}>item2</MenuItem>
  <MenuItem key={3} isDivider />
  <MenuItem key={4} value={3}>item3</MenuItem>
</MenuList>
`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The MenuList (and MenuItem) provide building blocks for more complex
            components such as the select and menu.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>MenuList Props</DocHeading>
          <DocCode>{menuListProps}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>MenuItem Props</DocHeading>
          <DocCode>{menuItemProps}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocRow margin="sm">
            <DocSubHeading>Default</DocSubHeading>
            <DocParagraph>
              Build a simple list with MenuList and MenuItem. To add a divider,
              simply add a MenuItem with an isDivider prop.
            </DocParagraph>
            <DocCode>{usage}</DocCode>
          </DocRow>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Examples</DocHeading>
          <SimpleItems />
          <ComplexItems />
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
