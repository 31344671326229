import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsTextarea } from '@plexus-ui/index';

interface ComponentProps {
  hasError?: boolean;
}

const TextareaExample: FC<ComponentProps> = ({ hasError }) => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextarea
      dataTestId="default-textarea"
      errorMessage="Error text to go here"
      hasError={hasError}
      label="Input name to go here"
      onChange={handleChange}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default TextareaExample;
