import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Invite: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg height="80" width="84" xmlns="http://www.w3.org/2000/svg">
    <circle cx="54" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M48.4 28.33L1.27 55.45a2.42 2.42 0 00.21 4.36l10.86 4.57 29.15-25.69a.58.58 0 01.82.81L17.93 69.26v8.22a2.41 2.41 0 004.27 1.63l6.5-7.92 12.59 5.38a2.43 2.43 0 003.35-1.93L52 30.77a2.36 2.36 0 00-3.6-2.44z"
    />
  </svg>
);

export default Invite;
