import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    '& > div:last-child': {
      flexGrow: 1,
    },
  },
  active: {
    padding: '4px 44px 4px 12px',
    '& input': {
      paddingBottom: '4px',
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingTop: '4px',
    },
  },
});
