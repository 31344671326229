import { makeStyles } from '@material-ui/core/styles';

import { breakpoints } from '@plexus-ui/styles';
import { getTextStyle } from '@plexus-ui/styles/text';
import { neutral } from '@plexus-ui/styles/color';

import { StyleProps } from './types';

const activeColor = neutral[200];
const baseColor = neutral[500];
const baseBorderColor = neutral[600];
const hoverColor = neutral[300];

export const useStyles = makeStyles(() => ({
  tab: ({ isActive }: StyleProps) => ({
    alignItems: 'center',
    display: 'flex',
    padding: '4px 16px 8px',
    position: 'relative',
    '&:hover': {
      cursor: isActive ? 'default' : 'pointer',
      '& $tabBorder': {
        backgroundColor: isActive ? activeColor : hoverColor,
      },
      '& $tabLabel': {
        color: isActive ? activeColor : hoverColor,
      },
    },
  }),
  tabBorder: ({ isActive }: StyleProps) => ({
    backgroundColor: isActive ? activeColor : baseBorderColor,
    bottom: '0',
    height: '2px',
    left: '0',
    position: 'absolute',
    right: '0',
  }),
  tabLabel: ({ isActive }: StyleProps) => ({
    ...getTextStyle({ size: 'md' }),
    color: isActive ? activeColor : baseColor,
    fontWeight: 600,
    minWidth: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  }),
  firstTab: {
    marginRight: '8px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginRight: '24px',
    },
  },
  lastTab: {
    marginLeft: '8px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginLeft: '24px',
    },
  },
  otherTab: {
    marginLeft: '8px',
    marginRight: '8px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginLeft: '24px',
      marginRight: '24px',
    },
  },
}));
