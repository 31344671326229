import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import { Fade } from '@material-ui/core';
import { usePopper } from 'react-popper';
import { spacing } from '../../../styles';
import { ModifierPhases } from '@popperjs/core';
import { useClickAway } from '../../../hooks';

const Popover: FC<ComponentProps> = ({
  anchorElement,
  children,
  dataTestId,
  id,
  isOpen,
  onClickAway,
  onCreated,
  onFlip,
  onTransitionedIn,
  onTransitionedOut,
  placement,
  placementOffset = [0, spacing.sm],
  TransitionComponent = Fade,
  transitionModifiers,
  fullWidth = false,
}) => {
  const classes = useStyles({ fullWidth });
  const ref = useRef<HTMLDivElement>(null);
  const [currentPlacement, setCurrentPlacement] = useState(placement);
  const [popperElement, setPopperElement] = useState(null);

  const onFlipModifier = useMemo(() => {
    if (onFlip) {
      return {
        name: 'onFlip',
        enabled: true,
        phase: 'main' as ModifierPhases,
        fn: ({ state }) => {
          const newPlacement = state.placement;
          if (currentPlacement !== newPlacement) {
            const isOriginalPlacement =
              newPlacement === state.options.placement;
            onFlip(isOriginalPlacement, newPlacement);
            setCurrentPlacement(newPlacement);
          }
        },
      };
    }
    return { name: 'flip', enabled: false };
  }, [onFlip, currentPlacement]);

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: { offset: placementOffset } },
      onFlipModifier,
    ],
    onFirstUpdate: onCreated,
  });

  useClickAway(ref, onClickAway);

  useEffect(() => setCurrentPlacement(placement), [placement]);

  return (
    <div id={id} data-testid={dataTestId} ref={ref}>
      <div
        className={classes.popover}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <TransitionComponent
          in={isOpen}
          mountOnEnter
          onEntered={onTransitionedIn}
          onExited={onTransitionedOut}
          timeout={100}
          unmountOnExit
          {...transitionModifiers}
        >
          {children}
        </TransitionComponent>
      </div>
    </div>
  );
};

export default Popover;
