import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const ToneBg: FC<ComponentProps> = ({ children, hasHover, tone }) => {
  const classes = useStyles({ hasHover, tone });
  return <div className={classes.root}>{children}</div>;
};

export default ToneBg;
