import React, { FC, Fragment, useState } from 'react';
import { DocHeading, DocParagraph, DocRow } from '@dev/components';
import { PlxsButton, PlxsButtonGroup, PlxsModal } from '@plexus-ui/index';

import PrimaryButton from './_internal/PrimaryButton';

const BasicExtended: FC = () => {
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <DocRow margin="xxl">
        <DocRow margin="lg">
          <DocHeading>Basic extended</DocHeading>
          <DocParagraph>
            This example extends the basic implementation with fixed width: 600,
            subheading: (string) and variant: white.
          </DocParagraph>
          <PlxsButtonGroup>
            <PlxsButton
              dataTestId="basic-modal--button"
              onClick={handleOpen}
              label="Open"
            />
          </PlxsButtonGroup>
          <PlxsModal
            dataTestId="basic-modal"
            fixedWidth={600}
            heading="Modal heading"
            isOpen={isOpen}
            onClose={handleClose}
            primaryButton={<PrimaryButton onClick={handleClose} />}
            subheading="Modal subheading"
            variant="white"
          >
            <p>React node with modal content</p>
          </PlxsModal>
        </DocRow>
      </DocRow>
    </Fragment>
  );
};

export default BasicExtended;
