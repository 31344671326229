import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  prop: {
    marginBottom: '40px',
    '& h3': {
      color: '#808090',
      marginBottom: '10px',
    },
    '& p': {
      marginBottom: '10px',
    },
  },
  bold: {
    fontWeight: 700,
  },
});
