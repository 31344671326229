import React, { FC } from 'react';
import PlxsChip from '../../Chip';

import { BRAND, NEUTRAL } from '../../../_constants';
import { ChipTone } from '../../../_types';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const IndicatorLabel: FC<ComponentProps> = ({ isActive, label, tone }) => {
  const classes = useStyles();

  if (!label) {
    return null;
  }

  let chipTone: ChipTone = isActive ? BRAND : NEUTRAL;
  let formattedLabel = label;

  const labelAsNumber = Number(label);

  if (!isNaN(labelAsNumber) && labelAsNumber > 999) {
    formattedLabel = `+999`;
  }

  if (tone) {
    chipTone = tone;
  }

  return (
    <div className={classes.wrapper}>
      <PlxsChip label={formattedLabel} tone={chipTone} />
    </div>
  );
};

export default IndicatorLabel;
