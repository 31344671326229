import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../styles/text';
import { getInputStyle } from '../../styles/input';

import { StyleProps } from './Input.types';

const calculateInputPaddingRight = (maxLength: number | undefined) => {
  if (!maxLength) {
    return '15px';
  }
  const basePadding = 46;
  const characterLength = String(maxLength).length;
  const characterWidth = 8;
  return `${basePadding + characterWidth * characterLength}px`;
};

export const useStyles = makeStyles(() => ({
  counter: {
    display: 'flex',
    position: 'absolute',
    right: '15px',
  },
  input: ({ maxLength }: StyleProps) => ({
    ...defaultTextStyle,
    ...getInputStyle({}),
    cursor: 'text',
    paddingTop: '10px',
    paddingRight: calculateInputPaddingRight(maxLength),
    paddingBottom: '10px',
    paddingLeft: '15px',
    position: 'relative',
  }),
  inputWrapper: {
    alignItems: 'center',
    display: 'inline-flex',
    position: 'relative',
  },
  text: ({ maxLength }: StyleProps) => ({
    ...defaultTextStyle,
    ...getInputStyle({}),
    display: 'inline-block',
    minWidth: '320px',
    paddingTop: '10px',
    paddingRight: calculateInputPaddingRight(maxLength),
    paddingBottom: '10px',
    paddingLeft: '15px',
    position: 'absolute',
    opacity: 0,
    width: 'auto',
    whiteSpace: 'pre',
  }),
  wrapper: {
    position: 'relative',
    right: '0',
    top: '0',
  },
}));
