import React from 'react';
import { DocBox, DocHeading, DocRow } from '@dev/components';
import { PlxsButton, PlxsButtonGroup } from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Left alignment</DocHeading>
      <DocBox>
        <PlxsButtonGroup>
          <PlxsButton
            dataTestId="button"
            label="Submit"
            size="md"
            variant="primary"
          />
          <PlxsButton
            dataTestId="button"
            label="Cancel"
            size="md"
            variant="secondary"
          />
        </PlxsButtonGroup>
      </DocBox>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Center alignment</DocHeading>
      <DocBox>
        <PlxsButtonGroup align="center">
          <PlxsButton
            dataTestId="button"
            label="Submit"
            size="md"
            variant="primary"
          />
          <PlxsButton
            dataTestId="button"
            label="Cancel"
            size="md"
            variant="secondary"
          />
        </PlxsButtonGroup>
      </DocBox>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Right alignment</DocHeading>
      <DocBox>
        <PlxsButtonGroup align="right">
          <PlxsButton
            dataTestId="button"
            label="Cancel"
            size="md"
            variant="secondary"
          />
          <PlxsButton
            dataTestId="button"
            label="Submit"
            size="md"
            variant="primary"
          />
        </PlxsButtonGroup>
      </DocBox>
    </DocRow>
  </DocRow>
);
