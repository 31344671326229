import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';

import Other from './Other';
import States from './States';
import Variants from './Variants';

const props = `interface ComponentProps {
  autoFocus?: boolean;
  dataTestId: string;
  errorMessage?: string;
  hasError?: boolean;
  id?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
  instructionText?: string;
  isDisabled?: boolean;
  isResizable?: boolean;
  label: string;
  labelVariant?: 'dynamic' | 'hidden' | 'static';
  onBlur?: () => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  padding?: 'sm' | 'md';
  placeholder?: string;
  secondaryLabel?: string;
  secondaryLabelUrl?: string;
  value: string;
}`;

const usage = `import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsTextarea } from '@plexus-ui/index';

const DefaultTextarea: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextarea
      dataTestId="default-textarea"
      label="Input name to go here"
      onChange={handleChange}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default DefaultTextarea;`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The Textarea component renders a multi line textarea element.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocCode>{usage}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Examples</DocHeading>
          <DocParagraph>
            Interact with the textareas below to view Active/Focus and Filled
            states.
          </DocParagraph>
        </DocRow>

        <States />
        <Variants />
        <Other />
      </DocRow>
    </DocRow>
  );
};
