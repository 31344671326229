import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  header: {
    backgroundColor: '#002040',
    color: '#ffffff',
    margin: '48px 0 32px',
    padding: '8px 16px',
  },
  heading: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'uppercase',
  },
});
