import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../../styles/text';

export const useStyles = makeStyles(() => ({
  root: {
    ...defaultTextStyle,
    listStyleType: 'none',
    padding: '0',
    '&:focus': {
      outline: 'none',
    },
  },
}));
