import React, { FC } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import { brand } from '../../styles/color';

import { useStyles } from './Confetti.styles';
import { ComponentProps } from './Confetti.types';

const PlxsConfetti: FC<ComponentProps> = ({
  recycle = false,
  enabled = true,
  onConfettiComplete,
}) => {
  const classes = useStyles();
  const { width, height } = useWindowSize();

  const numberOfPieces = 400;
  const friction = 0.99;
  const wind = 0;
  const gravity = 0.1;
  const initialVelocityX = 4;
  const initialVelocityY = 10;
  //Below is the default colors.  The ones specified are the Plexus Blue colors confirmed with Gemma.
  //const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548'];
  const colors = [
    brand[600],
    brand[500],
    brand[400],
    brand[300],
    brand[200],
    brand[100],
  ];
  const opacity = 1;

  return (
    <div className={classes.root}>
      <Confetti
        width={width}
        height={height}
        numberOfPieces={enabled ? numberOfPieces : 0}
        friction={friction}
        wind={wind}
        gravity={gravity}
        initialVelocityX={initialVelocityX}
        initialVelocityY={initialVelocityY}
        colors={colors}
        opacity={opacity}
        recycle={recycle}
        onConfettiComplete={(confetti) => {
          if (!recycle) {
            if (onConfettiComplete) {
              onConfettiComplete();
            }
            enabled = false;
            confetti.reset();
          }
        }}
      />
    </div>
  );
};

export default PlxsConfetti;
