import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

import {
  BasicContent1,
  BasicContent2,
  BasicContent3,
} from './_internal/BasicContent';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>With Icon</DocHeading>
      <PlxsTabProvider ariaLabel="Icon Example" dataTestId="icon" height={250}>
        <PlxsTabContent
          id="icon-tab-1"
          tabIcon="bulletedList"
          tabLabel="Tab one"
        >
          <BasicContent1 />
        </PlxsTabContent>
        <PlxsTabContent
          id="icon-tab-2"
          tabIcon="bulletedList"
          tabLabel="Tab two"
        >
          <BasicContent2 />
        </PlxsTabContent>
        <PlxsTabContent
          id="icon-tab-3"
          tabIcon="bulletedList"
          tabLabel="Tab three"
        >
          <BasicContent3 />
        </PlxsTabContent>
      </PlxsTabProvider>
    </DocRow>
  </DocRow>
);
