import { makeStyles } from '@material-ui/core/styles';

import { toneBg } from '../../../styles/tone';

import { StyleProps } from './types';

export const useStyles = makeStyles({
  messagePanel: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: toneBg[props.tone],
    borderRadius: '3px',
    padding: '8px 16px',
    ...(props.styleOverrides && props.styleOverrides.css),
  }),
  icon: {
    display: 'flex',
    marginRight: '8px',
  },
  content: {
    width: '100%',
  },
  dismiss: {
    display: 'flex',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    marginLeft: '8px',
  },
});
