import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import { matchSorter } from 'match-sorter';
import ReactAutosuggest from 'react-autosuggest';

import { ComponentProps } from '../AutoComplete.types';
import { maxMenuListHeightDefault } from '../_constants';
import { useStyles } from '../AutoComplete.styles';
import Dropdown from '../_internal/Dropdown';
import FieldWrapper from '../_internal/FieldWrapper';

const AutoCompleteSelect: FC<ComponentProps> = (props) => {
  const {
    autoFocus,
    dataTestId,
    dropdownFooter,
    getOptionValue,
    hasError,
    id,
    initialValue,
    inputRef,
    isDefaultActive = false,
    isDisabled,
    isInline = true,
    matchKeys,
    maxMenuListHeight = maxMenuListHeightDefault,
    onBlur,
    onFocus,
    onSelect,
    optionList,
    renderOption,
    matchByOption,
  } = props;

  const classes = useStyles({ isDisabled });

  const autoCompleteDataTestId = `${dataTestId}--auto-complete`;

  const getInitialValue = () => {
    if (initialValue) {
      return getOptionValue ? getOptionValue(initialValue) : initialValue;
    }
    return '';
  };

  const [_optionList, setOptionList] = useState(optionList);
  const [isActive, setActive] = useState(isDefaultActive);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState(getInitialValue());

  const getSuggestionValue = (suggestion: any) => {
    return getOptionValue ? getOptionValue(suggestion) : suggestion;
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setActive(false);
    onBlur && onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, { newValue }) => {
    setValue(newValue);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setActive(true);
    onFocus && onFocus(event);
  };

  const onSuggestionSelected = (event: FormEvent, { suggestion }) => {
    onSelect(suggestion);
  };

  const onSuggestionsFetchRequested = ({ reason, value }) => {
    if (reason === 'input-focused' && value !== '') {
      setValue('');
      onSelect(undefined);
    }
    const options = matchByOption
      ? { threshold: matchSorter.rankings[matchByOption] }
      : null;
    const suggestions = matchSorter(_optionList, value, {
      keys: matchKeys,
      ...options,
    });
    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion: any) => {
    return renderOption ? renderOption(suggestion) : suggestion;
  };

  const renderSuggestionsContainer = ({ children, containerProps }) => {
    return (
      children && (
        <Dropdown
          containerProps={containerProps}
          dataTestId={autoCompleteDataTestId}
          dropdownFooter={dropdownFooter}
          hasError={hasError}
          isActive={isActive}
          isDisabled={isDisabled}
          maxMenuListHeight={maxMenuListHeight}
        >
          {children}
        </Dropdown>
      )
    );
  };

  useEffect(() => {
    if (optionList) {
      setOptionList(optionList);
    }
  }, [optionList]);

  const inputProps = {
    autoFocus,
    'data-testid': `${autoCompleteDataTestId}--input`,
    disabled: isDisabled,
    id,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    ref: inputRef,
    value,
  };

  const hasValue = Boolean(value);
  const isDynamicLabelMinimised = hasValue || isActive;
  const isOpen = hasValue && Boolean(suggestions.length);

  return (
    <FieldWrapper
      {...props}
      isActive={isActive}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      isOpen={isOpen}
    >
      <ReactAutosuggest
        getSuggestionValue={getSuggestionValue}
        highlightFirstSuggestion={true}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        theme={classes}
      />
    </FieldWrapper>
  );
};

export default AutoCompleteSelect;
