import React, { FC } from 'react';
import { ComponentProps } from './Tag.types';
import { useStyles } from './Tag.styles';

const Tag: FC<ComponentProps> = ({ label, variant = 'none' }) => {
  const classes = useStyles({ variant });
  return <div className={classes.root}>{label}</div>;
};

export default Tag;
