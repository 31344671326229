import React from 'react';
import { DocBox, DocHeading, DocRow } from '@dev/components';
import { PlxsColumn, PlxsRow } from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Row with alignX left (default)</DocHeading>
      <PlxsRow>
        <DocBox />
      </PlxsRow>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Row with alignX center</DocHeading>
      <PlxsRow alignX="center">
        <DocBox />
      </PlxsRow>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Row with alignX right</DocHeading>
      <PlxsRow alignX="right">
        <DocBox />
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>Row with alignY top (default)</DocHeading>
      <PlxsRow>
        <PlxsColumn>
          <DocBox />
        </PlxsColumn>
        <PlxsColumn>
          <DocBox />
          <DocBox />
        </PlxsColumn>
        <PlxsColumn>
          <DocBox />
        </PlxsColumn>
      </PlxsRow>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Row with alignY center</DocHeading>
      <PlxsRow alignY="center">
        <PlxsColumn>
          <DocBox />
        </PlxsColumn>
        <PlxsColumn>
          <DocBox />
          <DocBox />
        </PlxsColumn>
        <PlxsColumn>
          <DocBox />
        </PlxsColumn>
      </PlxsRow>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Row with alignY bottom</DocHeading>
      <PlxsRow alignY="bottom">
        <PlxsColumn>
          <DocBox />
        </PlxsColumn>
        <PlxsColumn>
          <DocBox />
          <DocBox />
        </PlxsColumn>
        <PlxsColumn>
          <DocBox />
        </PlxsColumn>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>Row with space between children</DocHeading>
      <PlxsRow spaceBetween="lg">
        <DocBox />
        <DocBox />
        <DocBox />
      </PlxsRow>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Multiple rows with space below</DocHeading>
      <PlxsRow spaceBelow="lg">
        <DocBox />
      </PlxsRow>
      <PlxsRow spaceBelow="lg">
        <DocBox />
      </PlxsRow>
      <PlxsRow spaceBelow="lg">
        <DocBox />
      </PlxsRow>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Row with flexbox disabled</DocHeading>
      <PlxsRow isFlex={false}>
        <DocBox />
        <DocBox />
        <DocBox />
      </PlxsRow>
    </DocRow>
  </DocRow>
);
