import { makeStyles } from '@material-ui/core/styles';
import { MaxMenuListHeight } from './Search.types';
import { color, zIndex } from '../../styles';
import { defaultTextStyle, getTextStyle } from '../../styles/text';
import { getTextInputStyles } from '../../styles/input';

interface StyleProps {
  maxMenuListHeight: MaxMenuListHeight;
  isActive: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  hasSelection?: boolean;
}

export const useStyles = makeStyles(() => ({
  '@keyframes listboxIn': {
    '0%': {
      transform: 'scaleY(0)',
      transformOrigin: 'top',
    },
    '100%': {
      transform: 'scaleY(1)',
      transformOrigin: 'top',
    },
  },
  root: (props: StyleProps) => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    minHeight: '40px',
    padding: props.hasSelection ? '4px 44px 4px 12px' : '0 44px 0 0',
  }),
  input: (props: StyleProps) => ({
    ...getTextInputStyles({
      isActive: props.isActive,
      isDisabled: props.isDisabled,
      hasError: props.hasError,
    }),
    display: 'block',
    position: 'relative',
    background: 'none',
    padding: props.hasSelection ? '4px 0px 4px 4px' : '4px 0px 4px 16px',
    border: '1px solid transparent',
    outline: 'none',
    width: 'auto',
    flexGrow: 1,
    '&:focus': {
      outline: 'none',
      border: '1px solid transparent',
    },
  }),
  listbox: (props: StyleProps) => ({
    visibility: props.isActive ? 'visible' : 'hidden',
    zIndex: props.isActive ? zIndex.menu : -1,
    backgroundColor: color.white,
    border: `1px solid ${color.brand}`,
    borderTop: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    left: 0,
    overflow: 'auto',
    position: 'absolute',
    top: '100%',
    width: '100%',
    maxHeight: props.maxMenuListHeight
      ? `${props.maxMenuListHeight}px`
      : '358px',
    padding: '8px 0',
    listStyle: 'none',
    margin: 0,
  }),
  listboxAnimation: {
    animation: '$listboxIn 300ms',
  },
  option: {
    ...defaultTextStyle,
    backgroundColor: color.white,
    padding: '8px 16px',
    cursor: 'pointer',
  },
  emptyOption: {
    ...getTextStyle({
      size: 'sm',
      color: 'disabled',
      weight: 'regular',
      align: 'left',
    }),
    padding: '8px 16px',
  },
  highlightOption: {
    backgroundColor: `${color.brandLightest} !important`,
  },
}));
