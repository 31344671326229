import React from 'react';
import { DocCode, DocRow, DocParagraph, DocSubHeading } from '@dev/components';
import { PlxsButton } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ButtonSize = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Size</DocSubHeading>
        <DocParagraph>
          There are three button sizes; small, medium, large. The medium
          button's width can be extended by adding the isExtraWide prop,
          currently this is prop is only supported on the medium button.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Large</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="large-button"
              label="Large button"
              size="lg"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'size="lg"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Medium</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="medium-button"
              label="Medium button"
              size="md"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'size="md"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Medium wide</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="medium-button"
              isExtraWide={true}
              label="Medium button"
              size="md"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isExtraWide={true} size="md"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Small</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="small-button"
              label="Small button"
              size="sm"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'size="sm"'}</DocCode>
          </div>
        </li>
      </ul>

      <DocRow margin="sm">
        <DocSubHeading>Full width</DocSubHeading>
        <DocParagraph>
          The isFullWidth prop applies 100% width to the button.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Large</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="full-width-large-button"
              isFullWidth={true}
              label="Large button"
              size="lg"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isFullWidth={true} size="lg"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Medium</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="full-width-medium-button"
              isFullWidth={true}
              label="Medium button"
              size="md"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isFullWidth={true} size="md"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Small</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="full-width-small-button"
              isFullWidth={true}
              label="Small button"
              size="sm"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isFullWidth={true} size="sm"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Large with icons</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="full-width-large-with-icons-button"
              decorativeIcon="signature"
              functionalIcon="arrowRight"
              isFullWidth={true}
              label="Large button"
              size="lg"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'decorativeIcon="signature" functionalIcon="arrowRight" isFullWidth={true} size="lg"'
              }
            </DocCode>
          </div>
        </li>
      </ul>

      <DocRow margin="sm">
        <DocSubHeading>Reponsive width</DocSubHeading>
        <DocParagraph>
          The isResponsiveWidth prop applies 100% width to the button on small
          screen (below 768px).
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Large</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="responsive-width-large-button"
              isResponsiveWidth={true}
              label="Large button"
              size="lg"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isResponsiveWidth={true} size="lg"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Medium</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="responsive-width-medium-button"
              isResponsiveWidth={true}
              label="Medium button"
              size="md"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isResponsiveWidth={true} size="md"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Small</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="responsive-width-small-button"
              isResponsiveWidth={true}
              label="Small button"
              size="sm"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isResponsiveWidth={true} size="sm"'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ButtonSize;
