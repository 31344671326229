import React, { useCallback, useState } from 'react';
import { DocParagraph, DocRow } from '@dev/components';

import ListBox from '@plexus-ui/components/_internal/ListBox';
import { PlxsTextLink } from '@plexus-ui/index';

const OPTIONS = [0, 1, 2, 3];
const OPTION_LABELS = [
  'Option 1',
  'Option 2 (duplicated)',
  'Option 3',
  'Option 2 (duplicated)',
];

export default () => {
  const [value, setValue] = useState<number[]>([]);

  const getLabel = useCallback((option: number) => OPTION_LABELS[option], []);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The ListBox component renders a list of selectable options. For now,
            it is implemented using Checkbox components. This is not exactly
            best practise - ideally we'd be using DIVs with the{' '}
            <PlxsTextLink href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role">
              ARIA listbox role
            </PlxsTextLink>
            , which has better accessibility. However, this approach is
            non-trivial and has not been prioritised.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <ListBox
              getLabel={getLabel}
              onChange={setValue}
              options={OPTIONS}
              value={value}
            />
          </DocRow>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
