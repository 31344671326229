import React, { FC, Fragment, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsRadioButtonGroup } from '@plexus-ui/index';
import { Option } from '@plexus-ui/components/RadioButtonGroup/RadioButtonGroup.types';

const RadioButtonGroup: FC = () => {
  const [season, setSeason] = useState<string>();
  const seasons = [
    { value: 'spring', label: 'Spring' },
    { value: 'summer', label: 'Summer' },
    { value: 'autumn', label: 'Autumn' },
    { value: 'winter', label: 'Winter' },
  ];
  const seasonsWithIcons: Option[] = [
    { value: 'spring', label: 'Spring', icon: 'expiry' },
    { value: 'summer', label: 'Summer', icon: 'expiry' },
    { value: 'autumn', label: 'Autumn', icon: 'expiry' },
    { value: 'winter', label: 'Winter', icon: 'expiry' },
  ];

  return (
    <Fragment>
      <DocRow margin="sm">
        <DocHeading>Default</DocHeading>
        <PlxsRadioButtonGroup
          dataTestId="radio-button-default"
          name="season"
          autoFocus
          label="Label"
          secondaryLabel="Secondary label"
          secondaryLabelUrl="#"
          instructionText="Instruction text"
          options={seasons}
          value={season}
          onChange={setSeason}
        />
      </DocRow>
      <DocRow margin="sm">
        <DocHeading>Variants</DocHeading>
        <PlxsRadioButtonGroup
          dataTestId="tile-variant"
          name="tile-variant"
          label="Tile variant"
          variant="tile"
          options={seasons}
          value={season}
          onChange={setSeason}
        />
        <PlxsRadioButtonGroup
          dataTestId="tile-variant-with-icons"
          name="tile-variant-with-icons"
          label="Tile variant, options with icons"
          variant="tile"
          options={seasonsWithIcons}
          value={season}
          onChange={setSeason}
        />
        <PlxsRadioButtonGroup
          dataTestId="tile-variant-disabled"
          name="tile-variant-disabled"
          label="Tile variant, disabled"
          variant="tile"
          isDisabled
          options={seasons}
          value={season}
          onChange={setSeason}
        />
        <PlxsRadioButtonGroup
          dataTestId="default-variant-error"
          name="default-variant-error"
          label="Error"
          hasError
          errorMessage="Error message here"
          options={seasons}
          value={season}
          onChange={setSeason}
        />
        <PlxsRadioButtonGroup
          dataTestId="default-variant-disabled"
          name="default-variant-disabled"
          label="Disabled"
          isDisabled
          options={seasons}
          value={season}
          onChange={setSeason}
        />
        <PlxsRadioButtonGroup
          dataTestId="vertical-variant"
          name="vertical-variant"
          label="Vertical variant"
          variant="vertical"
          options={seasons}
          value={season}
          onChange={setSeason}
        />
      </DocRow>
    </Fragment>
  );
};

export default RadioButtonGroup;
