import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle, getTextStyle } from '../../../styles/text';

import { StyleProps } from './types';
import { VARIANT_HELP } from './constants';

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    display: 'flex',
    ...defaultTextStyle,
    ...getTextStyle({ weight: 'semiBold' }),
    letterSpacing: 'none',
    '& > span': {
      textDecoration: props.variant === VARIANT_HELP ? 'underline' : undefined,
    },
    '& > svg': {
      marginRight: '8px',
      flexShrink: 0,
    },
  }),
}));
