import { DocHeading, DocRow } from '@dev/components';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

import { BasicContent1, BasicContent2 } from './_internal/BasicContent';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Collapsible</DocHeading>
      <PlxsTabProvider
        ariaLabel="Indicator"
        dataTestId="indicator"
        height={250}
        isCollapsible={true}
      >
        <PlxsTabContent
          id="indicator-tab-1"
          tabIndicatorLabel="2"
          tabLabel="Tab one"
        >
          <BasicContent1 />
        </PlxsTabContent>
        <PlxsTabContent
          id="indicator-tab-2"
          tabIndicatorLabel="999"
          tabLabel="Tab two"
          tabIcon="stars"
        >
          <BasicContent2 />
        </PlxsTabContent>
      </PlxsTabProvider>
    </DocRow>
  </DocRow>
);
