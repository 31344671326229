import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  description: {
    marginLeft: '10px',
  },
  displayName: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    marginBottom: '8px',
  },
  displayNameLight: {
    color: '#ffffff',
  },
  icons: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '8px',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    borderRadius: '4px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    marginRight: '20px',
    minWidth: '160px',
    padding: '10px',
    listStyleType: 'none',
  },
  listItemBackground: {
    background: '#f3f3f4',
  },
  listItemDark: {
    background: '#002040',
  },
  name: {
    color: '#808090',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '11px',
  },
});
