import React from 'react';
import { DocCode, DocParagraph, DocRow } from '@dev/components';
import { PlxsIcon } from '@plexus-ui/index';

import { useStyles } from './styles';
import { ICON_NAMES } from '@plexus-ui/icons/types';

const IconLibrary = () => {
  const classes = useStyles();
  return (
    <DocRow margin="xxl">
      <DocRow margin="md">
        <DocParagraph>
          The list below shows all icons available for use with the Icon
          component. For usage examples and props see Components/Icon.
        </DocParagraph>
        <DocParagraph>
          The three icon sizes displayed are small, large and customSize set to
          24.
        </DocParagraph>
      </DocRow>

      <DocRow margin="md">
        <ul className={classes.list}>
          {ICON_NAMES.map((iconName) => (
            <li className={classes.listItem} key={iconName}>
              <div className={classes.name}>
                <DocCode>{`name="${iconName}"`}</DocCode>
              </div>
              <div className={classes.icons}>
                <PlxsIcon name={iconName} size="sm" />
                <PlxsIcon name={iconName} size="lg" />
                <PlxsIcon customSize={24} name={iconName} />
              </div>
            </li>
          ))}
        </ul>
      </DocRow>
    </DocRow>
  );
};

export default IconLibrary;
