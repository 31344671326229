import React, { FC } from 'react';

import { useStyles } from './styles';

const Heading: FC = ({ children }) => {
  const classes = useStyles();
  return <h3 className={classes.heading}>{children}</h3>;
};

export default Heading;
