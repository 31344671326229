import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

import {
  BasicContent1,
  BasicContent2,
  BasicContent3,
} from './_internal/BasicContent';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>With Icon and Indicator</DocHeading>
      <PlxsTabProvider
        ariaLabel="Icon Indicator"
        dataTestId="iconIndicator"
        height={250}
      >
        <PlxsTabContent
          id="iconIndicator-tab-1"
          tabIcon="bulletedList"
          tabIndicatorLabel="2"
          tabLabel="Tab one"
        >
          <BasicContent1 />
        </PlxsTabContent>
        <PlxsTabContent
          id="iconIndicator-tab-2"
          tabIcon="bulletedList"
          tabIndicatorLabel="63"
          tabLabel="Tab two"
        >
          <BasicContent2 />
        </PlxsTabContent>
        <PlxsTabContent
          id="iconIndicator-tab-3"
          tabIcon="bulletedList"
          tabIndicatorLabel="428"
          tabLabel="Tab three"
        >
          <BasicContent3 />
        </PlxsTabContent>
      </PlxsTabProvider>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>With Icon and Indicator and Tones</DocHeading>
      <PlxsTabProvider
        ariaLabel="Icon Indicator"
        dataTestId="iconIndicatorTone"
        height={250}
      >
        <PlxsTabContent
          id="iconIndicatorTone-tab-1"
          tabIcon="stars"
          tabIconTone="caution"
          tabIndicatorLabel="NEW"
          tabIndicatorTone="positive"
          tabLabel="Tab one"
        >
          <BasicContent1 />
        </PlxsTabContent>
        <PlxsTabContent
          id="iconIndicatorTone-tab-2"
          tabIcon="bulletedList"
          tabIndicatorLabel="EXCITING"
          tabIndicatorTone="caution"
          tabLabel="Tab two"
        >
          <BasicContent2 />
        </PlxsTabContent>
        <PlxsTabContent
          id="iconIndicatorTone-tab-3"
          tabIcon="cross"
          tabIconTone="urgent"
          tabIndicatorLabel="ERROR"
          tabIndicatorTone="urgent"
          tabLabel="Tab three"
        >
          <BasicContent3 />
        </PlxsTabContent>
      </PlxsTabProvider>
    </DocRow>
  </DocRow>
);
