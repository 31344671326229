import React, { ChangeEvent, useState } from 'react';

import {
  getDropdownLabel,
  getDropdownPlaceholder,
  getSelectedIndex,
  isSelection,
} from '../_internal';
import Icon from '../../Icon';
import SelectFieldWrapper from '../_internal/SelectFieldWrapper';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const NativeSelect = <Option extends any>(props: ComponentProps<Option>) => {
  const {
    dataTestId,
    getOptionLabel,
    hasError,
    id,
    isDisabled,
    isDefaultActive = false,
    onIndexSelected,
    options,
    selected,
  } = props;

  const [isActive, setIsActive] = useState(isDefaultActive);

  const hasSelection = isSelection(selected);
  const selectedIndex = getSelectedIndex(props);

  const classes = useStyles({
    hasError,
    hasSelection,
    isActive,
    isDisabled,
  });

  const newItems = options.map((option, index) => (
    <option key={index} value={index} data-testid={`${dataTestId}--item`}>
      {getOptionLabel(option)}
    </option>
  ));

  newItems.unshift(
    <option key="placeholder" disabled value="">
      {getDropdownPlaceholder(props)}
    </option>
  );

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedIndex = Number(event.currentTarget.value);
    onIndexSelected(newSelectedIndex);
  };

  return (
    <SelectFieldWrapper {...props} isActive={isActive}>
      <div className={classes.root}>
        <select
          className={classes.dropDownMenu}
          data-testid={dataTestId}
          disabled={isDisabled}
          id={id}
          onBlur={() => setIsActive(false)}
          onChange={handleChange}
          onFocus={() => setIsActive(true)}
          value={hasSelection ? selectedIndex : ''}
        >
          {newItems}
        </select>
        <label className={classes.selectInput} htmlFor={id}>
          <span>{getDropdownLabel(props)}</span>
          <Icon name="chevronDown" />
        </label>
      </div>
    </SelectFieldWrapper>
  );
};

export default NativeSelect;
