import React, { FC } from 'react';

import { ComponentProps } from './_types';

const PermissionsBasic: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    height="75"
    width="74"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35.6" cy="24" r="23.2" fill={circleFill} />
    <path
      fill={iconFill}
      d="M18.7 39c5.5 0 10-4.5 10-10s-4.5-10-10-10c-5.6 0-10 4.5-10 10-.1 5.5 4.4 10 10 10zm6.9 2.5h-1.3c-1.7.9-3.6 1.3-5.6 1.3s-4-.4-5.7-1.3h-1.3c-5.8 0-10.5 4.8-10.5 10.6v3.2c0 2.1 1.6 3.8 3.8 3.8h27.6c2 0 3.8-1.6 3.8-3.8v-3.2c-.2-5.8-5-10.6-10.8-10.6z"
    />
  </svg>
);

export default PermissionsBasic;
