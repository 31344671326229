import React from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import { SimpleSearch } from './Examples/SimpleSearch';
import { SearchWithButton } from './Examples/SearchWithButton';
import { SearchWithError } from './Examples/SearchWithError';
import { SearchWithDisabled } from './Examples/SearchWithDisabled';
import { EmptyState } from './Examples/EmptyState';
import { DocRow } from '@dev/components';

// description about component Search
const description = [
  `Search component is used to search and select options from a list.
  It is a wrapper around FieldWrapper component.
  It has all the props of FieldWrapper component and some additional props.`,

  `It will search and filter options based on the value entered in the input field by onChange event.
  It will show loading state when isLoading prop is set to true.
  Use this prop to show loading state when fetching data from API.`,

  `The selected options will be rendered as chips and can be removed by clicking on the cross icon.
  It will show tooltip on hover of the chips.`,

  `The options can be selected by clicking on the option from the dropdown list or by pressing enter key.
  You can also use up and down arrow keys to navigate through the options and press enter key to select the option.
  You can also use backspace key to remove the last selected option.`,
];

const example = (
  <DocRow margin="xxl">
    <SimpleSearch />
    <SearchWithButton />
    <SearchWithError />
    <SearchWithDisabled />
    <EmptyState />
  </DocRow>
);

const implementation = `import React, { FC, useState } from 'react';

import { PlxsSearch } from '@plexlogic/plexus-ui';

interface User {
  email: string;
  id?: number;
  name: string;
}

const searchUser = (name: string, exclude: User[]) => {
  // simulate API call user search
  return response;
};

export const SimpleSearch: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState<User[]>();
  const [suggestionUser, setSuggestionUser] = useState<User[]>([]);

  const renderSelectedOptionValue = (option: User) => option.name;

  const handleSelect = (option: User[]) => {
    setSelectedList(option);
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return \`\${name} (\${id ? email : 'External'})\`;
  };

  const handleChange = async (textValue: string, selected: User[]) => {
    setIsLoading(true);
    const response = await searchUser(textValue, selected);
    setIsLoading(false);
    setSuggestionUser(response);
  };

  return (
    <PlxsSearch
      dataTestId="search-simple"
      getOptionValue={getOptionValue}
      initialValue={selectedList}
      label="Search User name"
      labelVariant="dynamic"
      onSelect={handleSelect}
      optionList={suggestionUser}
      renderSelectedOptionValue={renderSelectedOptionValue}
      tooltipTextKey="email"
      onChange={handleChange}
      isLoading={isLoading}
      placeholder='Type user name here'
    />
  );
};`;

const propList = [
  {
    component: 'Search',
    description: 'Provide data-testid for component.',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'Search',
    description: 'Provide function to handle select event.',
    isRequired: true,
    prop: 'onSelect',
    type: '(selected: TOption[]) => void',
  },
  {
    component: 'Search',
    description: 'Provide list of options to select from.',
    isRequired: true,
    prop: 'optionList',
    type: 'TOption[]',
  },
  {
    component: 'Search',
    description: 'Provide renderOption to render option.',
    isRequired: true,
    prop: 'renderOption',
    type: '(option: TOption) => ReactNode',
  },
  {
    component: 'Search',
    description: 'Provide tooltipTextKey to set tooltip text.',
    isRequired: true,
    prop: 'tooltipTextKey',
    type: 'string',
  },
  {
    component: 'Search',
    description: 'Provide onChange to handle change event.',
    isRequired: true,
    prop: 'onChange',
    type: '(textValue: string, selected?: TOption[]) => void',
  },
  {
    component: 'Search',
    description:
      'Provide renderSelectedOptionValue to render selected option value in chips',
    isRequired: true,
    prop: 'renderSelectedOptionValue',
    type: '(option: TOption) => string',
  },
  {
    component: 'Search',
    description: 'Provide initial value.',
    isRequired: false,
    prop: 'initialValue',
    type: 'TOption[]',
  },
  {
    component: 'Search',
    description: 'Provide isDefaultActive to set default active state.',
    isRequired: false,
    prop: 'isDefaultActive',
    type: 'boolean',
  },
  {
    component: 'Search',
    description: 'Provide isDisabled to disable search field.',
    isRequired: false,
    prop: 'isDisabled',
    type: 'boolean',
  },
  {
    component: 'Search',
    description: 'Provide isInline to set search field inline.',
    isRequired: false,
    prop: 'isInline',
    type: 'boolean',
  },
  {
    component: 'Search',
    description: 'Provide maxMenuListHeight to set max height of menu list.',
    isRequired: false,
    prop: 'maxMenuListHeight',
    type: 'MaxMenuListHeight',
  },
  {
    component: 'Search',
    description: 'Provide onBlur to handle blur event.',
    isRequired: false,
    prop: 'onBlur',
    type: '(event: FocusEvent<HTMLInputElement>) => void',
  },
  {
    component: 'Search',
    description: 'Provide onFocus to handle focus event.',
    isRequired: false,
    prop: 'onFocus',
    type: '(event: FocusEvent<HTMLInputElement>) => void',
  },
  {
    component: 'Search',
    description: 'Provide isLoading to set loading state.',
    isRequired: false,
    prop: 'isLoading',
    type: 'boolean',
  },
  {
    component: 'Search',
    description: 'Provide button to render button.',
    isRequired: false,
    prop: 'button',
    type: 'ReactElement',
  },
  {
    component: 'Search',
    description:
      'Provide optionKeyMatch to set default key compare. Default is "id".',
    isRequired: false,
    prop: 'optionKeyMatch',
    type: 'string',
  },
];

const tsInterface = `interface ComponentProps<TOption> extends FieldProps {
  dataTestId: string;
  optionList: TOption[];
  renderOption: (option: TOption) => ReactNode;
  tooltipTextKey: string;
  onChange: (textValue: string, selected?: TOption[]) => void;
  renderSelectedOptionValue: (option: TOption) => string;
  isDefaultActive?: boolean;
  initialValue?: TOption[];
  isDisabled?: boolean;
  isInline?: boolean;
  maxMenuListHeight?: MaxMenuListHeight;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onSelect: (selected: TOption[]) => void;
  isLoading?: boolean;
  button?: ReactElement;
  optionKeyMatch?: string;
}`;

export default () => {
  return (
    <PageTemplate
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
