import { makeStyles } from '@material-ui/core/styles';

import { brand, neutral, urgent } from '../../../styles/color';
import { color } from '../../../styles';

export const useStyles = makeStyles({
  root: {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    border: `1px solid ${neutral[500]}`,
    backgroundColor: color.white,
    margin: '0 !important', // This is needed to override CSS in legal gateway
    position: 'relative',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${neutral[400]}`,
    },
    '&:disabled': {
      background: neutral[800],
      border: `1px solid ${neutral[500]}`,
      cursor: 'not-allowed',
    },
    '&:invalid, &[data-has-error="true"]': {
      border: `1px solid ${urgent[400]}`,
    },
    '&:focus': {
      outline: 'none',
      '&::before': {
        position: 'absolute',
        content: '""',
        top: '-5px',
        left: '-5px',
        height: '32px',
        width: '32px',
        borderRadius: '5px',
        border: `2px solid ${brand[800]}`,
        boxSizing: 'border-box',
      },
    },
    '&:checked': {
      background: brand[400],
      '&:not(:invalid):not([data-has-error="true"])': {
        border: `1px solid ${brand[400]}`,
      },
      '&::after': {
        position: 'absolute',
        content: '""',
        top: '5px',
        left: '5px',
        height: '12px',
        width: '12px',
        background: 'white',
        borderRadius: '50%',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
      },
    },
    '&[type="radio"]:focus': {
      outline: 'none',
    },
  },
});
