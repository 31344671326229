import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsAutoComplete } from '@plexus-ui/index';

import { FunctionalTest } from './Examples/FunctionalTest';
import DropdownFooter, {
  DropdownFooterSnippet,
} from './Examples/DropdownFooter';
import SingleSelectComplex, {
  SingleSelectComplexSnippet,
} from './Examples/SingleSelectComplex';
import SingleSelectSimple, {
  SingleSelectSimpleSnippet,
} from './Examples/SingleSelectSimple';
import MultiSelectComplex, {
  MultiSelectComplexSnippet,
} from './Examples/MultiSelectComplex';
import MultiSelectSimple, {
  MultiSelectSimpleSnippet,
} from './Examples/MultiSelectSimple';
import SingleSelectWordFilter, {
  SingleSelectWordFilterSnippet,
} from './Examples/SingleSelectWordFilter';
import SingleSelectActive, {
  SingleSelectActiveSnippet,
} from './Examples/SingleSelectActive';

const props = `interface ComponentProps extends FieldProps {
  autoFocus?: boolean;
  dataTestId: string;
  dropdownFooter?: ReactNode;
  getOptionValue?: (option: any) => string;
  id?: string;
  initialValue?: any;
  inputRef?: RefObject<HTMLInputElement>;
  isDefaultActive?: boolean;
  isInline?: boolean;
  isMultiSelect?: boolean;
  matchKeys?: string[];
  maxMenuListHeight?: MaxMenuListHeight;
  multiSelectFilter?: (optionList: any[], suggestion: any) => any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onSelect: (value: any) => void;
  optionList: any[];
  renderOption?: (option: any) => ReactNode;
  tooltipTextKey?: string;
}`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The AutoComplete component renders a single line text input with a
            searchable dropdown list. On typing, the list will be filtered to
            show relevant results. The search method and result display is
            customisable.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Functional Test</DocHeading>
          <FunctionalTest />
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Examples</DocHeading>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Single Select - Simple List</DocSubHeading>
            <DocParagraph>
              For basic usage pass an array of strings as optionList.
            </DocParagraph>
          </DocRow>
          <SingleSelectSimple />
          <DocCode>{SingleSelectSimpleSnippet}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Single Select - Complex List</DocSubHeading>
            <DocParagraph>
              For advanced usage pass an array of objects as optionList. To
              customise the display text pass the util function getOptionLabel.
              To customise the searchable text pass an array of strings listing
              the object keys to be included in the search filter.
            </DocParagraph>
          </DocRow>
          <SingleSelectComplex />
          <DocCode>{SingleSelectComplexSnippet}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Multi Select - Missing props</DocSubHeading>
            <DocParagraph>
              Renders an error message if props are missing for the
              isMultiSelect variant.
            </DocParagraph>
          </DocRow>
          <PlxsAutoComplete
            dataTestId="multi-select-missing-props"
            isMultiSelect={true}
            onSelect={() => {}}
            optionList={[]}
          />
          <DocCode>{`<PlxsAutoComplete
  dataTestId="multi-select-missing-props"
  isMultiSelect={true}
  onSelect={() => {}}
  optionList={[]}
/>`}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Multi Select - Simple List</DocSubHeading>
            <DocParagraph>
              Extends the Single Select - Simple List functionality, providing
              multiple selections that render the tooltip and chip components.
            </DocParagraph>
          </DocRow>
          <MultiSelectSimple />
          <DocCode>{MultiSelectSimpleSnippet}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Multi Select - Complex List</DocSubHeading>
            <DocParagraph>
              Extends the Single Select - Complex List functionality, providing
              multiple selections that render the tooltip and chip components.
            </DocParagraph>
          </DocRow>
          <MultiSelectComplex />
          <DocRow margin="sm">
            <DocParagraph>
              <strong>Note:</strong> In the implementation below, id is a
              required key of User interface as it is referenced in
              multiSelectFilter to filter results.
            </DocParagraph>
          </DocRow>
          <DocCode>{MultiSelectComplexSnippet}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Dropdown Footer</DocSubHeading>
            <DocParagraph>
              A ReactNode can be passed to AutoComplete to render a footer
              element inside the dropdown menu. The example below shows how this
              is used to render the Add new contact button.
            </DocParagraph>
          </DocRow>
          <DropdownFooter />
          <DocRow margin="sm">
            <DocParagraph>
              <strong>Note:</strong> In the implementation below, onMouseDown is
              the required event handler for button to ensure this is event
              takes priority over selecting a menu item.
            </DocParagraph>
          </DocRow>
          <DocCode>{DropdownFooterSnippet}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Single Select - Match Word Filter</DocSubHeading>
            <DocParagraph>
              For basic usage pass an array of strings as optionList with filter
              WORD_STARTS_WITH.
            </DocParagraph>
          </DocRow>
          <SingleSelectWordFilter />
          <DocCode>{SingleSelectWordFilterSnippet}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocSubHeading>Single Select - Active by default</DocSubHeading>
            <DocParagraph>
              AutoComplete field is active by default.
            </DocParagraph>
          </DocRow>
          <SingleSelectActive />
          <DocCode>{SingleSelectActiveSnippet}</DocCode>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
