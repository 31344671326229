import { MouseEvent, RefObject, useEffect } from 'react';

/**
 * This little hook is inspired from Material-UI v4 and react-use. In the current form however, both have an issue
 * affecting our current use case. Hence this little hook.
 *
 * - Material-ui v4's `ClickAwayListener` component: there's an issue where if paired it with opening/closing of
 *   Popper, clicking the Popper's trigger element to open the element does not even open it. I actually think it does
 *   but it gets quickly closed again and I believe it's to do with event bubbling issue. There's a few workaround but
 *   they're smelly. The issue is still present in the latest v4.11.4. But might be absent in v5 if we still want
 *   to use it then.
 *
 * - React-use's `useClickAway` hook: This worked quite well for our use case except for one I've seen where if the
 *   target element within the node tree changes its attributes when clicked, the click away handler woudl be
 *   incorrectly triggered. I'm not entirely sure why but in some cases the node tree has not picked the changes of the
 *   target element when it was clicked and thus deduce that the click happened outside.
 *
 *   Since we have now stopped supporting IE11, we can flip the check such that we look at the hierarchy where the
 *   event will be bubbled up and use this to check if the node is in the path. If so, then we've clicked inside. Let's
 *   see if this is enough, if not we can add more checks to it.

 *   https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
 */

export default (
  nodeRef: RefObject<HTMLElement | null>,
  onMouseEvent: (event: MouseEvent) => void | undefined
) => {
  useEffect(() => {
    const currentNode = nodeRef && nodeRef.current;
    const doc = (currentNode && currentNode.ownerDocument) || document;

    const eventHandler = (event) => {
      const happenedOutside =
        currentNode && event.composedPath().indexOf(currentNode) === -1;

      if (happenedOutside) {
        onMouseEvent?.(event);
      }
    };

    doc.addEventListener('click', eventHandler);

    return () => {
      doc.removeEventListener('click', eventHandler);
    };
  }, [nodeRef, onMouseEvent]);
};
