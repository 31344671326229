import { makeStyles } from '@material-ui/core/styles';
import { neutral, brand } from '../../../../styles/color';
import { spacing } from '../../../../styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: {
    height: '16px',
    marginTop: '8px',
  },
  input: {
    width: '24px',
    height: '24px',
    outlineColor: 'transparent',
    opacity: 0,
    margin: '0 !important', // This is needed to override CSS in legal gateway
    '&:focus + span': {
      borderColor: brand[800],
    },
    '& + span > span': {
      borderColor: neutral[500],
      background: 'white',
      '& > svg': {
        color: 'transparent',
        visibility: 'hidden',
        opacity: 0,
      },
    },
    '&:hover + span > span': {
      borderColor: neutral[400],
    },
    '&:checked + span > span': {
      borderColor: brand[400],
      background: brand[400],
      '& > svg': {
        color: 'white',
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  inputOverlay: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    border: 'solid 1px',
    '& > svg': {
      textShadow: '0px 1px 3px rgb(0 0 0 / 80%)',
    },
  },
  label: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    padding: spacing['sm'],
    // The following section is needed to override bootstrap.css in legal-gateway
    margin: 0,
    '& > :not(:last-child)': {
      margin: '0 16px 0 0',
    },
  },
  inputContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
  },
  overlay: {
    width: '35px',
    position: 'absolute',
    top: '-6px',
    right: '-6px',
    bottom: '-6px',
    left: '-6px',
    padding: '3px',
    border: `3px solid transparent`,
    borderRadius: '3px',
  },
  icon: {
    background: 'transparent',
  },
});
