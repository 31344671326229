import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Ghost: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    height="76"
    width="75"
    viewBox="0 0 75 76"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="44.5" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M22.3906 23.6016C11.7266 23.9062 3.5 33.1484 3.5 43.9141V70.625C3.5 72.1484 5.22656 72.8594 6.24219 71.8438L8.78125 69.9141C9.39062 69.4062 10.4062 69.5078 10.9141 70.1172L15.2812 75.0938C15.8906 75.7031 17.0078 75.7031 17.6172 75.0938L21.7812 70.4219C22.3906 69.7109 23.5078 69.7109 24.1172 70.4219L28.2812 75.0938C28.8906 75.7031 30.0078 75.7031 30.6172 75.0938L34.9844 70.1172C35.4922 69.5078 36.5078 69.4062 37.1172 69.9141L39.6562 71.8438C40.6719 72.8594 42.5 72.1484 42.5 70.625V43C42.5 32.0312 33.3594 23.1953 22.3906 23.6016ZM16.5 46.25C14.6719 46.25 13.25 44.8281 13.25 43C13.25 41.2734 14.6719 39.75 16.5 39.75C18.2266 39.75 19.75 41.2734 19.75 43C19.75 44.8281 18.2266 46.25 16.5 46.25ZM29.5 46.25C27.6719 46.25 26.25 44.8281 26.25 43C26.25 41.2734 27.6719 39.75 29.5 39.75C31.2266 39.75 32.75 41.2734 32.75 43C32.75 44.8281 31.2266 46.25 29.5 46.25Z"
    />
  </svg>
);

export default Ghost;
