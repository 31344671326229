import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsIcon, PlxsTextField } from '@plexus-ui/index';

const IconRightTextField: FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextField
      iconRight={
        <PlxsIcon
          name="search"
          size="sm"
          tone={isFocused ? 'brand' : 'neutral'}
        />
      }
      dataTestId="icon-right-text-field"
      label="Input name to go here"
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default IconRightTextField;
