import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { ComponentProps, DateRange } from './DatePicker.types';
import { useStyles } from './DatePicker.styles';
import Popover from '../_internal/Popover';
import Calendar from './Calendar';
import TextInput from './TextInput';
import { CalendarPlacement } from './_types';
import { CALENDAR_BOTTOM } from './_constants';
import {
  getDateOrDateRange,
  getDateString,
  getErrorMessage,
  isDate,
  isDateRange,
  parseInputText,
} from './_utils';
import {
  DATE_FORMAT,
  DATE_FORMAT_US,
  DATE_FORMAT_US_WITH_TIME,
  DATE_FORMAT_WITH_TIME,
} from './DatePicker.constants';
import { EN_AU, EN_US } from '../../_constants';
import { TimePeriodType } from './TimePeriod/TimePeriod.types';

const DatePicker: FC<ComponentProps> = ({
  dataTestId,
  errorMessage,
  hasError,
  id,
  isDefaultActive = false,
  maxDate,
  minDate,
  onSelect,
  onSelectRange,
  selected,
  selectedRange,
  locale = EN_AU,
  mode = 'single',
  isTimeEditable = false,
  ...otherTextInputProps
}) => {
  /**
   * It is very easy to pass strings instead of dates to this component by
   * accident, which will result in exceptionally confusing stack traces.
   * We guard against this case here.
   */
  if (typeof minDate === 'string') {
    throw new Error('minDate must be Date, not string');
  } else if (typeof maxDate === 'string') {
    throw new Error('maxDate must be Date, not string');
  } else if (typeof selected === 'string') {
    throw new Error('selected must be Date or DateRange, not string');
  } else if (isDateRange(selected)) {
    if (typeof selected.from === 'string') {
      throw new Error('selected.from from must be Date, not string');
    } else if (typeof selected.to === 'string') {
      throw new Error('selected.to must be Date, not string');
    }
  }

  /**
   * We have properties like minDate which restrict the values that can be selected (i.e.,
   * passed to the parent component via onSelect). This means we have to distinguish between
   * what the user has *tried* to select (which may be displayed by the control) and what
   * has *actually* been selected (which may be passed to the parent component).
   * We will use requestedValue to represent what the user has tried to select but which
   * is not necessarily valid and therefore not passed to the parent component.
   */
  const [requestedValue, setRequestedValue] = useState<Date | DateRange>();

  const [inputText, setInputText] = useState<string>();
  const [internalErrorMessage, setInternalErrorMessage] = useState<string>();

  const [timePeriod, setTimePeriod] = useState<TimePeriodType>(
    selected ? (selected.getHours() >= 12 ? 'PM' : 'AM') : 'AM'
  );

  const [anchorElement, setAnchorElement] = useState<HTMLInputElement>();

  const [calendarPlacement, setCalendarPlacement] =
    useState<CalendarPlacement>(CALENDAR_BOTTOM);

  const { label, secondaryLabel, instructionText } = otherTextInputProps;
  const textFieldHasTopLabel = Boolean(label) || Boolean(secondaryLabel);
  const textFieldHasBottomLabel = Boolean(instructionText);

  const getDateFormatter = (locale: string) => {
    if (isTimeEditable && mode === 'single') {
      return locale === EN_US
        ? DATE_FORMAT_US_WITH_TIME
        : DATE_FORMAT_WITH_TIME;
    } else {
      return locale === EN_US ? DATE_FORMAT_US : DATE_FORMAT;
    }
  };

  const dateFormat = getDateFormatter(locale);

  const classes = useStyles({
    calendarPlacement,
    textFieldHasTopLabel,
    textFieldHasBottomLabel,
  });
  const inputRef = useRef();
  const isCalendarOpen = Boolean(anchorElement);

  const handleCalendarButtonClick = () => {
    setAnchorElement(isCalendarOpen ? undefined : inputRef.current);
  };

  const handlePopoverClickAway = () => setAnchorElement(undefined);

  const handlePopoverFlip = (_, placement) => setCalendarPlacement(placement);

  const handleInput = useCallback(
    (text: string, value?: Date | DateRange, error?: string) => {
      if (!error) {
        if (mode === 'single' && !isDateRange(value) && onSelect) {
          onSelect(value);
          return;
        }
        if (mode === 'range' && !isDate(value) && onSelectRange) {
          onSelectRange(value);
          return;
        }
      }

      setInputText(text);
      setInternalErrorMessage(error);
      setRequestedValue(value);
    },
    [
      mode,
      onSelect,
      onSelectRange,
      setInputText,
      setInternalErrorMessage,
      setRequestedValue,
    ]
  );

  const handleDateInput = useCallback(
    (inputDate: Date) => {
      const inputValue =
        mode === 'single'
          ? inputDate
          : getDateOrDateRange(requestedValue, inputDate);
      const error = getErrorMessage(inputValue, dateFormat, minDate, maxDate);

      handleInput(getDateString(inputValue, dateFormat), inputValue, error);
    },
    [dateFormat, handleInput, maxDate, minDate, mode, requestedValue]
  );

  const handleTextInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputText = event.target.value;
      const inputValue = parseInputText(inputText, dateFormat, isTimeEditable);
      const error =
        inputText && getErrorMessage(inputValue, dateFormat, minDate, maxDate);

      console.log(`handleTextInput ${inputValue} ${error}`);

      handleInput(inputText, inputValue, error);
    },
    [dateFormat, handleInput, maxDate, minDate]
  );

  useEffect(() => {
    if (mode === 'single') {
      setRequestedValue(selected);
      setInputText(getDateString(selected, dateFormat));
      setInternalErrorMessage(undefined);
      setTimePeriod(selected?.getHours() >= 12 ? 'PM' : 'AM');
    }
  }, [dateFormat, selected, mode, setRequestedValue, setInputText]);

  useEffect(() => {
    if (mode === 'range') {
      if (selectedRange) {
        const fromDateString = getDateString(selectedRange.from, dateFormat);
        const toDateString = getDateString(selectedRange.to, dateFormat);

        setInputText(`${fromDateString} - ${toDateString}`);
      } else {
        setInputText('');
      }

      setRequestedValue(selectedRange);
      setInternalErrorMessage(undefined);
    }
  }, [dateFormat, selectedRange, mode, setRequestedValue]);

  useEffect(() => {
    if (isDefaultActive) {
      setAnchorElement(inputRef.current);
    }
  }, [inputRef, isDefaultActive]);

  const handleTimePeriodChange = (newTimePeriod: TimePeriodType) => {
    if (isDate(requestedValue) && isTimeEditable) {
      const newDate = new Date(requestedValue);
      if (newTimePeriod === 'AM' && newDate.getHours() >= 12) {
        newDate.setHours(newDate.getHours() - 12);
      } else if (newTimePeriod === 'PM' && newDate.getHours() < 12) {
        newDate.setHours(newDate.getHours() + 12);
      }
      onSelect(newDate);
    }
  };

  return (
    <div className={classes.root} data-testid={dataTestId} id={id}>
      <div className={classes.textInputWrapper}>
        <TextInput
          {...otherTextInputProps}
          value={inputText}
          hasError={hasError || Boolean(internalErrorMessage)}
          // Internal error message (e.g., out of range) takes precedence over props (e.g., field required)
          errorMessage={internalErrorMessage || errorMessage}
          calendarPlacement={calendarPlacement}
          dataTestId={dataTestId}
          dateFormat={dateFormat}
          isCalendarOpen={isCalendarOpen}
          inputRef={inputRef}
          onCalendarButtonClick={handleCalendarButtonClick}
          onTextInputChange={handleTextInput}
          mode={mode}
          timePeriod={timePeriod}
          isTimeEditable={isTimeEditable}
        />
        <Popover
          anchorElement={anchorElement}
          dataTestId={`${dataTestId}-popover`}
          isOpen={isCalendarOpen}
          onClickAway={handlePopoverClickAway}
          onFlip={handlePopoverFlip}
          placement={CALENDAR_BOTTOM}
          placementOffset={[0, 0]}
          transitionModifiers={{ exit: false }}
        >
          <div className={classes.calendarWrapper}>
            <Calendar
              dataTestId={dataTestId}
              maxDate={maxDate}
              minDate={minDate}
              onDateSelected={handleDateInput}
              value={requestedValue}
              isTimeEditable={isTimeEditable}
              timePeriod={timePeriod}
              onTimePeriodChange={handleTimePeriodChange}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default DatePicker;
