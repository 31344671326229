import React, { FC, useCallback } from 'react';
import PlxsText from '../Text';
import PlxsIcon from '../Icon';

import { ComponentProps } from './Checkbox.types';
import { useStyles } from './Checkbox.styles';
import InternalLabel from '../_internal/Label';

const Checkbox: FC<ComponentProps> = ({
  autoFocus,
  dataTestId,
  errorMessage,
  hasError,
  id,
  isDisabled,
  label,
  onChange,
  value,
  indeterminate,
  align = 'left',
  labelWeight = 'semiBold',
  padding,
}) => {
  const classes = useStyles({
    hasError,
    hasLabel: Boolean(label),
    isDisabled,
    indeterminate,
    align,
    padding,
  });

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  // This is used to stop the checkbox losing focus when the label
  // is clicked, which can make other UI elements flicker, like the
  // blue border given to focused fields.
  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      e.preventDefault();
    },
    []
  );

  return (
    <div className={classes.root} data-testid={dataTestId}>
      <label className={classes.label} onMouseDown={handleMouseDown}>
        <div className={classes.inputContainer}>
          <input
            id={id}
            className={classes.input}
            autoFocus={autoFocus}
            disabled={isDisabled}
            type="checkbox"
            checked={value}
            onChange={handleInputChange}
          />
          <span className={classes.overlay}>
            <span className={classes.inputOverlay}>
              {indeterminate && !value ? (
                <PlxsIcon name="minus" />
              ) : (
                <PlxsIcon name="check" />
              )}
            </span>
          </span>
        </div>
        {label && <PlxsText weight={labelWeight}>{label}</PlxsText>}
      </label>
      {hasError && errorMessage && (
        <div className={classes.errorMessage}>
          <InternalLabel content={errorMessage} variant="error" />
        </div>
      )}
    </div>
  );
};

export default Checkbox;
