import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Anchor: FC<ComponentProps> = ({
  children,
  isExternal,
  url,
  dataTestId,
}) => {
  const classes = useStyles();
  const targetProps = {
    rel: 'noopener noreferrer',
    target: '_blank',
  };
  const conditionalProps = isExternal ? targetProps : {};
  return (
    <a
      className={classes.root}
      data-testid={dataTestId}
      href={url}
      {...conditionalProps}
    >
      {children}
    </a>
  );
};

export default Anchor;
