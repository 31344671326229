import React, { FC, useState } from 'react';

import { PlxsSelect } from '@plexus-ui/index';

interface ComponentProps {
  hasError?: boolean;
}

const SelectExample: FC<ComponentProps> = ({ hasError }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: 1, label: 'Australia and New Zealand' },
    { value: 2, label: 'Banking and Finance' },
    { value: 3, label: 'Head Office Leadership Team' },
    { value: 4, label: 'IT Department Records' },
    { value: 5, label: 'Legal Ops and Administration' },
    { value: 6, label: 'Melbourne CBD Franchise' },
    { value: 7, label: 'National Sales Organisation' },
    { value: 8, label: 'System Administrators' },
    { value: 9, label: 'Research and Development' },
    {
      value: 10,
      label:
        'A very very long option label that will break into multiple lines when necessary',
    },
  ];

  return (
    <PlxsSelect
      dataTestId="select-input-2"
      errorMessage="Error text to go here"
      hasError={hasError}
      onSelect={setSelectedOption}
      selected={selectedOption}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.value}
      placeholder="Please select an option..."
    />
  );
};

export default SelectExample;
