import React, {
  FC,
  Fragment,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DocCode, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton, PlxsRichTextEditor } from '@plexus-ui/index';

interface ComponentProps {
  initialValue?: string;
}

const Uncontrolled: FC<ComponentProps> = ({ initialValue }) => {
  const [isDirty, setDirty] = useState(false);
  const [value, setValue] = useState(
    'Click the get editor content button above to print the value.'
  );

  const editorRef = useRef(null);

  const handleInit = (event: any, editor: any) => (editorRef.current = editor);

  const handleGetContent = (event: MouseEvent) => {
    if (editorRef.current) {
      setValue(editorRef.current.getContent());
      setDirty(false);
      editorRef.current.setDirty(false);
    }
  };

  useEffect(() => setDirty(false), [initialValue]);

  return (
    <Fragment>
      <DocRow margin="sm">
        <PlxsRichTextEditor
          dataTestId="uncontrolled-rich-text-editor"
          initialValue={initialValue}
          label="Uncontrolled Rich Text Editor"
          onDirty={() => setDirty(true)}
          onInit={handleInit}
          placeholder="Editor placeholder text..."
        />
      </DocRow>

      <DocRow margin="sm">
        <PlxsButton
          dataTestId="get-content-button"
          isDisabled={!isDirty}
          label="Get Editor Content"
          onClick={handleGetContent}
        />
      </DocRow>

      <DocRow margin="sm">
        <DocSubHeading>Value</DocSubHeading>
        <DocCode>{value}</DocCode>
      </DocRow>
    </Fragment>
  );
};

export default Uncontrolled;

export const uncontrolledExample = `import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';

import { PlxsRichTextEditor } from '@plexus-ui/index';

const UncontrolledExample: FC = ({ initialValue }) => {
  const [isDirty, setDirty] = useState(false);

  const editorRef = useRef(null);

  const handleInit = (event: any, editor: any) => (editorRef.current = editor);

  const handleGetContent = (event: MouseEvent) => {
    if (editorRef.current) {
      setValue(editorRef.current.getContent());
      setDirty(false);
      editorRef.current.setDirty(false);
    }
  };

  useEffect(() => setDirty(false), [initialValue]);

  return (
    <PlxsRichTextEditor
      dataTestId="uncontrolled-rich-text-editor"
      initialValue={initialValue}
      label="Uncontrolled Rich Text Editor"
      onDirty={() => setDirty(true)}
      onInit={handleInit}
      placeholder="Editor placeholder text..."
    />
  );
};

export default UncontrolledExample;`;
