import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';

import Examples from './Examples';

const props = `interface ComponentProps {
  children: ReactNode;
  dataTestId?: string;
  handleOnClick?: (event: MouseEvent) => void;
  href?: string;
  iconName?: IconName (see icon name types);
  target?: '_self' | '_blank' | string;
}`;

const usage = `import React, { FC } from 'react';
import { PlxsText, PlxsTextLink } from '@plexus-ui/index';

const BasicExample: FC = () => {
  return (
    <PlxsText>
      Paragraph text with <PlxsTextLink>text link</PlxsTextLink>.
    </PlxsText>
  );
};

export default BasicExample;`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph margin="md">
            The TextLink component renders an HTML anchor element. It can be
            used with an href or click event handler and has the option to
            display the external link icon for added context.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocRow margin="sm">
            <DocCode>{usage}</DocCode>
          </DocRow>
        </DocRow>

        <Examples />
      </DocRow>
    </DocRow>
  );
};
