import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Input: FC<ComponentProps> = ({
  hasDynamicLabel,
  hasError,
  isActive,
  isDisabled,
  isDynamicLabelMinimised,
  placeholder,
  styleOverrides,
}) => {
  const classes = useStyles({
    hasError,
    isActive,
    isDisabled,
    isDynamicLabelMinimised,
    styleOverrides,
  });

  const hasPlaceholder = !hasDynamicLabel && placeholder;
  const placeholderEllipsis = styleOverrides.hidePlaceholderEllipsis
    ? ''
    : '...';

  return (
    <div className={classes.input}>
      {hasPlaceholder && (
        <span className={classes.placeholder}>
          {placeholder}
          {placeholderEllipsis}
        </span>
      )}
    </div>
  );
};

export default Input;
