import React, { FC, useState } from 'react';
import { PlxsRow } from '@plexus-ui/index';

import Code from '@dev/components/Code';

import { ComponentProps, NavigationList } from './types';
import { useStyles } from './styles';
import BestPractice from './_internal/BestPractice';
import Description from './_internal/Description';
import Navigation from './_internal/Navigation';
import Props from './_internal/Props';

const navigationList: NavigationList[] = [
  {
    id: 'design-thinking',
    label: 'Design thinking',
  },
  {
    id: 'props',
    label: 'Props',
  },
  {
    id: 'implementation',
    label: 'Implementation',
  },
  {
    id: 'examples',
    label: 'Examples',
  },
];

const PageTemplate: FC<ComponentProps> = ({
  bestPractice,
  description,
  example,
  implementation,
  implementationComponent,
  propList,
  tsInterface,
}) => {
  const classes = useStyles();

  const [selectedContentId, setSelectedContentId] = useState('');

  const handleNavigation = (id: string) => {
    setSelectedContentId(id);
  };

  const handleContent = () => {
    switch (selectedContentId) {
      case 'props': {
        return (
          <div className={classes.tabContent}>
            <PlxsRow isFlex={false} spaceBelow="md">
              <h2>TypeScript Interface</h2>
              <Code>{tsInterface}</Code>
            </PlxsRow>

            <PlxsRow isFlex={false} spaceBelow="md">
              <h2>Props</h2>
              <Props propList={propList}></Props>
            </PlxsRow>
          </div>
        );
      }

      case 'implementation': {
        return (
          <div className={classes.tabContent}>
            <PlxsRow isFlex={false} spaceBelow="md">
              <Code>{implementation}</Code>
            </PlxsRow>
            {implementationComponent}
          </div>
        );
      }

      case 'examples': {
        return <div className={classes.tabContent}>{example}</div>;
      }

      default:
        return (
          <div className={classes.tabContent}>
            <Description>{description}</Description>
            <BestPractice lists={bestPractice} />
          </div>
        );
    }
  };

  return (
    <div>
      <Navigation list={navigationList} onClick={handleNavigation} />
      {handleContent()}
    </div>
  );
};

export default PageTemplate;
