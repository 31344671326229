import React, { Fragment, useState } from 'react';
import { BRAND, CAUTION, POSITIVE, URGENT } from '@plexus-ui/_constants';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import {
  PlxsButton,
  PlxsCheckbox,
  PlxsColumn,
  PlxsIcon,
  PlxsRow,
  PlxsSelect,
  PlxsText,
  PlxsToast,
} from '@plexus-ui/index';
import { IconName } from '@plexus-ui/types';

import { useStyles } from './styles';

const Playground = () => {
  const classes = useStyles();

  const icons: any[] = [
    { id: 1, name: 'bin' },
    { id: 2, name: 'clock' },
    { id: 3, name: 'gift' },
    { id: 4, name: 'infoCircle' },
    { id: 5, name: 'warning' },
  ];

  const tones: any[] = [
    { id: 1, name: BRAND },
    { id: 2, name: CAUTION },
    { id: 3, name: POSITIVE },
    { id: 4, name: URGENT },
  ];

  const contents: any[] = [
    {
      name: 'Single line',
      node: <PlxsText>You have deleted 3 alerts</PlxsText>,
    },
    {
      name: 'Multi line',
      node: (
        <Fragment>
          <PlxsRow>
            <PlxsText weight="semiBold" element="span">
              Changes to a document will trigger a mandatory restart of the
              workflow.
            </PlxsText>
          </PlxsRow>
          <PlxsRow>
            <PlxsText element="span">
              All approvals and signatures will need recollecting.
            </PlxsText>
          </PlxsRow>
        </Fragment>
      ),
    },
  ];

  const [canManuallyDismiss, setCanManuallyDismiss] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [content, setContent] = useState(contents[0]);
  const [icon, setIcon] = useState(icons[0]);
  const [isAutoDismiss, setIsAutoDismiss] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [tone, setTone] = useState(tones[0]);

  const iconName = icon ? icon.name : icons[0].name;
  const toneName = tone ? tone.name : tones[0].name;

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Playground</DocSubHeading>
        <DocParagraph>
          Explore the following props and see what effect these have to the
          toast.
        </DocParagraph>
        <DocParagraph>
          Note: the timer for auto-dismissal of the toast will be stopped when a
          user hovers on the toast and will be reset upon leaving the toast.
        </DocParagraph>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <div className={classes.displayName}>Icon</div>
            <div className={classes.componentWrapper}>
              <PlxsColumn expand={true}>
                <PlxsSelect
                  dataTestId="toast-example-icon-select"
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  id="toast-example-icon-selection"
                  onSelect={setIcon}
                  options={icons}
                  renderOption={(option) => (
                    <PlxsIcon name={option.name as IconName} size="lg" />
                  )}
                  selected={icon}
                />
              </PlxsColumn>
            </div>
            <div className={classes.name}>
              <DocCode>{`icon="${iconName}"`}</DocCode>
            </div>
          </li>
          <li className={classes.listItem}>
            <div className={classes.displayName}>Tone</div>
            <div className={classes.componentWrapper}>
              <PlxsColumn expand={true}>
                <PlxsSelect
                  dataTestId="toast-example-tone-select"
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  id="toast-example-tone-selection"
                  onSelect={setTone}
                  options={tones}
                  renderOption={(option) => option.name}
                  selected={tone}
                />
              </PlxsColumn>
            </div>
            <div className={classes.name}>
              <DocCode>{`tone="${toneName}"`}</DocCode>
            </div>
          </li>
          <li className={classes.listItem}>
            <div className={classes.displayName}>
              With button to manually dismiss
            </div>
            <div className={classes.componentWrapper}>
              <PlxsCheckbox
                dataTestId="toast-example-can-manually-dismiss-checkbox"
                onChange={setCanManuallyDismiss}
                value={canManuallyDismiss}
              />
            </div>
            <div className={classes.name}>
              <DocCode>
                {`canManuallyDismiss={${
                  canManuallyDismiss ? 'true' : 'false'
                }}`}
              </DocCode>
            </div>
          </li>
          <li className={classes.listItem}>
            <div className={classes.displayName}>
              With button to undo action
            </div>
            <div className={classes.componentWrapper}>
              <PlxsCheckbox
                dataTestId="toast-example-on-undo-checkbox"
                onChange={setCanUndo}
                value={canUndo}
              />
            </div>
            <div className={classes.name}>
              <DocCode>
                {`onUndo={${canUndo ? '() => {}' : 'undefined'}}`}
              </DocCode>
            </div>
          </li>
          <li className={classes.listItem}>
            <div className={classes.displayName}>
              Auto dismiss after 10 seconds
            </div>
            <div className={classes.componentWrapper}>
              <PlxsCheckbox
                dataTestId="toast-example-is-auto-dismiss-checkbox"
                onChange={setIsAutoDismiss}
                value={isAutoDismiss}
              />
            </div>
            <div className={classes.name}>
              <DocCode>
                {`isAutoDismiss={${isAutoDismiss ? 'true' : 'false'}}`}
              </DocCode>
            </div>
          </li>
          <li className={classes.listItem}>
            <div className={classes.displayName}>Content</div>
            <div className={classes.componentWrapper}>
              <PlxsColumn expand={true}>
                <PlxsSelect
                  dataTestId="toast-example-content-select"
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.name}
                  id="toast-example-content-selection"
                  onSelect={setContent}
                  options={contents}
                  renderOption={(option) => option.name}
                  selected={content}
                />
              </PlxsColumn>
            </div>
          </li>
        </ul>

        <PlxsButton
          dataTestId="toast-example-button"
          isDisabled={isVisible}
          label="Let's toast!"
          onClick={() => setIsVisible(true)}
        />
      </DocRow>
      <PlxsToast
        canManuallyDismiss={canManuallyDismiss}
        dataTestId="auto-dismiss-sample"
        icon={iconName as IconName}
        isAutoDismiss={isAutoDismiss}
        isVisible={isVisible}
        onDismiss={() => setIsVisible(false)}
        onUndo={canUndo ? () => {} : undefined}
        tone={toneName}
      >
        {content.node}
      </PlxsToast>
    </DocRow>
  );
};

export default Playground;
