import React, { useState } from 'react';
import { DocCode, DocHeading, DocRow } from '@dev/components';
import { PlxsTileSelector } from '@plexus-ui/index';
import { useStyles } from './styles';
import { PLACEHOLDER_ICON } from '@dev/constants';

const data = [
  {
    id: 1,
    icon: PLACEHOLDER_ICON,
    name: 'Approve and eSign',
  },
  {
    id: 2,
    icon: PLACEHOLDER_ICON,
    name: 'Advertising Compliance Review',
  },
  {
    id: 3,
    icon: PLACEHOLDER_ICON,
    name: 'Automation framework',
  },
  {
    id: 4,
    icon: PLACEHOLDER_ICON,
    name: 'Plexus order form',
  },
  {
    id: 5,
    icon: PLACEHOLDER_ICON,
    name: 'Request legal support',
  },
  {
    id: 6,
    icon: PLACEHOLDER_ICON,
    name: 'Create and NDA',
  },
];

const MultiSelect = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState<any[]>([1, 2]);

  const handleClick = (app: any) => {
    const isSelected = selected.includes(app.id);
    let items = [...selected];
    if (isSelected) {
      items = selected.filter((item) => item !== app.id);
    } else {
      items.push(app.id);
    }

    setSelected(items);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Multi Selector</DocHeading>
        <div className={classes.grid}>
          {data.map((item, key) => (
            <div key={key}>
              <PlxsTileSelector
                dataTestId="test"
                image={item.icon}
                label={item.name}
                labelElement="h6"
                onChange={() => handleClick(item)}
                value={selected.indexOf(item.id) > -1}
              />
            </div>
          ))}
        </div>
        <br />
        <div>
          <h6>Value:</h6>
          <DocCode>{JSON.stringify(selected, undefined, 2)}</DocCode>
        </div>
      </DocRow>
    </DocRow>
  );
};

export default MultiSelect;
