import React from 'react';
import {
  DocCode,
  DocParagraph,
  DocPropGroupHeading,
  DocRow,
  DocSubHeading,
} from '@dev/components';

import DynamicLabelTextarea from './DynamicLabel';
import HelpLabelTextarea from './HelpLabel';
import HiddenLabelTextarea from './HiddenLabel';
import StaticLabelTextarea from './StaticLabel';

export default () => {
  return (
    <DocRow margin="lg">
      <DocPropGroupHeading>Label variants</DocPropGroupHeading>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Static label (default)</DocSubHeading>
          <DocParagraph>
            The static label is displayed above the Textarea.
          </DocParagraph>
        </DocRow>
        <StaticLabelTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="static-label-textarea"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Hidden label</DocSubHeading>
          <DocParagraph>The hidden label is not displayed.</DocParagraph>
        </DocRow>
        <HiddenLabelTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="hidden-label-textarea"
  label="Input name to go here"
  labelVariant="hidden"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Dynamic label</DocSubHeading>
          <DocParagraph>
            The dynamic label animates the placeholder text to the dynamic label
            position.
          </DocParagraph>
        </DocRow>
        <DynamicLabelTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="dynamic-label-textarea"
  label="Input name to go here"
  labelVariant="dynamic"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Help label</DocSubHeading>
          <DocParagraph>
            The secondary label is for linking to help articles. The props
            secondaryLabel and secondaryLabelUrl are required in combination
            with the static (default) label variant.
          </DocParagraph>
        </DocRow>
        <HelpLabelTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="help-label-textarea"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
  secondaryLabel="View help article"
  secondaryLabelUrl="#"
/>`}</DocCode>
      </DocRow>
    </DocRow>
  );
};
