import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsIcon, PlxsTextField } from '@plexus-ui/index';
import { neutral } from '@plexus-ui/styles/color';

const SearchTextField: FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const searchIcon = (
    <PlxsIcon name="search" size="sm" tone={isFocused ? 'brand' : 'neutral'} />
  );

  const styleOverrides = {
    css: {
      backgroundColor: 'transparent',
      borderColor: neutral[700],
    },
  };

  return (
    <PlxsTextField
      iconRight={searchIcon}
      dataTestId="icon-right-text-field"
      isInline={true}
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      placeholder="Search for something"
      styleOverrides={!isFocused && styleOverrides}
      value={value}
    />
  );
};

export default SearchTextField;
