import React, { FC, useState } from 'react';

import { PlxsCheckbox } from '@plexus-ui/index';

interface ComponentProps {
  hasError?: boolean;
}

const TextareaExample: FC<ComponentProps> = ({ hasError }) => {
  const [value, setValue] = useState(false);

  const handleChange = (value: boolean) => {
    setValue(value);
  };

  return (
    <PlxsCheckbox
      dataTestId="default-unchecked"
      errorMessage="Error text to go here"
      hasError={hasError}
      label="Would you like to hear from us with feedback?"
      onChange={handleChange}
      value={value}
    />
  );
};

export default TextareaExample;
