import React, { FC, useEffect, useState } from 'react';
import PlxsTextField from '../../TextField/TextField';

import { useStyles } from './TimeInput.styles';
import { ComponentProps } from './TimeInput.types';
import { decreaseValue, increaseValue, isNumber, formatValue } from '../_utils';

const TimeInput: FC<ComponentProps> = ({
  dataTestId,
  value,
  onChange,
  onFocus,
  minNumber = 0,
  maxNumber = 59,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    let newValue = formatValue(event.target.value, maxNumber);
    if (newValue === '') {
      newValue = formatValue(maxNumber.toString(), maxNumber);
    } else if (Number(newValue) < minNumber) {
      newValue = formatValue(minNumber.toString(), maxNumber);
    } else if (Number(newValue) > maxNumber) {
      newValue = formatValue(maxNumber.toString(), maxNumber);
    }
    setInputValue(newValue);
    onChange && onChange(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        setInputValue(
          formatValue(decreaseValue(inputValue, minNumber), maxNumber)
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setInputValue(
          formatValue(increaseValue(inputValue, maxNumber), maxNumber)
        );
        break;
    }

    // disable typing letters
    if (
      !isNumber(key) &&
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      <PlxsTextField
        isInline
        dataTestId={`${dataTestId}--input`}
        maxLength={2}
        onKeyDown={handleKeyDown}
        value={inputValue}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

export default TimeInput;
