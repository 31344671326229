import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  heading: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '39px',
    marginBottom: '16px',
  },
});
