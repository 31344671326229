export const brand = {
  100: '#002040',
  200: '#034B7D',
  300: '#0469AF',
  400: '#008DEF',
  500: '#2FA3FA',
  600: '#5FB8F7',
  700: '#B4DFFD',
  800: '#D1ECFF',
  900: '#E5F5FF',
};

export const caution = {
  100: '#614D00',
  200: '#947500',
  300: '#C79E00',
  400: '#FCC800',
  500: '#FFD42E',
  600: '#FFDE61',
  700: '#FFE994',
  800: '#FFEDA8',
  900: '#FFF8DB',
};

export const neutral = {
  100: '#26262C',
  200: '#555562',
  300: '#666675',
  400: '#808090',
  500: '#B8B8C4',
  600: '#E0E0E3',
  700: '#E6E6E6',
  800: '#ECECEE',
  900: '#F3F3F4',
};

export const positive = {
  100: '#000F07',
  200: '#00421E',
  300: '#007536',
  400: '#00AA4E',
  500: '#00DB65',
  600: '#0FFF7D',
  700: '#42FF99',
  800: '#75FFB4',
  900: '#D1FFE6',
};

export const urgent = {
  100: '#470600',
  200: '#7A0B00',
  300: '#AB1000',
  400: '#E01500',
  500: '#FF2A14',
  600: '#FF5947',
  700: '#FF877A',
  800: '#FFB5AD',
  900: '#FFE3E0',
};

export const white = '#ffffff';
