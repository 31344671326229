import React, { FC } from 'react';

import { ComponentProps } from './HelpLink.types';
import InternalLabel from '../_internal/Label';
import InternalAnchor from '../_internal/Anchor';

const PlxsHelpLink: FC<ComponentProps> = ({ url, children, dataTestId }) => (
  <InternalAnchor dataTestId={dataTestId} isExternal={true} url={url}>
    <InternalLabel content={children} variant="help" />
  </InternalAnchor>
);

export default PlxsHelpLink;
