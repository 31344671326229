import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Basic from './Basic';
import Icon from './Icon';
import IconIndicator from './IconIndicator';
import ImplementationComponent from './ImplementationComponent';
import Indicator from './Indicator';
import Scroll from './Scroll';
import ExternalControl from './ExternalControl';
import Collapsible from './Collapsible';
import CollapsibleScroll from './CollapsibleScroll';

const bestPractice = {
  doList: [
    'Use tabs to organise and navigate between groups of content that are related and at the same level of hierarchy',
    'Use tabs to alternate between views within the same context',
    'Use tabs only when users don’t need to see content from multiple tabs simultaneously',
    'Keep tab labels short where possible',
  ],
  doNotList: [
    'Do not use tabs to compare content e.g different sizes',
    'Do not use more than 4 options',
  ],
};

const description =
  'Tabs are a navigation element used in web design that allow users to easily access different areas of a site or different parts of an individual page. They’re sort of like tabbed dividers in a filing cabinet – by clicking a tab, users can easily locate a page containing related content.';

const example = (
  <Fragment>
    <Basic />
    <Icon />
    <Indicator />
    <IconIndicator />
    <Scroll />
    <ExternalControl />
    <Collapsible />
    <CollapsibleScroll />
  </Fragment>
);

const implementation = `import React from 'react';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

export default () => (
  <PlxsTabProvider dataTestId="default" ariaLabel="Example">
    <PlxsTabContent
      id="tab-1"
      tabLabel="Sumenda potius"
      tabIcon="bulletedList"
      tabIndicatorLabel="3"
    >
      <div>
        <h2>Sumenda potius quam expetenda.</h2>
        <p>
          Si verbum sequimur, primum longius verbum praepositum quam bonum.
          Suam denique cuique naturam esse ad vivendum ducem.{' '}
          <b>Sed nunc, quod agimus;</b> Refert tamen, quo modo.{' '}
        </p>
        <ul>
          <li>Suam denique cuique naturam esse ad vivendum ducem.</li>
          <li>Nosti, credo, illud: Nemo pius est, qui pietatem-;</li>
        </ul>
      </div>
    </PlxsTabContent>

    <PlxsTabContent
      id="tab-2"
      tabLabel="Se dicere"
      tabIcon="bulletedList"
      tabIndicatorLabel="235"
    >
      <ul>
        <li>
          Se dicere inter honestum et turpe nimium quantum, nescio quid
          inmensum, inter ceteras res nihil omnino interesse.
        </li>
        <li>Iam in altera philosophiae parte.</li>
        <li>Ergo id est convenienter naturae vivere, a natura discedere.</li>
        <li>
          An vero displicuit ea, quae tributa est animi virtutibus tanta
          praestantia?
        </li>
      </ul>
    </PlxsTabContent>

    <PlxsTabContent
      id="tab-3"
      tabLabel="Lorem ipsum dolor"
      tabIcon="bulletedList"
      tabIndicatorLabel="86"
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliter autem
        vobis placet. <i>Memini me adesse P.</i> Bonum integritas corporis:
        misera debilitas. Cyrenaici quidem non recusant; Ecce aliud simile
        dissimile. <i>Duo Reges: constructio interrete.</i>
      </p>
    </PlxsTabContent>
  </PlxsTabProvider>
);`;

const propList = [
  {
    component: 'TabContent',
    description: 'the content of TabContent, displayed for the active tab',
    isRequired: true,
    prop: 'children',
    type: 'ReactNode',
  },
  {
    component: 'TabContent',
    description: 'the text label on each tab',
    isRequired: true,
    prop: 'tabLabel',
    type: 'string',
  },
  {
    component: 'TabContent',
    description:
      'if provided, the icon name as listed in the icon library, renders the icon component, left aligned to the tab label',
    isRequired: false,
    prop: 'tabIcon',
    type: 'IconName',
  },
  {
    component: 'TabContent',
    description:
      'if provided, the chip text label, renders the chip component, right aligned to the tab label',
    isRequired: false,
    prop: 'tabIndicatorLabel',
    type: 'string',
  },
  {
    component: 'TabProvider',
    description: 'either singular or multiple TabContent components',
    isRequired: true,
    prop: 'children',
    type: 'ReactElement | Array<ReactElement>',
  },
  {
    component: 'TabProvider',
    description:
      'renders as data-testid on the parent div, child components extend data-testid for unqiue identification',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'TabProvider',
    description:
      'if provided, gives the parent a fixed height, applies a scrollbar to tab content area',
    isRequired: false,
    prop: 'height',
    type: 'string',
  },
  {
    component: 'TabProvider',
    description: 'if provided, value is used for setting the active tab',
    isRequired: false,
    prop: 'activeTab',
    type: 'string',
  },
  {
    component: 'TabProvider',
    description:
      "if provided, event handler overrides the child components' handler",
    isRequired: false,
    prop: 'onClick',
    type: '(activeTabId: string) => void',
  },
];

const tsInterface = `interface TabContent {
  children: ReactNode;
  tabLabel: string;
  tabIcon?: IconName;
  tabIndicatorLabel?: string;
}

interface TabProvider {
  children: ReactElement | Array<ReactElement>;
  dataTestId: string;
  height?: number;
  activeTab?: string;
  onClick?: (activeTabId: string) => void;
  isCollapsible?: boolean;
}
`;

export default () => {
  return (
    <PageTemplate
      bestPractice={bestPractice}
      description={description}
      example={example}
      implementation={implementation}
      implementationComponent={<ImplementationComponent />}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
