import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const ButtonGroup: FC<ComponentProps> = ({
  errorMessageComponent,
  primaryButton,
  secondaryButton,
  tertiaryButton,
}) => {
  const hasErrorMessageComponent = Boolean(errorMessageComponent);
  const hasSecondaryButton = Boolean(secondaryButton);
  const hasTertiaryButton = Boolean(tertiaryButton);

  const rightColumnChildComponent = hasTertiaryButton
    ? tertiaryButton
    : errorMessageComponent;

  const hasRightColumn = hasErrorMessageComponent || hasTertiaryButton;

  const classes = useStyles({ hasRightColumn, hasSecondaryButton });

  return (
    <div className={classes.buttonGroupWrapper}>
      <div className={classes.leftColumn}>
        <div className={classes.primaryButton}>{primaryButton}</div>
        {hasSecondaryButton && (
          <div className={classes.secondaryButton}>{secondaryButton}</div>
        )}
      </div>
      {hasRightColumn && (
        <div className={classes.rightColumn}>{rightColumnChildComponent}</div>
      )}
    </div>
  );
};

export default ButtonGroup;
