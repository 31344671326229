import React, { FC } from 'react';

import PlxsHeading from '../../../Heading';
import PlxsText from '../../../Text';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Header: FC<ComponentProps> = ({ heading, subheading }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {heading && (
        <div className={classes.heading}>
          <PlxsHeading element="h1" variant="h3">
            {heading}
          </PlxsHeading>
        </div>
      )}
      {subheading && (
        <div className={classes.subheading}>
          {typeof subheading === 'string' ? (
            <PlxsText>{subheading}</PlxsText>
          ) : (
            subheading
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
