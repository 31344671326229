import React, { FC } from 'react';
import { DocCode, DocRow } from '@dev/components';
import { PlxsHelpLink } from '@plexus-ui/index';

const HelpLinkDocs: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocCode>{`<PlxsHelpLink url="http://google.com">Click here to visit Google</PlxsHelpLink>`}</DocCode>
      </DocRow>
      <DocRow margin="lg">
        <PlxsHelpLink dataTestId="help-link-example" url="http://google.com">
          Click here to visit Google
        </PlxsHelpLink>
      </DocRow>
    </DocRow>
  );
};

export default HelpLinkDocs;
