import React from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ButtonDisabled = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Disabled button</DocSubHeading>
        <DocParagraph>
          A universal disabled style is applied to all button variants.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Primary disabled</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-disabled-button"
              isDisabled={true}
              label="Primary button"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isDisabled={true}'}</DocCode>
          </div>
        </li>
        <li className={`${classes.listItem} ${classes.listItemBackground}`}>
          <div className={classes.displayName}>Primary white disabled</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-white-disabled-button"
              isDisabled={true}
              label="Primary button"
              tone="white"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isDisabled={true} tone="white"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Secondary disabled</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="secondary-disabled-button"
              isDisabled={true}
              label="Secondary button"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isDisabled={true} variant="secondary"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Link disabled</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="link-disabled-button"
              isDisabled={true}
              label="Link button"
              variant="link"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isDisabled={true} variant="link"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Icon only disabled</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="icon-only-disabled-button"
              functionalIcon="signature"
              isDisabled={true}
              isIconOnly={true}
              label="Icon only button"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isDisabled={true} isIconOnly={true}'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ButtonDisabled;
