import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsTextarea } from '@plexus-ui/index';

const InstructionTextarea: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextarea
      dataTestId="instruction-textarea"
      instructionText="Additional instructions go here"
      label="Input name to go here"
      onChange={handleChange}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default InstructionTextarea;
