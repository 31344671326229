import React, { FC, useState } from 'react';

import { DocHeading, DocRow } from '@dev/components';
import { PlxsSearch, PlxsText } from '@plexus-ui/index';
import { users } from '@dev/mocks/users';

interface User {
  email: string;
  id?: number;
  name: string;
}

const searchUser = (name: string, exclude: User[]) => {
  const response = new Promise<User[]>((resolve) => {
    setTimeout(() => {
      const filteredUsers = users
        .filter(
          (user) =>
            user.name.toLowerCase().includes(name.toLowerCase()) &&
            !exclude.find((item) => item.id === user.id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      resolve(filteredUsers);
    }, 1000);
  });
  return response;
};

export const SimpleSearch: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState<User[]>();
  const [selection, setSelection] = useState('');
  const [suggestionUser, setSuggestionUser] = useState<User[]>([]);

  const renderSelectedOptionValue = (option: User) => option.name;

  const handleSelect = (option: User[]) => {
    setSelection(JSON.stringify(option));
    setSelectedList(option);
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  const handleChange = async (value: string, selected: User[]) => {
    setIsLoading(true);
    const response = await searchUser(value, selected);
    setIsLoading(false);
    setSuggestionUser(response);
  };

  return (
    <DocRow margin="md">
      <DocHeading>Simple Search</DocHeading>
      <PlxsSearch
        isDefaultActive
        dataTestId="search-simple"
        renderSelectedOptionValue={renderSelectedOptionValue}
        initialValue={selectedList}
        label="Search User name"
        labelVariant="dynamic"
        onSelect={handleSelect}
        optionList={suggestionUser}
        renderOption={renderOption}
        tooltipTextKey="email"
        onChange={handleChange}
        isLoading={isLoading}
        placeholder="Type user name here"
      />
      <PlxsText>Selected option: {selection}</PlxsText>
    </DocRow>
  );
};
