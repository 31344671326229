import React, { FC, Fragment } from 'react';

import PlxsIcon from '../Icon';

import { ComponentProps } from './TextLink.types';
import { useStyles } from './TextLink.styles';

const TextLink: FC<ComponentProps> = ({
  children,
  dataTestId,
  handleOnClick,
  href,
  iconName,
  target,
}) => {
  const classes = useStyles();
  return (
    <a
      className={classes.root}
      data-testid={dataTestId}
      href={href}
      onClick={handleOnClick}
      target={target}
    >
      <Fragment>
        {children}
        {iconName && <PlxsIcon name={iconName} tone="inherit" />}
      </Fragment>
    </a>
  );
};

export default TextLink;
