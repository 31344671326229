import { makeStyles } from '@material-ui/core/styles';

import { breakpoints, focusState } from '../../../../styles';
import { defaultTextStyle } from '../../../../styles/text';
import { neutral } from '../../../../styles/color';
import { tone, toneBg, toneBgHover, toneHover } from '../../../../styles/tone';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  tab: ({ isActive }: StyleProps) => ({
    alignItems: 'center',
    display: 'flex',
    padding: '5px 16px 11px',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      '& $tabBorder': {
        backgroundColor: isActive ? toneHover.brand : toneBgHover.neutral,
      },
      '& $tabLabel': {
        color: isActive ? toneHover.brand : neutral[100],
      },
    },
    '&:focus': {
      ...focusState,
    },
  }),
  tabBorder: ({ isActive }: StyleProps) => ({
    backgroundColor: isActive ? tone.brand : toneBg.neutral,
    borderRadius: '12px 12px 0 0',
    bottom: '0',
    height: '4px',
    left: '0',
    position: 'absolute',
    right: '0',
  }),
  tabLabel: ({ isActive }: StyleProps) => ({
    ...defaultTextStyle,
    color: isActive ? tone.brand : neutral[200],
    fontWeight: 600,
    marginBottom: '3px',
    marginTop: '3px',
    minWidth: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  firstTab: {
    marginRight: '8px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginRight: '16px',
    },
  },
  lastTab: {
    marginLeft: '8px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginLeft: '16px',
    },
  },
  otherTab: {
    marginLeft: '8px',
    marginRight: '8px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
}));
