import React from 'react';

import { SvgProps } from '../types';

export default (props: SvgProps) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18">
    <path
      fill="currentColor"
      d="M7.07 13.66a.59.59 0 00.86 0l6.89-7.47a.7.7 0 000-.94L14 4.34a.56.56 0 00-.84 0l-5.65 6.09-2.65-2.84a.56.56 0 00-.84 0l-.84.91a.7.7 0 000 .94z"
    />
  </svg>
);
