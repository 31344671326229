import React, { FC } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';

import { useStyles } from '../../Tooltip/Tooltip.styles';

import { ComponentProps } from './types';

const Tooltip: FC<ComponentProps> = ({ children, dataTestId, title }) => {
  const classes = useStyles();
  return (
    <MuiTooltip
      arrow
      classes={classes}
      data-testid={`${dataTestId}--tooltip`}
      title={title}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
