import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  contentHeader: {
    background: '#f3f3f4',
    padding: '53px',
  },
  contentBody: {
    maxWidth: '1400px',
    padding: '53px',
  },
  pageHeading: {
    fontSize: '42px',
    fontWeight: 700,
  },
});
