import { makeStyles } from '@material-ui/core/styles';
import { toneBg, toneBgHover } from '../../../styles/tone';

import { StyleProps } from './types';

const hoverStyles = (props: StyleProps) => {
  const styles = {
    '&:hover': {
      backgroundColor: toneBgHover[props.tone],
    },
  };
  return props.hasHover ? styles : null;
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    backgroundColor: toneBg[props.tone],
    ...hoverStyles(props),
  }),
}));
