import React, { FC } from 'react';
import PlxsIcon from '../../Icon';

import { useStyles } from './Counter.styles';
import { ComponentProps } from './Counter.types';

const Counter: FC<ComponentProps> = ({
  dataTestId,
  onIncrease,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid={dataTestId}>
      <button type="button" onClick={() => onIncrease(1)}>
        <PlxsIcon name="chevronUp" />
      </button>
      <button type="button" onClick={() => onIncrease(-1)}>
        <PlxsIcon name="chevronDown" />
      </button>
    </div>
  );
};

export default Counter;
