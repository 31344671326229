import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DocRow } from '@dev/components';
import {
  PlxsAutoComplete,
  PlxsButton,
  PlxsButtonGroup,
  PlxsText,
} from '@plexus-ui/index';
import { users } from '@dev/mocks/users';

interface User {
  email: string;
  id?: number;
  name: string;
}

const useStyles = makeStyles(() => ({
  button: {
    '& button': {
      borderRadius: '0 0 3px 3px',
      justifyContent: 'flex-start',
    },
  },
}));

export const FunctionalTest: FC = () => {
  const classes = useStyles();

  const [isDisabled, setDisabled] = useState(false);
  const [selectedList, setSelectedList] = useState<User[]>([
    {
      id: 4,
      name: 'Claudia Tousy',
      email: 'ctousy3@jimdo.com',
    },
    {
      id: 6,
      name: 'Tammi Cornfoot',
      email: 'tcornfoot5@t.co',
    },
  ]);
  const [selection, setSelection] = useState('');

  const getOptionValue = (option: User) => option.name;

  const handleAddContact = () => {
    setSelectedList([
      ...selectedList,
      {
        email: 'new@user.com',
        id: 123,
        name: 'New User',
      },
    ]);
  };

  const handleClear = () => {
    setSelection('');
    setSelectedList([]);
  };

  const handleSelect = (option: User[]) => {
    setSelection(JSON.stringify(option));
    setSelectedList(option);
  };

  const handleToggleDisabled = () => {
    setDisabled(!isDisabled);
  };

  const multiSelectFilter = (optionList: User[], suggestion: User) => {
    return optionList.filter((item) => item.id !== suggestion.id);
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  const newContactButton = (
    <div className={classes.button}>
      <PlxsButton
        dataTestId="new-contact-button"
        decorativeIcon="add"
        isFullWidth={true}
        label="New contact"
        onMouseDown={() => {}}
        variant="link"
      />
    </div>
  );

  return (
    <DocRow margin="md">
      <PlxsAutoComplete
        dataTestId="multi-select-complex"
        dropdownFooter={newContactButton}
        getOptionValue={getOptionValue}
        initialValue={selectedList}
        isDisabled={isDisabled}
        isMultiSelect={true}
        label="Recipients"
        labelVariant="dynamic"
        matchKeys={['email', 'name']}
        multiSelectFilter={multiSelectFilter}
        onSelect={handleSelect}
        optionList={users}
        renderOption={renderOption}
        tooltipTextKey="email"
        button={
          <PlxsButton
            dataTestId="clear-all-button"
            isDisabled={isDisabled}
            label="Clear All"
            onClick={handleClear}
          />
        }
      />
      <DocRow margin="sm">
        <PlxsButtonGroup>
          <PlxsButton
            dataTestId="add-contact-button"
            label="Add contact"
            onClick={handleAddContact}
            variant="secondary"
          />
          <PlxsButton
            dataTestId="toggle-disabled-button"
            label="Toggle disabled"
            onClick={handleToggleDisabled}
            variant="secondary"
          />
        </PlxsButtonGroup>
      </DocRow>
      <PlxsText>Selected option: {selection}</PlxsText>
    </DocRow>
  );
};
