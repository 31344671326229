import React, { FC } from 'react';
import MuiDialog from '@material-ui/core/Dialog';

import { ComponentProps } from './Dialog.types';
import { useStyles } from './Dialog.styles';
import PlxsButton from '../Button';
import PlxsHeading from '../Heading';
import PlxsText from '../Text';

const Dialog: FC<ComponentProps> = ({
  ariaLabelledBy,
  ariaDescribedBy,
  dataTestId,
  heading,
  isOpen,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  subheading,
  variant = 'default',
}) => {
  const classes = useStyles();

  const hasSecondaryButton =
    Boolean(onSecondaryButtonClick) && secondaryButtonLabel;
  const primaryButtonTone = variant === 'delete' ? 'urgent' : 'brand';
  const secondaryButtonTone = variant === 'delete' ? 'brand' : 'urgent';
  const onClose = hasSecondaryButton
    ? onSecondaryButtonClick
    : onPrimaryButtonClick;

  return (
    <MuiDialog
      aria-labelledby={ariaLabelledBy || dataTestId}
      aria-describedby={ariaDescribedBy || dataTestId}
      BackdropProps={{ className: classes.backdrop }}
      classes={{ paper: classes.contentContainer }}
      data-testid={dataTestId}
      fullWidth={true}
      maxWidth={false}
      onClose={onClose}
      open={isOpen}
    >
      <PlxsHeading element="h3">{heading}</PlxsHeading>
      <div className={classes.subHeadingWrapper}>
        {typeof subheading === 'string' ? (
          <PlxsText>{subheading}</PlxsText>
        ) : (
          subheading
        )}
      </div>
      <div className={classes.footerWrapper}>
        {hasSecondaryButton && (
          <PlxsButton
            dataTestId={`${dataTestId}-secondary-button`}
            label={secondaryButtonLabel}
            onClick={onSecondaryButtonClick}
            tone={secondaryButtonTone}
            variant="secondary"
          />
        )}
        <PlxsButton
          dataTestId={`${dataTestId}-primary-button`}
          label={primaryButtonLabel}
          onClick={onPrimaryButtonClick}
          tone={primaryButtonTone}
        />
      </div>
    </MuiDialog>
  );
};

export default Dialog;
