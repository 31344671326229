import React from 'react';
import {
  DocCode,
  DocParagraph,
  DocProp,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsChip } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ChipIcon = () => {
  const classes = useStyles();
  const handleChipClick = () => {
    console.log('onChipClick handled');
  };
  const handleFunctionalIconClick = () => {
    console.log('onFunctionalIconClick handled');
  };

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>With/without icon</DocSubHeading>
        <DocParagraph>
          To render chips with/without icons, use any combination of the{' '}
          <DocProp>decorativeIcon</DocProp>, <DocProp>functionalIcon</DocProp>{' '}
          and <DocProp>label</DocProp> props. When specifying a{' '}
          <DocProp>functionalIcon</DocProp>, also include the{' '}
          <DocProp>onFunctionalIconClick</DocProp> prop to handle the click
          event.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Label only</div>
          <div className={classes.icons}>
            <PlxsChip label="Internal" />
          </div>
          <div className={classes.name}>
            <DocCode>{`label="Internal"`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>With decorative icon</div>
          <div className={classes.icons}>
            <PlxsChip decorativeIcon="signature" label="In progress" />
          </div>
          <div className={classes.name}>
            <DocCode>{`decorativeIcon="signature" label="In progress"`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>With functional icon</div>
          <div className={classes.icons}>
            <PlxsChip
              functionalIcon="cross"
              label="Tomas Michaels"
              onFunctionalIconClick={() => {}}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`functionalIcon="cross" label="Tomas Michaels" onFunctionalIconClick={() => {}}`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>
            With decorative and functional icons
          </div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              onFunctionalIconClick={() => {}}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`decorativeIcon="person" functionalIcon="cross" label="Tomas Michaels" onFunctionalIconClick={() => {}}`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>
            With functional icon, onChipClick and onFunctionalIconClick.
          </div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              onChipClick={handleChipClick}
              onFunctionalIconClick={handleFunctionalIconClick}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{` functionalIcon="cross" label="Tomas Michaels" onChipClick={handleChipClick} onFunctionalIconClick={handleFunctionalIconClick}`}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ChipIcon;
