import React, { FC } from 'react';

import { BRAND, DISABLED } from '../../../../_constants';
import InternalFieldWrapper from '../../../_internal/FieldWrapper';
import PlxsIcon from '../../../Icon';

import { ComponentProps } from './types';
import PlxsSpinner from '../../../Spinner';

const FieldWrapper: FC<ComponentProps> = ({
  button,
  children,
  errorMessage,
  hasError,
  instructionText,
  isActive,
  isDisabled,
  isDynamicLabelMinimised,
  isInline,
  isOpen,
  label,
  labelVariant = 'static',
  placeholder,
  secondaryLabel,
  secondaryLabelUrl,
  isLoading,
}) => {
  const iconRightTone = () => {
    if (isDisabled) {
      return DISABLED;
    }

    if (isActive) {
      return BRAND;
    }
  };

  const iconRight = <PlxsIcon name="search" tone={iconRightTone()} />;
  const iconLoading = (
    <div style={{ padding: '8px 8px 0 0' }}>
      <PlxsSpinner size={18} />
    </div>
  );

  return (
    <InternalFieldWrapper
      button={button}
      errorMessage={errorMessage}
      hasError={hasError}
      iconRight={isLoading ? iconLoading : iconRight}
      instructionText={instructionText}
      isActive={isActive}
      isDisabled={isDisabled}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      label={label}
      labelVariant={labelVariant}
      placeholder={placeholder}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
      styleOverrides={
        isOpen
          ? { css: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } }
          : {}
      }
    >
      {children}
    </InternalFieldWrapper>
  );
};

export default FieldWrapper;
