import React from 'react';
import { DocBulletList, DocHeading, DocRow } from '@dev/components';

const DesignSystem = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <p>
          We are in the process of developing practical guides to help you
          understand how to design and build for the Plexus Gateway platform.
        </p>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Purpose</DocHeading>
        <p>
          A design system is a constantly evolving collection of guidelines,
          tools and assets that are used to guide the design and development of
          our products.
        </p>
      </DocRow>
      <DocRow margin="lg">
        <p>The components of a design system include:</p>
        <DocBulletList
          listItems={[
            'product and UX principles,',
            'content guidelines,',
            'product design guidelines (aka a style guide),',
            'a pattern library of UI assets and interactive patterns, and',
            'a component library (a database of front end components for use by developers).',
          ]}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Delivery plan</DocHeading>
        <p>The Plexus Design System is currently under development.</p>
      </DocRow>
    </DocRow>
  );
};

export default DesignSystem;
