import React from 'react';

import { PlxsText } from '@plexus-ui/index';

export const BasicContent1 = () => {
  return (
    <PlxsText>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tincidunt
      ultricies interdum. Quisque auctor auctor ex, quis rhoncus dui elementum
      ac. Etiam dictum, mauris non condimentum pulvinar, magna massa ullamcorper
      ex, nec vehicula leo odio elementum dui. Nullam ac aliquam quam. Nam
      finibus orci eget ullamcorper iaculis. Aenean aliquet, enim in posuere
      vestibulum, eros ipsum consectetur nisl, at tristique tortor enim id
      lectus. Nullam eleifend nibh vel lectus iaculis, et pretium odio
      imperdiet. Mauris id quam eget erat gravida fermentum. Sed non dolor non
      nisl gravida ultricies. Ut nulla massa, venenatis ac diam quis, interdum
      vestibulum sapien.
    </PlxsText>
  );
};

export const BasicContent2 = () => {
  return (
    <PlxsText>
      Sed commodo vestibulum nibh non pretium. Cras aliquet neque in euismod
      pellentesque. Donec lacinia diam nisi, vel fringilla odio suscipit ac. Nam
      euismod malesuada purus, et gravida nibh posuere vel. Integer nec lorem
      ante. Nulla ut rhoncus lorem. Maecenas pellentesque id nunc sed consequat.
      Curabitur non suscipit justo, quis varius lorem. Cras suscipit eros ac leo
      aliquet placerat. Praesent tristique, enim non pellentesque dictum, erat
      tellus elementum libero, id auctor lectus mi id felis. Nunc ornare ipsum
      odio, sed pellentesque nibh ullamcorper eu. Nunc convallis, enim vitae
      convallis auctor, leo dolor aliquet libero, vitae iaculis ligula ipsum at
      mi. Integer elementum nunc sed aliquam rutrum. Nam mattis, ante eget
      mattis mattis, lacus tortor ultrices augue, non congue felis sapien eu
      purus.
    </PlxsText>
  );
};

export const BasicContent3 = () => {
  return (
    <PlxsText>
      Pellentesque auctor elit odio, vel pellentesque sem fermentum non.
      Maecenas nec lacinia diam. Duis volutpat ligula id mauris mattis, sit amet
      feugiat nunc laoreet. Duis pharetra, lacus nec viverra cursus, tellus nisl
      laoreet leo, in gravida nisl velit a sapien. Proin cursus enim diam, eget
      varius eros auctor eu. Mauris ex nulla, ornare vel tempus at, congue ut
      felis. Sed gravida est a nunc placerat, ornare imperdiet erat porttitor.
      Vestibulum ornare est sed libero tincidunt pellentesque. Cras at nulla
      ornare est bibendum tempus id ac lacus. Nam volutpat porta nibh, ut
      commodo nunc laoreet ut. Integer viverra quis nulla a mollis. Ut laoreet
      pellentesque enim, tempus iaculis arcu rutrum vitae. Integer ornare sapien
      ac vulputate vehicula.
    </PlxsText>
  );
};

export const BasicContent4 = () => {
  return (
    <PlxsText>
      Curabitur iaculis interdum leo, at scelerisque augue laoreet sit amet.
      Duis at sodales quam. Phasellus tempor tempus aliquam. Sed ex lacus,
      placerat at lectus mattis, facilisis scelerisque ex. Morbi posuere
      sollicitudin felis, nec bibendum nibh feugiat in. Duis sed nibh ac justo
      semper fringilla ut eu justo. Quisque dapibus, nibh sed pellentesque
      egestas, velit metus fringilla diam, eu laoreet orci lacus nec nibh.
      Quisque vitae ex dictum, euismod mauris non, feugiat est. Phasellus
      venenatis placerat quam. Vestibulum ante ipsum primis in faucibus orci
      luctus et ultrices posuere cubilia curae; Sed vel quam consectetur,
      interdum metus vitae, euismod nisi. Sed egestas, ante sagittis cursus
      dignissim, velit leo hendrerit diam, et volutpat nulla lorem ut nulla.
      Morbi gravida in magna vitae imperdiet. Ut risus felis, tristique eget
      magna eu, rhoncus vehicula libero. Mauris tempor suscipit sem ut pharetra.
      Vestibulum lacinia semper felis, sed mattis tortor semper ultricies.
    </PlxsText>
  );
};

export const BasicContent5 = () => {
  return (
    <PlxsText>
      Cras ut nisi non orci tempus efficitur et at risus. Nullam egestas magna
      massa, sagittis lobortis leo consectetur non. Sed eu lectus aliquet,
      euismod purus non, maximus dolor. Ut mattis, enim sed sollicitudin
      molestie, dui purus viverra risus, et sodales enim dolor facilisis arcu.
      Donec varius orci nec ipsum ultricies gravida. Ut gravida tellus ac leo
      tincidunt, in elementum est cursus. Duis non imperdiet est. Sed ut lacus
      sed purus ultricies mattis ut a nisi. Aliquam nec rhoncus leo. Sed
      bibendum non eros eget ultricies. Phasellus ut imperdiet ex, at rhoncus
      tellus. Nullam metus libero, ullamcorper quis finibus eu, bibendum et mi.
      Duis at mauris lorem.
    </PlxsText>
  );
};

export const BasicContent6 = () => {
  return (
    <PlxsText>
      Proin est elit, tristique et lobortis eget, mattis quis orci. Quisque
      ullamcorper, turpis ac scelerisque finibus, lacus sapien euismod nunc, vel
      hendrerit neque augue vitae ligula. Suspendisse malesuada leo dui,
      hendrerit pharetra massa aliquet quis. Proin bibendum lacinia enim sed
      laoreet. Suspendisse aliquet tellus ac nisi suscipit molestie. Donec
      finibus elit in odio pulvinar, vitae condimentum tortor consectetur. Duis
      non consequat nisl. Ut viverra enim sit amet dui malesuada vehicula in a
      nisi. Nullam in risus ut turpis maximus vestibulum.
    </PlxsText>
  );
};

export const BasicContent7 = () => {
  return (
    <PlxsText>
      Maecenas quam dui, venenatis id faucibus nec, finibus commodo velit. Sed
      venenatis, massa a elementum hendrerit, nisl ante dictum odio, ut rhoncus
      elit velit ut lectus. Maecenas vel commodo leo. Quisque fringilla aliquam
      velit sollicitudin egestas. Sed et rutrum sapien. Fusce commodo risus
      odio, vel finibus nisl fringilla eu. Nam consectetur lobortis quam non
      aliquet. Orci varius natoque penatibus et magnis dis parturient montes,
      nascetur ridiculus mus.
    </PlxsText>
  );
};

export const BasicContent8 = () => {
  return (
    <PlxsText>
      Donec consequat orci a accumsan ultricies. Sed et aliquam lectus, eget
      volutpat mauris. Duis eu velit non diam vehicula euismod eget gravida
      diam. Sed ligula leo, tempor sed odio eget, euismod consequat velit. Donec
      et massa ac tortor tempus sodales. Nullam egestas elit eget efficitur
      aliquet. Morbi quam nibh, venenatis quis sapien dictum, mollis ultricies
      enim. Maecenas mollis neque massa, vitae tristique urna scelerisque vitae.
      Nulla mollis commodo dolor ut egestas. Donec id arcu nulla. Suspendisse
      potenti. Sed turpis dolor, pretium sed vestibulum at, porttitor et ex.
      Nunc varius massa diam, at pulvinar urna elementum ac. Sed non
      pellentesque libero. Nunc vel nisl ac sem congue ultricies vitae sit amet
      ipsum. Ut ipsum ipsum, tempor nec consectetur in, porttitor quis neque.
    </PlxsText>
  );
};

export const BasicContent9 = () => {
  return (
    <PlxsText>
      Nam non pellentesque justo. Etiam a velit vitae massa fringilla mattis sit
      amet nec nisl. Morbi porta arcu ut eros vestibulum, eget tincidunt ante
      suscipit. Maecenas dapibus orci eros, a varius augue ultrices sit amet.
      Pellentesque ante massa, interdum eget quam in, lobortis pretium elit.
      Aenean interdum mi ligula, nec venenatis nibh facilisis quis. Fusce
      vestibulum purus lacus, in feugiat augue interdum id. Donec et magna nec
      dui vehicula varius vitae a leo. Integer ultrices ligula ac eros accumsan
      consequat. Sed mattis ligula nec nisi malesuada, consectetur fermentum mi
      mattis. Integer interdum iaculis tellus et maximus. Suspendisse tempus
      libero eu tristique imperdiet.
    </PlxsText>
  );
};

export const BasicContent10 = () => {
  return (
    <PlxsText>
      Donec viverra elit arcu, eget scelerisque neque consectetur id. Vivamus
      vitae nisi orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      In aliquet dictum egestas. Sed maximus est quis sodales lobortis. Aliquam
      pellentesque justo sit amet quam aliquet rutrum. Mauris ultrices
      scelerisque turpis, non tempus dui scelerisque non. Proin lobortis vitae
      mauris non pretium. Aliquam ut erat id lorem volutpat vestibulum ac nec
      lorem. Fusce tristique, tellus at ultricies tincidunt, nunc neque pretium
      ex, sit amet condimentum neque sem vitae odio. Phasellus posuere, leo vel
      placerat pharetra, felis magna bibendum sem, sed interdum orci ipsum in
      augue. Quisque efficitur dictum ante, at feugiat ante rhoncus ut. Sed id
      venenatis tortor. Sed sollicitudin gravida diam, vel sagittis leo accumsan
      a.
    </PlxsText>
  );
};

export const BasicContent11 = () => {
  return (
    <PlxsText>
      Pellentesque ultricies sagittis magna, sed elementum massa ultrices id.
      Integer sit amet odio non ante commodo molestie. Aliquam at nisi diam.
      Vivamus suscipit ex sit amet eros fermentum, vitae cursus lorem pulvinar.
      Quisque non tincidunt ligula. Pellentesque rhoncus vulputate maximus.
      Aenean in laoreet purus. Mauris in consectetur velit. Maecenas molestie
      orci sit amet luctus dapibus.
    </PlxsText>
  );
};

export const BasicContent12 = () => {
  return (
    <PlxsText>
      Suspendisse id tellus massa. Nulla pellentesque, dolor et bibendum
      tristique, nibh sem lobortis velit, feugiat elementum ex justo ut turpis.
      Nunc purus mi, bibendum ac sagittis nec, vulputate tincidunt lacus.
      Praesent non molestie nunc, id tempus augue. Sed leo lectus, rhoncus id
      elit quis, gravida feugiat libero. Fusce iaculis sem non aliquam volutpat.
      Nullam eleifend blandit viverra. Nam feugiat pharetra ex quis ullamcorper.
    </PlxsText>
  );
};
