import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { DocHeading, DocRow, DocParagraph } from '@dev/components';

import { PlxsInput } from '@plexus-ui/index';

const Default: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Fragment>
      <DocRow margin="xxl">
        <DocRow margin="lg">
          <DocHeading>Default</DocHeading>
          <DocParagraph>The input in default state.</DocParagraph>
          <PlxsInput
            dataTestId="default-input"
            onChange={handleChange}
            value={value}
          />
        </DocRow>
      </DocRow>
    </Fragment>
  );
};

export default Default;
