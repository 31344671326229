import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ComponentProps } from './Search.types';
import FieldWrapper from './_internal/FieldWrapper';
import MultiSelectList from '../_internal/MultiSelectList';

import { useStyles } from './Search.styles';
import { useClickAway } from '../../hooks';

function Search<TOption>(props: ComponentProps<TOption>) {
  const {
    dataTestId,
    renderSelectedOptionValue,
    initialValue,
    isDefaultActive = false,
    isDisabled = false,
    isInline = true,
    maxMenuListHeight = 300,
    onBlur,
    onFocus,
    onSelect,
    optionList,
    renderOption,
    tooltipTextKey,
    onChange,
    isLoading,
    optionKeyMatch = 'id',
    hasError,
    emptyOptionText,
  } = props;
  const searchDataTestId = `${dataTestId}--search`;

  const [_optionList, setOptionList] = useState<TOption[]>([]);
  const [isActive, setActive] = useState(isDefaultActive);
  const [selectedList, setSelectedList] = useState<TOption[]>([]);
  const [value, setValue] = useState<string>('');
  const [highlightOptionIndex, setHighlightOptionIndex] = useState(-1);

  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listboxRef = useRef<HTMLUListElement>(null);

  const hasValue = Boolean(value);
  const hasSelection = Boolean(selectedList.length);
  const isDynamicLabelMinimised = hasValue || hasSelection || isActive;
  const isOpen = Boolean(_optionList.length);

  const classes = useStyles({
    maxMenuListHeight,
    isActive,
    hasError,
    isDisabled,
    hasSelection,
  });

  useClickAway(searchRef, () => setActive(false));

  useEffect(() => {
    if (optionList) {
      setHighlightOptionIndex(optionList.length > 0 ? 0 : -1);
      setOptionList(optionList);
    }
  }, [optionList]);

  useEffect(() => {
    if (initialValue) {
      setSelectedList(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (isDefaultActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDefaultActive]);

  useEffect(() => {
    if (listboxRef.current && listboxRef.current.scrollHeight > 0) {
      const padding = 16;
      const { clientHeight: listboxClientHeight, scrollTop: listboxScrollTop } =
        listboxRef.current;
      let totalOptionHeightVisible = 0;
      for (let index = 0; index <= highlightOptionIndex; index++) {
        const { scrollHeight: currentTargetHeight } =
          listboxRef.current.children[index];
        totalOptionHeightVisible += currentTargetHeight;
      }

      const totalScrollHeight = listboxClientHeight + listboxScrollTop;

      // handle scroll to down when highlightOptionIndex is not in view
      if (totalOptionHeightVisible > totalScrollHeight) {
        listboxRef.current.scrollTop =
          totalOptionHeightVisible - listboxClientHeight + padding;
      }

      // handle scroll to up when highlightOptionIndex is not in view
      let topOptionHeightVisible = 0;
      for (let index = 0; index <= highlightOptionIndex; index++) {
        const { scrollHeight: currentTargetHeight } =
          listboxRef.current.children[index];
        topOptionHeightVisible += currentTargetHeight;
      }

      if (listboxRef.current?.children[highlightOptionIndex]) {
        const { scrollHeight: currentTargetHeight } =
          listboxRef.current.children[highlightOptionIndex];
        if (topOptionHeightVisible - padding < listboxScrollTop) {
          listboxRef.current.scrollTop = listboxScrollTop - currentTargetHeight;
        }
      }
    }
  }, [highlightOptionIndex]);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.value, selectedList);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setActive(true);
    onFocus && onFocus(event);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        if (highlightOptionIndex < _optionList.length - 1) {
          setHighlightOptionIndex(highlightOptionIndex + 1);
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (highlightOptionIndex > 0) {
          setHighlightOptionIndex(highlightOptionIndex - 1);
        }
        break;
      case 'Enter':
        event.preventDefault();
        highlightOptionIndex > -1 &&
          handleSelect(_optionList[highlightOptionIndex]);
        break;
      case 'Escape':
        event.preventDefault();
        setValue('');
        break;
      case 'Backspace':
        if (!value && selectedList.length > 0) {
          const lastSelected = selectedList[selectedList.length - 1];
          handleRemove(lastSelected);
        }
    }
  };

  const handleOptionMouseEnter = (event: MouseEvent<HTMLLIElement>) => {
    const { currentTarget } = event;
    const { suggestionIndex } = currentTarget.dataset;
    setHighlightOptionIndex(Number(suggestionIndex));
  };

  const handleRemove = (suggestion: TOption) => {
    const selectedListUpdate = selectedList.filter(
      (item: TOption) => item[optionKeyMatch] !== suggestion[optionKeyMatch]
    );
    setSelectedList(selectedListUpdate);
    onSelect(selectedListUpdate);
    setOptionList([]);
    setHighlightOptionIndex(-1);
    if (inputRef.current) {
      inputRef.current.focus();

      if (inputRef.current.value) {
        onChange(inputRef.current.value, selectedListUpdate);
      }
    }
  };

  const handleSelect = (suggestion: TOption) => {
    const selectedListUpdate = [...selectedList, suggestion];
    setSelectedList(selectedListUpdate);
    onSelect(selectedListUpdate);
    setOptionList([]);
    setValue('');
    setHighlightOptionIndex(-1);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <FieldWrapper
      {...props}
      isActive={isActive}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      isDisabled={isDisabled}
      isOpen={!isLoading && hasValue && isOpen}
      labelVariant={props.labelVariant ? props.labelVariant : 'static'}
    >
      <div className={classes.root} ref={searchRef}>
        <MultiSelectList
          dataTestId={`${searchDataTestId}--selected-list`}
          getOptionValue={renderSelectedOptionValue}
          isDisabled={isDisabled}
          isFocused={isActive}
          onRemove={handleRemove}
          selectedList={selectedList}
          tooltipTextKey={tooltipTextKey}
        />
        <input
          ref={inputRef}
          type="text"
          id="search-input"
          data-testid={`${searchDataTestId}--input`}
          className={`${classes.input}`}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          value={value}
          disabled={isDisabled}
        />

        {!isLoading && hasValue && (isOpen || Boolean(emptyOptionText)) && (
          <div data-testid={`${searchDataTestId}--dropdown`}>
            <ul
              className={`${classes.listbox} ${
                isActive && classes.listboxAnimation
              }`}
              role="listbox"
              ref={listboxRef}
            >
              {_optionList.length === 0 && (
                <li className={classes.emptyOption} role="option">
                  {emptyOptionText}
                </li>
              )}

              {_optionList.length > 0 &&
                _optionList.map((suggestion: TOption, idx: number) => {
                  return (
                    <li
                      className={`${classes.option} ${
                        highlightOptionIndex === idx && classes.highlightOption
                      }`}
                      role="option"
                      aria-selected={highlightOptionIndex === idx}
                      key={suggestion[optionKeyMatch]}
                      onClick={() => handleSelect(suggestion)}
                      data-suggestion-index={idx}
                      onMouseEnter={handleOptionMouseEnter}
                    >
                      {renderOption(suggestion)}
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </FieldWrapper>
  );
}

export default Search;
