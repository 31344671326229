import { makeStyles } from '@material-ui/core/styles';
import { neutral } from '../../../../styles/color';
import { tone } from '../../../../styles/tone';
import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  panelHeader: (props: StyleProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: props.hasBorder ? `1px solid ${neutral[800]}` : 'none',
    minHeight: props.variant === 'tab' ? '40px' : '50px',
    cursor: 'pointer',
    borderBottom:
      props.variant === 'tab'
        ? `1px solid ${neutral[600]}`
        : props.hasBorder
        ? `1px solid ${neutral[800]}`
        : 'none',
  }),

  titleContainer: (props: StyleProps) => ({
    display: 'flex',
    padding: props.variant === 'tab' ? '8px 16px' : '16px',
    flexGrow: props.variant === 'tab' ? 0 : 1,
    position: 'relative',
    marginLeft: props.variant === 'tab' ? '38px' : '0',
    flexDirection: 'row',
    alignItems: 'center',
  }),

  panelBorder: {
    backgroundColor: tone.brand,
    borderRadius: '12px 12px 0 0',
    bottom: '0',
    height: '4px',
    left: '0',
    position: 'absolute',
    right: '0',
  },

  expandIconContainer: (props: StyleProps) => ({
    display: 'flex',
    width: props.variant === 'tab' ? '40px' : '56px',
    minWidth: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.variant === 'tab' ? '40px' : 'auto',
    borderLeft: props.variant === 'tab' ? `1px solid ${neutral[600]}` : 'none',
  }),
}));
