import React from 'react';
import {
  DocCode,
  DocParagraph,
  DocPropGroupHeading,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsTextarea } from '@plexus-ui/index';

import InstructionTextarea from './Instruction';
import ResizableTextarea from './Resizable';

export default () => {
  return (
    <DocRow margin="lg">
      <DocPropGroupHeading>Other props</DocPropGroupHeading>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Auto Focus</DocSubHeading>
          <DocParagraph>
            Setting autoFocus to true will focus the Textarea on load.
          </DocParagraph>
        </DocRow>
        <DocCode>{`<PlxsTextarea
  autoFocus={true}
  dataTestId="auto-focus-textarea"
  label="Input name to go here"
  onChange={handleChange}
  placeholder="Field helper text"
  value={value}
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Instruction text</DocSubHeading>
          <DocParagraph>
            Renders an additional message in small font directly below the
            Textarea and above the error message (if displayed).
          </DocParagraph>
        </DocRow>
        <InstructionTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="instruction-textarea"
  instructionText="Additional instructions go here"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Placeholder text</DocSubHeading>
          <DocParagraph>
            Renders placeholder text inside the Textarea. For the dynamic label
            variant the placeholder text will be replaced by the dynamic label.
          </DocParagraph>
        </DocRow>
        <DocCode>{`<PlxsTextarea
  dataTestId="placeholder-textarea"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Padding</DocSubHeading>
          <DocParagraph>
            Renders the Textarea with small or medium padding. Defaults to
            medium.
          </DocParagraph>
        </DocRow>
        <PlxsTextarea
          dataTestId="placeholder-textarea"
          label="Input name to go here"
          onChange={() => {}}
          padding="sm"
          value="Example text to show small padding variant."
        />
        <DocCode>{`<PlxsTextarea
  dataTestId="placeholder-textarea"
  label="Input name to go here"
  onChange={() => {}}
  padding="sm"
  value="Example text to show small padding variant."
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Resizable</DocSubHeading>
          <DocParagraph>The textarea height can be resized.</DocParagraph>
        </DocRow>
        <ResizableTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="reset-textarea"
  isResizable={true}
  label="Input name to go here"
  onChange={() => {}}
  onReset={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>
    </DocRow>
  );
};
