import React, { FC } from 'react';

import { ComponentProps } from './_types';

const ApprovalSuccess: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    width="78"
    height="76"
    viewBox="0 0 78 76"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="48" cy="29.9998" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M3.25 75.4998H48.75V68.9998H3.25V75.4998ZM42.25 49.4998H35.4453C33.8203 49.4998 32.5 48.1794 32.5 46.5544V45.6404C32.5 42.7966 33.3125 40.156 34.6328 37.7185C35.5469 35.8904 35.9531 33.7576 35.5469 31.5232C34.8359 27.6638 31.6875 24.4138 27.7266 23.7029C21.5312 22.5857 16.25 27.3591 16.25 33.2498C16.25 34.7732 16.5547 36.0935 17.0625 37.3123C18.4844 40.2576 19.5 43.3044 19.5 46.5544C19.5 48.1794 18.0781 49.4998 16.4531 49.4998H9.75C4.26562 49.4998 0 53.8669 0 59.2498V62.4998C0 64.3279 1.42188 65.7498 3.25 65.7498H48.75C50.4766 65.7498 52 64.3279 52 62.4998V59.2498C52 53.8669 47.6328 49.4998 42.25 49.4998Z"
    />
  </svg>
);

export default ApprovalSuccess;
