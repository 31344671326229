import React, { FC } from 'react';
import { useStyles } from './styles';

export interface ComponentProps {
  listItems: string[];
}

const BulletList: FC<ComponentProps> = ({ listItems }) => {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {listItems.map((item, index) => (
        <li className={classes.listItem} key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default BulletList;
