import React, { FC } from 'react';

import { ComponentProps } from './_types';

const SignatureDeclined: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    width="79"
    height="76"
    viewBox="0 0 79 76"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="49" cy="29.9998" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M51.4766 31.1169L45.3828 25.0232C43.5547 23.0935 40.4062 23.0935 38.4766 25.0232L32.7891 30.7107L45.7891 43.7107L51.4766 38.0232C53.4062 36.0935 53.4062 32.9451 51.4766 31.1169ZM26.4922 28.9841C24.8672 27.4607 22.3281 27.4607 20.7031 28.9841L8.61719 41.0701C8.00781 41.6794 8.00781 42.6951 8.61719 43.406L10.9531 45.6404C11.5625 46.2498 12.5781 46.2498 13.2891 45.6404L23.5469 35.281L25.8828 37.6169L10.4453 53.0544C5.26562 58.2341 1.8125 65.1404 1 72.8591C0.796875 74.3826 2.11719 75.7029 3.64062 75.4998C11.3594 74.6873 18.2656 71.2341 23.4453 66.0544L43.4531 46.0466L35.0234 37.6169L26.4922 28.9841Z"
    />
  </svg>
);

export default SignatureDeclined;
