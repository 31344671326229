import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '@plexus-ui/styles/color';

const borderStyle = `1px solid ${neutral[600]}`;

export const useStyles = makeStyles(() => ({
  navigation: {
    borderBottom: borderStyle,
    marginBottom: '16px',
    '& .react-horizontal-scrolling-menu--wrapper': {
      alignItems: 'flex-end',
    },
    '& .react-horizontal-scrolling-menu--scroll-container': {
      overflow: 'hidden',
    },
  },
}));
