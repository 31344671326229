import React, { FC, ReactNode } from 'react';

import InternalFieldWrapper from '../../_internal/FieldWrapper';
import { FieldStyleOverrides } from '../../_internal/FieldWrapper/types';
import { SelectProps } from '../Select.types';

export interface SelectFieldWrapperProps extends SelectProps<any> {
  isActive: boolean;
  children: ReactNode;
  styleOverrides?: FieldStyleOverrides;
}

const SelectFieldWrapper: FC<SelectFieldWrapperProps> = ({
  children,
  errorMessage,
  hasError,
  instructionText,
  isActive,
  isInline,
  isDisabled,
  label,
  secondaryLabel,
  secondaryLabelUrl,
  styleOverrides,
}) => {
  return (
    <InternalFieldWrapper
      errorMessage={errorMessage}
      hasError={hasError}
      instructionText={instructionText}
      isActive={isActive}
      isInline={isInline}
      isDisabled={isDisabled}
      label={label}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
      styleOverrides={styleOverrides}
    >
      {children}
    </InternalFieldWrapper>
  );
};

export default SelectFieldWrapper;
