import React, {
  Children,
  FC,
  isValidElement,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { PROCESSABLE_KEYS } from './constants';
import { ComponentProps } from './types';
import { useStyles } from './styles';

const MenuList: FC<ComponentProps> = ({
  autoFocus = false,
  children,
  dataTestId,
  id,
  onKeyExit,
  onSelect,
}) => {
  const classes = useStyles();
  const childrenRef = useRef([]);
  const listRef = useRef(null);

  const [highlightedItemIndex, setHighlightedItemIndex] = useState(
    autoFocus ? 0 : -1
  );

  const childrenCount = Children.count(children);
  const maxItemIndex = childrenCount - 1; // 0-based index for collection of children

  const setItemFocus = (itemIndex: number) => {
    if (itemIndex < 0) {
      const currentChild = childrenRef.current[highlightedItemIndex];
      currentChild && currentChild.blur();
    } else {
      childrenRef.current[itemIndex].focus();
    }

    setHighlightedItemIndex(itemIndex);
  };

  const handleItemClick = (event: MouseEvent, itemValue) => {
    onSelect(itemValue);
  };

  const handleItemMouseEnter = (event: MouseEvent, itemIndex: number) => {
    listRef.current.focus();
    setItemFocus(itemIndex);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (PROCESSABLE_KEYS.includes(key)) {
      event.preventDefault();

      if (key === 'ArrowUp') {
        const newIndex = Math.min(highlightedItemIndex - 1, maxItemIndex);
        setItemFocus(newIndex < 0 ? maxItemIndex : newIndex);
      } else if (key === 'ArrowDown') {
        const newIndex = Math.max(0, highlightedItemIndex + 1);
        setItemFocus(newIndex > maxItemIndex ? 0 : newIndex);
      } else if (key === ' ' || key === 'Enter') {
        const child = Children.toArray(children)[highlightedItemIndex];

        if (isValidElement(child) && !child.props.isDisabled) {
          onSelect(child.props.value);
        }
      } else {
        onKeyExit();
      }
    }
  };

  const handleMouseLeave = (event: MouseEvent) => {
    setItemFocus(-1);
    listRef.current.focus();
  };

  const items = Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      const newChildProps = {
        onClick: (event: MouseEvent) =>
          handleItemClick(event, child.props.value),
        onMouseEnter: (event: MouseEvent) => handleItemMouseEnter(event, index),
        ref: (ref) => (childrenRef.current[index] = ref),
      };

      return React.cloneElement(child, newChildProps);
    }
  });

  useEffect(() => {
    if (autoFocus && childrenRef.current[0]) {
      childrenRef.current[0].focus();
    }
  }, [autoFocus]);

  return (
    <ul
      className={classes.root}
      data-testid={dataTestId}
      id={id}
      onKeyDown={handleKeyDown}
      onMouseLeave={handleMouseLeave}
      ref={listRef}
      tabIndex={0}
    >
      {items}
    </ul>
  );
};

export default MenuList;
