import React, { useState } from 'react';
import { DocCode, DocParagraph, DocRow } from '@dev/components';
import {
  PlxsButton,
  PlxsButtonGroup,
  PlxsField,
  PlxsMultiSelect,
} from '@plexus-ui/index';

export default () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasError, setError] = useState(false);
  const [label, setLabel] = useState<string>();
  const [selection, setSelection] = useState<string[]>([]);

  const code =
    '<PlxsField\n' +
    `  errorMessage="${errorMessage}"\n` +
    `  hasError="${hasError}"\n` +
    `  label="${label}"\n` +
    '>\n' +
    '  <PlxsMultiSelect ... />\n' +
    '</PlxsField>';

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocParagraph>
          The PlxsField component is used to render labels and other elements
          which typically surround input components.
        </DocParagraph>
        <DocParagraph>
          It is also responsible for communicating error states to those
          components (meaning those components must be wrapped in a PlxsField to
          show an error state). This avoids the need to pass error information
          to two different components (both the PlxsField and its child) for
          consistent styling.
        </DocParagraph>
      </DocRow>
      <DocRow margin="lg">
        <DocCode>{code}</DocCode>
      </DocRow>
      <DocRow margin="lg">
        <PlxsButtonGroup>
          <PlxsButton
            dataTestId="placeholder"
            label="Toggle label"
            onClick={() => setLabel(label ? undefined : 'Field Name')}
          />
          <PlxsButton
            dataTestId="placeholder"
            label="Toggle error"
            onClick={() => setError(!hasError)}
          />
          <PlxsButton
            dataTestId="placeholder"
            label="Toggle error message"
            onClick={() =>
              setErrorMessage(errorMessage ? undefined : 'An error occurred')
            }
          />
        </PlxsButtonGroup>
      </DocRow>
      <DocRow margin="lg">
        <PlxsField
          errorMessage={errorMessage}
          hasError={hasError}
          label={label}
        >
          <PlxsMultiSelect
            dataTestId="placeholder"
            getLabel={(o) => o}
            onChange={setSelection}
            options={['Item 1', 'Item 2', 'Item 3']}
            placeholder="This field contains a PlxsMultiSelect component, for illustrative purposes"
            value={selection}
          />
        </PlxsField>
      </DocRow>
    </DocRow>
  );
};
