import React, { FC, useState } from 'react';

import { DocRow } from '@dev/components';
import { PlxsAutoComplete, PlxsText } from '@plexus-ui/index';

const fruits = ['apple', 'kiwi', 'mango', 'pear', 'pineapple'];

const SingleSelectActive: FC = () => {
  const [selection, setSelection] = useState('');

  const handleSelect = (option: string) => {
    setSelection(JSON.stringify(option));
  };

  return (
    <DocRow margin="md">
      <PlxsAutoComplete
        dataTestId="single-select-simple"
        autoFocus={true}
        label="Fruits"
        onSelect={handleSelect}
        optionList={fruits}
        isDefaultActive={true}
      />
      <PlxsText>Selected option: {selection}</PlxsText>
    </DocRow>
  );
};

export default SingleSelectActive;

export const SingleSelectActiveSnippet = `import React, { FC } from 'react';

import { PlxsAutoComplete } from '@plexus-ui/index';

const fruits = ['apple', 'kiwi', 'mango', 'pear', 'pineapple'];

const SingleSelectActive: FC = () => {
  const handleSelect = (option: string) => {
    setSelection(JSON.stringify(option));
  };

  return (
    <PlxsAutoComplete
      dataTestId="single-select-simple"
      autoFocus={true}
      label="Fruits"
      onSelect={handleSelect}
      optionList={fruits}
      isDefaultActive={true}
    />
  );
};

export default SingleSelectActive;`;
