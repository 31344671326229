import {
  AnchorDocs,
  FieldWrapperDocs,
  LabelDocs,
  ListBoxDocs,
  MenuListDocs,
  ToneDocs,
  ToneBgDocs,
} from '@dev/docs/_internal/index';

const componentListInternal = [
  {
    component: AnchorDocs,
    name: 'Anchor',
    path: '/anchor',
  },
  {
    component: FieldWrapperDocs,
    name: 'FieldWrapper',
    path: '/fieldWrapper',
  },
  {
    component: LabelDocs,
    name: 'Label',
    path: '/label',
  },
  {
    component: ListBoxDocs,
    name: 'ListBox',
    path: '/ListBox',
  },
  {
    component: MenuListDocs,
    name: 'MenuList',
    path: '/menuList',
  },
  {
    component: ToneDocs,
    name: 'Tone',
    path: '/tone',
  },
  {
    component: ToneBgDocs,
    name: 'ToneBg',
    path: '/toneBg',
  },
];

export default componentListInternal;
