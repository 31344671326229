import { makeStyles } from '@material-ui/core/styles';
import { color, fontColor } from '../../styles';
import { StyleProps } from './Alert.types';

const backgroundColor = (props: StyleProps) => {
  switch (props.variant) {
    case 'error': {
      return color.urgentLightest;
    }
    case 'warning': {
      return color.cautionLightest;
    }
    case 'success': {
      return color.postiveLightest;
    }
    default: {
      return 'inherit';
    }
  }
};

const variantColor = (props: StyleProps) => {
  switch (props.variant) {
    case 'error': {
      return color.urgent;
    }
    case 'warning': {
      return color.caution;
    }
    case 'success': {
      return color.positive;
    }
    default: {
      return 'inherit';
    }
  }
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    borderRadius: '4px',
    border: props.hasOutline ? `1px solid ${variantColor(props)}` : undefined,
    overflow: 'hidden',
    padding: '16px 16px 16px 12px',
    position: 'relative',
    width: props.isFullWidth ? '100%' : undefined,
  }),
  background: (props: StyleProps) => ({
    backgroundColor: backgroundColor(props),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }),
  children: (props: StyleProps) => ({
    textAlign: props.hasTextAlignCenter ? 'center' : undefined,
    width: '100%',
  }),
  iconWrapper: (props: StyleProps) => ({
    color: variantColor(props),
    marginRight: '12px',
  }),
  innerWrapper: {
    alignItems: 'center',
    color: fontColor.default,
    display: 'flex',
    position: 'relative',
    zIndex: 1,
  },
}));
