import { makeStyles } from '@material-ui/core/styles';
import { DataType } from 'csstype';
import { brand, neutral, urgent } from '../../styles/color';
import { Tone } from '../../_types';

interface FieldStyle {
  color: DataType.Color;
  tone: Tone;
}

function getFieldStyle(isActive: boolean, hasError?: boolean): FieldStyle {
  if (isActive) {
    return { color: brand[400], tone: 'brand' };
  } else if (hasError) {
    return { color: urgent[400], tone: 'urgent' };
  } else {
    return { color: neutral[400], tone: 'neutral' };
  }
}

export function getFieldColor(
  isActive: boolean,
  hasError?: boolean
): DataType.Color {
  const { color } = getFieldStyle(isActive, hasError);

  return color;
}

export function getFieldTone(isActive: boolean, hasError?: boolean): Tone {
  const { tone } = getFieldStyle(isActive, hasError);

  return tone;
}

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '8px',
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
});
