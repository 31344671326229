import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@plexus-ui/styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  root: ({ margin }: StyleProps) => ({
    marginBottom: margin && spacing[margin],
  }),
}));
