import React, { FC } from 'react';

import { ComponentProps } from './_types';

const PermissionsEmpty: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    height="75"
    width="74"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36.5" cy="23.9" r="23.5" fill={circleFill} />
    <path
      fill={iconFill}
      d="M26.7 31.6c-1.1-7.1-3.9-12.1-7-12.1-3.2 0-5.9 5-7.1 12.1h14.1zm-14.6 7.6c0 1.8.1 3.5.2 5.1H27c.2-1.6.2-3.3.2-5.1 0-1.7-.1-3.4-.2-5.1H12.3c-.1 1.7-.2 3.4-.2 5.1zm25.7-7.6C35.6 26.3 31 22 25.3 20.4c2 2.7 3.3 6.8 4 11.2h8.5zM14.1 20.4C8.3 22 3.7 26.3 1.5 31.6h8.6c.7-4.5 2-8.5 4-11.2zm24.6 13.7h-9.1c.2 1.7.3 3.4.3 5.1 0 1.7-.2 3.4-.3 5.1h9.1c.4-1.6.6-3.3.6-5.1 0-1.7-.2-3.4-.6-5.1zM9.5 39.2c0-1.7.1-3.4.2-5.1H.6c-.4 1.7-.6 3.4-.6 5.1 0 1.8.2 3.5.6 5.1h9.1c-.1-1.7-.2-3.3-.2-5.1zm3.1 7.6c1.2 7.2 3.9 12.1 7.1 12.1 3.1 0 5.9-4.9 7-12.1H12.6zm12.7 11.3c5.7-1.7 10.3-5.9 12.6-11.3h-8.6c-.7 4.6-2 8.6-4 11.3zM1.5 46.8c2.2 5.4 6.8 9.6 12.6 11.3-2-2.7-3.3-6.8-4-11.3H1.5z"
    />
  </svg>
);

export default PermissionsEmpty;
