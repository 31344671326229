import React, { useCallback } from 'react';
import { ComponentProps } from './ListBox.types';
import { useStyles } from './ListBox.styles';
import PlxsCheckbox from '../../Checkbox';
import { getValue } from './ListBox.utils';

const ListBox = <Option extends any>({
  getDataTestId,
  getLabel,
  onChange,
  options,
  value,
}: ComponentProps<Option>) => {
  const classes = useStyles();

  const handleOptionValueChange = useCallback(
    (option: Option, optionValue: boolean) => {
      const newValue = getValue(value, option, optionValue);

      onChange(newValue);
    },
    [onChange, value]
  );

  return (
    <div className={classes.root}>
      {options.map((option, index) => (
        <div className={classes.option} key={index}>
          <PlxsCheckbox
            dataTestId={getDataTestId ? getDataTestId(option) : ''}
            label={getLabel(option)}
            onChange={(value) => handleOptionValueChange(option, value)}
            padding="sm"
            value={value.includes(option)}
          />
        </div>
      ))}
    </div>
  );
};

export default ListBox;
