import React, { FC, Fragment, useState } from 'react';
import { DocHeading, DocRow, DocParagraph } from '@dev/components';
import {
  PlxsButton,
  PlxsButtonGroup,
  PlxsModal,
  PlxsText,
  PlxsTextLink,
} from '@plexus-ui/index';

import { Content } from './_internal/Content';
import ErrorMessage from './_internal/ErrorMessage';
import PrimaryButton from './_internal/PrimaryButton';

const WithContent: FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [hasError, setError] = useState(false);

  const handleToggleErrorMessage = () => {
    setError(!hasError);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const subheading = (
    <PlxsText>
      Modal subheading with <PlxsTextLink>a link</PlxsTextLink>
    </PlxsText>
  );

  return (
    <Fragment>
      <DocRow margin="xxl">
        <DocRow margin="lg">
          <DocHeading>With content</DocHeading>
          <DocParagraph>
            This example shows the modal with a full content block and error
            message toggle interaction.
          </DocParagraph>
          <PlxsButtonGroup>
            <PlxsButton
              dataTestId="basic-modal--button"
              onClick={handleOpen}
              label="Open"
            />
          </PlxsButtonGroup>
          <PlxsModal
            dataTestId="basic-modal"
            errorMessage={hasError && <ErrorMessage />}
            heading="Modal Heading"
            isOpen={isOpen}
            onClose={handleClose}
            primaryButton={<PrimaryButton onClick={handleClose} />}
            subheading={subheading}
          >
            <Content onToggleErrorMessage={handleToggleErrorMessage} />
          </PlxsModal>
        </DocRow>
      </DocRow>
    </Fragment>
  );
};

export default WithContent;
