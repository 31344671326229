import React from 'react';
import { DocHeading, DocParagraph, DocRow } from '@dev/components';
import {
  brand,
  caution,
  neutral,
  positive,
  urgent,
} from '@plexus-ui/styles/color';

import SwatchList from './SwatchList';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="md">
      <DocParagraph>
        From the Plexus style guide a digital version of the Plexus Blue has
        been created. The original style guide has been created from a marketing
        and print perspective and lacks the vibrancy associated with digital
        product and on screen viewing.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>60% - Dominant colour (Neutral tones)</DocHeading>
      <DocParagraph>
        Neutral tones used for background and containers. This colour will make
        up 60% of the product.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>30% - Brand colour</DocHeading>
      <DocParagraph>
        Referred to as the secondary colour this is usually the brand colour
        that is used in contrast again the dominant colour to draw the eye.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>10% - Supporting accents</DocHeading>
      <DocParagraph>
        This is can be selection of colours that should compliment the brand
        colour and will be used in limited amounts for status labels, alerts and
        progress bars.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Shades</DocHeading>
      <DocParagraph>
        Shades of each of the colours are created to give a wider variety in
        colour options. These can only be used in conjunction with their
        corresponding colour as a background or outline.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <SwatchList title="Primary Color - Brand" tone={brand} />
    </DocRow>

    <DocRow margin="md">
      <SwatchList title="Primary Color - Positive" tone={positive} />
    </DocRow>

    <DocRow margin="md">
      <SwatchList title="Primary Color - Caution" tone={caution} />
    </DocRow>

    <DocRow margin="md">
      <SwatchList title="Primary Color - Urgent" tone={urgent} />
    </DocRow>

    <DocRow margin="md">
      <SwatchList title="Primary Color - Neutral" tone={neutral} />
    </DocRow>
  </DocRow>
);
