import React from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ButtonIconOnly = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Icon Only</DocSubHeading>
        <DocParagraph>
          The prop isIconOnly will render an icon only button. For this type of
          button to render correctly a combination of props; functionalIcon and
          variant must be used.
        </DocParagraph>
      </DocRow>
      <DocRow margin="sm">
        <DocSubHeading>Variant</DocSubHeading>
        <DocParagraph>
          The variant prop will further modify the tone, it's important to note
          not all variants have a corresponding tone, this is by design.
        </DocParagraph>
      </DocRow>
      <DocRow margin="sm">
        <DocSubHeading>Primary</DocSubHeading>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Positive</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="positive-button"
              functionalIcon="signature"
              isIconOnly={true}
              label="Primary button"
              tone="positive"
              variant="primary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="signature" isIconOnly={true} tone="positive" variant="primary"'
              }
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Brand</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="brand-button"
              functionalIcon="infoCircle"
              isIconOnly={true}
              label="Primary button"
              tone="brand"
              variant="primary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="infoCircle" isIconOnly={true} tone="brand" variant="primary"'
              }
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Urgent</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="urgent-button"
              functionalIcon="bin"
              isIconOnly={true}
              label="Primary button"
              tone="urgent"
              variant="primary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="bin" isIconOnly={true} tone="urgent" variant="primary"'
              }
            </DocCode>
          </div>
        </li>
        <li className={`${classes.listItem} ${classes.listItemBackground}`}>
          <div className={classes.displayName}>White</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="white-button"
              functionalIcon="person"
              isIconOnly={true}
              label="Primary button"
              tone="white"
              variant="primary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="person" isIconOnly={true} tone="white" variant="primary"'
              }
            </DocCode>
          </div>
        </li>
      </ul>

      <DocRow margin="sm">
        <DocSubHeading>Secondary</DocSubHeading>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Neutral</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="white-button"
              functionalIcon="person"
              isIconOnly={true}
              label="Secondary button"
              tone="neutral"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="person" isIconOnly={true} tone="neutral" variant="secondary"'
              }
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Brand</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="brand-button"
              functionalIcon="infoCircle"
              isIconOnly={true}
              label="Secondary button"
              tone="brand"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="infoCircle" isIconOnly={true} tone="brand" variant="secondary"'
              }
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Urgent</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="urgent-button"
              functionalIcon="bin"
              isIconOnly={true}
              label="Secondary button"
              tone="urgent"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="bin" isIconOnly={true} tone="urgent" variant="secondary"'
              }
            </DocCode>
          </div>
        </li>
      </ul>

      <DocRow margin="sm">
        <DocSubHeading>Link</DocSubHeading>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Neutral</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="white-button"
              functionalIcon="person"
              isIconOnly={true}
              label="Link button"
              tone="neutral"
              variant="link"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="person" isIconOnly={true} tone="neutral" variant="link"'
              }
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Brand</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="brand-button"
              functionalIcon="person"
              isIconOnly={true}
              label="Link button"
              tone="brand"
              variant="link"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {
                'functionalIcon="person" isIconOnly={true} tone="brand" variant="link"'
              }
            </DocCode>
          </div>
        </li>
      </ul>

      <DocRow margin="sm">
        <DocSubHeading>Size</DocSubHeading>
        <DocParagraph>
          The size prop works in combination with the icon only button to match
          the width and height of the button of corresponding size.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Small</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="small-icon-only-button"
              functionalIcon="infoCircle"
              isIconOnly={true}
              label="Small icon only button"
              size="sm"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'functionalIcon="infoCircle" isIconOnly={true} size="sm"'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Medium</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="medium-icon-only-button"
              functionalIcon="infoCircle"
              isIconOnly={true}
              label="Medium icon only button"
              size="md"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'functionalIcon="infoCircle" isIconOnly={true} size="md"'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Large</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="large-icon-only-button"
              functionalIcon="infoCircle"
              isIconOnly={true}
              label="Large icon only button"
              size="lg"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'functionalIcon="infoCircle" isIconOnly={true} size="lg"'}
            </DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ButtonIconOnly;
