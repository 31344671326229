import { makeStyles } from '@material-ui/core/styles';
import { neutral } from '../../../../styles/color';
import { StyleProps } from './types';

const borderStyle = `1px solid ${neutral[800]}`;

export const useStyles = makeStyles(() => ({
  panelContent: (props: StyleProps) => ({
    padding: '16px',
    borderRight: props.hasBorder ? borderStyle : 'none',
    borderLeft: props.hasBorder ? borderStyle : 'none',
    borderBottom: props.hasBorder ? borderStyle : 'none',
  }),
}));
