import React, { FC, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';

import { ComponentProps } from './CollapsePanel.types';
import { useStyles } from './CollapsePanel.styles';

import PanelHeader from './_internal/PanelHeader';
import PanelContent from './_internal/PanelContent';

const CollapsePanel: FC<ComponentProps> = ({
  dataTestId,
  title,
  isExpanded = true,
  hasBorder = true,
  unmountOnExit = true,
  onExpandedChange = undefined,
  children,
  variant = 'default',
  indicatorLabel,
  indicatorTone,
}) => {
  const classes = useStyles({ hasBorder });
  const [expanded, setExpanded] = React.useState(isExpanded);

  const onPanelHeaderClick = () => {
    const updatedExpandedState = !expanded;

    setExpanded(updatedExpandedState);
    if (onExpandedChange) {
      onExpandedChange(updatedExpandedState);
    }
  };

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <div data-testid={dataTestId} className={classes.panelWrapper}>
      <PanelHeader
        title={title}
        isExpanded={expanded}
        hasBorder={hasBorder}
        onClick={onPanelHeaderClick}
        variant={variant}
        indicatorLabel={indicatorLabel}
        indicatorTone={indicatorTone}
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit={unmountOnExit}>
        <PanelContent hasBorder={hasBorder}>{children}</PanelContent>
      </Collapse>
    </div>
  );
};

export default CollapsePanel;
