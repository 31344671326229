import React, { FC, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import InternalFieldWrapper from '../_internal/FieldWrapper';

import { ComponentProps, ToolbarMode } from './RichTextEditor.types';
import { editorStyles, useStyles } from './RichTextEditor.styles';

const fullToolbarOptions =
  'formatselect alignleft aligncenter alignright alignjustify bold italic underline strikethrough link forecolor lineheight numlist bullist indent outdent image';

const basicToolbarOptions =
  'formatselect bold italic underline forecolor indent outdent numlist bullist link';

const RichTextEditor: FC<ComponentProps> = ({
  autoFocus,
  dataTestId,
  errorMessage,
  hasError,
  id,
  initialValue,
  instructionText,
  isDisabled,
  isInline,
  height = 310,
  label,
  labelVariant = 'static',
  minHeight,
  onBlur,
  onDirty,
  onEditorChange,
  onFocus,
  onInit,
  placeholder,
  resize = false,
  secondaryLabel,
  secondaryLabelUrl,
  styleOverrides = {
    css: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
  toolbar = 'full',
  value,
}) => {
  const [isActive, setIsActive] = useState(false);

  const classes = useStyles({
    hasError,
    hasResize: resize,
    isActive,
    isDisabled,
  });

  const handleBlur = (event: any, editor: any) => {
    if (typeof onBlur === 'function') {
      onBlur(event, editor);
    }
    setIsActive(false);
  };

  const handleFocus = (event: any, editor: any) => {
    if (typeof onFocus === 'function') {
      onFocus(event, editor);
    }
    setIsActive(true);
  };

  const init = {
    auto_focus: autoFocus ? id : null,
    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3;',
    branding: false,
    content_style: editorStyles,
    elementpath: false,
    height,
    lineheight_formats: '1 1.1 1.5 2',
    min_height: minHeight ? minHeight : height,
    menubar: false,
    placeholder,
    plugins: ['lists link image'],
    resize,
    statusbar: resize ? true : false,
    toolbar: toolbar === 'basic' ? basicToolbarOptions : fullToolbarOptions,
    toolbar_mode: 'wrap' as ToolbarMode,
    contextmenu: '',
    browser_spellcheck: true,
  };

  const tinymceScriptSrc =
    'https://cdn.jsdelivr.net/npm/tinymce@5.8.2/tinymce.min.js';

  return (
    <div data-testid={dataTestId}>
      <InternalFieldWrapper
        errorMessage={errorMessage}
        hasError={hasError}
        instructionText={instructionText}
        isActive={isActive}
        isDisabled={isDisabled}
        isInline={isInline}
        label={label}
        labelVariant={labelVariant}
        secondaryLabel={secondaryLabel}
        secondaryLabelUrl={secondaryLabelUrl}
        styleOverrides={styleOverrides}
      >
        <div className={classes.root}>
          <Editor
            disabled={isDisabled}
            id={id}
            onBlur={handleBlur}
            onDirty={onDirty}
            onEditorChange={onEditorChange}
            onFocus={handleFocus}
            onInit={onInit}
            initialValue={initialValue}
            init={init}
            tinymceScriptSrc={tinymceScriptSrc}
            value={value}
          />
        </div>
      </InternalFieldWrapper>
    </div>
  );
};

export default RichTextEditor;
