import React, { Fragment, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsText, PlxsToggleButton } from '@plexus-ui/index';

export default () => {
  const [selectedValue, setSelectedValue] = useState('yes');
  const [selectedPlace, setSelectedPlace] = useState('');

  return (
    <Fragment>
      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <PlxsToggleButton
          buttons={[
            {
              label: 'All',
              dataTestId: 'all-toggle',
              value: 'all',
              icon: 'compass',
            },
            {
              label: 'Australia',
              dataTestId: 'australia-toggle',
              value: 'australia',
            },
            { label: 'Europe', dataTestId: 'europe-toggle', value: 'europe' },
            { label: 'Asia', dataTestId: 'asia-toggle', value: 'asia' },
          ]}
          onChange={setSelectedPlace}
          value={selectedPlace}
        />
        <PlxsText>Selected place is {selectedPlace}.</PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Positive & urgent</DocHeading>
        <PlxsToggleButton
          buttons={[
            {
              label: 'Yes',
              dataTestId: 'yes-toggle',
              value: 'yes',
              icon: 'check',
              tone: 'positive',
            },
            {
              label: 'No',
              dataTestId: 'no-toggle',
              value: 'no',
              icon: 'cross',
              tone: 'urgent',
            },
          ]}
          onChange={setSelectedValue}
          value={selectedValue}
        />
        <PlxsText>Selected value is {selectedValue}.</PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Disabled</DocHeading>
        <PlxsToggleButton
          buttons={[
            { label: 'Good', dataTestId: 'good-toggle', value: 'good' },
            { label: 'Bad', dataTestId: 'bad-toggle', value: 'bad' },
          ]}
          isDisabled={true}
        />
      </DocRow>
    </Fragment>
  );
};
