export const POPOVER_LEFT_END = 'left-end';
export const POPOVER_LEFT_START = 'left-start';
export const POPOVER_RIGHT_END = 'right-end';
export const POPOVER_RIGHT_START = 'right-start';
export const POPOVER_TOP_START = 'top-start';
export const POPOVER_TOP_END = 'top-end';
export const POPOVER_TOP = 'top';
export const POPOVER_BOTTOM_START = 'bottom-start';
export const POPOVER_BOTTOM_END = 'bottom-end';
export const POPOVER_BOTTOM = 'bottom';
