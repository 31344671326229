import { BRAND, DISABLED, WHITE } from '../../../_constants';

import { StyleProps } from '../Button.types';
import { VARIANT_LINK, VARIANT_SECONDARY } from '../Button.constants';

export default (props: StyleProps) => {
  if (props.isDisabled) {
    return DISABLED;
  }

  // Render icon in reverse brand color for usage on colored background
  if (props.tone === WHITE) {
    return BRAND;
  }

  // Render icon as tone color for usage on transparent background
  if (props.variant === VARIANT_LINK || props.variant === VARIANT_SECONDARY) {
    return props.tone;
  }

  // Render white icon for usage on colored background
  return WHITE;
};
