import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocProp,
  DocRow,
} from '@dev/components';
import WithTextarea from './WithTextarea';

const props = `interface ComponentProps {
  dataTestId: string;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  padding?: 'sm' | 'md';
  value: string;
}`;

const usage = `import React, { ChangeEvent, Fragment, MouseEvent, useState } from 'react';
import { PlxsTextarea, PlxsTextEditor } from '@plexus-ui/index';

const initialContent =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quaerat quibusdam earum maxime cum pariatur, eos omnis veniam, atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!';

const WithTextarea = () => {
  const [isEditMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialContent);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    setEditMode(false);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setEditMode(true);
  };

  return (
    <Fragment>
      {isEditMode ? (
        <PlxsTextarea
          autoFocus={true}
          dataTestId="textarea"
          isAutoResizing={true}
          isInline={true}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
        />
      ) : (
        <PlxsTextEditor
          dataTestId="text-content-edit"
          onClick={handleClick}
          value={value}
        />
      )}
    </Fragment>
  );
};

export default WithTextarea;`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph margin="md">
            The TextEditor component is designed to be used with a
            Textarea/TextField for displaying and editing plain text.
          </DocParagraph>
          <DocParagraph>
            The styling is inline with the aforementioned components, with an
            onClick event handler to be used to toggle state between the
            TextEditor and chosen text editing component.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocRow margin="sm">
            <DocCode>{usage}</DocCode>
          </DocRow>
        </DocRow>

        <DocRow margin="sm">
          <DocHeading>Example with Textarea</DocHeading>
          <DocParagraph margin="md">
            When used with a Textarea, enable <DocProp>autofocus</DocProp> and
            for auto resizing <DocProp>isAutoResizing</DocProp>
          </DocParagraph>
          <WithTextarea />
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
