import React, { FC } from 'react';

import { inputTransparent } from '../../styles/input';
import InternalFieldWrapper from '../_internal/FieldWrapper';
import InternalLabel from '../_internal/Label';
import InternalRadioButton from '../_internal/RadioButton';
import PlxsIcon from '../Icon';

import { ComponentProps } from './RadioButtonGroup.types';
import { useStyles } from './RadioButtonGroup.styles';

const RadioButtonGroup: FC<ComponentProps> = ({
  autoFocus,
  dataTestId,
  errorMessage,
  hasError,
  isDisabled,
  instructionText,
  isInline,
  label,
  name,
  onChange,
  options,
  required,
  secondaryLabel,
  secondaryLabelUrl,
  value,
  variant = 'default',
}) => {
  const classes = useStyles({ isDisabled, hasError, variant });

  return (
    <InternalFieldWrapper
      errorMessage={errorMessage}
      isInline={isInline}
      hasError={hasError}
      instructionText={instructionText}
      label={label}
      labelVariant="static"
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
      styleOverrides={{
        css: inputTransparent,
      }}
    >
      <fieldset
        className={classes.fieldset}
        disabled={isDisabled}
        data-testid={dataTestId}
        name={name}
      >
        {options.map(({ id, label, value: optionValue, icon }, index) => {
          const checked = optionValue === value;
          const labelClass = checked
            ? classes.checkedLabel
            : classes.uncheckedLabel;
          return (
            <label key={optionValue} className={labelClass}>
              <InternalRadioButton
                autoFocus={autoFocus && (checked || (!checked && index === 0))}
                checked={checked}
                dataTestId={`${dataTestId}--radio-button`}
                hasError={hasError}
                id={id}
                name={name}
                onChange={onChange}
                required={required}
                value={optionValue}
              />
              {label && (
                <div className={classes.labelText}>
                  <InternalLabel content={label} />
                </div>
              )}
              {icon && <PlxsIcon name={icon} size="lg" />}
            </label>
          );
        })}
      </fieldset>
    </InternalFieldWrapper>
  );
};

export default RadioButtonGroup;
