import React, { FC, Fragment } from 'react';

import { ComponentProps } from './types';
import { formatDate } from '../../../utils';

const DateDisplay: FC<ComponentProps> = ({ date, dateFormat, locale }) => {
  return <Fragment>{formatDate(date, dateFormat, locale)}</Fragment>;
};

export default DateDisplay;
