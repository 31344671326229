import React, { FC, Fragment, useEffect, useState } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import DayPicker from 'react-day-picker';
import YearPicker from './YearPicker';
import MonthNavigationButtons from './MonthNavigationButtons';
import TodayButton from './TodayButton';
import { DAYS_OF_WEEK } from './constants';
import { isAfterDate, isBeforeDate, isDate, isDateRange } from '../_utils';
import TimeSelector from '../TimeSelector/TimeSelector';

const Calendar: FC<ComponentProps> = ({
  dataTestId,
  maxDate,
  minDate,
  onDateSelected,
  onTimePeriodChange,
  value,
  isTimeEditable = false,
  timePeriod,
}) => {
  const classes = useStyles({ mode: isDateRange(value) ? 'range' : 'single' });

  const [dayTransition, setDayTransition] = useState('');
  const [isYearPickerVisible, setIsYearPickerVisible] = useState(false);
  const [focusedDate, setFocusedDate] = useState<Date>(new Date());

  useEffect(() => {
    setFocusedDate(isDateRange(value) ? value.from : value || new Date());
  }, [setFocusedDate, value]);

  const handleCaptionClick = () => {
    setDayTransition('');
    setIsYearPickerVisible(!isYearPickerVisible);
  };

  const handleTodayButtonClick = () => {
    handleMonthChange(new Date());
    onDateSelected(new Date());
  };

  const handleMonthChange = (date: Date) => {
    if (isAfterDate(date, focusedDate)) {
      setDayTransition(classes.daySlideLeft);
      setFocusedDate(date);
    } else if (isBeforeDate(date, focusedDate)) {
      setDayTransition(classes.daySlideRight);
      setFocusedDate(date);
    }
  };

  const handleYearClick = (date: Date) => {
    setIsYearPickerVisible(false);
    setFocusedDate(date);
  };

  // This "modifiers" is an object where the key is a `className` that
  // will be applied to days matching the value.
  const internalModifiers: any = {
    [dayTransition]: { daysOfWeek: DAYS_OF_WEEK },
  };

  if (isDateRange(value)) {
    internalModifiers[classes.from] = value.from;
    internalModifiers[classes.to] = value.to;
  }

  const handleDayClick = (date: Date) => {
    date = new Date(date);

    if (isDate(value)) {
      date.setHours(value.getHours());
      date.setMinutes(value.getMinutes());
    }

    onDateSelected(date);
  };

  return (
    <Fragment>
      <DayPicker
        captionElement={({ date, onClick }) => (
          <YearPicker
            dataTestId={dataTestId}
            date={date}
            isOpen={isYearPickerVisible}
            maxDate={maxDate}
            minDate={minDate}
            onToggle={onClick}
            onYearClick={handleYearClick}
          />
        )}
        classNames={classes}
        disabledDays={{ before: minDate, after: maxDate }}
        fixedWeeks
        modifiers={internalModifiers}
        month={focusedDate}
        navbarElement={({
          nextMonth,
          previousMonth,
          onNextClick,
          onPreviousClick,
        }) =>
          isYearPickerVisible ? null : (
            <MonthNavigationButtons
              dataTestId={dataTestId}
              maxDate={maxDate}
              minDate={minDate}
              nextMonth={nextMonth}
              previousMonth={previousMonth}
              onNextMonthClick={() => onNextClick()}
              onPreviousMonthClick={() => onPreviousClick()}
            />
          )
        }
        numberOfMonths={1}
        onCaptionClick={handleCaptionClick}
        onDayClick={handleDayClick}
        onMonthChange={handleMonthChange}
        selectedDays={value}
        showOutsideDays
        weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
      />
      {isTimeEditable && isDate(value) && (
        <div className={classes.timeSelector}>
          <TimeSelector
            dataTestId={`${dataTestId}--time-picker--selector`}
            onChange={onDateSelected}
            date={value}
            timePeriod={timePeriod}
            onTimePeriodChange={onTimePeriodChange}
          />
        </div>
      )}
      {!isYearPickerVisible && (
        <div className={classes.todayButtonWrapper}>
          <TodayButton
            dataTestId={dataTestId}
            onClick={handleTodayButtonClick}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Calendar;
