import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { breakpoints } from '../../styles';

import { SelectProps } from './Select.types';
import CustomSelect from './CustomSelect';
import NativeSelect from './NativeSelect';

const Select = <Option extends any>(props: SelectProps<Option>) => {
  const { isNativeOnSmallScreen, onSelect, options } = props;

  const isSmallScreen = !useMediaQuery(`(min-width:${breakpoints.md})`);
  const isNative = isNativeOnSmallScreen && isSmallScreen;

  const handleIndexSelected = (index: number) => {
    const newSelected = options[index];
    onSelect(newSelected);
  };

  return isNative ? (
    <NativeSelect {...props} onIndexSelected={handleIndexSelected} />
  ) : (
    <CustomSelect {...props} onIndexSelected={handleIndexSelected} />
  );
};

export default Select;
