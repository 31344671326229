import React, { FC, useCallback, useMemo } from 'react';
import PlxsText from '../../../Text';
import IconCheck from '../../../../icons/sm/check';

import { ComponentProps } from './Checkbox.types';
import { useStyles } from './Checkbox.styles';

const Checkbox: FC<ComponentProps> = ({
  dataTestId,
  isDisabled,
  label,
  onChange,
  value,
}) => {
  const classes = useStyles({
    isShowIcon: Boolean(value),
  });

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  // This is used to stop the checkbox losing focus when the label
  // is clicked, which can make other UI elements flicker, like the
  // blue border given to focused fields.
  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      e.preventDefault();
    },
    []
  );

  return (
    <div className={classes.root}>
      <label className={classes.label} onMouseDown={handleMouseDown}>
        <div className={classes.inputContainer}>
          <input
            className={classes.input}
            data-testid={dataTestId}
            disabled={isDisabled}
            type="checkbox"
            checked={value}
            onChange={handleInputChange}
          />
          <span className={classes.overlay}>
            <span className={classes.inputOverlay}>
              <IconCheck className={classes.icon} />
            </span>
          </span>
        </div>
        <PlxsText>{label}</PlxsText>
      </label>
    </div>
  );
};

export default Checkbox;
