import Layout from '@dev/components/AppContent/Layout';
import Breakpoints from '@dev/components/AppContent/Breakpoints';

const overview = [
  {
    component: Layout,
    name: 'Layout',
    path: '/layout',
  },
  {
    component: Breakpoints,
    name: 'Breakpoints',
    path: '/breakpoints',
  },
];

export default overview;
