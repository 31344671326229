import React, { FC } from 'react';

import { DARK, DISABLED } from '../../../_constants';
import PlxsIcon from '../../Icon';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const FunctionalIcon: FC<ComponentProps> = ({
  dataTestId,
  isDisabled,
  onClick,
  name,
}) => {
  const classes = useStyles({
    isDisabled,
  });

  let functionalIconDataTestId: null | string;

  if (dataTestId) {
    const nameAttributeFormat = name
      .split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase();
    functionalIconDataTestId = `${dataTestId}--${nameAttributeFormat}-icon`;
  }

  const handleClick = (event) => {
    if (!isDisabled && Boolean(onClick)) {
      event.stopPropagation();
      onClick(event);
    }
  };

  const tone = isDisabled ? DISABLED : DARK;

  return (
    <span
      className={classes.functionalIcon}
      data-testid={functionalIconDataTestId}
      onClick={handleClick}
    >
      <PlxsIcon name={name} tone={tone} />
    </span>
  );
};

export default FunctionalIcon;
