import React, { FC } from 'react';

import { brand, positive, urgent, white } from '../../styles/color';
import { tone as darkTone } from '../../styles/tone';

import { ComponentProps, Tone, Variant } from './SpotlightIcon.types';
import {
  AI,
  APPROVAL_DECLINED,
  APPROVAL_SUCCESS,
  GHOST,
  INVITE,
  MESSAGE,
  NOTIFICATIONS,
  PERMISSIONS_BASIC,
  PERMISSIONS_CUSTOM,
  PERMISSIONS_EMPTY,
  PERMISSIONS_FULL,
  RECENTLY_VIEWED,
  SHARE,
  SIGNATURE_DECLINED,
  SIGNATURE_SUCCESS,
  TASKS,
  USER,
  VARIANT_WHITE,
} from './_constants';
import Ai from './_internal/Ai';
import ApprovalDeclined from './_internal/ApprovalDeclined';
import ApprovalSuccess from './_internal/ApprovalSuccess';
import Ghost from './_internal/Ghost';
import Invite from './_internal/Invite';
import Message from './_internal/Message';
import Notifications from './_internal/Notifications';
import PermissionsEmpty from './_internal/PermissionsEmpty';
import PermissionsBasic from './_internal/PermissionsBasic';
import PermissionsFull from './_internal/PermissionsFull';
import PermissionsCustom from './_internal/PermissionsCustom';
import RecentlyViewed from './_internal/RecentlyViewed';
import Share from './_internal/Share';
import SignatureDeclined from './_internal/SignatureDeclined';
import SignatureSuccess from './_internal/SignatureSuccess';
import Tasks from './_internal/Tasks';
import User from './_internal/User';
import Icon from '../Icon';
import { useStyles } from './SpotlightIcon.styles';

export const lightTone: Record<Tone, string> = {
  brand: brand[700],
  positive: positive[800],
  urgent: urgent[800],
};

const SpotlightIcon: FC<ComponentProps> = ({ icon, tone, variant }) => {
  const classes = useStyles();
  const circleFill =
    variant === VARIANT_WHITE ? darkTone[tone] : lightTone[tone];
  const iconFill = variant === VARIANT_WHITE ? white : darkTone[tone];

  switch (icon) {
    case AI: {
      return <Ai circleFill={circleFill} iconFill={iconFill} />;
    }

    case APPROVAL_DECLINED: {
      return <ApprovalDeclined circleFill={circleFill} iconFill={iconFill} />;
    }

    case APPROVAL_SUCCESS: {
      return <ApprovalSuccess circleFill={circleFill} iconFill={iconFill} />;
    }

    case GHOST: {
      return <Ghost circleFill={circleFill} iconFill={iconFill} />;
    }

    case INVITE: {
      return <Invite circleFill={circleFill} iconFill={iconFill} />;
    }

    case MESSAGE: {
      return <Message circleFill={circleFill} iconFill={iconFill} />;
    }

    case NOTIFICATIONS: {
      return <Notifications circleFill={circleFill} iconFill={iconFill} />;
    }

    case PERMISSIONS_EMPTY: {
      return <PermissionsEmpty circleFill={circleFill} iconFill={iconFill} />;
    }

    case PERMISSIONS_BASIC: {
      return <PermissionsBasic circleFill={circleFill} iconFill={iconFill} />;
    }

    case PERMISSIONS_FULL: {
      return <PermissionsFull circleFill={circleFill} iconFill={iconFill} />;
    }

    case PERMISSIONS_CUSTOM: {
      return <PermissionsCustom circleFill={circleFill} iconFill={iconFill} />;
    }

    case RECENTLY_VIEWED: {
      return <RecentlyViewed circleFill={circleFill} iconFill={iconFill} />;
    }

    case SHARE: {
      return <Share circleFill={circleFill} iconFill={iconFill} />;
    }

    case SIGNATURE_DECLINED: {
      return <SignatureDeclined circleFill={circleFill} iconFill={iconFill} />;
    }

    case SIGNATURE_SUCCESS: {
      return <SignatureSuccess circleFill={circleFill} iconFill={iconFill} />;
    }

    case TASKS: {
      return <Tasks circleFill={circleFill} iconFill={iconFill} />;
    }

    case USER: {
      return <User circleFill={circleFill} iconFill={iconFill} />;
    }

    default: {
      const foregroundTone = variant === VARIANT_WHITE ? 'white' : tone;
      const size = 60;

      return (
        <div className={classes.root}>
          <svg height={size} width={size} xmlns="http://www.w3.org/2000/svg">
            <circle cx="50%" cy="50%" r="50%" fill={circleFill} />
          </svg>
          <Icon name={icon} tone={foregroundTone} customSize={size} />
        </div>
      );
    }
  }
};

export default SpotlightIcon;
