import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsButton, PlxsPopover, PlxsText } from '@plexus-ui/index';

const VariantPaddingNone: FC = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const isOpen = Boolean(anchorElement);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Variant: "paddingNone"</DocHeading>
        <PlxsButton
          dataTestId="reset-padding-menu--button"
          onClick={handleOpen}
          isActive={isOpen}
          label="Open"
        />
        <PlxsPopover
          anchorElement={anchorElement}
          dataTestId="reset-padding-menu"
          isOpen={isOpen}
          onClickAway={handleClose}
          variant="paddingNone"
        >
          <Fragment>
            <PlxsText>Item one</PlxsText>
            <PlxsText>Item two</PlxsText>
            <PlxsText>Item three</PlxsText>
          </Fragment>
        </PlxsPopover>
      </DocRow>
    </DocRow>
  );
};

export default VariantPaddingNone;
