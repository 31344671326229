import { makeStyles } from '@material-ui/core/styles';
import {
  CALENDAR_BUTTON_HEIGHT,
  CALENDAR_ELEMENT_MARGIN_BOTTOM,
  cellDisabled,
  cellSelectable,
  cellSelected,
} from '../_styles';
import {
  INPUT_BORDER_RADIUS,
  INPUT_BORDER_WIDTH,
  INPUT_PADDING,
} from '../../_styles';

const YEAR_BUTTON_OUTLINE_WIDTH = '4px';

export const useStyles = makeStyles({
  root: {
    marginBottom: CALENDAR_ELEMENT_MARGIN_BOTTOM,
  },
  yearsWrapper: {
    background: 'white',
    borderColor: 'transparent',
    borderRadius: INPUT_BORDER_RADIUS,
    position: 'absolute',
    padding: INPUT_PADDING,
    left: INPUT_BORDER_WIDTH,
    marginTop: `${YEAR_BUTTON_OUTLINE_WIDTH}`,
    width: `calc(100% - 2 * ${INPUT_BORDER_WIDTH})`,
    height: `calc(100% - ${INPUT_PADDING} - ${CALENDAR_BUTTON_HEIGHT} - ${YEAR_BUTTON_OUTLINE_WIDTH} - ${INPUT_BORDER_WIDTH})`,
    overflowY: 'auto',
  },
  yearsRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  yearWrapper: {
    ...cellSelectable,
    margin: '1px 0',
    width: '52px',
  },
  selected: { ...cellSelected },
  disabled: { ...cellDisabled },
});
