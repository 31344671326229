import React, { FC } from 'react';

import PlxsText from '../Text';

import { ComponentProps } from './AutoComplete.types';
import AutoCompleteMultiSelect from './MultiSelect';
import AutoCompleteSelect from './Select';

const AutoComplete: FC<ComponentProps> = (props) => {
  const { isMultiSelect, multiSelectFilter } = props;

  if (isMultiSelect) {
    return Boolean(multiSelectFilter) ? (
      <AutoCompleteMultiSelect {...props} />
    ) : (
      <PlxsText color="urgent" weight="bold">
        Error: Required props missing, this variant requires the
        multiSelectFilter.
      </PlxsText>
    );
  }

  return <AutoCompleteSelect {...props} />;
};

export default AutoComplete;
