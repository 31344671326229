import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsTag } from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Default tag</DocHeading>
      <PlxsTag label="Draft" />
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Success tag</DocHeading>
      <PlxsTag label="Current" variant="success" />
    </DocRow>
  </DocRow>
);
