import React, { FC, Fragment, MouseEvent, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import {
  PlxsButton,
  PlxsHeading,
  PlxsPopover,
  PlxsRow,
  PlxsText,
} from '@plexus-ui/index';

const WithButtons: FC = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const isOpen = Boolean(anchorElement);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Dropdown with buttons</DocHeading>
        <PlxsButton
          dataTestId="default-menu--button"
          onClick={handleOpen}
          id="default-menu-button"
          isActive={isOpen}
          label="Open"
        />
        <PlxsPopover
          anchorElement={anchorElement}
          dataTestId="default-menu"
          isOpen={isOpen}
          onClickAway={handleClose}
        >
          <Fragment>
            <PlxsRow spaceBelow="md">
              <PlxsHeading element="h2">Heading text</PlxsHeading>
            </PlxsRow>
            <PlxsRow spaceBelow="md">
              <PlxsText element="span">Sub heading text</PlxsText>
            </PlxsRow>
            <PlxsRow spaceBelow="md">
              <PlxsButton
                dataTestId="cta-one--button"
                label="Call to action"
                onClick={() => {}}
              />
            </PlxsRow>
            <PlxsRow spaceBelow="md">
              <PlxsButton
                dataTestId="cta-two--button"
                label="Call to action"
                onClick={() => {}}
                variant="secondary"
              />
            </PlxsRow>
            <PlxsRow>
              <PlxsButton
                dataTestId="cta-three--button"
                label="Call to action"
                onClick={() => {}}
                tone="urgent"
              />
            </PlxsRow>
          </Fragment>
        </PlxsPopover>
      </DocRow>
    </DocRow>
  );
};

export default WithButtons;
