import React from 'react';
import { DocHeading, DocParagraph, DocRow } from '@dev/components';
import { PlxsIcon } from '@plexus-ui/index';

const IconDesign = () => (
  <DocRow margin="xxl">
    <DocRow margin="md">
      <DocParagraph>
        Plexus UI uses Font Awesome for its iconography. This font contains
        numerous glyphs that can be styled by UI designers during the design
        process.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Icon Small</DocHeading>
      <DocRow margin="sm">
        <DocParagraph>
          Small icons are placed inside a 18x18px bounding box. Each icon has a
          minumum of 3px padding. They represent the majority of icons on our
          platform, as they fit alongside text and buttons and inside inputs.
        </DocParagraph>
      </DocRow>
      <DocParagraph>Font: Font Awesome 5 Pro, 13/15 Solid</DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Icon Large</DocHeading>
      <DocRow margin="sm">
        <DocParagraph>
          Large icons are placed inside a 24x24px bounding box. Each icon has a
          minumum of 4px padding. They are used in platform navigation, and for
          other decorative purposes.
        </DocParagraph>
      </DocRow>
      <DocParagraph>Font: Font Awesome 5 Pro, 16/24 Solid</DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocRow margin="sm">
        <DocHeading>Tones</DocHeading>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon background="#e6e6e6" name="image" size="lg" tone="white" />{' '}
          White - Default state for links or information (dark backgrounds)
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="brand" /> Brand - Default state
          for links or information (medium to light backgrounds)
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="positive" /> Positive - Success
          or completion messaging
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="urgent" /> Urgent - Error or
          deletion messaging
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="caution" /> Caution - Tertiary
          information or caution messaging
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="neutral" /> Neutral - Default
          state for links or information (medium to light backgrounds)
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="disabled" /> Disabled -
          Disabled/inactive messaging
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="dark" /> Dark - Default state
          for links or information (light backgrounds)
        </DocParagraph>
      </DocRow>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Animation</DocHeading>
      <DocParagraph>
        Icons should inherit the animation properties of their parent container,
        and do not have unique animations on load, show, hide or click.
      </DocParagraph>
    </DocRow>
  </DocRow>
);

export default IconDesign;
