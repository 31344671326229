import React, { FC, Fragment } from 'react';

import DesignSystem from './DesignSystem';

import { useStyles } from './styles';

interface Props {
  heading: string;
}

const Content: FC<Props> = ({ children, heading }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.contentHeader}>
        <h1 className={classes.pageHeading}>
          {heading || 'Plexus Design System'}
        </h1>
      </div>
      <div className={classes.contentBody}>
        {children ? children : <DesignSystem />}
      </div>
    </Fragment>
  );
};

export default Content;
