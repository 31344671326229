import { makeStyles } from '@material-ui/core/styles';
import { fontColor } from '../../styles';

export const useStyles = makeStyles({
  root: {
    color: fontColor.link,
    fontWeight: 700,
    textDecoration: 'underline',
    '&:active, &:hover': {
      color: fontColor.linkHover,
      cursor: 'pointer',
    },
    '& > svg': {
      left: '1px',
      position: 'relative',
      top: '-1px',
      verticalAlign: 'middle',
    },
  },
});
