import React, { FC } from 'react';

import PlxsHeading from '../Heading';
import PlxsSpotlightIcon from '../SpotlightIcon';
import PlxsText from '../Text';
import PlxsRow from '../Row';

import { ComponentProps } from './ContentSplash.types';
import { useStyles } from './ContentSplash.styles';

const ContentSplash: FC<ComponentProps> = ({
  alignCenter,
  children,
  heading,
  maxWidth = 320,
  spotlightIcon,
}) => {
  const styles = useStyles({ alignCenter, maxWidth });

  const defaultSpotlightIcon = 'ghost';

  const defaultHeading = 'Unable to load';

  const defaultContent =
    'We are unable to complete your request. Please try again later.';

  return (
    <div className={styles.root}>
      <PlxsRow spaceBelow="lg" alignX="center">
        <PlxsSpotlightIcon
          icon={spotlightIcon ? spotlightIcon : defaultSpotlightIcon}
          tone="brand"
        />
      </PlxsRow>
      <PlxsRow spaceBelow="lg" alignX="center">
        <PlxsHeading element="h3">
          {heading ? heading : defaultHeading}
        </PlxsHeading>
      </PlxsRow>
      <PlxsRow alignX="center">
        <PlxsText align="center" element="span" size="md">
          {children ? children : defaultContent}
        </PlxsText>
      </PlxsRow>
    </div>
  );
};

export default ContentSplash;
