import React from 'react';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

export default () => (
  <PlxsTabProvider dataTestId="default" ariaLabel="Example">
    <PlxsTabContent
      id="tab-1"
      tabLabel="Sumenda potius"
      tabIcon="bulletedList"
      tabIndicatorLabel="3"
    >
      <div>
        <h2>Sumenda potius quam expetenda.</h2>
        <p>
          Si verbum sequimur, primum longius verbum praepositum quam bonum. Suam
          denique cuique naturam esse ad vivendum ducem.{' '}
          <b>Sed nunc, quod agimus;</b> Refert tamen, quo modo.{' '}
        </p>
        <ul>
          <li>Suam denique cuique naturam esse ad vivendum ducem.</li>
          <li>Nosti, credo, illud: Nemo pius est, qui pietatem-;</li>
        </ul>
      </div>
    </PlxsTabContent>

    <PlxsTabContent
      id="tab-2"
      tabLabel="Se dicere"
      tabIcon="bulletedList"
      tabIndicatorLabel="235"
    >
      <ul>
        <li>
          Se dicere inter honestum et turpe nimium quantum, nescio quid
          inmensum, inter ceteras res nihil omnino interesse.
        </li>
        <li>Iam in altera philosophiae parte.</li>
        <li>Ergo id est convenienter naturae vivere, a natura discedere.</li>
        <li>
          An vero displicuit ea, quae tributa est animi virtutibus tanta
          praestantia?
        </li>
      </ul>
    </PlxsTabContent>

    <PlxsTabContent
      id="tab-3"
      tabLabel="Lorem ipsum dolor"
      tabIcon="bulletedList"
      tabIndicatorLabel="86"
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliter autem
        vobis placet. <i>Memini me adesse P.</i> Bonum integritas corporis:
        misera debilitas. Cyrenaici quidem non recusant; Ecce aliud simile
        dissimile. <i>Duo Reges: constructio interrete.</i>
      </p>
    </PlxsTabContent>
  </PlxsTabProvider>
);
