import React from 'react';
import PlxsIcon from '../../Icon';
import { TableIconSortProps } from './types';

const TableIconSort: React.ComponentType<TableIconSortProps> = React.memo(
  ({ className }) => (
    <div className={className}>
      <PlxsIcon name="arrowDown" size="sm" tone="dark" />
    </div>
  )
);

export default TableIconSort;
