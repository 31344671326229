import React, { useState } from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';
import { PlxsRow, PlxsTileSelector } from '@plexus-ui/index';

const SingleSelect = () => {
  const [selected, setSelected] = useState<string | null>(null);

  const handleClick = (id: string) => {
    setSelected(id);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Single Select</DocHeading>

        <DocParagraph margin="md">
          The example below renders the TileSelector component with a
          RadioButton control, to be used in scenarios where only one tile can
          be selected at a time.
        </DocParagraph>

        <DocParagraph margin="md">
          This configuration gives the flexibility to place the TileSelector
          items in a grid specified by the parent component.
        </DocParagraph>

        <DocRow margin="md">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <PlxsRow isFlex={false} spaceBelow="lg">
                <PlxsTileSelector
                  control="radioButton"
                  dataTestId="basic"
                  icon="person"
                  label="Basic"
                  onChange={() => handleClick('basic')}
                  value={selected === 'basic'}
                />
              </PlxsRow>
            </div>
            <div className="col-md-6 col-lg-4">
              <PlxsRow isFlex={false} spaceBelow="lg">
                <PlxsTileSelector
                  control="radioButton"
                  dataTestId="full"
                  icon="personWithShield"
                  label="Full"
                  onChange={() => handleClick('full')}
                  value={selected === 'full'}
                />
              </PlxsRow>
            </div>
            <div className="col-md-6 col-lg-4">
              <PlxsRow isFlex={false} spaceBelow="lg">
                <PlxsTileSelector
                  control="radioButton"
                  dataTestId="custom"
                  icon="pencils"
                  label="Custom"
                  onChange={() => handleClick('custom')}
                  value={selected === 'custom'}
                />
              </PlxsRow>
            </div>
          </div>
        </DocRow>
        <div>
          <h6>Value</h6>
          <DocCode>{selected ? selected : 'null'}</DocCode>
        </div>
      </DocRow>
    </DocRow>
  );
};

export default SingleSelect;
