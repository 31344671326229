import {
  Order,
  RowData,
  TableCellParams,
  TableCellRenderParams,
  TableClasses,
  TableColDef,
  TableColumnHeaderParams,
  UpdateColumnFieldHandler,
  UpdateRowFieldHandler,
} from './Table.types';

// When sorting rows, to avoid calculating sort keys each time a comparison occurs
// (potentially costly if there's a large number of rows), we use this interface to
// cache sort keys
export interface KeyedRow {
  key: keyof RowData;
  row: RowData;
}

export function compareAscending(
  keyedRow1: KeyedRow,
  keyedRow2: KeyedRow
): number {
  if (keyedRow1.key === keyedRow2.key) {
    return 0;
  } else if (
    typeof keyedRow1.key === 'number' &&
    typeof keyedRow2.key === 'number'
  ) {
    return keyedRow1.key > keyedRow2.key ? 1 : -1;
  } else {
    return keyedRow1.key
      .toString()
      .localeCompare(keyedRow2.key.toString(), undefined, {
        numeric: true,
        sensitivity: 'base',
      });
  }
}

export function compareDescending(
  keyedRow1: KeyedRow,
  keyedRow2: KeyedRow
): number {
  return -compareAscending(keyedRow1, keyedRow2);
}

export function getComparator(
  order: Order
): (keyedRow1: KeyedRow, keyedRow2: KeyedRow) => number {
  return order === 'desc' ? compareDescending : compareAscending;
}

export function getSortedRows(
  columns: TableColDef[],
  rows: RowData[],
  orderBy: keyof RowData,
  order: Order
): RowData[] {
  const sortColumn = columns.find((column) => column.field === orderBy);
  if (sortColumn) {
    const comparator = getComparator(order);
    const rowsWithSortKeys = rows.map((row) => {
      const value = sortColumn ? getValue(sortColumn, row) : '';
      const key = value ? value.toString().toLocaleLowerCase() : '';
      return { key, row };
    });

    return stableSort(rowsWithSortKeys, comparator).map((e) => e.row);
  } else {
    return rows;
  }
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const tableClassesDefault: TableClasses = {
  table: null,
  tableBody: null,
  tableRow: null,
  tableCell: null,
};

function getValue(column: TableColDef, row: RowData): any {
  /**
   * Gets the value to be displayed by a column for the given row
   */
  const fieldValue = column.field !== undefined ? row[column.field] : undefined;
  if (column.valueGetter) {
    const params: TableCellParams = {
      field: column.field,
      value: fieldValue,
      row: row,
    };
    return column.valueGetter(params).toString().trim();
  }
  return fieldValue;
}

export const renderControlHeader = (
  col: TableColDef,
  rows: RowData[],
  updateAllRows: UpdateColumnFieldHandler
) => {
  if (
    col.renderHeaderControl &&
    typeof col.renderHeaderControl === 'function'
  ) {
    const params: TableColumnHeaderParams = {
      field: col.field,
      updateAllRows,
    };
    return col.renderHeaderControl(params);
  }

  return null;
};

export const getCellContent = (
  col: TableColDef,
  row: RowData,
  updateRow: UpdateRowFieldHandler
) => {
  const value = getValue(col, row);

  if (col.renderCell) {
    const params: TableCellRenderParams = {
      field: col.field,
      value: value,
      row: row,
      updateRow,
    };

    return col.renderCell(params);
  }

  return value;
};
