import React from 'react';

import { useStyles } from './styles';

const AppFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <p className={classes.text}>&copy; 2022 Plexus</p>
    </footer>
  );
};

export default AppFooter;
