import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

import {
  BasicContent1,
  BasicContent2,
  BasicContent3,
  BasicContent4,
  BasicContent5,
  BasicContent6,
  BasicContent7,
  BasicContent8,
  BasicContent9,
  BasicContent10,
  BasicContent11,
  BasicContent12,
} from './_internal/BasicContent';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Test Scroll (12 Tabs)</DocHeading>
      <PlxsTabProvider ariaLabel="Scrolling" dataTestId="scroll" height={250}>
        <PlxsTabContent id="scroll-tab-1" tabLabel="Tab one">
          <BasicContent1 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-2" tabLabel="Tab two">
          <BasicContent2 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-3" tabLabel="Tab three">
          <BasicContent3 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-4" tabLabel="Tab four">
          <BasicContent4 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-5" tabLabel="Tab five">
          <BasicContent5 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-6" tabLabel="Tab six">
          <BasicContent6 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-7" tabLabel="Tab seven">
          <BasicContent7 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-8" tabLabel="Tab eight">
          <BasicContent8 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-9" tabLabel="Tab nine">
          <BasicContent9 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-10" tabLabel="Tab ten">
          <BasicContent10 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-11" tabLabel="Tab eleven">
          <BasicContent11 />
        </PlxsTabContent>
        <PlxsTabContent id="scroll-tab-12" tabLabel="Tab twelve">
          <BasicContent12 />
        </PlxsTabContent>
      </PlxsTabProvider>
    </DocRow>
  </DocRow>
);
