import React, { FC } from 'react';

import { POPOVER_BOTTOM_START } from '../_internal/Popover/constants';
import InternalPopover from '../_internal/Popover';

import { ComponentProps } from './Popover.types';
import { useStyles } from './Popover.styles';

const Popover: FC<ComponentProps> = ({
  anchorElement,
  children,
  dataTestId,
  isOpen,
  isUnmountOnTransitionedOut = true,
  onClickAway,
  placement = POPOVER_BOTTOM_START,
  variant,
}) => {
  const classes = useStyles({ variant });

  const contentRef = React.useRef<HTMLDivElement>(null);

  const handleClickAway = () => {
    if (isOpen) {
      contentRef.current.blur();
      if (typeof onClickAway == 'function') {
        onClickAway();
      }
    }
  };

  const handlePopoverTransitionedOut = () => {
    contentRef.current.blur();
  };

  const handlePopoverCreated = () => {
    contentRef.current.focus();
  };

  return (
    <InternalPopover
      anchorElement={anchorElement}
      dataTestId={dataTestId}
      isOpen={isOpen}
      onClickAway={handleClickAway}
      onCreated={handlePopoverCreated}
      onTransitionedOut={handlePopoverTransitionedOut}
      placement={placement}
      transitionModifiers={{ unmountOnExit: isUnmountOnTransitionedOut }}
    >
      <div className={classes.background} ref={contentRef}>
        {children}
      </div>
    </InternalPopover>
  );
};

export default Popover;
