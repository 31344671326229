import { makeStyles } from '@material-ui/core/styles';

import { breakpoints } from '../../../../styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  buttonGroupWrapper: {
    [`@media (min-width:${breakpoints.md})`]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  leftColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    [`@media (min-width:${breakpoints.md})`]: {
      flexDirection: 'row-reverse',
      flexShrink: '0',
      flexWrap: 'nowrap',
    },
  },
  rightColumn: {
    display: 'flex',
  },
  primaryButton: ({ hasRightColumn, hasSecondaryButton }: StyleProps) => ({
    marginBottom: hasRightColumn || hasSecondaryButton ? '32px' : '0',
    marginRight: '32px',
    [`@media (min-width:${breakpoints.md})`]: {
      marginBottom: '0',
      marginLeft: '32px',
      marginRight: '0',
    },
  }),
  secondaryButton: ({ hasRightColumn }: StyleProps) => ({
    marginBottom: hasRightColumn ? '32px' : '0',
    [`@media (min-width:${breakpoints.md})`]: {
      marginBottom: '0',
      marginLeft: '32px',
    },
  }),
}));
