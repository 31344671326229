import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Share: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg height="75" width="87" xmlns="http://www.w3.org/2000/svg">
    <circle cx="57" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M39 35.39a2.87 2.87 0 00-.71-1.73l-9.95-9.95a2.87 2.87 0 00-1.73-.71H26v13h13zm19 18.89l-9.75-9.75a1.67 1.67 0 00-2.84 1.12v6.6H38.9v6.5h6.5v6.7a1.67 1.67 0 002.84 1.12L58 56.72a1.85 1.85 0 000-2.44zm-38.5 2.84v-3.24a1.67 1.67 0 011.62-1.63H39v-13H25.19a2.35 2.35 0 01-2.44-2.44V23H2.44A2.41 2.41 0 000 25.44v47.12A2.35 2.35 0 002.44 75h34.12A2.41 2.41 0 0039 72.56V58.75H21.12a1.6 1.6 0 01-1.62-1.63z"
    />
  </svg>
);

export default Share;
