import { SelectProps } from '../Select.types';

export function isSelection(selected: any): boolean {
  return selected !== undefined && selected !== null;
}

function getOptionsEqual<Option extends any>(
  props: SelectProps<Option>,
  option1: Option,
  option2: Option
): boolean {
  const { getOptionKey } = props;
  if (getOptionKey) {
    return getOptionKey(option1) === getOptionKey(option2);
  } else {
    return option1 === option2;
  }
}

export function getSelectedIndex<Option extends any>(
  props: SelectProps<Option>
): number | undefined {
  const { selected, options } = props;

  if (!isSelection(selected)) {
    return undefined;
  }

  let selectedIndex = options.findIndex((option) =>
    getOptionsEqual(props, option, selected)
  );
  return selectedIndex !== -1 ? selectedIndex : undefined;
}

export const getDropdownPlaceholder = ({
  placeholder,
}: SelectProps<any>): string => placeholder ?? 'Select...';

export function getDropdownLabel(props: SelectProps<any>): string {
  const { selected, getOptionLabel } = props;

  if (isSelection(selected)) {
    return getOptionLabel(selected);
  } else {
    return getDropdownPlaceholder(props);
  }
}
