import React from 'react';
import { DocHeading, DocRow } from '@dev/components';
import DemoModalButton from './DemoModalButton';

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Basic</DocHeading>
        <DemoModalButton dataTestId="basic-modal" />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Specified width</DocHeading>
        <DemoModalButton dataTestId="specified-width-modal" width={800} />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>LightGreyModal</DocHeading>
        <DemoModalButton dataTestId="light-grey-modal" variant="lightGrey" />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Full width</DocHeading>
        <DemoModalButton dataTestId="full-width-modal" width="full" />
      </DocRow>
    </DocRow>
  );
};
