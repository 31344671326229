import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Disabled from './Disabled';
import Group from './Group';
import Hover from './Hover';
import Icon from './Icon';
import Tone from './Tone';
import Variant from './Variant';

const bestPractice = {
  doList: [
    'Use chips for selecting, filtering and calling out key information/statuses',
    'Use the small icon size for buttons and text, and large for decorative elements',
    'Use chips for short, one line pieces of information',
    'Use matching chip background, icon and text colours for unity',
    'Use singular left masonry alignment for groups of chips',
  ],
  doNotList: [
    'Do not use chips for simple choice selections with 4 or less options',
    'Do not use chips for selecting numeric or date ranges',
    'Do not use singular chips for lengthy or multi-line pieces of information',
  ],
};

const description = '';

const example = (
  <Fragment>
    <Icon />
    <Tone />
    <Hover />
    <Disabled />
    <Variant />
    <Group />
  </Fragment>
);

const implementation = `import React, { FC } from 'react';

import { PlxsChip } from '@plexus-ui/index';

const Example: FC = () => (
  <PlxsChip
    dataTestId="example"
    decorativeIcon="person"
    functionalIcon="cross"
    label="Tomas Michaels"
    onChipClick={() => {}}
    onFunctionalIconClick={() => {}}
    tone="brand"
  />
);

export default Example;`;

const propList = [
  {
    component: 'Chip',
    description: 'renders the icon type',
    isRequired: true,
    prop: 'dataTestId',
    type: '"invite" | "message" | "share" | "user"',
  },
  {
    component: 'Chip',
    description:
      'sets the icon and background circle fill to the appropriate tone',
    isRequired: true,
    prop: 'decorativeIcon',
    type: '"brand" | "positive" | "urgent"',
  },
  {
    component: 'Chip',
    description:
      'if provided, adjusts the icon colors for use on darker backgrounds',
    isRequired: false,
    prop: 'functionalIcon',
    type: '"white"',
  },
  {
    component: 'Chip',
    description:
      'if provided, adjusts the icon colors for use on darker backgrounds',
    isRequired: false,
    prop: 'hasHover',
    type: '"white"',
  },
  {
    component: 'Chip',
    description:
      'if provided, adjusts the icon colors for use on darker backgrounds',
    isRequired: false,
    prop: 'isDisabled',
    type: '"white"',
  },
  {
    component: 'Chip',
    description:
      'if provided, adjusts the icon colors for use on darker backgrounds',
    isRequired: false,
    prop: 'label',
    type: '"white"',
  },
  {
    component: 'Chip',
    description: 'if provided, handles the click event on the entire chip',
    isRequired: false,
    prop: 'onChipClick',
    type: '(event: MouseEvent) => void',
  },

  {
    component: 'Chip',
    description:
      'if provided, handles the click event on the functional icon; if provided alongside onChipClick, triggering onFunctionalIconClick prevents onChipClick event',
    isRequired: false,
    prop: 'onFunctionalIconClick',
    type: '(event: MouseEvent) => void',
  },
  {
    component: 'Chip',
    description:
      'if provided, adjusts the icon colors for use on darker backgrounds',
    isRequired: false,
    prop: 'tone',
    type: '"white"',
  },
  {
    component: 'Chip',
    description:
      'if provided, adjusts the label styling on normal and hover states',
    isRequired: false,
    prop: 'variant',
    type: '"plainText"',
  },
];

const tsInterface = `interface ComponentProps {
  dataTestId?: string;
  decorativeIcon?: IconName; // Refer to the Icon Library for a full list of icon names
  functionalIcon?: IconName; // Refer to the Icon Library for a full list of icon names
  hasHover?: boolean;
  isDisabled?: boolean;
  label: string;
  onChipClick?: (event: MouseEvent) => void;
  onFunctionalIconClick?: (event: MouseEvent) => void;
  tone?: Tone; // 'brand' | 'caution' | 'neutral' | 'positive' | 'urgent'
  variant?: Variant; // 'plainText'
}`;

export default () => {
  return (
    <PageTemplate
      bestPractice={bestPractice}
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
