import React, { FC, MouseEvent, useState } from 'react';
import { DocHeading, DocParagraph, DocRow } from '@dev/components';
import { PlxsButton, PlxsIcon, PlxsSelect } from '@plexus-ui/index';
import { users } from '@dev/mocks/users';
import { positive } from '@plexus-ui/styles/color';
import InteractiveFieldWrapper from '@dev/docs/_internal/InteractiveFieldWrapper';
import { breakpoints } from '@plexus-ui/styles';

const SelectDocs: FC = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: 1, label: 'Australia and New Zealand' },
    { value: 2, label: 'Banking and Finance' },
    { value: 3, label: 'Head Office Leadership Team' },
    { value: 4, label: 'IT Department Records' },
    { value: 5, label: 'Legal Ops and Administration' },
    { value: 6, label: 'Melbourne CBD Franchise' },
    { value: 7, label: 'National Sales Organisation' },
    { value: 8, label: 'System Administrators' },
    { value: 9, label: 'Research and Development' },
    {
      value: 10,
      label:
        'A very very long option label that will break into multiple lines when necessary',
    },
  ];

  const [selectedComplexOption, setSelectedComplexOption] = useState(null);
  const [visibilities, setVisibilities] = useState([true, true, false, true]);
  const handleVisibilityToggle = (
    event: MouseEvent,
    visibilityIndex: number
  ) => {
    event.stopPropagation();
    const newVisibilities = [...visibilities];
    const currentVisibilityAtIndex = newVisibilities[visibilityIndex];
    newVisibilities.splice(visibilityIndex, 1, !currentVisibilityAtIndex);
    setVisibilities(newVisibilities);
  };
  const complexOption = [
    {
      value: 1,
      version: '1',
      createdBy: users[0].name,
      createdAt: '25 May 2021, 09:21am',
      visible: visibilities[0],
      visibilityIndex: 0,
      iconName: 'openBook',
      iconTone: 'neutral',
    },
    {
      value: 2,
      version: '2',
      createdBy: users[1].name,
      createdAt: '27 May 2021, 01:25pm',
      visible: visibilities[1],
      visibilityIndex: 1,
      iconName: 'pen',
      iconTone: 'brand',
    },
    {
      value: 3,
      version: '2.1',
      createdBy: users[1].name,
      createdAt: '28 May 2021, 11:18pm',
      visible: visibilities[2],
      visibilityIndex: 2,
      iconName: 'check',
      iconTone: 'positive',
    },
    {
      value: 4,
      version: '3',
      createdBy: users[2].name,
      createdAt: '30 May 2021, 10:21pm',
      isLatest: true,
      visible: visibilities[3],
      visibilityIndex: 3,
      iconName: 'circleFull',
      iconTone: 'urgent',
    },
  ];

  const resetSelection = () => {
    setSelectedOption(null);
    setSelectedComplexOption(null);
    setVisibilities([true, true, false, true]);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <PlxsButton
          dataTestId="select-docs-reset-selection-button"
          label="Reset selection"
          onClick={resetSelection}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Interactive example</DocHeading>
        <InteractiveFieldWrapper dataTestId="interactive-select">
          {({ hasError, errorMessage, dataTestId, isDisabled }) => (
            <PlxsSelect
              autoFocus
              isNativeOnSmallScreen
              isDisabled={isDisabled}
              hasError={hasError}
              errorMessage={errorMessage}
              dataTestId={dataTestId}
              label="Input name to go here"
              onSelect={setSelectedOption}
              selected={selectedOption}
              options={options}
              getOptionLabel={(option) => option.label}
              getOptionKey={(option) => option.value}
            />
          )}
        </InteractiveFieldWrapper>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Custom placeholder</DocHeading>
        <PlxsSelect
          dataTestId="select-input-2"
          onSelect={setSelectedOption}
          selected={selectedOption}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
          placeholder="Please select an option..."
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Custom options</DocHeading>
        <PlxsSelect
          dataTestId="select-input-3"
          onSelect={setSelectedComplexOption}
          selected={selectedComplexOption}
          options={complexOption}
          getOptionLabel={(option) =>
            `V${option.version} by ${option.createdBy} (${option.createdAt})`
          }
          getOptionKey={(option) => option.value}
          renderOption={(option) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flexBasis: '50px' }}>
                  <strong>{option.version}</strong>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <b>{option.createdBy}</b>
                  </div>
                  <div>
                    <i>{option.createdAt}</i>
                  </div>
                </div>
                <div
                  onClick={(event) =>
                    handleVisibilityToggle(event, option.visibilityIndex)
                  }
                >
                  {option.visible ? (
                    <PlxsIcon customSize={18} name="eye" />
                  ) : (
                    <PlxsIcon customSize={18} name="eyeSlash" />
                  )}
                </div>
                <div style={{ flexBasis: '80px' }}>
                  {option.isLatest && (
                    <div
                      style={{
                        color: 'white',
                        padding: '4px',
                        borderRadius: '3px',
                        backgroundColor: positive[500],
                        textAlign: 'center',
                      }}
                    >
                      Current
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Custom options with custom icons</DocHeading>
        <PlxsSelect
          dataTestId="select-input-4"
          onSelect={setSelectedComplexOption}
          selected={selectedComplexOption}
          options={complexOption}
          getOptionLabel={(option) => option.iconName}
          getOptionKey={(option) => option.value}
          renderOption={(option) => {
            return (
              <div>
                <PlxsIcon
                  name={option.iconName}
                  size="sm"
                  tone={option.iconTone}
                />
                {option.iconName}
              </div>
            );
          }}
          hasCustomStyle={true}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Maximum height</DocHeading>
        <PlxsSelect
          dataTestId="select-input-5"
          maxMenuListHeight={120}
          onSelect={setSelectedOption}
          selected={selectedOption}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Native select</DocHeading>
        <DocParagraph>
          Variant appears on small screens only (width less than{' '}
          {breakpoints.md}).
        </DocParagraph>
        <PlxsSelect
          dataTestId="select-input-6"
          isNativeOnSmallScreen={true}
          onSelect={setSelectedOption}
          selected={selectedOption}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Default Active</DocHeading>
        <DocParagraph>Selection list is open by default.</DocParagraph>
        <PlxsSelect
          dataTestId="select-input-7"
          onSelect={setSelectedOption}
          selected={selectedOption}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
          isDefaultActive={true}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Auto placement</DocHeading>
        <DocParagraph>
          Selection list automatically opens upwards or downwards.
        </DocParagraph>
        <PlxsSelect
          dataTestId="select-input-8"
          onSelect={setSelectedOption}
          selected={selectedOption}
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
          autoPlacement={true}
        />
      </DocRow>
    </DocRow>
  );
};

export default SelectDocs;
