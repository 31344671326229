export function getValue<Option>(
  oldValue: Option[],
  option: Option,
  optionValue: boolean
) {
  if (!optionValue) {
    return oldValue.filter((o) => o !== option);
  } else if (oldValue.includes(option)) {
    return oldValue;
  } else {
    return [...oldValue, option];
  }
}
