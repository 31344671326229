import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '../../styles/color';

export const useStyles = makeStyles(() => ({
  root: () => {
    return {
      border: `1px solid ${neutral[600]}`,
      width: 'max-content',
      padding: '2px',
      borderRadius: '3px',
      display: 'flex',
    };
  },
}));
