import React, { FC } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';

import { ComponentProps } from './Tooltip.types';
import { useStyles, useChildWrapperStyles } from './Tooltip.styles';

const Tooltip: FC<ComponentProps> = ({
  children,
  placement = 'right',
  title,
  variant,
}) => {
  const childWrapperClasses = useChildWrapperStyles({ variant });
  const tooltipClasses = useStyles();
  return (
    <MuiTooltip
      arrow
      classes={tooltipClasses}
      placement={placement}
      // MuiTooltip disappears given empty string, but appears as an empty box given null or undefined,
      // which isn't really desirable
      title={title || ''}
    >
      <div className={childWrapperClasses.root}>{children}</div>
    </MuiTooltip>
  );
};

export default Tooltip;
