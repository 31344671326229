import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocProp,
  DocRow,
  DocSubHeading,
} from '@dev/components';

import Controlled, { controlledExample } from './Controlled';
import InteractiveExample from './InteractiveExample';
import Uncontrolled, { uncontrolledExample } from './Uncontrolled';
import BasicExample from './BasicExample';

const props = `interface ComponentProps extends FieldProps {
  autoFocus?: boolean;
  dataTestId: string;
  height?: number;
  id?: string;
  initialValue?: string;
  minHeight?: number;
  onBlur?: (event: any, editor: any) => void;
  onEditorChange?: (event: any, editor: any) => void;
  onFocus?: (event: any, editor: any) => void;
  onInit: (event: any, editor: any) => void;
  resize?: boolean | 'both';
  toolbar?: 'basic' | 'full';
  value?: string;
  isInline?: boolean;
}`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The RichTextEditor component renders a rich text editor to allow the
            user to create styled html content.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocHeading>Installation</DocHeading>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              The RichTextEditor provides a wrapper for the TinyMCE react
              component. The react component dynamically loads the TinyMCE
              community package from JSDELIVR CDN.
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              Plexus UI is currently using the TinyMCE Community package{' '}
              <DocProp>v5.8.2</DocProp> with the npm package{' '}
              <DocProp>"@tinymce/tinymce-react": "^3.12.6",</DocProp>. If either
              package is upgraded in future, ensure compatibility between the
              community and react packages.
            </DocParagraph>
          </DocRow>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocHeading>Additional features</DocHeading>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              The initial build is a simple implementation, in future we can add
              more customisations, for more info on available formatting options{' '}
              <a
                href="https://www.tiny.cloud/docs/advanced/available-toolbar-buttons/"
                rel="noopener noreferrer"
                target="_blank"
              >
                refer to the TinyMCE docs.
              </a>
            </DocParagraph>
          </DocRow>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Example States</DocHeading>
          <InteractiveExample />
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocHeading>Uncontrolled example</DocHeading>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              The TinyMCE React component is designed to be used as an
              uncontrolled component, which allows the editor to perform well on
              larger documents.
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              When using the editor as an uncontrolled component, avoid using
              the <DocProp>value</DocProp> and <DocProp>onEditorChange</DocProp>{' '}
              props. Tiny recommends retrieving the editor content when it is
              needed.
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              For more information{' '}
              <a
                href="https://www.tiny.cloud/docs/integrations/react/#usingthetinymcereactcomponentasauncontrolledcomponent"
                rel="noopener noreferrer"
                target="_blank"
              >
                refer to the TinyMCE docs.
              </a>
            </DocParagraph>
          </DocRow>
          <Uncontrolled />
          <DocSubHeading>Code</DocSubHeading>
          <DocCode>{uncontrolledExample}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocHeading>Controlled example</DocHeading>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              Caution: The controlled component can have performance problems on
              large documents as it requires converting the entire document to a
              string on each keystroke or modification.
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              To use the editor as a controlled component, both the{' '}
              <DocProp>value</DocProp> and
              <DocProp>onEditorChange</DocProp> props are required.
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              For more information{' '}
              <a
                href="https://www.tiny.cloud/docs/integrations/react/#usingthetinymcereactcomponentasacontrolledcomponent"
                rel="noopener noreferrer"
                target="_blank"
              >
                refer to the TinyMCE docs.
              </a>
            </DocParagraph>
          </DocRow>
          <Controlled />
          <DocSubHeading>Code</DocSubHeading>
          <DocCode>{controlledExample}</DocCode>
        </DocRow>
        <DocRow margin="md">
          <DocRow margin="sm">
            <DocHeading>Use inside modal</DocHeading>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              When used inside PlxsModal, pass the prop{' '}
              <DocProp>disableEnforceFocus</DocProp> to the modal to fix the
              issue where the modal prevents focus on the TinyMCE dialog for
              adding link and image details.
            </DocParagraph>
          </DocRow>
        </DocRow>

        <DocRow margin="md">
          <DocRow margin="sm">
            <DocHeading>Basic Editor</DocHeading>
          </DocRow>
          <DocRow margin="sm">
            <BasicExample />
          </DocRow>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
