import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  '@global': {
    h1: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: 1.25,
      marginBottom: '24px',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: 1.25,
      marginBottom: '24px',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: 1.25,
      marginBottom: '24px',
    },
    h4: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: 1.25,
      marginBottom: '24px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.25,
      marginBottom: '24px',
    },
    h6: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.25,
      marginBottom: '24px',
    },
    p: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      marginBottom: '24px',
    },
  },
  header: {
    backgroundColor: '#002040',
    color: '#ffffff',
    padding: '12px 20px',
  },
  heading: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 1,
  },
});
