import { makeStyles } from '@material-ui/core/styles';
import { StyleProps } from './Column.types';
import { spacing } from '../../styles';

const align = (align: string) => {
  const styles = {
    center: 'center',
    left: 'flex-start',
    right: 'flex-end',
    stretch: 'stretch',
  };
  return styles[align];
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    alignItems: align(props.align),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: props.expand ? 1 : undefined,
    maxWidth: props.maxWidth ? `${props.maxWidth}px` : undefined,
    '& > *': props.spaceBetween && {
      marginBottom: spacing[props.spaceBetween],
      '&:last-child': {
        marginBottom: '0',
      },
    },
  }),
}));
