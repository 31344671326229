import React, { FC, MouseEvent } from 'react';
import { PlxsButton } from '@plexus-ui/index';

interface ComponentProps {
  onToggleErrorMessage: (event: MouseEvent) => void;
}

export const Content: FC<ComponentProps> = ({ onToggleErrorMessage }) => {
  return (
    <div>
      <h2>Content area</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Beatus sibi
        videtur esse moriens. Duo Reges: constructio interrete. Nemo igitur esse
        beatus potest. <b>Non est ista, inquam, Piso, magna dissensio.</b>
      </p>
      <p>
        Cur deinde Metrodori liberos commendas? Ita multa dicunt, quae vix
        intellegam.
      </p>
      <h3>Interactive</h3>
      <p>
        Error message:{' '}
        <PlxsButton
          dataTestId="error-toggle--button"
          label="Toggle on / off"
          onClick={onToggleErrorMessage}
          variant="link"
        />
      </p>
      <p style={{ marginBottom: '0' }}>
        <b>Important:</b> Remove margin and or padding from last child of
        content area.
      </p>
    </div>
  );
};
