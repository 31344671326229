import React, { useState } from 'react';
import { DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton, PlxsDialog } from '@plexus-ui/index';

const VariantDefault = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DocRow margin="sm">
      <DocSubHeading>Default Dialog variant.</DocSubHeading>
      <PlxsButton
        dataTestId="dialog-open"
        label="Open dialog"
        onClick={() => setIsOpen(true)}
      />
      <PlxsDialog
        ariaLabelledBy="dialog-example"
        ariaDescribedBy="dialog-for-lorem-ipsum"
        dataTestId="dialog"
        heading="Dialog header to go here"
        isOpen={isOpen}
        onPrimaryButtonClick={() => console.log('primary button clicked!')}
        onSecondaryButtonClick={() => setIsOpen(false)}
        primaryButtonLabel="Primary action button"
        secondaryButtonLabel="Secondary action button"
        subheading="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet mauris lectus."
      />
    </DocRow>
  );
};

export default VariantDefault;
