import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../styles/text';
import { neutral } from '../../styles/color';
import { tone as toneColor } from '../../styles/tone';

import { StyleProps } from './ProgressBar.types';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '18px',
  },
  progressBar: {
    flexGrow: 1,
    boxSizing: 'content-box',
    height: '0px',
    border: `3px solid ${neutral[900]}`,
    backgroundColor: neutral[900], // Fixes rare compatibility issue in very specific browser versions
    borderRadius: '3px',
  },
  progressBarFill: ({ progress, tone }: StyleProps) => ({
    display: progress <= 0 ? 'none' : 'static',
    boxSizing: 'content-box',
    width: `${progress * 100}%`,
    height: '0px',
    margin: '-3px',
    border: `3px solid ${toneColor[tone]}`,
    backgroundColor: toneColor[tone], // Fixes rare compatibility issue in very specific browser versions
    borderRadius: '3px',
  }),
  label: ({ labelWidth }: StyleProps) => ({
    ...defaultTextStyle,
    overflow: 'hidden',
    textAlign: 'right',
    width: labelWidth ? `${labelWidth}px` : 'auto',
    marginLeft: '16px',
    color: neutral[400],
  }),
}));
