import React, { FC } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { DocText } from '@dev/components';

import { useStyles } from './styles';

const Code: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <DocText>
        <SyntaxHighlighter language="javascript" style={solarizedlight}>
          {children}
        </SyntaxHighlighter>
      </DocText>
    </span>
  );
};

export default Code;
