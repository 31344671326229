import React, { FC } from 'react';

import InternalLabel from '../../../Label';

import { useStyles } from './styles';

const ErrorMessage: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {children && <InternalLabel content={children} variant="error" />}
    </div>
  );
};

export default ErrorMessage;
