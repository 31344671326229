import React, { FC, Fragment } from 'react';

import { BRAND, CAUTION, NEUTRAL, POSITIVE, URGENT } from '../../_constants';
import { IconName } from '../../icons/types';
import MessagePanel from '../_internal/MessagePanel';
import PlxsRow from '../Row';
import PlxsText from '../Text';

import { ComponentProps, Tone } from './Callout.types';

const Callout: FC<ComponentProps> = ({
  dataTestId,
  fitContentWidth = false,
  icon,
  message,
  onDismiss,
  supportingCopy,
  tone = BRAND,
}) => {
  const icons: Record<Tone, IconName> = {
    [BRAND]: 'infoCircle',
    [CAUTION]: 'warning',
    [URGENT]: 'cross',
    [POSITIVE]: 'check',
    [NEUTRAL]: 'lock',
  };

  const messageElement =
    typeof message === 'string' ? (
      <PlxsText weight="semiBold">{message}</PlxsText>
    ) : (
      message
    );

  const supportingCopyElement =
    typeof supportingCopy === 'string' ? (
      <PlxsText>{supportingCopy}</PlxsText>
    ) : (
      supportingCopy
    );

  const content = supportingCopy ? (
    <Fragment>
      <PlxsRow>{messageElement}</PlxsRow>
      <PlxsRow>{supportingCopyElement}</PlxsRow>
    </Fragment>
  ) : (
    messageElement
  );

  return (
    <MessagePanel
      dataTestId={dataTestId}
      icon={icon ? icon : icons[tone]}
      onDismiss={onDismiss}
      styleOverrides={
        fitContentWidth ? { css: { width: 'fit-content' } } : undefined
      }
      tone={tone}
    >
      {content}
    </MessagePanel>
  );
};

export default Callout;
