import React from 'react';
import { DocCode, DocHeading, DocRow } from '@dev/components';
import { PlxsChip, PlxsColumn } from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Column</DocHeading>
      <DocCode>{`<PlxsColumn>`}</DocCode>
      <PlxsColumn>
        <PlxsChip label="Item 1" />
        <PlxsChip label="Item 2" />
      </PlxsColumn>
      <DocHeading>Column with spaceBetween</DocHeading>
      <DocCode>{`<PlxsColumn spaceBetween="md">`}</DocCode>
      <PlxsColumn spaceBetween="md">
        <PlxsChip label="Item 1" />
        <PlxsChip label="Item 2" />
      </PlxsColumn>
    </DocRow>
  </DocRow>
);
