import React, { FC } from 'react';

import { BRAND, DISABLED } from '../../../../_constants';
import InternalFieldWrapper from '../../../_internal/FieldWrapper';
import PlxsIcon from '../../../Icon';

import { ComponentProps } from './types';

const FieldWrapper: FC<ComponentProps> = ({
  button,
  children,
  errorMessage,
  hasError,
  instructionText,
  isActive,
  isDisabled,
  isDynamicLabelMinimised,
  isInline,
  isOpen,
  label,
  labelVariant,
  placeholder,
  secondaryLabel,
  secondaryLabelUrl,
}) => {
  const iconRightTone = () => {
    if (isDisabled) {
      return DISABLED;
    }

    if (isActive) {
      return BRAND;
    }
  };

  const iconRight = <PlxsIcon name="search" tone={iconRightTone()} />;

  return (
    <InternalFieldWrapper
      button={button}
      errorMessage={errorMessage}
      hasError={hasError}
      iconRight={iconRight}
      instructionText={instructionText}
      isActive={isActive}
      isDisabled={isDisabled}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      label={label}
      labelVariant={labelVariant}
      placeholder={placeholder}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
      styleOverrides={
        isOpen
          ? { css: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } }
          : {}
      }
    >
      {children}
    </InternalFieldWrapper>
  );
};

export default FieldWrapper;
