import { makeStyles } from '@material-ui/core/styles';
import { brand } from '@plexus-ui/styles/color';
import { StyleProps } from './types';

export const useStyles = makeStyles({
  displayName: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    marginBottom: '8px',
  },
  componentWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    marginBottom: '8px',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    borderRadius: '4px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    marginRight: '20px',
    minWidth: '160px',
    padding: '10px',
  },
  name: {
    color: '#808090',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '11px',
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  optionIconWrapper: {
    display: 'inherit',
    marginRight: '8px',
  },
  viewport: (props: StyleProps) => ({
    height: '450px',
    maxWidth: '600px',
    width: '100%',
    border: `1px dashed ${brand[400]}`,
    borderRadius: '3px',
    overflow: props.isViewportLocked ? 'hidden' : 'scroll',
  }),
  oversizedContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '250%',
    width: '250%',
  },
});
