import React, {
  FC,
  lazy,
  ReactElement,
  Suspense,
  useEffect,
  useState,
} from 'react';

import { ComponentProps, Size } from './Icon.types';
import { useStyles } from './Icon.styles';

function getSizeInPixels(size: Size, customSize?: number) {
  if (customSize !== undefined) {
    return customSize;
  } else {
    switch (size) {
      case 'lg':
        return 24;
      case 'sm':
        return 16;
    }
  }
}

const Icon: FC<ComponentProps> = ({
  background = 'transparent',
  customSize,
  name,
  size = 'sm',
  tone = 'neutral',
}) => {
  const classes = useStyles({ background, customSize, tone });
  const [icon, setIcon] = useState<ReactElement | null>(null);
  const sizePath = customSize ? 'crop' : size;
  const sizeInPixels = getSizeInPixels(size, customSize);

  useEffect(() => {
    const ImportedIcon = lazy(
      () => import(`../../icons/${sizePath}/${name}.tsx`)
    );
    setIcon(<ImportedIcon className={classes.root} />);
  }, [classes.root, name, sizePath]);

  return <Suspense fallback={(
    <svg xmlns="http://www.w3.org/2000/svg" width={sizeInPixels} height={sizeInPixels}></svg>
  )}>
    {icon}
  </Suspense>;
};

export default Icon;
