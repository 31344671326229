import { makeStyles } from '@material-ui/core/styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    fontSize: '14px',
    fontWeight: props.isBold ? 700 : 400,
  }),
}));
