import React, { FC } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsTable } from '@plexus-ui/index';
import {
  TableColDef,
  TableCellRenderParams,
} from '@plexus-ui/components/Table/Table.types';
import { useStyles } from './styles';
import { editableRows } from './data';

import AutoCompleteCell from './AutoCompleteCell';
import DropDownCell from './DropDownCell';
import DatePickerCell from './DatePickerCell';
import TextFieldCell from './TextFieldCell';

const EditableCell: FC = () => {
  const classes = useStyles();
  const columns: TableColDef[] = [
    {
      field: 'key',
    },
    {
      field: 'value',
      renderCell: (params: TableCellRenderParams) => {
        const displayName = params.row.displayName;
        const isEditable = params.row.isEditable;
        const value = params.value;

        switch (params.row.displayGroup) {
          case 'categorySubcategory':
            return (
              <DropDownCell
                label={displayName}
                value={value}
                isEditable={isEditable}
                editIcon="chevronDown"
              />
            );
          case 'created':
            return (
              <DatePickerCell
                label={displayName}
                value={value}
                isEditable={isEditable}
                editIcon="calendar"
              />
            );
          case 'companyAbn':
            return (
              <AutoCompleteCell
                label={displayName}
                value={value}
                isEditable={isEditable}
                editIcon="search"
              />
            );
          case 'counterparty':
          default:
            return (
              <TextFieldCell
                label={displayName}
                value={value}
                isEditable={isEditable}
                editIcon="edit"
              />
            );
        }
      },
    },
  ];
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Editable Table</DocHeading>
        <p>Table cells have editable content</p>
        <PlxsTable
          rows={editableRows}
          columns={columns}
          classes={{ table: classes.table, tableCell: classes.tableCell }}
          isHeaderVisuallyHidden={true}
        />
      </DocRow>
    </DocRow>
  );
};

export default EditableCell;
