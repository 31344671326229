import React, { FC } from 'react';
import { PlxsIcon, PlxsText } from '@plexus-ui/index';
import { useStyles } from './styles';

interface ComponentProps {
  value: string;
}

const LockedCell: FC<ComponentProps> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.lockedCell}>
      <PlxsText element="span" weight="semiBold">
        {value} <PlxsIcon name="lock" tone="dark" />
      </PlxsText>
    </div>
  );
};

export default LockedCell;
