import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: 80,
    height: 80,
    position: 'relative',
    '& > *:first-child': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    '& > *:last-child': {
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  },
});
