import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { StyleProps } from './Modal.types';
import { color } from '../../../styles';
import { brand } from '../../../styles/color';

// New colour add to styles if reusable
const lightGrey = '#f3f3f4';

const fullWidthStyles = {
  display: 'block',
  boxShadow: 'none',
  borderRadius: '0',
  width: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 0,
};

const numericWidthStyles = (props: StyleProps) => ({
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.253988)',
  borderRadius: '3px',
  padding: '24px',
  width: props.width ? `${props.width}px` : 'auto',
});

export const useBackdropStyles = makeStyles({
  backdrop: {
    backgroundColor: fade(brand[100], 0.25),
  },
});

export const useDialogStyles = makeStyles(() => ({
  paper: (props: StyleProps) => ({
    backgroundColor: props.variant === 'lightGrey' ? lightGrey : color.white,
    boxSizing: 'border-box',
    outline: 'none',
    ...(props.width === 'full' ? fullWidthStyles : numericWidthStyles(props)),
  }),
}));
