import { makeStyles } from '@material-ui/core/styles';

import { StyleProps } from './Spinner.types';

export const useStyles = makeStyles(() => ({
  root: {
    animationDuration: '1.4s',
    animationTimingFunction: 'linear',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationPlayState: 'running',
    animationName: '$spinner',
    display: 'flex',
  },
  size: (props: StyleProps) => ({
    height: `${props.size}px`,
    width: `${props.size}px`,
  }),
  '@keyframes spinner': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
}));
