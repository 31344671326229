import React, { FC } from 'react';
import { useStyles } from './RichTextContent.styles';
import { ComponentProps } from './RichTextContent.types';

const RichTextContent: FC<ComponentProps> = ({ dataTestId, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid={dataTestId}>
      {children}
    </div>
  );
};

export default RichTextContent;
