import React, { FC, Fragment, MouseEvent, ReactNode, useState } from 'react';

import { PlxsButton, PlxsButtonGroup } from '@plexus-ui/index';

export interface ChildrenProps {
  isDisabled: boolean;
  hasError: boolean;
  errorMessage?: string;
  dataTestId: string;
}

export interface ComponentProps {
  dataTestId: string;
  children: (props: ChildrenProps) => ReactNode;
  customButton?: React.ReactNode | React.ReactNode[];
}

const InteractiveFieldWrapper: FC<ComponentProps> = ({
  dataTestId,
  children,
  customButton,
}: ComponentProps) => {
  const [hasError, setHasError] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const errorMessage = 'Error message here';

  const handleClearError = (_: MouseEvent) => {
    setHasError(false);
  };

  const handleShowError = (_: MouseEvent) => {
    setHasError(true);
  };

  const handleToggleDisabled = (_: MouseEvent) => {
    setDisabled(!isDisabled);
  };

  return (
    <Fragment>
      {children({ hasError, errorMessage, dataTestId, isDisabled })}
      <PlxsButtonGroup>
        <PlxsButton
          dataTestId={`${dataTestId}--clear-error-button`}
          label="Clear error"
          onClick={handleClearError}
          tone="brand"
          variant="secondary"
        />
        <PlxsButton
          dataTestId={`${dataTestId}--show-error-button`}
          label="Show error"
          onClick={handleShowError}
          tone="urgent"
          variant="secondary"
        />
        <PlxsButton
          dataTestId={`${dataTestId}--disable-button`}
          label="Toggle disabled"
          onClick={handleToggleDisabled}
          tone="neutral"
          variant="secondary"
        />
        {customButton}
      </PlxsButtonGroup>
    </Fragment>
  );
};

export default InteractiveFieldWrapper;
