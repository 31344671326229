import React, { FC } from 'react';
import MuiTableCell from '@material-ui/core/TableCell';
import { getCellContent } from '../Table.utils';
import { useBreakpoint } from '../Table.hooks';
import { TableCellProps } from './types';

const TableCell: FC<TableCellProps> = React.memo(
  ({ className, col, row, onUpdateRow }) => {
    const breakpoint = useBreakpoint(col);

    const hide =
      col.responsive &&
      col.responsive[breakpoint] &&
      col.responsive[breakpoint].hide !== undefined
        ? col.responsive[breakpoint].hide
        : col.hide;

    if (hide) return null;

    return (
      <MuiTableCell align={col.align ?? 'left'} className={className}>
        {getCellContent(col, row, onUpdateRow)}
      </MuiTableCell>
    );
  }
);

export default TableCell;
