import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '4px',
  },
  subheading: {
    marginBottom: '4px',
  },
  wrapper: {
    marginBottom: '28px',
  },
}));
