import React, { FC } from 'react';

import { PlxsAutoComplete } from '@plexus-ui/index';

interface ComponentProps {
  hasError?: boolean;
}

const fruits = ['apple', 'kiwi', 'mango', 'pear', 'pineapple'];

const AutoCompleteElement: FC<ComponentProps> = ({ hasError }) => {
  const handleSelect = (option: string) => {
    console.log('handleSelect', option);
  };

  const multiSelectFilter = (optionList: string[], suggestion: string) => {
    return optionList.filter((item) => item !== suggestion);
  };

  return (
    <PlxsAutoComplete
      dataTestId="multi-select-simple"
      errorMessage="Error text to go here"
      hasError={hasError}
      isMultiSelect={true}
      label="Fruits"
      labelVariant="dynamic"
      multiSelectFilter={multiSelectFilter}
      onSelect={handleSelect}
      optionList={fruits}
    />
  );
};

export default AutoCompleteElement;
