import React, { FC } from 'react';

import { ComponentProps } from './types';
import PlxsButton from '../../../Button';

const TodayButton: FC<ComponentProps> = ({ dataTestId, onClick }) => {
  return (
    <PlxsButton
      dataTestId={`${dataTestId}-today-button`}
      label="Today"
      onClick={onClick}
      size="sm"
      tone="brand"
      variant="link"
    />
  );
};

export default TodayButton;
