import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';
import PrimaryButtonOnly from './PrimaryButtonOnly';
import SubheadingElement from './SubheadingElement';
import VariantDefault from './VariantDefault';
import VariantDelete from './VariantDelete';

const props = `interface ComponentProps {
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  dataTestId: string;
  heading: string;
  isOpen: boolean;
  onPrimaryButtonClick: (event?: MouseEvent) => void;
  onSecondaryButtonClick?: (event: MouseEvent) => void;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  subheading: ReactElement | string;
  variant?: 'default' | 'delete';
}`;

const usage = `import React, { Fragment, useState } from 'react';
import { PlxsButton, PlxsDialog } from '@plexus-ui/index';

const VariantDefault = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <PlxsButton
        dataTestId="dialog-open"
        label="Open dialog"
        onClick={() => setIsOpen(true)}
      />
      <PlxsDialog
        ariaLabelledBy="dialog-example"
        ariaDescribedBy="dialog-for-lorem-ipsum"
        dataTestId="dialog"
        heading="Dialog header to go here"
        isOpen={isOpen}
        onPrimaryButtonClick={() => console.log('primary button clicked!')}
        onSecondaryButtonClick={() => setIsOpen(false)}
        primaryButtonLabel="Primary action button"
        secondaryButtonLabel="Secondary action button"
        subheading="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet mauris lectus."
      />
    </Fragment>
  );
};

export default VariantDefault;`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocRow margin="sm">
            <DocParagraph>
              The Dialog component consist of simply a heading, a subheading and
              two buttons.
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              The primary button always appears on the left primary blue button
              style. This button always takes to user back to the relevant
              page/feature to allow them to review/edit content. Pressing escape
              completes the same action that clicking the primary button does
            </DocParagraph>
          </DocRow>
          <DocRow margin="sm">
            <DocParagraph>
              The secondary button always appears on the right of the button
              group in the secondary urgent button style. This button always
              deletes content
            </DocParagraph>
          </DocRow>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocRow margin="sm">
            <DocCode>{usage}</DocCode>
          </DocRow>
        </DocRow>

        <DocRow margin="sm">
          <DocHeading>Examples</DocHeading>
          <PrimaryButtonOnly />
          <SubheadingElement />
          <VariantDefault />
          <VariantDelete />
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
