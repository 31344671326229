import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';

const svgExample = (
  <DocCode>
    {`<svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
>
  <path
    fill="currentColor"
    d="M18.86 ..."
  />
</svg>`}
  </DocCode>
);

const AddingNewIcons = () => (
  <DocRow margin="xxl">
    <DocRow margin="md">
      <DocParagraph>
        Follow the process below to add a new icon to Plexus UI.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Designer to supply SVG</DocHeading>
      <DocParagraph>
        Design will provide an SVG for each icon to be added to the library.
        There is a Dropbox folder containing all icons saved in our supported
        sizes, we currently have two sizes, small and large. If you need access
        to the Dropbox folder ask design to share this with you.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Create new react components</DocHeading>
      <DocParagraph>
        Using camel case naming convention, create new react components in
        /src/icons/[size]/[name].tsx. The easiest approach is to copy an
        existing icon to use as a template.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Clean SVG</DocHeading>
      <DocParagraph>
        Clean the supplied SVG{' '}
        <a
          href="https://jakearchibald.github.io/svgomg/"
          rel="noopener noreferrer"
          target="_blank"
        >
          using this online tool.
        </a>{' '}
        In addition to the default options select; Prettify markup, Sort attrs
        and Remove style elements.
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Save and update the output</DocHeading>
      <DocParagraph>
        Copy the SVG output into the new react component. Add in the props
        object spread, remove the attribute data-layer and update the fill color
        attribute to currentColor. The SVG code should look like something like
        this:
      </DocParagraph>
      {svgExample}
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Add the icon to the Icon component types</DocHeading>
      <DocParagraph>
        Add the icon name to the IconName type in /src/icons/types.ts
      </DocParagraph>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Verify new icon</DocHeading>
      <DocParagraph>
        Do a visual check to verify the new icon renders in the Icon Library,
        ensuring all sizes display correctly as per the design.
      </DocParagraph>
    </DocRow>
  </DocRow>
);

export default AddingNewIcons;
