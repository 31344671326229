import React, { FC } from 'react';
import MuiTablePagination from '@material-ui/core/TablePagination';
import { TablePaginationProps } from './types';

const TablePagination: FC<TablePaginationProps> = React.memo(
  ({
    rowsPerPageOptions,
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  }) => (
    <MuiTablePagination
      component="div"
      count={count}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  )
);

export default TablePagination;
