import { makeStyles } from '@material-ui/core/styles';

import { feedbackColor } from '../../styles';
import { getTextStyle } from '../../styles/text';

import { StyleProps } from './Tag.types';

const backgroundColor = (props: StyleProps) => {
  const styles = {
    none: '#808090',
    success: feedbackColor.success,
  };
  return styles[props.variant];
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => {
    return {
      ...getTextStyle({
        size: 'xs',
        weight: 'bold',
        color: 'white',
        align: 'left',
      }),
      backgroundColor: backgroundColor(props),
      borderRadius: '3px',
      display: 'inline-block',
      padding: '4px',
    };
  },
}));
