import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Default from './Default';
import MaxLength from './MaxLength';
import InlineHeadingEditor from './InlineHeadingEditor';
import InlineHeadingEditorCodeBlock from './InlineHeadingEditorCodeBlock';

const description =
  'The input component is a flexible width input field that can be used for inline text editing. If you are looking for an input, with label and error message, to use in a form, check out the TextField component.';

const example = (
  <Fragment>
    <Default />
    <MaxLength />
    <InlineHeadingEditor />
    <InlineHeadingEditorCodeBlock />
  </Fragment>
);

const implementation = `import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsInput } from '@plexus-ui/index';

const DefaultInput: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsInput
      dataTestId="default-input"
      onChange={handleChange}
      value={value}
    />
  );
};

export default DefaultInput;
`;

const propList = [
  {
    component: 'Input',
    description: 'used to autofocus the input on render',
    isRequired: false,
    prop: 'autoFocus',
    type: 'boolean',
  },
  {
    component: 'Input',
    description: 'data-testid applied to the div wrapper',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'Input',
    description: 'id applied to the input element',
    isRequired: false,
    prop: 'id',
    type: 'string',
  },
  {
    component: 'Input',
    description: 'ref applied to the input element',
    isRequired: false,
    prop: 'inputRef',
    type: 'RefObject<HTMLInputElement>',
  },
  {
    component: 'Input',
    description:
      'displays a character countdown chip inside the field. Prevents input value to exceed max length.',
    isRequired: false,
    prop: 'maxLength',
    type: 'number',
  },
  {
    component: 'Input',
    description: 'onBlur event applied to the input element',
    isRequired: false,
    prop: 'onBlur',
    type: '(event: FocusEvent<HTMLInputElement>) => void',
  },
  {
    component: 'Input',
    description: 'onChange event applied to the input element',
    isRequired: true,
    prop: 'onChange',
    type: '(event: ChangeEvent<HTMLInputElement>) => void;',
  },
  {
    component: 'Input',
    description: 'onClick event applied to teh input element',
    isRequired: false,
    prop: 'onClick',
    type: '(event: MouseEvent<HTMLInputElement>) => void',
  },
  {
    component: 'Input',
    description: 'onFocus event applied to the input element',
    isRequired: false,
    prop: 'onFocus',
    type: '(event: FocusEvent<HTMLInputElement>) => void',
  },
  {
    component: 'Input',
    description: 'onKeyDown event applied to the input element',
    isRequired: false,
    prop: 'onKeyDown',
    type: '(event: KeyboardEvent<HTMLInputElement>) => void',
  },
  {
    component: 'Input',
    description:
      'renders as data-testid on the parent div, child components extend data-testid for unqiue identification',
    isRequired: false,
    prop: 'type',
    type: "'email' | 'password' | 'text'",
  },
  {
    component: 'Input',
    description:
      'the value of the input, required as this is a controlled field',
    isRequired: true,
    prop: 'value',
    type: 'string',
  },
];

const tsInterface = `interface Input {
  autoFocus?: boolean;
  dataTestId: string;
  id?: string;
  inputRef?: RefObject<HTMLInputElement>;
  maxLength?: number;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  type?: 'email' | 'password' | 'text';
  value: string;
}`;

export default () => {
  return (
    <PageTemplate
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
