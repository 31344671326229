import React, { FC } from 'react';

import { ComponentProps } from './_types';

const RecentlyViewed: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg height="75" width="74" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M22.75 36.81V23H2.44A2.41 2.41 0 0 0 0 25.44v47.12A2.35 2.35 0 0 0 2.44 75h34.12A2.41 2.41 0 0 0 39 72.56V39.25H25.19a2.35 2.35 0 0 1-2.44-2.44ZM39 35.39a2.87 2.87 0 0 0-.71-1.73l-9.95-9.95a2.87 2.87 0 0 0-1.73-.71H26v13h13Z"
    />
  </svg>
);

export default RecentlyViewed;
