import { makeStyles } from '@material-ui/core/styles';

import { getTextInputStyles } from '../../styles/input';

import { StyleProps } from './Textarea.types';

const paddingStyles = (props: StyleProps) => {
  if (props.padding === 'sm') {
    return {
      padding: '7px',
    };
  }
  return {};
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    ...getTextInputStyles({ isDisabled: props.isDisabled }),
    ...paddingStyles(props),
    minHeight: '112px',
    minWidth: '300px',
    position: 'relative',
    resize: props.isResizable ? 'vertical' : 'none',
  }),
}));
