import { makeStyles } from '@material-ui/core/styles';

import { getTextInputStyles } from '../../styles/input';

import { StyleProps } from './TextField.types';

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    ...getTextInputStyles({ isDisabled: props.isDisabled }),
    paddingRight: props.hasIconRight ? '55px' : '15px',
    position: 'relative',
  }),
}));
