import React, { FC } from 'react';

import { DocHeading, DocRow } from '@dev/components';
import { PlxsSearch } from '@plexus-ui/index';

export const SearchWithDisabled: FC = () => {
  return (
    <DocRow margin="md">
      <DocHeading>Disabled</DocHeading>
      <PlxsSearch
        dataTestId="search-with-disabled"
        onChange={() => {}}
        renderOption={(_) => null}
        tooltipTextKey=""
        onSelect={() => {}}
        optionList={[]}
        renderSelectedOptionValue={() => ''}
        label="Search User name"
        isDisabled
        placeholder="Type user name here"
      />
    </DocRow>
  );
};
