import React, { FC } from 'react';

import { DARK } from '../../_constants';
import InternalLabel from '../_internal/Label';
import InternalRadioButton from '../_internal/RadioButton';
import PlxsCheckbox from '../Checkbox';
import PlxsIcon from '../Icon';
import PlxsHeading from '../Heading';

import { ComponentProps } from './TileSelector.types';
import { useStyles } from './TileSelector.styles';

const TileSelector: FC<ComponentProps> = ({
  control = 'checkbox',
  dataTestId,
  icon,
  image,
  label,
  labelElement,
  onChange,
  value,
}) => {
  const classes = useStyles({ value });

  const handleClick = () => {
    onChange(!value);
  };

  return (
    <div className={classes.root} data-testid={dataTestId}>
      <div className={classes.clickArea} onClick={handleClick}></div>

      {image && <img alt="" className={classes.image} src={image} />}

      {icon && <PlxsIcon name={icon} size="lg" tone={DARK} />}

      <div className={classes.label}>
        {labelElement ? (
          <PlxsHeading element={labelElement}>{label}</PlxsHeading>
        ) : (
          <InternalLabel content={label} />
        )}
      </div>

      {control === 'checkbox' && (
        <PlxsCheckbox
          dataTestId={`${dataTestId}--checkbox`}
          onChange={onChange}
          value={value}
        />
      )}

      {control === 'radioButton' && (
        <InternalRadioButton
          checked={value}
          dataTestId={`${dataTestId}--radio-button`}
          onChange={handleClick}
        />
      )}
    </div>
  );
};

export default TileSelector;
