import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    color: 'currentColor',
    maxHeight: '48px',
    maxWidth: '48px',
    width: '100%',
    height: 'auto',
  },
});
