import {
  AlertDocs,
  BoxDocs,
  ButtonGroupDocs,
  ChipSelectFieldDocs,
  ColumnDocs,
  LegacyButtonDocs,
  RowDocs,
  SpinnerDocs,
  SwitchDocs,
  TagDocs,
  TextDocs,
  TooltipDocs,
} from '@dev/docs/index';

const componentListToBeUpdated = [
  {
    component: AlertDocs,
    name: 'Alert (WIP)',
    path: '/alert',
  },
  {
    component: BoxDocs,
    name: 'Box (WIP)',
    path: '/box',
  },
  {
    component: ButtonGroupDocs,
    name: 'ButtonGroup (WIP)',
    path: '/buttonGroup',
  },
  {
    component: ChipSelectFieldDocs,
    name: 'ChipSelectField (WIP)',
    path: '/chipSelectField',
  },
  {
    component: ColumnDocs,
    name: 'Column (WIP)',
    path: '/column',
  },
  {
    component: LegacyButtonDocs,
    name: 'LegacyButton (WIP)',
    path: '/legacyButton',
  },
  {
    component: RowDocs,
    name: 'Row (WIP)',
    path: '/row',
  },
  {
    component: SpinnerDocs,
    name: 'Spinner (WIP)',
    path: '/spinner',
  },
  {
    component: SwitchDocs,
    name: 'Switch (WIP)',
    path: '/switch',
  },
  {
    component: TagDocs,
    name: 'Tag (WIP)',
    path: '/tag',
  },
  {
    component: TextDocs,
    name: 'Text (WIP)',
    path: '/text',
  },
  {
    component: TooltipDocs,
    name: 'Tooltip (WIP)',
    path: '/tooltip',
  },
];

export default componentListToBeUpdated;
