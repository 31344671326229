import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../styles';

export const useStyles = makeStyles({
  root: {
    visibility: 'collapse',
  },
  expandableActionColumn: {
    borderBottom: `2px solid ${color.neutralShade[800]}`,
  },
});
