import React, { FC } from 'react';
import PlxsIcon from '../../../Icon';

import { BRAND, NEUTRAL } from '../../../../_constants';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const TabIcon: FC<ComponentProps> = ({ isActive, name, tone }) => {
  const classes = useStyles();

  if (!name) {
    return null;
  }

  const iconTone = isActive ? tone || BRAND : NEUTRAL;

  return (
    <div className={classes.tabIcon}>
      <PlxsIcon name={name} tone={iconTone} />
    </div>
  );
};

export default TabIcon;
