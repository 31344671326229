import { makeStyles } from '@material-ui/core/styles';

import { breakpoints } from '../../styles';
import { defaultTextStyle } from '../../styles/text';
import { getInputStyle } from '../../styles/input';

import { StyleProps } from './EditField.types';

const calculateInputPaddingRight = (maxLength: number | undefined) => {
  if (!maxLength) {
    return '15px';
  }
  const basePadding = 46;
  const characterLength = String(maxLength).length;
  const characterWidth = 8;
  return `${basePadding + characterWidth * characterLength}px`;
};

const inputStyles = (props: StyleProps) => {
  const { maxLength, minWidth } = props;
  return {
    ...defaultTextStyle,
    ...getInputStyle({}),
    cursor: 'text',
    minWidth: '100px',
    paddingBottom: '10px',
    paddingLeft: '15px',
    paddingRight: calculateInputPaddingRight(maxLength),
    paddingTop: '10px',
    [`@media only screen and (min-width:${breakpoints.sm})`]: {
      minWidth: '200px',
    },
    [`@media only screen and (min-width:${breakpoints.md})`]: {
      minWidth: minWidth && `${minWidth}px`,
    },
  };
};

export const useStyles = makeStyles(() => ({
  counter: {
    display: 'flex',
    position: 'absolute',
    right: '15px',
  },
  input: (props: StyleProps) => ({
    ...inputStyles(props),
    position: 'relative',
  }),
  inputWrapper: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '16px',
    position: 'relative',
  },
  text: (props: StyleProps) => ({
    ...inputStyles(props),
    display: 'inline-block',
    position: 'absolute',
    opacity: 0,
    width: 'auto',
    whiteSpace: 'pre',
  }),
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
}));
