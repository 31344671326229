import React, { FC, Fragment, useState } from 'react';
import { DocCode, DocHeading, DocRow } from '@dev/components';
import { PlxsButton, PlxsCheckbox, PlxsTextLink } from '@plexus-ui/index';
import InteractiveFieldWrapper from '@dev/docs/_internal/InteractiveFieldWrapper';
import { useStyles } from '../../styles/panelList';

const Checkbox: FC = () => {
  const [value, setValue] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const classes = useStyles();
  const noop = () => {};

  const handleChange = () => {
    if (value) {
      setIndeterminate(false);
    }
    setValue(!value);
  };
  const handleIndeterminate = () => {
    setIndeterminate(!indeterminate);
    if (value) {
      setValue(false);
      setIndeterminate(true);
    }
  };

  const indeterminateButton = (
    <PlxsButton
      dataTestId="checkbox--toggle-indeterminate-button"
      label="Indeterminate"
      onClick={handleIndeterminate}
      tone="neutral"
      variant="secondary"
    />
  );

  return (
    <DocRow margin="sm">
      <DocRow margin="lg">
        <DocHeading>Interactive Checkbox</DocHeading>
        <InteractiveFieldWrapper
          dataTestId="interactive-checkbox"
          customButton={indeterminateButton}
        >
          {({ dataTestId, isDisabled, hasError, errorMessage }) => (
            <PlxsCheckbox
              label={
                <Fragment>
                  Optional label here with <PlxsTextLink>link</PlxsTextLink>
                </Fragment>
              }
              dataTestId={dataTestId}
              isDisabled={isDisabled}
              value={value}
              hasError={hasError}
              errorMessage={errorMessage}
              onChange={handleChange}
              indeterminate={indeterminate}
            />
          )}
        </InteractiveFieldWrapper>
      </DocRow>

      <DocHeading>Variants</DocHeading>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Checked"
              value
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox value />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Indeterminate"
              indeterminate
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox indeterminate />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Unchecked"
              value={false}
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Align right"
              align="right"
              value={false}
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox align="right" />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Regular label weight"
              labelWeight="regular"
              value={false}
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox labelWeight="regular" />`}</DocCode>
          </div>
        </li>
      </ul>

      <DocHeading>Disabled variants</DocHeading>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Checked"
              isDisabled
              value
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox isDisabled value />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Indeterminate"
              isDisabled
              indeterminate
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox isDisabled indeterminate />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Unchecked"
              value={false}
              isDisabled
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox isDisabled />`}</DocCode>
          </div>
        </li>
      </ul>

      <DocHeading>Error variants</DocHeading>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Checked"
              value
              hasError
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox value hasError />`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.icons}>
            <PlxsCheckbox
              dataTestId="default-labelled-checked"
              label="Unchecked"
              value={false}
              hasError
              onChange={noop}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`<PlxsCheckbox hasError />`}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default Checkbox;
