import React, { FC } from 'react';
import { ComponentProps } from './Text.types';
import { useStyles } from './Text.styles';

const Text: FC<ComponentProps> = ({
  align,
  children,
  color,
  dataTestId,
  element = 'p',
  overflow = 'clip',
  size = 'sm',
  variant,
  weight,
}) => {
  const classes = useStyles({ align, color, overflow, size, variant, weight });
  switch (element) {
    case 'span': {
      return (
        <span className={classes.root} data-testid={dataTestId}>
          {children}
        </span>
      );
    }
    default: {
      return (
        <p className={classes.root} data-testid={dataTestId}>
          {children}
        </p>
      );
    }
  }
};

export default Text;
