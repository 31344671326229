import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    background: 'white',
    borderRadius: '3px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    display: 'inline-block',
  },
});
