import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import DynamicLabel from './_internal/DynamicLabel';
import ErrorMessage from './_internal/ErrorMessage';
import IconRight from './_internal/IconRight';
import InlineButtonWrapper from './_internal/InlineButtonWrapper';
import Input from './_internal/Input';
import InstructionText from './_internal/InstructionText';
import StaticLabel from './_internal/StaticLabel';
import cx from 'classnames';

const FieldWrapper: FC<ComponentProps> = ({
  button,
  children,
  errorMessage,
  hasError,
  iconRight,
  instructionText,
  isActive,
  isDisabled,
  isDynamicLabelMinimised,
  isInline,
  onBlurCapture,
  onFocusCapture,
  label,
  labelVariant = 'static',
  placeholder,
  secondaryLabel,
  secondaryLabelUrl,
  styleOverrides = {},
}) => {
  const classes = useStyles();

  const hasDynamicLabel = Boolean(label) && labelVariant === 'dynamic';
  const hasStaticLabel = Boolean(label) && labelVariant === 'static';
  const visibleErrorMessage = hasError ? errorMessage : undefined;

  return (
    <div
      className={cx(classes.wrapper, !isInline && classes.marginBottom)}
      onBlurCapture={onBlurCapture}
      onFocusCapture={onFocusCapture}
    >
      {hasStaticLabel && (
        <StaticLabel
          hasError={hasError}
          label={label}
          secondaryLabel={secondaryLabel}
          secondaryLabelUrl={secondaryLabelUrl}
        />
      )}

      <InlineButtonWrapper button={button}>
        <div className={classes.inputWrapper}>
          <Input
            hasDynamicLabel={hasDynamicLabel}
            hasError={hasError}
            isActive={isActive}
            isDisabled={isDisabled}
            isDynamicLabelMinimised={isDynamicLabelMinimised}
            placeholder={placeholder}
            styleOverrides={styleOverrides}
          />

          {hasDynamicLabel && (
            <DynamicLabel
              isDisabled={isDisabled}
              isDynamicLabelMinimised={isDynamicLabelMinimised}
              label={label}
            />
          )}

          {children}

          <IconRight>{iconRight}</IconRight>
        </div>
      </InlineButtonWrapper>

      <InstructionText>{instructionText}</InstructionText>

      {(visibleErrorMessage || !isInline) && (
        <ErrorMessage>{visibleErrorMessage}</ErrorMessage>
      )}
    </div>
  );
};

export default FieldWrapper;
