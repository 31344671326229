import { makeStyles } from '@material-ui/core/styles';

import { breakpoints } from '../../styles';
import { defaultTextStyle } from '../../styles/text';
import { neutral } from '../../styles/color';

import { StyleProps } from './ChipSelectField.types';

const itemHeight = 24;
const itemSpacing = 8;

function getHeight(rows?: number): number | 'auto' {
  if (rows === undefined) {
    return 'auto';
  } else {
    return rows * (itemHeight + itemSpacing);
  }
}

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    lineHeight: 0,
    padding: '7px 12px 7px 12px',
  },
  itemContainerScrollView: ({ rows, lowResolutionRows }: StyleProps) => ({
    height: getHeight(lowResolutionRows ?? rows),
    [`@media only screen and (min-width:${breakpoints.sm})`]: {
      height: getHeight(rows),
    },
    overflow: 'clip auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: neutral[600],
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: neutral[400],
      borderRadius: '4px',
    },
  }),
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    lineHeight: 0,
    '& > *': {
      margin: `${itemSpacing / 2}px`,
    },
  },
  input: {
    ...defaultTextStyle,
    flexGrow: 1,
    border: 0,
    outline: 0,
    margin: `${itemSpacing / 2}px`,
    padding: 0,
    background: 'transparent',
    minHeight: `${itemHeight}px`,
  },
}));
