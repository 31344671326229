import React, { useState } from 'react';
import { DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton, PlxsDialog, PlxsText } from '@plexus-ui/index';

const SubheadingElement = () => {
  const [isOpen, setIsOpen] = useState(false);

  const subheading = (
    <PlxsText>
      <PlxsText element="span" weight="bold">
        Lorem ipsum dolor sit amet,
      </PlxsText>{' '}
      consectetur adipiscing elit. Quisque sit amet mauris lectus.
    </PlxsText>
  );
  return (
    <DocRow margin="sm">
      <DocSubHeading>
        Dialog with PlxsText as subheading for adding bold text content.
      </DocSubHeading>
      <PlxsButton
        dataTestId="dialog-open"
        label="Open dialog"
        onClick={() => setIsOpen(true)}
      />
      <PlxsDialog
        ariaLabelledBy="dialog-example"
        ariaDescribedBy="dialog-for-lorem-ipsum"
        dataTestId="dialog"
        heading="Dialog header to go here"
        isOpen={isOpen}
        onPrimaryButtonClick={() => setIsOpen(false)}
        primaryButtonLabel="Primary action button"
        subheading={subheading}
      />
    </DocRow>
  );
};

export default SubheadingElement;
