import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsText, PlxsTextLink } from '@plexus-ui/index';

import Label from '@plexus-ui/components/_internal/Label';

const props = `interface ComponentProps {
  decorativeIcon?: IconName; // Refer to the Icon Library for a full list of icon names
  hasError?: boolean;
  text: string;
  variant?: Variant; // 'error' | 'help'
}`;

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocRow margin="md">
        <DocParagraph>
          The Label component is used to render field labels in their various
          states.
        </DocParagraph>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Props</DocHeading>
        <DocCode>{props}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Usage</DocHeading>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Field label</DocSubHeading>
          <Label content="Input name to go here" />
        </DocRow>
        <DocCode>{`<Label content="Input name to go here" />`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Field label with custom decorative icon</DocSubHeading>
          <Label decorativeIcon="clock" content="Input name to go here" />
        </DocRow>
        <DocCode>{`<Label decorativeIcon="clock" content="Input name to go here" />`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Field label error state</DocSubHeading>
          <Label hasError={true} content="Input name to go here" />
        </DocRow>
        <DocCode>{`<Label hasError={true} content="Input name to go here" />`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Help label</DocSubHeading>
          <Label content="View help article" variant="help" />
        </DocRow>
        <DocCode>{`<Label content="View help article" variant="help" />`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Error message</DocSubHeading>
          <Label content="Please fix this error" variant="error" />
        </DocRow>
        <DocCode>{`<Label content="Please fix this error" variant="error" />`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Long error message with link</DocSubHeading>
          <Label
            content={
              <PlxsText color="urgent" weight="bold">
                An unexpected error occurred. Please try again or{' '}
                <PlxsTextLink>contact support.</PlxsTextLink>
              </PlxsText>
            }
            variant="error"
          />
        </DocRow>
        <DocCode>{`<Label content="Please fix this error" variant="error" />`}</DocCode>
      </DocRow>
    </DocRow>
  </DocRow>
);
