import { ACTION, ERROR, ERROR_LIGHT, SUCCESS, WARNING } from '../_constants';
import { brand, caution, neutral, positive, urgent } from './color';

// Breakpoints (screen widths)
export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '1200px',
  xl: '1400px',
  xxl: '1920px',
};

// Color
export const backgroundColor = {
  disabled: neutral[900],
};

export const color = {
  black: '#000000',
  brand: brand[400],
  brandDark: brand[300],
  brandLight: brand[800],
  brandLightest: brand[900],
  caution: caution[400],
  cautionDark: caution[300],
  cautionLight: caution[800],
  cautionLightest: caution[900],
  feedbackError: urgent[400],
  feedbackErrorLight: '#ffefed',
  feedbackSuccess: positive[400],
  feedbackSuccessLight: '#bdf0bd',
  feedbackWarning: caution[400],
  neutral: neutral[400],
  neutralBackgroundGrey: '#f3f3f4',
  neutralDarkGrey: '#002040',
  neutralDeeperGrey: '#555562',
  neutralLightGrey: '#e6e6e6',
  neutralMidGrey: '#b8b8c4',
  neutralShade: {
    100: neutral[100],
    200: neutral[200],
    300: neutral[300],
    400: neutral[400],
    500: neutral[500],
    600: neutral[600],
    700: neutral[700],
    800: neutral[800],
    900: neutral[900],
  },
  primary: '#0050f0',
  primaryDark: '#0038a8',
  primaryDarker: '#002060',
  primaryLight: '#e5edfd',
  positive: positive[400],
  positiveDark: positive[300],
  positiveLight: positive[800],
  postiveLightest: positive[900],
  secondaryPurple: '#834fd0',
  urgent: urgent[400],
  urgentDark: urgent[300],
  urgentLight: urgent[800],
  urgentLightest: urgent[900],
  white: '#ffffff',
};

export const feedbackColor = {
  [ACTION]: color.secondaryPurple,
  [ERROR]: color.feedbackError,
  [ERROR_LIGHT]: color.feedbackErrorLight,
  [SUCCESS]: color.feedbackSuccess,
  [WARNING]: color.feedbackWarning,
};

export const formColor = {
  active: color.neutralDarkGrey,
  border: '#e0e0e3',
  placeholder: '#808090',
  invalid: color.feedbackError,
  inputBg: color.white,
};

export const focusState = {
  boxShadow: `${color.white} 0px 0px 0px 1px, ${brand[700]} 0px 0px 0px 4px`,
  outline: 0,
};

// Typography
export const fontColor = {
  default: brand[100],
  disabled: neutral[400],
  link: brand[400],
  linkHover: brand[300],
  white: color.white,
};

export const formLineHeight = '18px';

// Spacing
export const spaceUnit = 4;

export const spacing = {
  xs: spaceUnit, // 4
  sm: spaceUnit * 2, // 8
  md: spaceUnit * 4, // 16
  lg: spaceUnit * 6, // 24
  xl: spaceUnit * 8, // 32
  xxl: spaceUnit * 10, // 40
};

// From Gemma as at 05Jul21: zIndex hierarchy would be:
// intercom -> toast -> actionmenu -> dialog and modal
const INTERCOM_APP_LAUNCHER_Z_INDEX = 2147483003;
export const zIndex = {
  menu: 1300,
  popover: INTERCOM_APP_LAUNCHER_Z_INDEX + 2,
  richTextEditor: 13001,
  toast: INTERCOM_APP_LAUNCHER_Z_INDEX + 1,
};

// Externally available i.e. through plexus-ui import
export default {
  breakpoints,
  color,
  feedbackColor,
  fontColor,
  formColor,
  spacing,
};
