import React, { FC } from 'react';

import PlxsIcon from '../Icon';

import { ComponentProps, Variant } from './Alert.types';
import { useStyles } from './Alert.styles';

const renderIcon = (variant: Variant) => {
  switch (variant) {
    case 'error': {
      return <PlxsIcon name="crossInCircle" size="lg" tone="inherit" />;
    }
    case 'warning': {
      return <PlxsIcon name="exclamationCircle" size="lg" tone="inherit" />;
    }
    case 'success': {
      return <PlxsIcon name="checkInCircle" size="lg" tone="inherit" />;
    }
    default: {
      return null;
    }
  }
};

const Alert: FC<ComponentProps> = ({
  children,
  hasOutline,
  hasTextAlignCenter,
  isFullWidth,
  variant = 'error',
}) => {
  const classes = useStyles({
    hasOutline,
    hasTextAlignCenter,
    isFullWidth,
    variant,
  });
  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <div className={classes.iconWrapper}>{renderIcon(variant)}</div>
        <div className={classes.children}>{children}</div>
      </div>
      <span className={classes.background}></span>
    </div>
  );
};

export default Alert;
