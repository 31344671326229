import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Dropdown: FC<ComponentProps> = ({
  children,
  containerProps,
  dataTestId,
  dropdownFooter,
  hasError,
  isActive,
  isDisabled,
  isMultiSelect,
  maxMenuListHeight,
}) => {
  const classes = useStyles({
    hasError,
    isActive,
    isDisabled,
    isMultiSelect,
    maxMenuListHeight,
  });
  const dropdownClasses = `${classes.dropDownMenu} ${classes.dropDownMenuAnimation}`;
  return (
    <div data-testid={`${dataTestId}--dropdown`} {...containerProps}>
      <div className={dropdownClasses}>
        <div className={classes.menuList}>{children}</div>
        {dropdownFooter && (
          <div className={classes.dropdownFooterWrapper}>
            <div className={classes.dropdownFooterBorder} />
            {dropdownFooter}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
