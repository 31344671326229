import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Basic from './Basic';
import BasicExtended from './BasicExtended';
import WithContent from './WithContent';
import WithContentTwoButtons from './WithContentTwoButtons';
import WithContentThreeButtons from './WithContentThreeButtons';

const bestPractice = {
  doList: [
    'Use modals when user focus needs to be directed to a particular feature or question',
    'Use modals for self-contained processes that don’t require multiple pages/loading screens',
    'Use modals when the context of a user’s action is important in their decision making process',
    'Always ensure modals launch after a user initiated action such as a button press',
    'Always provide users with a clear method of closing modals',
  ],
  doNotList: [
    'Do not use modals for multi-step processes',
    'Do not create modals that cover an entire page - always show context',
    'Do not use modals if the user is taking a new action that has no flow on effects to other system content',
    'Do not overlay multiple messages over the top of modals which would make it more difficult to get back to main page content',
    'Do not automatically activate modals on page load',
    'Do not use more than three buttons inside a modal footer',
  ],
};

const description =
  "A modal window is an element that sits on top of an application's main window. It creates a mode that disables the main window but keeps it visible with the modal window as a child window in front of it. Users must interact with the modal window before they can return to the parent application.";

const example = (
  <Fragment>
    <Basic />
    <BasicExtended />
    <WithContent />
    <WithContentTwoButtons />
    <WithContentThreeButtons />
  </Fragment>
);

const implementation = `import React, { FC, Fragment, useState } from 'react';
import {
  PlxsButton,
  PlxsModal,
  PlxsTextLink,
} from '@plexus-ui/index';

const Example: FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [hasError, setError] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const errorMessage = hasError && (
    <Fragment>
      An unexpected error occurred. Please try again or{' '}
      <PlxsTextLink href={'mailto:support@plexus.co'}>
        contact support.
      </PlxsTextLink>
    </Fragment>
  );

  const primaryButton = (
    <PlxsButton
      dataTestId="primary-action--button"
      functionalIcon="add"
      onClick={handleClose}
      label="Primary action button"
    />
  );

  const secondaryButton = (
    <PlxsButton
      dataTestId="secondary-action--button"
      functionalIcon="cross"
      onClick={handleClose}
      label="Secondary action button"
      tone="neutral"
      variant="secondary"
    />
  );

  const tertiaryButton = (
    <PlxsButton
      dataTestId="tertiary-action--button"
      functionalIcon="bin"
      onClick={handleClose}
      label="Tertiary action button"
      tone="urgent"
      variant="secondary"
    />
  );

  return (
    <Fragment>
      <PlxsButton
        dataTestId="example-modal--button"
        onClick={handleOpen}
        label="Open"
      />
      <PlxsModal
        dataTestId="example-modal"
        errorMessage={errorMessage}
        heading="Modal Heading"
        isOpen={isOpen}
        onClose={handleClose}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        subHeading="Modal Subheading"
        tertiaryButton={tertiaryButton}
      >
        <p>React node with modal content</p>
      </PlxsModal>
    </Fragment>
  );
};

export default Example;`;

const propList = [
  {
    component: 'Modal',
    description: 'The id(s) of the element(s) that describe the dialog.',
    isRequired: false,
    prop: 'ariaDescribedBy',
    type: 'string',
  },
  {
    component: 'Modal',
    description: 'The id(s) of the element(s) that label the dialog.',
    isRequired: false,
    prop: 'ariaLabelledBy',
    type: 'string',
  },
  {
    component: 'Modal',
    description:
      'Renders the content placed between the header and button group elements',
    isRequired: true,
    prop: 'children',
    type: 'ReactNode',
  },
  {
    component: 'Modal',
    description:
      'Renders as data-testid on the parent div, child components extend data-testid for unqiue identification',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'Modal',
    description: 'disableClose',
    isRequired: false,
    prop: 'disableClose',
    type: 'boolean',
  },
  {
    component: 'Modal',
    description: 'disableEnforceFocus',
    isRequired: false,
    prop: 'disableEnforceFocus',
    type: 'boolean',
  },
  {
    component: 'Modal',
    description: 'enableOverflow',
    isRequired: false,
    prop: 'enableOverflow',
    type: 'boolean',
  },
  {
    component: 'Modal',
    description:
      'The error message displayed in the modal footer or to the left of the primary and secondary buttons if a tertiary button has not been defined',
    isRequired: false,
    prop: 'errorMessage',
    type: 'ReactNode',
  },
  {
    component: 'Modal',
    description: 'Sets the modal content area to a specific width',
    isRequired: false,
    prop: 'fixedWidth',
    type: 'number',
  },
  {
    component: 'Modal',
    description:
      'The main heading positioned as the top element. Note this prop will change to required, once backwards compatibility work is complete.',
    isRequired: false,
    prop: 'heading',
    type: 'string',
  },
  {
    component: 'Modal',
    description:
      'Determines if the modal is opened or closed, usually handled by the parent components, trigger button, click event',
    isRequired: true,
    prop: 'isOpen',
    type: 'boolean',
  },
  {
    component: 'Modal',
    description: 'Callback fired when the component requests to be closed.',
    isRequired: true,
    prop: 'onClose',
    type: '(event: MouseEvent) => void',
  },
  {
    component: 'Modal',
    description:
      'A PlxsButton component, common usage; tone: brand, variant: primary. Note this prop will change to required, once backwards compatibility work is complete.',
    isRequired: false,
    prop: 'primaryButton',
    type: 'ReactElement',
  },
  {
    component: 'Modal',
    description:
      'If true the default margin is removed from the content area. Use this if margin is applied to the last child in the content area. i.e. in the instance of a form.',
    isRequired: false,
    prop: 'removeContentMargin',
    type: 'boolean',
  },
  {
    component: 'Modal',
    description:
      'A PlxsButton component, common usage; tone: neutral, variant: secondary. With all three buttons displayed, the primary button is positioned hard right.',
    isRequired: false,
    prop: 'secondaryButton',
    type: 'ReactElement',
  },
  {
    component: 'Modal',
    description:
      'The subheading positioned under the main heading. If the subheading has a link PlxsText wrapping PlxsTextLink is the correct approach.',
    isRequired: false,
    prop: 'subheading',
    type: 'ReactElement | string',
  },
  {
    component: 'Modal',
    description:
      'A PlxsButton component, common usage; tone: urgent, variant: secondary. With all three buttons displayed, the tertiary button is positioned hard left.',
    isRequired: false,
    prop: 'tertiaryButton',
    type: 'ReactElement',
  },
  {
    component: 'Modal',
    description: 'Determines the modal background colour, defaults to: grey',
    isRequired: false,
    prop: 'variant',
    type: "'grey' | 'white'",
  },
];

const tsInterface = `interface ComponentProps {
  ariaDescribedBy?: string;
  ariaLabelledBy?: string;
  children: ReactNode;
  dataTestId: string;
  disableClose?: boolean;
  disableEnforceFocus?: boolean;
  enableOverflow?: boolean;
  errorMessage?: ReactNode;
  fixedWidth?: number;
  heading?: string;
  isOpen: boolean;
  onClose: (event: MouseEvent) => void;
  primaryButton?: ReactElement;
  removeContentMargin?: boolean;
  secondaryButton?: ReactElement;
  subheading?: string;
  tertiaryButton?: ReactElement;
  variant?: 'grey' | 'white';
}
`;

export default () => {
  return (
    <PageTemplate
      bestPractice={bestPractice}
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
