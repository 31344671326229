import React from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ButtonWithIcon = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Button with icon</DocSubHeading>
        <DocParagraph>
          There are two props for displaying icons; decorativeIcon,
          functionalIcon. Decorative icons are displayed to the left of the
          button label, functional icons to the right. Icons can be displayed
          independently or together. These props accept an icon name which is
          applied to the Icon component inside Button, refer to the docs
          Icon/Icon Library to view the available icons.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>
            Decorative and functional icon
          </div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="positive-button"
              decorativeIcon="signature"
              functionalIcon="arrowRight"
              label="Job to be done"
              tone="positive"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'decorativeIcon="signature" functionalIcon="arrowRight"'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Decorative icon</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="positive-button"
              decorativeIcon="signature"
              label="Job to be done"
              tone="positive"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'decorativeIcon="signature"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Functional icon</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="positive-button"
              functionalIcon="arrowRight"
              label="Job to be done"
              tone="positive"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'functionalIcon="arrowRight"'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ButtonWithIcon;
