import React, { forwardRef, Fragment, MouseEvent } from 'react';

import PlxsIcon from '../Icon';
import PlxsSpinner from '../Spinner';

import { BRAND } from '../../_constants';

import { ComponentProps } from './Button.types';
import { SIZE_LG, SIZE_MD, SIZE_SM, VARIANT_PRIMARY } from './Button.constants';
import { useStyles } from './Button.styles';
import mapIconTone from './_utils/mapIconTone';

const Button = forwardRef<HTMLButtonElement, ComponentProps>(
  (
    {
      dataTestId,
      decorativeIcon,
      onClick,
      onMouseDown,
      onMouseOver,
      onMouseLeave,
      functionalIcon,
      id,
      isActive,
      isDisabled,
      isExtraWide,
      isFullWidth,
      isIconOnly,
      isLoading,
      isResponsiveWidth,
      label,
      size = SIZE_MD,
      tone = BRAND,
      type = 'button',
      variant = VARIANT_PRIMARY,
    },
    ref
  ) => {
    const styleProps = {
      hasDecorativeIcon: Boolean(decorativeIcon),
      hasFunctionalIcon: Boolean(functionalIcon),
      isActive,
      isDisabled,
      isExtraWide,
      isFullWidth,
      isIconOnly,
      isLoading,
      isResponsiveWidth,
      size,
      tone,
      variant,
    };
    const classes = useStyles(styleProps);

    const handleClick = (event: MouseEvent) => {
      if (typeof onClick === 'function') {
        return isLoading ? null : onClick(event);
      }
      return null;
    };

    const renderButtonInnerElement = () => {
      const iconSize = size === SIZE_LG ? SIZE_LG : SIZE_SM;
      const iconTone = mapIconTone(styleProps);
      if (functionalIcon && isIconOnly) {
        return (
          <PlxsIcon name={functionalIcon} size={iconSize} tone={iconTone} />
        );
      }
      return (
        <span className={classes.labelWrapper}>
          {decorativeIcon && (
            <PlxsIcon name={decorativeIcon} size={iconSize} tone={iconTone} />
          )}
          <span className={classes.label}>{label}</span>
          {functionalIcon && (
            <PlxsIcon name={functionalIcon} size={iconSize} tone={iconTone} />
          )}
        </span>
      );
    };

    const renderLoading = () => {
      const mapButtonToSpinnerSize = {
        sm: 18,
        md: 18,
        lg: 23,
      };
      return (
        <Fragment>
          <span className={classes.loadingOverlay}>
            <PlxsSpinner size={mapButtonToSpinnerSize[size]} />
          </span>
          {renderButtonInnerElement()}
        </Fragment>
      );
    };

    return (
      <button
        className={classes.root}
        data-testid={dataTestId}
        id={id}
        ref={ref}
        onClick={handleClick}
        onMouseDown={onMouseDown}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        disabled={isDisabled}
        type={type}
      >
        {isLoading ? renderLoading() : renderButtonInnerElement()}
      </button>
    );
  }
);

export default Button;
