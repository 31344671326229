import React, { FC, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsButton, PlxsRow, PlxsSwitch } from '@plexus-ui/index';
import { FormControlLabel } from '@material-ui/core';
import InteractiveFieldWrapper from '@dev/docs/_internal/InteractiveFieldWrapper';

const Switch: FC = () => {
  const [checked, setChecked] = useState(true);
  const [value, setValue] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const toggleChecked = () => setChecked(!checked);

  const handleSelected = () => {
    if (value) {
      setIndeterminate(false);
    }
    setValue(!value);
  };

  const handleIndeterminate = () => {
    setIndeterminate(!indeterminate);
    if (value) {
      setValue(false);
      setIndeterminate(true);
    }
  };

  const indeterminateButton = (
    <PlxsButton
      dataTestId="switch--toggle-indeterminate-button"
      label="Indeterminate"
      onClick={handleIndeterminate}
      tone="neutral"
      variant="secondary"
    />
  );

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Interactive Switch</DocHeading>
        <InteractiveFieldWrapper
          dataTestId="interactive-switch"
          customButton={indeterminateButton}
        >
          {({ dataTestId, isDisabled }) => (
            <PlxsSwitch
              dataTestId={dataTestId}
              isDisabled={isDisabled}
              checked={value}
              onChange={handleSelected}
              indeterminate={indeterminate}
            />
          )}
        </InteractiveFieldWrapper>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>States</DocHeading>
        <PlxsRow spaceBetween="lg">
          <FormControlLabel
            control={<PlxsSwitch checked onChange={() => {}} />}
            label="Checked"
            labelPlacement="start"
          />
        </PlxsRow>
        <PlxsRow spaceBetween="lg">
          <FormControlLabel
            control={<PlxsSwitch checked={false} onChange={() => {}} />}
            label="Unchecked"
            labelPlacement="start"
          />
        </PlxsRow>
        <PlxsRow spaceBetween="lg">
          <FormControlLabel
            control={
              <PlxsSwitch checked={false} onChange={() => {}} indeterminate />
            }
            label="Indeterminate"
            labelPlacement="start"
          />
        </PlxsRow>
        <PlxsRow spaceBetween="lg">
          <FormControlLabel
            control={<PlxsSwitch checked onChange={toggleChecked} isDisabled />}
            label="Checked, Disabled"
            labelPlacement="start"
            disabled
          />
        </PlxsRow>
        <PlxsRow spaceBetween="lg">
          <FormControlLabel
            control={
              <PlxsSwitch checked={false} onChange={toggleChecked} isDisabled />
            }
            label="Unchecked, Disabled"
            labelPlacement="start"
            disabled
          />
        </PlxsRow>
        <PlxsRow spaceBetween="lg">
          <FormControlLabel
            control={
              <PlxsSwitch
                checked={false}
                onChange={toggleChecked}
                indeterminate
                isDisabled
              />
            }
            label="Indeterminate, Disabled"
            labelPlacement="start"
            disabled
          />
        </PlxsRow>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Form Control</DocHeading>
        <FormControlLabel
          control={<PlxsSwitch checked={checked} onChange={toggleChecked} />}
          label="With form label"
          labelPlacement="start"
        />
      </DocRow>
    </DocRow>
  );
};

export default Switch;
