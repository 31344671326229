import React, { useState } from 'react';
import { DocRow, DocSubHeading } from '@dev/components';
import MenuList from '@plexus-ui/components/_internal/MenuList';
import MenuItem from '@plexus-ui/components/_internal/MenuItem';
import { useStyles } from './styles';
import { PlxsIcon } from '@plexus-ui/index';
import { Tone } from '@plexus-ui/types';

const ComplexItems = () => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);

  const exampleData = [
    {
      disabled: false,
      icon: <PlxsIcon name="upload" size="sm" />,
      label: 'Upload a file',
      value: 32,
    },
    {
      disabled: false,
      icon: <PlxsIcon name="signature" size="sm" />,
      label: 'Write a book',
      value: 211,
    },
    {
      disabled: true,
      icon: <PlxsIcon name="gift" size="sm" tone="disabled" />,
      label: 'Send a gift',
      value: 52,
    },
    {
      isDivider: true,
    },
    {
      disabled: false,
      icon: <PlxsIcon name="bin" size="sm" tone="urgent" />,
      label: 'Delete some files',
      value: 7,
      tone: 'urgent',
    },
    {
      disabled: true,
      icon: <PlxsIcon name="share" size="sm" tone="urgent" />,
      label: 'Share a document',
      value: 990,
      tone: 'urgent',
    },
  ];

  return (
    <DocRow margin="sm">
      <DocSubHeading>
        More complex with icons, urgent tones, a divider, and disabled options
      </DocSubHeading>
      <div className={classes.root}>
        <MenuList
          id="menu-list-2"
          dataTestId="menu-list-2"
          onKeyExit={() => {}}
          onSelect={setSelectedValue}
        >
          {exampleData.map((example, index) =>
            example.isDivider ? (
              <MenuItem key={index} isDivider />
            ) : (
              <MenuItem
                key={index}
                isDisabled={example.disabled}
                isHighlighted={selectedValue === example.value}
                value={example.value}
                tone={example.tone as Tone}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ display: 'inherit', marginRight: '8px' }}>
                    {example.icon}
                  </span>
                  <span>{example.label}</span>
                </div>
              </MenuItem>
            )
          )}
        </MenuList>
      </div>
    </DocRow>
  );
};

export default ComplexItems;
