import React, { FC, useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import { ComponentProps, RowData, Order } from './Table.types';
import { getSortedRows, tableClassesDefault } from './Table.utils';
import { useStyles } from './Table.styles';
import TableHead from './TableHead';
import TableCell from './TableCell';
import TablePagination from './TablePagination';
import Collapse from '@material-ui/core/Collapse';
import PlxsButton from '../Button';

const Table: FC<ComponentProps> = ({
  rows,
  columns,
  onClickRow,
  classes: tableClasses,
  emptyIndicator: EmptyComponent,
  rowRole = 'checkbox',
  isPaging = false,
  rowsPerPageOptions = [5, 10, 20],
  rowsPerPage = 5,
  highlightOnHover = false,
  highlightOnClick = false,
  expandable = false,
  renderExpandable = () => null,
  orderDefault = 'asc',
  sortFieldDefault = 'id',
  onRowsChange,
  disabledRows,
  isHeaderVisuallyHidden = false,
}) => {
  const classes = useStyles({
    rowCursor: onClickRow && typeof onClickRow === 'function',
  });
  const [order, setOrder] = React.useState<Order>(orderDefault);
  const [orderBy, setOrderBy] = React.useState<keyof RowData>(sortFieldDefault);
  const [page, setPage] = React.useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = React.useState(0);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState<string[]>([]);

  const isSelected = useCallback(
    (name: string) => selected.indexOf(name) !== -1,
    [selected]
  );

  const isOpen = useCallback(
    (name: string) => open.indexOf(name) !== -1,
    [open]
  );

  useEffect(() => {
    setCurrentRowsPerPage(isPaging ? rowsPerPage : rows.length);
  }, [isPaging, rows, rowsPerPage]);

  const handleRowSelect = useCallback(
    (name: string) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);

      return newSelected;
    },
    [selected]
  );

  const extendedClasses = useMemo(
    () => ({ tableClassesDefault, ...tableClasses }),
    [tableClasses]
  );

  const handleRequestSort = useCallback(
    (property: keyof RowData) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleClickRow = useCallback(
    (row: RowData) => (_?: React.MouseEvent<unknown>) => {
      if (onClickRow && typeof onClickRow === 'function') {
        let selectedRows: string[] = [];
        if (highlightOnClick) {
          selectedRows = handleRowSelect(row.id.toString());
        }
        onClickRow(
          row,
          rows.filter((row) => selectedRows.includes(row.id.toString()))
        );
      }
    },
    [handleRowSelect, onClickRow, highlightOnClick, rows]
  );

  return (
    <div className={classes.root}>
      <MuiTableContainer className={extendedClasses.tableContainer}>
        <MuiTable
          className={extendedClasses.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            isEmptyState={rows.length === 0}
            onRowsChange={onRowsChange}
            rows={rows}
            isVisuallyHidden={isHeaderVisuallyHidden}
            expandable={expandable}
          />
          <MuiTableBody
            className={cx(classes.tableBody, extendedClasses.tableBody)}
          >
            {rows.length > 0 &&
              getSortedRows(columns, rows, orderBy, order)
                .slice(
                  page * currentRowsPerPage,
                  page * currentRowsPerPage + currentRowsPerPage
                )
                .map((row) => {
                  const isItemSelected = isSelected(row.id.toString());

                  return (
                    <React.Fragment>
                      <MuiTableRow
                        key={row.id}
                        onClick={handleClickRow(row)}
                        role={rowRole}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        hover={highlightOnHover}
                        tabIndex={-1}
                        className={cx(
                          classes.tableRow,
                          disabledRows &&
                            disabledRows.some(
                              (disabledRow) => disabledRow.id === row.id
                            ) &&
                            classes.tableRowDisabled,
                          extendedClasses.tableRow
                        )}
                        classes={{
                          hover: 'tableRowHover',
                          selected: 'tableRowSelected',
                        }}
                      >
                        {columns.map((column, index) => {
                          const handleUpdateRow = (value: any) => {
                            if (!column.field) {
                              throw Error(
                                'Cannot update rows for column with no field.'
                              );
                            } else if (onRowsChange) {
                              const updatedRows = rows.map((updatedRow) =>
                                updatedRow.id === row.id
                                  ? { ...updatedRow, [column.field]: value }
                                  : updatedRow
                              );
                              onRowsChange(updatedRows);
                            }
                          };

                          return (
                            <TableCell
                              key={index}
                              col={column}
                              row={row}
                              className={cx(
                                classes.tableCell,
                                extendedClasses.tableCell
                              )}
                              onUpdateRow={handleUpdateRow}
                            />
                          );
                        })}

                        {expandable && (
                          <MuiTableCell
                            className={cx(
                              classes.tableCell,
                              classes.expandableRow,
                              classes.expandableActionCell,
                              extendedClasses.tableCell
                            )}
                          >
                            {isOpen(row.id.toString()) ? (
                              <PlxsButton
                                dataTestId={`${row.id}--close-expandable-action`}
                                label="Close"
                                functionalIcon="chevronUp"
                                isIconOnly={true}
                                tone="neutral"
                                variant="link"
                                size="sm"
                                onClick={() => {
                                  setOpen([]);
                                }}
                              />
                            ) : (
                              <PlxsButton
                                dataTestId={`${row.id}--open-expandable-action`}
                                label="Close"
                                functionalIcon="chevronDown"
                                isIconOnly={true}
                                tone="neutral"
                                variant="link"
                                size="sm"
                                onClick={() => {
                                  setOpen([row.id.toString()]);
                                }}
                              />
                            )}
                          </MuiTableCell>
                        )}
                      </MuiTableRow>

                      {expandable && (
                        <MuiTableRow>
                          <MuiTableCell
                            colSpan={columns.length + 1}
                            className={cx(
                              classes.expandableRow,
                              isOpen(row.id.toString())
                                ? classes.expandableTableCell
                                : classes.expandableTableCellNoBorder
                            )}
                          >
                            <Collapse
                              in={isOpen(row.id.toString())}
                              timeout="auto"
                            >
                              {renderExpandable(row)}
                            </Collapse>
                          </MuiTableCell>
                        </MuiTableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            {EmptyComponent && rows.length === 0 && (
              <MuiTableRow>
                <MuiTableCell colSpan={columns.length}>
                  <EmptyComponent />
                </MuiTableCell>
              </MuiTableRow>
            )}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
      {isPaging && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={rows.length}
          rowsPerPage={currentRowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default React.memo(Table);
