import { RowData } from '@plexus-ui/components/Table/Table.types';

export const rowsSort: RowData[] = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: '', age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  { id: 10, lastName: 'stark', firstName: 'Eddard', age: 65 },
  { id: 11, lastName: 'Stark', firstName: 'robb', age: 65 },
  { id: 12, lastName: 'Stark', firstName: 'robb', age: 65 },
  { id: 13, lastName: 'stark', firstName: 'Bran', age: 65 },
];

export const rows: RowData[] = [
  {
    id: 1,
    username: 'User name 1',
    email: 'user.1@example.com',
    status: 'Active',
  },
  {
    id: 2,
    username: 'User name 2',
    email: 'user.2@example.com',
    status: 'Active',
  },
  {
    id: 3,
    username: 'User name 3',
    email: 'user.3@example.com',
    status: 'Active',
  },
  {
    id: 4,
    username: 'User name 4',
    email: 'user.4@example.com',
    status: 'Active',
  },
  {
    id: 5,
    username: 'User name 5',
    email: 'user.5@example.com',
    status: 'Active',
  },
  {
    id: 6,
    username: 'User name 6',
    email: 'user.6@example.com',
    status: 'Active',
  },
  {
    id: 7,
    username: 'User name 7',
    email: 'user.7@example.com',
    status: 'Active',
  },
];

export const dataDocumentRows: RowData[] = [
  {
    id: 1,
    documentType: 'App - Independent Contractor Assessment 1',
    roles: 421,
    documents: '31091',
    users: '2531',
    active: false,
  },
  {
    id: 2,
    documentType: 'App - Independent Contractor Assessment 2',
    roles: 422,
    documents: '31092',
    users: '2532',
    active: false,
  },
  {
    id: 3,
    documentType: 'App - Independent Contractor Assessment 3',
    roles: 423,
    documents: '31093',
    users: '2533',
    active: false,
  },
  {
    id: 4,
    documentType: 'App - Independent Contractor Assessment 4',
    roles: 424,
    documents: '31094',
    users: '2534',
    active: false,
  },
  {
    id: 5,
    documentType: 'App - Independent Contractor Assessment 5',
    roles: 425,
    documents: '31095',
    users: '2535',
    active: false,
  },
  {
    id: 6,
    documentType: 'App - Independent Contractor Assessment 6',
    roles: 426,
    documents: '31096',
    users: '2536',
    active: false,
  },
  {
    id: 7,
    documentType: 'App - Independent Contractor Assessment 7',
    roles: 427,
    documents: '31097',
    users: '2537',
    active: false,
  },
  {
    id: 8,
    documentType: 'App - Independent Contractor Assessment 8',
    roles: 428,
    documents: '31098',
    users: '2538',
    active: false,
  },
];

export const getBigData = (max = 5000) => {
  const results: RowData[] = [];

  for (let index = 0; index < max; index++) {
    const item: RowData = {
      id: index,
      documentType: `App - Independent Contractor Assessment ${index}`,
      roles: 420 + index,
      documents: 31090 + index,
      users: 2530 + index,
    };

    results.push(item);
  }

  return results;
};
