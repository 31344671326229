import React, { FC, forwardRef } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import { BRAND } from '../../../_constants';

/**
 * IMPORTANT: DO NOT REMOVE THIS forwardRef. IT MAY LOOK UNUSED,
 * BUT THIS IS NOT THE CASE
 */
const MenuItem = forwardRef<HTMLLIElement, ComponentProps>(
  (
    {
      children,
      hasBorderRadius = false,
      isDisabled = false,
      isDivider = false,
      isHighlighted = false,
      onClick,
      onMouseEnter,
      value,
      tone = BRAND,
      dataTestId,
    },
    ref
  ) => {
    const classes = useStyles({
      hasBorderRadius,
      isDisabled,
      isDivider,
      isHighlighted,
      tone,
    });

    const isActionable = !(isDisabled || isDivider);

    return (
      <li
        ref={ref}
        className={classes.root}
        onClick={isActionable ? onClick : undefined}
        onMouseEnter={isActionable ? onMouseEnter : undefined}
        tabIndex={-1}
        value={value}
        data-testid={dataTestId}
      >
        {isDivider ? <hr className={classes.divider} /> : children}
      </li>
    );
  }
);

export default MenuItem;
