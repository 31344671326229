import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  header: {
    backgroundColor: '#002040',
    color: '#ffffff',
    padding: '12px 20px',
  },
  heading: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 1,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    borderRadius: '4px',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    marginRight: '40px',
    overflow: 'hidden',
  },
  swatch: {
    height: '100px',
    marginBottom: '12px',
    width: '100px',
  },
  shadeValue: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    marginBottom: '12px',
    padding: '0 12px',
  },
  hexValue: {
    color: '#808090',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '11px',
    marginBottom: '12px',
    padding: '0 12px',
  },
});
