import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const IconRight: FC<ComponentProps> = ({ children, isDisabled }) => {
  const classes = useStyles({
    isDisabled,
  });

  return children ? <div className={classes.wrapper}>{children}</div> : null;
};

export default IconRight;
