import { makeStyles } from '@material-ui/core/styles';
import { PlxsStyles } from '@plexus-ui/index';

export const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '24px',
    gridAutoRows: '1fr',
    [`@media (min-width:${PlxsStyles.breakpoints.md})`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [`@media (min-width:${PlxsStyles.breakpoints.lg})`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
});
