import { ModalDeprecatedDocs } from '@dev/docs/_deprecated/index';

const componentListDeprecated = [
  {
    component: ModalDeprecatedDocs,
    name: 'ModalDeprecated',
    path: '/modalDeprecated',
  },
];

export default componentListDeprecated;
