import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Props: FC<ComponentProps> = ({ propList }) => {
  const classes = useStyles();
  return (
    <div>
      {propList.map((propListItem, index) => {
        const { description, isRequired, prop, type } = propListItem;
        return (
          <div className={classes.prop} key={index}>
            <h3>{prop}</h3>
            <p>
              <span className={classes.bold}>Type:</span> {type}{' '}
              {isRequired && '(Required)'}
            </p>
            <p>
              <span className={classes.bold}>Description:</span> {description}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Props;
