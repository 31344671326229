import React, { FC } from 'react';
import './index.css';

const TextContent: FC = () => {
  return (
    <div className="text-content">
      <h2>Lorem ipsum dolor</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
        pharetra, dui hendrerit euismod interdum, dolor quam bibendum arcu, nec
        pellentesque tellus velit vitae arcu.
      </p>
    </div>
  );
};

export default TextContent;
