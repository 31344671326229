import React, { FC } from 'react';

import { ComponentProps } from './Field.types';
import { FieldContext } from './Field.context';
import { useStyles } from './Field.styles';
import PlxsText from '../Text';
import PlxsIcon from '../Icon/Icon';

const Field: FC<ComponentProps> = ({
  children,
  errorMessage,
  hasError,
  label,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {label && (
        <PlxsText
          element="span"
          color={hasError ? 'urgent' : 'dark'}
          weight="bold"
        >
          {label}
        </PlxsText>
      )}
      <FieldContext.Provider value={{ hasError }}>
        {children}
      </FieldContext.Provider>
      {hasError && errorMessage && (
        <PlxsText element="span" color="urgent" weight="bold">
          <PlxsIcon name="warning" tone="urgent" />
          {errorMessage}
        </PlxsText>
      )}
    </div>
  );
};

export default Field;
