import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../styles';

export const useStyles = makeStyles({
  button: {
    backgroundColor: color.brandLight,
    borderColor: color.brandLight,
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: color.brand,
    lineHeight: '100%',
    padding: '9px',
    margin: '0px',
    marginRight: '12px',
  },
});
