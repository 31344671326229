import { color } from '../../../styles';
import { BRAND, POSITIVE, URGENT, WHITE } from '../../../_constants';

import { StyleProps } from '../Button.types';

export const linkStyles = (props: StyleProps) => {
  switch (props.tone) {
    case BRAND: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.brandLight,
            borderColor: color.brandLight,
            color: color.brand,
          }
        : {
            color: color.brand,
          };
      return {
        backgroundColor: props.isLoading ? color.brandLight : 'transparent',
        borderColor: props.isLoading ? color.brandLight : 'transparent',
        color: color.brand,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
    // POSITIVE | NEUTRAL | URGENT | WHITE
    default: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.neutralShade[600],
            borderColor: color.neutralShade[600],
            color: color.neutralShade[200],
          }
        : {
            color: color.neutralShade[200],
          };
      return {
        backgroundColor: props.isLoading
          ? color.neutralShade[600]
          : 'transparent',
        borderColor: props.isLoading ? color.neutralShade[600] : 'transparent',
        color: color.neutralShade[200],
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
  }
};

export const primaryStyles = (props: StyleProps) => {
  switch (props.tone) {
    case POSITIVE: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.positiveDark,
            borderColor: color.positiveDark,
            color: color.white,
          }
        : {
            color: color.white,
          };
      return {
        backgroundColor: color.positive,
        borderColor: color.positive,
        color: color.white,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
    case URGENT: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.urgentDark,
            borderColor: color.urgentDark,
            color: color.white,
          }
        : {
            color: color.white,
          };
      return {
        backgroundColor: color.urgent,
        borderColor: color.urgent,
        color: color.white,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
    case WHITE: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.brandLight,
            borderColor: color.brandLight,
            color: color.brand,
          }
        : {
            color: color.brand,
          };
      return {
        backgroundColor: color.white,
        borderColor: color.white,
        color: color.brand,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
    // BRAND | NEUTRAL
    default: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.brandDark,
            borderColor: color.brandDark,
            color: color.white,
          }
        : {
            color: color.white,
          };
      return {
        backgroundColor: color.brand,
        borderColor: color.brand,
        color: color.white,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
  }
};

export const secondaryStyles = (props: StyleProps) => {
  switch (props.tone) {
    case BRAND: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.brandLight,
            borderColor: color.brandLight,
            color: color.brand,
          }
        : {
            color: color.brand,
          };
      return {
        backgroundColor: props.isLoading ? color.white : 'transparent',
        borderColor: color.brandLight,
        color: color.brand,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
    case URGENT: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.urgentLight,
            borderColor: color.urgentLight,
            color: color.urgent,
          }
        : {
            color: color.urgent,
          };
      return {
        backgroundColor: props.isLoading ? color.white : 'transparent',
        borderColor: color.urgentLight,
        color: color.urgent,
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
    // POSITIVE | NEUTRAL | WHITE
    default: {
      const hoverStyles = !props.isLoading
        ? {
            backgroundColor: color.neutralShade[600],
            borderColor: color.neutralShade[600],
            color: color.neutralShade[200],
          }
        : {
            color: color.neutralShade[200],
          };
      return {
        backgroundColor: props.isLoading ? color.white : 'transparent',
        borderColor: color.neutralShade[600],
        color: color.neutralShade[200],
        ...(props.isActive ? hoverStyles : {}),
        '&:hover': hoverStyles,
      };
    }
  }
};
