import React, { FC, memo } from 'react';

import { BRAND, DARK, URGENT } from '../../../_constants';
import InternalTone from '../Tone';
import PlxsIcon from '../../Icon';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import { VARIANT_ERROR, VARIANT_HELP } from './constants';

const HELP_ICON = 'help';
const WARNING_ICON = 'warning';

const Label: FC<ComponentProps> = ({
  decorativeIcon,
  hasError,
  content,
  variant,
}) => {
  const classes = useStyles({ variant });

  switch (variant) {
    case VARIANT_ERROR: {
      return (
        <span className={classes.root}>
          <PlxsIcon name={WARNING_ICON} tone={URGENT} />
          <InternalTone tone={URGENT}>{content}</InternalTone>
        </span>
      );
    }
    case VARIANT_HELP: {
      return (
        <span className={classes.root}>
          <PlxsIcon name={HELP_ICON} tone={BRAND} />
          <InternalTone hasHover={true} tone={BRAND}>
            {content}
          </InternalTone>
        </span>
      );
    }
    default: {
      const tone = hasError ? URGENT : DARK;
      return (
        <span className={classes.root}>
          {decorativeIcon && <PlxsIcon name={decorativeIcon} tone={tone} />}
          <InternalTone tone={tone}>{content}</InternalTone>
        </span>
      );
    }
  }
};

export default memo(Label);
