import React, { FC, Fragment } from 'react';
import { ComponentProps } from './types';

const PlaceholderText: FC<ComponentProps> = ({ length }) => {
  switch (length) {
    case 'short': {
      return (
        <Fragment>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Fragment>
      );
    }
    case 'medium': {
      return (
        <Fragment>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          mi dui, placerat nec tellus eu, luctus ultricies justo.
        </Fragment>
      );
    }
    default: {
      return (
        <Fragment>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          mi dui, placerat nec tellus eu, luctus ultricies justo. Pellentesque
          maximus, eros a tempor viverra, nisi elit placerat velit, vitae
          posuere sem ante eget ligula.
        </Fragment>
      );
    }
  }
};

export default PlaceholderText;
