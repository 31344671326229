import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';
import Playground from './Playground';

const props = `interface ComponentProps {
  autoDismissVisibleDuration?: number;
  canManuallyDismiss?: boolean;
  children: ReactNode;
  dataTestId: string;
  icon: IconName;
  id?: string;
  isAutoDismiss?: boolean;
  isVisible: boolean;
  onDismiss: (event?: MouseEvent) => void;
  onTransitionedIn?: () => void;
  onTransitionedOut?: () => void;
  onUndo?: (event: MouseEvent) => void;
  tone?: BaseTone;
}`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>The Toast component.</DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocCode>
            {`<PlxsToast icon="bin" dataTestId="toast-usage" isVisible={true} onDismiss={() => {}}>{children}</PlxsToast>`}
          </DocCode>
        </DocRow>

        <DocRow margin="sm">
          <DocHeading>Examples</DocHeading>
          <DocParagraph>
            The appearance and behaviour of the toast can be modified via a
            number of props.
          </DocParagraph>
        </DocRow>

        <Playground />
      </DocRow>
    </DocRow>
  );
};
