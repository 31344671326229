import { makeStyles } from '@material-ui/core/styles';

import { breakpoints, color, focusState } from '../../styles';
import { defaultTextStyle } from '../../styles/text';
import { tone } from '../../styles/tone';

import { linkStyles, primaryStyles, secondaryStyles } from './_styles/tone';
import { StyleProps } from './Button.types';
import {
  SIZE_LG,
  SIZE_MD,
  VARIANT_LINK,
  VARIANT_SECONDARY,
} from './Button.constants';

const disabledStyles = (props: StyleProps) => {
  if (props.isDisabled) {
    return {
      backgroundColor: color.neutralShade[800],
      borderColor: color.neutralShade[800],
      color: tone.disabled,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: color.neutralShade[800],
        borderColor: color.neutralShade[800],
        color: tone.disabled,
      },
      '&[disabled]': {
        cursor: 'not-allowed',
      },
    };
  }
};

const sizeStyles = (props: StyleProps) => {
  // Padding values are the design system spec - 1px
  // to offset the buttons 1px border.
  if (props.isIconOnly) {
    switch (props.size) {
      case SIZE_LG: {
        return {
          fontSize: '1px',
          lineHeight: '1px',
          padding: '17px',
        };
      }
      case SIZE_MD: {
        return {
          fontSize: '1px',
          lineHeight: '1px',
          padding: '10px',
        };
      }
      // SIZE_SM
      default: {
        return {
          fontSize: '1px',
          lineHeight: '1px',
          padding: '6px',
        };
      }
    }
  }

  switch (props.size) {
    case SIZE_LG: {
      return {
        fontSize: '16px',
        lineHeight: '24px',
        padding: '17px 31px',
      };
    }
    case SIZE_MD: {
      const padding = props.isExtraWide ? '10px 47px' : '10px 15px';
      return {
        fontSize: '13px',
        lineHeight: '18px',
        padding,
      };
    }
    // SIZE_SM
    default: {
      return {
        fontSize: '13px',
        lineHeight: '18px',
        padding: '6px 15px',
      };
    }
  }
};

const variantStyles = (props: StyleProps) => {
  switch (props.variant) {
    case VARIANT_LINK: {
      return linkStyles(props);
    }
    case VARIANT_SECONDARY: {
      return secondaryStyles(props);
    }
    // VARIANT_PRIMARY
    default: {
      return primaryStyles(props);
    }
  }
};

const widthStyles = (props: StyleProps) => {
  const fullWidth = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };

  const responsiveWidth = {
    ...fullWidth,
    [`@media only screen and (min-width:${breakpoints.sm})`]: {
      display: 'inline-block',
      width: 'auto',
    },
  };

  if (props.isFullWidth) {
    return fullWidth;
  }

  if (props.isResponsiveWidth) {
    return responsiveWidth;
  }

  return null;
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    '-webkit-font-smoothing': 'inherit',
    '-moz-osx-font-smoothing': 'inherit',
    '-webkit-appearance': 'none',
    background: 'transparent',
    border: '1px solid transparent',
    borderRadius: 3,
    cursor: 'pointer',
    margin: 0,
    overflow: 'visible',
    position: 'relative',
    // Maintain order below for inheritance
    ...defaultTextStyle,
    fontWeight: 600,
    ...sizeStyles(props),
    ...variantStyles(props),
    ...widthStyles(props),
    // Disabled styles go last
    ...disabledStyles(props),
    '&:focus': focusState,
  }),
  label: (props: StyleProps) => ({
    paddingLeft: props.hasDecorativeIcon ? '8px' : undefined,
    paddingRight: props.hasFunctionalIcon ? '8px' : undefined,
  }),
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingOverlay: (props: StyleProps) => ({
    alignItems: 'center',
    border: '1px solid transparent',
    borderRadius: 3,
    cursor: 'wait',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    ...variantStyles(props),
  }),
}));
