import { makeStyles } from '@material-ui/core/styles';

import { tone as stylesTone } from '../../styles/tone';

import { StyleProps } from './Icon.types';

export const useStyles = makeStyles(() => ({
  root: ({ background, tone, customSize }: StyleProps) => ({
    background,
    color: tone === 'inherit' ? tone : stylesTone[tone],
    height: customSize,
    width: customSize,
  }),
}));
