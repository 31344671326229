import { FC } from 'react';
import { DocCode, DocHeading, DocRow, DocSubHeading } from '@dev/components';
import { PlxsDateTime } from '@plexus-ui/index';

const DateTimeDocs: FC = () => {
  const dateNow = new Date();
  const timestamp = '2022-02-02T14:48:56Z';

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Locale</DocHeading>
        <DocRow margin="sm">
          <DocSubHeading>AU</DocSubHeading>
          <DocCode>{`<PlxsDateTime locale="en-AU">
  {new Date()}
</PlxsDateTime>`}</DocCode>
          <PlxsDateTime locale="en-AU">{dateNow}</PlxsDateTime>
        </DocRow>

        <DocRow margin="sm">
          <DocSubHeading>US</DocSubHeading>
          <DocCode>{`<PlxsDateTime locale="en-US">
  {new Date()}
</PlxsDateTime>`}</DocCode>
          <PlxsDateTime locale="en-US">{dateNow}</PlxsDateTime>
        </DocRow>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Accepted types</DocHeading>
        <DocRow margin="sm">
          <DocSubHeading>Date</DocSubHeading>
          <DocCode>{`<PlxsDateTime locale="en-AU">
  {new Date()}
</PlxsDateTime>`}</DocCode>
          <PlxsDateTime locale="en-AU">{dateNow}</PlxsDateTime>
        </DocRow>

        <DocRow margin="sm">
          <DocSubHeading>Date string</DocSubHeading>
          <DocCode>{`<PlxsDateTime locale="en-AU">
  Tue May 16 2023 14:37:43 GMT+1000 (Australian Eastern Standard Time)
</PlxsDateTime>`}</DocCode>
          <PlxsDateTime locale="en-AU">
            Tue May 16 2023 14:37:43 GMT+1000 (Australian Eastern Standard Time)
          </PlxsDateTime>
        </DocRow>

        <DocRow margin="sm">
          <DocSubHeading>Timestamp</DocSubHeading>
          <DocCode>{`<PlxsDateTime locale="en-AU">
  2022-02-02T14:48:56Z
</PlxsDateTime>`}</DocCode>
          <PlxsDateTime locale="en-AU">{timestamp}</PlxsDateTime>
        </DocRow>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Date format</DocHeading>

        <DocSubHeading>Date as digits</DocSubHeading>

        <DocCode>{`<PlxsDateTime
  dateFormat="digits"
  locale="en-AU"
>
  2022-02-02T14:48:56Z
</PlxsDateTime>`}</DocCode>

        <PlxsDateTime dateFormat="digits" locale="en-AU">
          {timestamp}
        </PlxsDateTime>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Variants</DocHeading>

        <DocRow margin="sm">
          <DocSubHeading>Date</DocSubHeading>

          <DocCode>{`<PlxsDateTime
  locale="en-AU"
  variant="date"
>
  2022-02-02T14:48:56Z
</PlxsDateTime>`}</DocCode>

          <PlxsDateTime locale="en-AU" variant="date">
            {timestamp}
          </PlxsDateTime>
        </DocRow>

        <DocRow margin="sm">
          <DocSubHeading>Time</DocSubHeading>

          <DocCode>{`<PlxsDateTime
  locale="en-AU"
  variant="time"
>
  2022-02-02T14:48:56Z
</PlxsDateTime>`}</DocCode>

          <PlxsDateTime locale="en-AU" variant="time">
            {timestamp}
          </PlxsDateTime>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};

export default DateTimeDocs;
