import React, { FC, Fragment } from 'react';
import { PlxsRow, PlxsText } from '@plexus-ui/index';

const SandboxTest: FC = () => {
  return (
    <Fragment>
      <PlxsRow spaceBelow="lg">
        <PlxsText size="md">
          Use this page to test out Plexus UI components.
        </PlxsText>
      </PlxsRow>
    </Fragment>
  );
};

export default SandboxTest;
