import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  timePeriod: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    '& button': {
      padding: '0px',
      margin: '0px',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
});
