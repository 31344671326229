import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsTextField } from '@plexus-ui/index';

const ErrorTextField: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextField
      dataTestId="error-text-field"
      hasError={true}
      errorMessage="Error text to go here"
      label="Input name to go here"
      onChange={handleChange}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default ErrorTextField;
