import React, { FC, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsButton, PlxsTabContent, PlxsTabProvider } from '@plexus-ui/index';

import { BasicContent1, BasicContent2 } from './_internal/BasicContent';

const ExternalControl: FC = () => {
  const [activeTab, setActiveTab] = useState('basic-tab-1');

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>External Control</DocHeading>
        <PlxsTabProvider
          activeTab={activeTab}
          ariaLabel="Example"
          dataTestId="basic"
          height={250}
          onClick={(activeTabId) => setActiveTab(activeTabId)}
        >
          <PlxsTabContent id="basic-tab-1" tabLabel="Tab one">
            <BasicContent1 />
          </PlxsTabContent>
          <PlxsTabContent id="basic-tab-2" tabLabel="Tab two">
            <BasicContent2 />
          </PlxsTabContent>
        </PlxsTabProvider>
        <PlxsButton
          dataTestId="example"
          size="sm"
          label="Go to Tab 2"
          onClick={() => {
            setActiveTab('basic-tab-2');
          }}
        />
      </DocRow>
    </DocRow>
  );
};

export default ExternalControl;
