import React, { FC } from 'react';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import MuiTableCell from '@material-ui/core/TableCell';
import TableIconSort from '../TableIconSort';
import { TableCellHeadProps } from './types';
import { useStyles } from './styles';
import { useBreakpoint } from '../Table.hooks';
import { renderControlHeader } from '../Table.utils';

const TableCellHead: FC<TableCellHeadProps> = React.memo(
  ({
    col,
    orderBy,
    order,
    createSortHandler,
    isEmptyState,
    onUpdateAllRows,
    rows,
  }) => {
    const breakpoint = useBreakpoint(col);
    const classes = useStyles();

    const width =
      col.responsive &&
      col.responsive[breakpoint] &&
      col.responsive[breakpoint].width !== undefined
        ? col.responsive[breakpoint].width
        : col.width;
    const minWidth =
      col.responsive &&
      col.responsive[breakpoint] &&
      col.responsive[breakpoint].minWidth !== undefined
        ? col.responsive[breakpoint].minWidth
        : col.minWidth;
    const hide =
      col.responsive &&
      col.responsive[breakpoint] &&
      col.responsive[breakpoint].hide !== undefined
        ? col.responsive[breakpoint].hide
        : col.hide;

    if (hide) return null;

    return (
      <MuiTableCell
        align={col.align ?? 'left'}
        padding="default"
        sortDirection={orderBy && orderBy === col.field ? order : false}
        className={classes.tableCellHead}
        style={{
          width: width,
          minWidth: minWidth,
        }}
      >
        <div className={classes.cellWrapp}>
          <div className={classes.controlHeader}>
            {renderControlHeader(col, rows, onUpdateAllRows)}
          </div>
          <div className={classes.textHeader}>
            {col.headerName && (
              <MuiTableSortLabel
                active={orderBy && orderBy === col.field}
                direction={order}
                onClick={createSortHandler(col.field)}
                className={classes.tableHeadLabel}
                IconComponent={TableIconSort}
                disabled={!(col.sortable ?? true) || isEmptyState}
              >
                {col.headerName}
              </MuiTableSortLabel>
            )}
          </div>
        </div>
      </MuiTableCell>
    );
  }
);

export default TableCellHead;
