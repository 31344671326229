import React, { Fragment, MouseEvent, useRef, useState } from 'react';

import { DocCode, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton, PlxsRichTextEditor } from '@plexus-ui/index';

const BasicExample = () => {
  const [value, setValue] = useState(
    'Click the get editor content button above to print the value.'
  );

  const editorRef = useRef(null);

  const handleInit = (event: any, editor: any) => (editorRef.current = editor);

  const handleGetContent = (event: MouseEvent) => {
    if (editorRef.current) {
      setValue(editorRef.current.getContent());
    }
  };

  return (
    <Fragment>
      <DocRow margin="sm">
        <PlxsRichTextEditor
          dataTestId="basic-rich-text-editor"
          label="Basic Rich Text Editor"
          onInit={handleInit}
          placeholder="Editor placeholder text..."
          toolbar="basic"
          height={160}
          resize={true}
        />
      </DocRow>

      <DocRow margin="sm">
        <PlxsButton
          dataTestId="get-content-button"
          label="Get Editor Content"
          onClick={handleGetContent}
        />
      </DocRow>

      <DocRow margin="sm">
        <DocSubHeading>Value</DocSubHeading>
        <DocCode>{value}</DocCode>
      </DocRow>
    </Fragment>
  );
};

export default BasicExample;
