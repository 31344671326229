import { makeStyles } from '@material-ui/core/styles';

import { focusState } from '../../../../styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  tabPanel: ({ isActive, hasPadding }: StyleProps) => ({
    display: isActive ? 'block' : 'none',
    '&:focus': {
      ...focusState,
    },
    padding: hasPadding ? '0 16px 16px 16px' : undefined,
  }),
}));
