import { RowData } from '@plexus-ui/components/Table/Table.types';

export const editableRows: RowData[] = [
  {
    id: 1,
    key: 'counterparty',
    value: 'user.2@example.com',
    displayName: 'counterparty',
    displayGroup: 'counterparty',
    isEditable: true,
  },
  {
    id: 2,
    key: 'created',
    value: '2022-06-15T23:58:06.424017Z',
    displayName: 'created',
    displayGroup: 'created',
    isEditable: true,
  },
  {
    id: 3,
    key: 'category',
    value: '1CategorySpamName',
    isEditable: true,
    displayName: 'category',
    displayGroup: 'categorySubcategory',
  },
  {
    id: 4,
    key: 'companyAbn',
    value: 'Acme',
    isEditable: true,
    displayName: 'companyAbn',
    displayGroup: 'companyAbn',
  },
  {
    id: 5,
    key: 'organisationName',
    value: 'Jons Perfect Default Org',
    isEditable: false,
    displayName: 'organisation unit',
    displayGroup: 'organisationName',
  },
];
