import React, { FC, MouseEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';

import InternalLabel from '../_internal/Label';

import { ComponentProps } from './Modal.types';
import { useStyles } from './Modal.styles';
import ButtonGroup from './_internal/ButtonGroup';
import Footer from './_internal/Footer';
import Header from './_internal/Header';

const Modal: FC<ComponentProps> = ({
  ariaDescribedBy,
  ariaLabelledBy,
  children,
  dataTestId,
  disableClose,
  disableEnforceFocus,
  enableOverflow,
  errorMessage,
  fixedWidth,
  removeContentMargin,
  heading,
  isOpen,
  onClose,
  primaryButton,
  secondaryButton,
  subheading,
  tertiaryButton,
  variant = 'grey',
}) => {
  const hasHeader = heading || subheading;
  const hasPrimaryButton = Boolean(primaryButton);
  const hasTertiaryButton = Boolean(tertiaryButton);

  const classes = useStyles({
    enableOverflow,
    fixedWidth,
    hasPrimaryButton,
    removeContentMargin,
    variant,
  });

  const errorMessageComponent = Boolean(errorMessage) && (
    <InternalLabel content={errorMessage} variant="error" />
  );

  const handleClose = (event: MouseEvent, reason: string) => {
    if (disableClose && reason === 'backdropClick') {
      return false;
    }

    if (disableClose && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onClose === 'function') {
      onClose(event);
    }
  };

  return (
    <Dialog
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      BackdropProps={{ className: classes.backdrop }}
      classes={{ paper: classes.contentContainer }}
      data-testid={dataTestId}
      disableEnforceFocus={disableEnforceFocus}
      fullWidth={true}
      maxWidth={false}
      onClose={handleClose}
      open={isOpen}
    >
      {hasHeader && <Header heading={heading} subheading={subheading} />}

      <div className={classes.content}>{children}</div>

      {hasPrimaryButton && (
        <ButtonGroup
          errorMessageComponent={errorMessageComponent}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          tertiaryButton={tertiaryButton}
        />
      )}

      <Footer>{hasTertiaryButton && errorMessageComponent}</Footer>
    </Dialog>
  );
};

export default Modal;
