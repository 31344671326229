import { makeStyles } from '@material-ui/core/styles';
import { brand, neutral } from '../../styles/color';
import { breakpoints, spaceUnit, spacing } from '../../styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles({
  backdrop: {
    backgroundColor: fade(brand[100], 0.3),
  },
  contentContainer: {
    backgroundColor: neutral[900],
    boxSizing: 'border-box',
    outline: 'none',
    boxShadow: 'none',
    margin: 0,
    padding: `${spacing.xl}px ${spacing.md}px`,
    width: '100vw',
    maxWidth: '100%',
    borderRadius: '3px',
    [`@media (min-width:${breakpoints.sm})`]: {
      padding: spacing.xl,
      width: '528px',
    },
  },
  subHeadingWrapper: {
    margin: `${spacing.md}px 0 0`,
  },
  footerWrapper: {
    margin: `${spaceUnit * 16}px 0 0`,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& > :not(:first-child)': {
      margin: `${spacing.xl}px 0 0`,
    },
    [`@media (min-width:${breakpoints.sm})`]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& > :not(:first-child)': {
        margin: `0 0 0 ${spacing.xl}px`,
      },
    },
  },
});
