import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const TabPanel: FC<ComponentProps> = ({
  id,
  children,
  ariaLabelledBy,
  isActive,
  hasPadding,
}) => {
  const classes = useStyles({ isActive, hasPadding });
  return (
    <div
      id={id}
      aria-labelledby={ariaLabelledBy}
      className={classes.tabPanel}
      role="tabpanel"
    >
      {children}
    </div>
  );
};

export default TabPanel;
