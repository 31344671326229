import ColorPalette from '@dev/docs/color';

const color = [
  {
    component: ColorPalette,
    name: 'Color Palette',
    path: '/colorPalette',
  },
];

export default color;
