import React from 'react';
import {
  DocCode,
  DocPropGroupHeading,
  DocRow,
  DocSubHeading,
} from '@dev/components';

import DefaultTextField from './Default';
import DisabledTextField from './Disabled';
import ErrorTextField from './Error';

export default () => {
  return (
    <DocRow margin="lg">
      <DocPropGroupHeading>States</DocPropGroupHeading>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Default</DocSubHeading>
        </DocRow>
        <DefaultTextField />
        <DocCode>{`<PlxsTextField
  dataTestId="default-text-field"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Disabled</DocSubHeading>
        </DocRow>
        <DisabledTextField />
        <DocCode>{`<PlxsTextField
  dataTestId="disabled-text-field"
  isDisabled={true}
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Error</DocSubHeading>
        </DocRow>
        <ErrorTextField />
        <DocCode>{`<PlxsTextField
  dataTestId="error-text-field"
  errorMessage="Error text to go here"
  hasError={true}
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>
    </DocRow>
  );
};
