import { makeStyles } from '@material-ui/core/styles';

import { brand } from '../../../styles/color';
import { defaultTextStyle } from '../../../styles/text';
import {
  getInputStyle,
  inputReset,
  inputTransparent,
} from '../../../styles/input';
import { tone } from '../../../styles/tone';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  root: {
    ...defaultTextStyle,
    position: 'relative',
  },
  selectInput: (props: StyleProps) => {
    const { hasError, isActive, isDisabled } = props;
    return {
      ...getInputStyle({ hasError, isActive, isDisabled }),
      ...inputTransparent,
      paddingRight: '9px',
      userSelect: 'none',
    };
  },
  dropDownMenu: {
    ...inputReset,
    position: 'absolute',
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    opacity: 0,
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0,
    },
    '& option': {
      color: brand[100],
    },
    '& option:disabled': {
      color: tone.disabled,
    },
  },
}));
