import React, { FC, Fragment } from 'react';
import { isValid } from 'date-fns';

import { ComponentProps } from './DateTime.types';
import { getDate } from './_utils';
import DateDisplay from './DateDisplay';
import TimeDisplay from './TimeDisplay';

const DateTime: FC<ComponentProps> = ({
  children,
  dateFormat = 'shortMonth',
  locale,
  variant = 'dateTime',
}) => {
  const date = getDate(children);

  if (!isValid(date)) {
    return null;
  }

  if (variant === 'date') {
    return <DateDisplay date={date} dateFormat={dateFormat} locale={locale} />;
  }

  if (variant === 'time') {
    return <TimeDisplay date={date} locale={locale} />;
  }

  return (
    <Fragment>
      <DateDisplay date={date} dateFormat={dateFormat} locale={locale} />
      {', '}
      <TimeDisplay date={date} locale={locale} />
    </Fragment>
  );
};

export default DateTime;
