import React from 'react';
import {
  DocCode,
  DocParagraph,
  DocProp,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsChip } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const Variant = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Variant</DocSubHeading>
        <DocParagraph>
          The <DocProp>variant</DocProp> prop adjusts the label styling on
          normal and hover states.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Plaintext</div>
          <div className={classes.icons}>
            <PlxsChip
              functionalIcon="cross"
              hasHover={true}
              label="Tomas Michaels"
              tone="brand"
              variant="plainText"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'hasHover={true} tone="brand" variant="plainText"'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Muted</div>
          <div className={classes.icons}>
            <PlxsChip
              functionalIcon="cross"
              hasHover={true}
              label="Tomas Michaels"
              tone="brand"
              variant="muted"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'hasHover={true} tone="brand" variant="muted"'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default Variant;
