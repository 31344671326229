import { makeStyles } from '@material-ui/core/styles';
import { color, formColor } from '../../styles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: color.white,
    border: `1px solid ${formColor.border}`,
    borderRadius: '2px',
  },
});
