import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../../../../styles/text';
import { fontColor } from '../../../../../styles';
import { getInputStyle } from '../../../../../styles/input';

import { StyleProps } from './types';

export const useStyles = makeStyles({
  input: (props: StyleProps) => {
    const { hasError, isActive, isDisabled, styleOverrides } = props;
    const { state: stateOverrides, css: cssOverrides } = styleOverrides;
    const inputState =
      stateOverrides && Object.keys(stateOverrides).length > 0
        ? stateOverrides
        : { hasError, isActive, isDisabled };

    return {
      ...getInputStyle(inputState),
      alignItems: 'flex-start',
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      ...(cssOverrides || {}),
    };
  },
  placeholder: (props: StyleProps) => ({
    ...defaultTextStyle,
    color: fontColor.disabled,
    opacity: props.isDynamicLabelMinimised ? 0 : 1,
    overflow: 'hidden',
    textWrap: 'nowrap',
  }),
});
