import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import { matchSorter } from 'match-sorter';
import ReactAutosuggest from 'react-autosuggest';

import { ComponentProps } from '../AutoComplete.types';
import { maxMenuListHeightDefault } from '../_constants';
import { useStyles as autoCompleteStyles } from '../AutoComplete.styles';
import Dropdown from '../_internal/Dropdown';
import FieldWrapper from '../_internal/FieldWrapper';
import MultiSelectList from '../../_internal/MultiSelectList';

import { useStyles as multiSelectStyles } from './styles';

const AutoCompleteMultiSelect: FC<ComponentProps> = (props) => {
  const {
    autoFocus,
    dataTestId,
    dropdownFooter,
    getOptionValue,
    hasError,
    id,
    initialValue,
    inputRef,
    isDefaultActive = false,
    isDisabled,
    isInline = true,
    matchKeys,
    maxMenuListHeight = maxMenuListHeightDefault,
    multiSelectFilter,
    onBlur,
    onFocus,
    onSelect,
    optionList,
    renderOption,
    tooltipTextKey,
    matchByOption,
  } = props;

  const autoCompleteClasses = autoCompleteStyles({ isDisabled });
  const autoCompleteDataTestId = `${dataTestId}--auto-complete`;
  const multiSelectClasses = multiSelectStyles();

  const [_optionList, setOptionList] = useState([]);
  const [isActive, setActive] = useState(isDefaultActive);
  const [selectedList, setSelectedList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState<string>('');

  const getSuggestionValue = (suggestion: any) => {
    return getOptionValue ? getOptionValue(suggestion) : suggestion;
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setActive(false);
    onBlur && onBlur(event);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, { newValue }) => {
    setValue(newValue);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setActive(true);
    onFocus && onFocus(event);
  };

  const handleRemove = (suggestion: any) => {
    const selectedListUpdate = multiSelectFilter(selectedList, suggestion);
    const _optionListUpdate = [suggestion, ..._optionList];
    setSelectedList(selectedListUpdate);
    setOptionList(_optionListUpdate);
    onSelect(selectedListUpdate);
  };

  const onSuggestionSelected = (event: FormEvent, { suggestion }) => {
    const selectedListUpdate = [...selectedList, suggestion];
    const _optionListUpdate = multiSelectFilter(_optionList, suggestion);
    setValue('');
    setSelectedList(selectedListUpdate);
    setOptionList(_optionListUpdate);
    onSelect(selectedListUpdate);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const options = matchByOption
      ? { threshold: matchSorter.rankings[matchByOption] }
      : null;
    const suggestions = matchSorter(_optionList, value, {
      keys: matchKeys,
      ...options,
    });
    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion: any) => {
    return renderOption ? renderOption(suggestion) : suggestion;
  };

  const renderSuggestionsContainer = ({ children, containerProps }) => {
    return (
      children && (
        <Dropdown
          containerProps={containerProps}
          dataTestId={autoCompleteDataTestId}
          dropdownFooter={dropdownFooter}
          hasError={hasError}
          isActive={isActive}
          isDisabled={isDisabled}
          isMultiSelect={true}
          maxMenuListHeight={maxMenuListHeight}
        >
          {children}
        </Dropdown>
      )
    );
  };

  useEffect(() => {
    if (optionList) {
      setOptionList(optionList);
    }
  }, [optionList]);

  useEffect(() => {
    if (initialValue) {
      setSelectedList(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    const hasInitialValue = initialValue && Boolean(initialValue.length);
    const hasSelectionMatch = initialValue === selectedList;
    if (hasInitialValue && !hasSelectionMatch) {
      let optionListUpdate = optionList;
      initialValue.forEach((value: any) => {
        optionListUpdate = multiSelectFilter(optionListUpdate, value);
      });
      setOptionList(optionListUpdate);
    }
  }, [initialValue, multiSelectFilter, optionList, selectedList]);

  const inputProps = {
    autoFocus,
    'data-testid': `${autoCompleteDataTestId}--input`,
    disabled: isDisabled,
    id,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    ref: inputRef,
    value,
  };

  const hasValue = Boolean(value);
  const hasSelection = Boolean(selectedList.length);
  const isDynamicLabelMinimised = hasValue || hasSelection || isActive;
  const isOpen = Boolean(suggestions.length);
  const innerWrapperStyle = `${multiSelectClasses.root}${
    hasSelection && ` ${multiSelectClasses.active}`
  }`;

  return (
    <FieldWrapper
      {...props}
      isActive={isActive}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      isOpen={isOpen}
    >
      <div className={innerWrapperStyle}>
        <MultiSelectList
          dataTestId={autoCompleteDataTestId}
          getOptionValue={getOptionValue}
          isDisabled={isDisabled}
          isFocused={isActive}
          onRemove={handleRemove}
          selectedList={selectedList}
          tooltipTextKey={tooltipTextKey}
        />
        <ReactAutosuggest
          getSuggestionValue={getSuggestionValue}
          highlightFirstSuggestion={true}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          suggestions={suggestions}
          theme={autoCompleteClasses}
        />
      </div>
    </FieldWrapper>
  );
};

export default AutoCompleteMultiSelect;
