import React, { FC } from 'react';
import classNames from 'classnames';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const DynamicLabel: FC<ComponentProps> = ({
  isDisabled,
  isDynamicLabelMinimised,
  label,
}) => {
  const classes = useStyles({ isDisabled });
  const className = classNames(classes.label, {
    [classes.labelAnimation]: isDynamicLabelMinimised && !isDisabled,
    [classes.labelDisabledAnimation]: isDynamicLabelMinimised && isDisabled,
  });
  return <span className={className}>{label}</span>;
};

export default DynamicLabel;
