import React from 'react';
import { PlxsRichTextContent } from '@plexus-ui/index';

const content = `
  <h1>Heading 1</h1>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <h2>Heading 2</h2>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <h3>Heading 3</h3>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <h4>Heading 4</h4>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <h5>Heading 5</h5>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <h6>Heading 6</h6>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <p><span style="color: #e03e2d;">Text color</span></p>
  <p><strong><span style="color: #000000;">Text bold</span></strong></p>
  <p><em><span style="color: #000000;">Text Italic</span></em></p>
  <p><span style="text-decoration: underline;"><span style="color: #000000; text-decoration: underline;">Text Underline</span></span></p>
  <p style="padding-left: 40px;"><span style="color: #000000;">Text Indent 1</span></p>
  <p style="padding-left: 80px;"><span style="color: #000000;">Text Indent 2</span></p>
  <ul>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
  </ul>
  <ol>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>Item 3</li>
  </ol>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
  Natus quaerat quibusdam earum maxime cum pariatur, 
  eos omnis veniam, 
  atque accusantium doloribus voluptatibus assumenda tenetur tempora voluptatem? Facere possimus quo iste!</p>
`;

const Examples = () => {
  return (
    <PlxsRichTextContent dataTestId="example">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </PlxsRichTextContent>
  );
};

export default Examples;
