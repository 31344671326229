import React from 'react';
import { DocHeading, DocRow, DocTextContent } from '@dev/components';
import { PlxsBox } from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Default</DocHeading>
      <PlxsBox>
        <DocTextContent />
      </PlxsBox>
    </DocRow>
  </DocRow>
);
