import React, { FC } from 'react';
import { DocCode } from '@dev/components';

const InlineHeadingEditorCodeBlock: FC = () => {
  return (
    <DocCode>
      {`import React, {
  ChangeEvent,
  FC,
  Fragment,
  KeyboardEvent,
  useState,
} from 'react';

import {
  PlxsButton,
  PlxsButtonGroup,
  PlxsHeading,
  PlxsInput,
  PlxsRow,
} from '@plexus-ui/index';

const InlineHeadingEditor: FC = () => {
  const initialValue = 'Inline heading click to edit';
  const [headingText, setHeadingText] = useState(initialValue);
  const [isEditing, setEditing] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHeadingText(event.target.value);
  };

  const handleEditHeading = () => {
    if (isEditing && headingText.trim() === '') {
      setHeadingText(initialValue);
    }
    setEditing(Boolean(!isEditing));
  };

  const handleEnterEscape = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      handleEditHeading();
    }
  };

  return (
    <PlxsRow>
      <div style={{ width: '100%' }}>
        {isEditing ? (
          <PlxsInput
            autoFocus={true}
            dataTestId="default-input"
            maxLength={255}
            onBlur={handleEditHeading}
            onChange={handleChange}
            onKeyDown={handleEnterEscape}
            value={headingText}
          />
        ) : (
          <div onClick={handleEditHeading} style={{ cursor: 'pointer' }}>
            <PlxsHeading element="h2">{headingText}</PlxsHeading>
          </div>
        )}
      </div>
      <div style={{ minWidth: '330px' }}>
        <PlxsButtonGroup align="right">
          <PlxsButton dataTestId="button-one" label="Button one" />
          <PlxsButton dataTestId="button-two" label="Button two" />
        </PlxsButtonGroup>
      </div>
    </PlxsRow>
  );
};

export default InlineHeadingEditor;
`}
    </DocCode>
  );
};

export default InlineHeadingEditorCodeBlock;
