import React, { FC } from 'react';

import { ComponentProps } from './_types';

const PermissionsCustom: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    height="75"
    width="74"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36.9" cy="24.3" r="23.1" fill={circleFill} />
    <path
      fill={iconFill}
      d="m8.6 38.4 10.5-10.5-3.5-3.4-4.8 4.8c-.2.1-.3.2-.5.2s-.3-.1-.4-.2l-.9-1c-.2-.2-.2-.6 0-.9l4.8-4.8-2.6-2.6c-.9-1-2.5-1-3.4 0l-7 6.9c-1 1-1 2.6 0 3.5l7.8 8zm30.2-9.2c1.5-1.5 1.5-3.8 0-5.3l-3.5-3.5c-1.5-1.5-3.8-1.5-5.3 0L26.4 24l8.8 8.8 3.6-3.6zm-14.1-3.5L1.5 48.9 0 57.3c-.2 1.1.8 2.1 1.9 1.9l8.4-1.5 23.2-23.2-8.8-8.8zm14.5 22.4-2.6-2.7-4.8 4.8c-.2.2-.6.2-.9 0l-.9-.9c-.2-.2-.2-.6 0-.9l4.8-4.8-3.4-3.5-10.5 10.6 7.9 7.8c.9 1 2.5 1 3.5 0l6.9-7c1.1-.9 1.1-2.5 0-3.4z"
    />
  </svg>
);

export default PermissionsCustom;
