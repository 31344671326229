import React, { FC } from 'react';

import { DISABLED, NEUTRAL } from '../../_constants';
import InternalToneBg from '../_internal/ToneBg';
import PlxsIcon from '../Icon';

import { ComponentProps } from './Chip.types';
import { useStyles } from './Chip.styles';
import FunctionalIcon from './FunctionalIcon';

const Chip: FC<ComponentProps> = ({
  dataTestId,
  decorativeIcon,
  functionalIcon,
  hasHover,
  isDisabled,
  label,
  onChipClick,
  onFunctionalIconClick,
  tone = NEUTRAL,
  variant,
}) => {
  const classes = useStyles({
    hasDecorativeIcon: Boolean(decorativeIcon),
    hasFunctionalIcon: Boolean(functionalIcon),
    hasHover,
    isDisabled,
    onChipClick,
    variant,
  });

  const chipDataTestId = dataTestId && `${dataTestId}--chip`;
  const backgroundTone = isDisabled ? DISABLED : tone;
  const decorativeIconTone = isDisabled ? DISABLED : tone;
  let wrapperClasses = classes.root;
  if (variant) {
    wrapperClasses = `${wrapperClasses} ${classes[variant]}`;
  }

  return (
    <div
      className={wrapperClasses}
      data-testid={chipDataTestId}
      onClick={onChipClick}
    >
      <InternalToneBg hasHover={hasHover} tone={backgroundTone}>
        <div className={classes.innerWrapper}>
          {decorativeIcon && (
            <PlxsIcon name={decorativeIcon} tone={decorativeIconTone} />
          )}
          <span className={classes.label} onClick={(e) => e.preventDefault()}>
            {label}
          </span>
          {functionalIcon && (
            <div className={classes.functionalIcon}>
              <FunctionalIcon
                dataTestId={chipDataTestId}
                isDisabled={isDisabled}
                name={functionalIcon}
                onClick={onFunctionalIconClick}
              />
            </div>
          )}
        </div>
      </InternalToneBg>
    </div>
  );
};

export default Chip;
