import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '@plexus-ui/styles/color';

export const useStyles = makeStyles(() => ({
  scrollButton: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '4px 0',
    width: '43px',
    '& button': {
      display: 'none',
    },
  },
  scrollButtonLeft: {
    borderRight: '1px solid transparent',
  },
  scrollButtonRight: {
    borderLeft: '1px solid transparent',
  },
  scrollButtonShow: {
    borderColor: neutral[600],
    '& button': {
      display: 'flex',
    },
  },
}));
