import { useMediaQuery } from '@material-ui/core';
import { TableColDef } from './Table.types';

export const useBreakpoint = (col: TableColDef) => {
  if (!col.responsive) return null;

  const keys = ['0px', ...Object.keys(col.responsive)];

  return keys.reduce((output, key) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMediaQuery(`(min-width:${key})`);

    return matches ? key : output;
  });
};
