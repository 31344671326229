import React, { useState, useRef } from 'react';
import { ComponentProps } from './MultiSelect.types';
import DropdownField from '../_internal/DropdownField';
import Dropdown from '../_internal/Dropdown';
import ListBox from './_internal/ListBox/ListBox';
import PlxsChip from '../Chip';
import { useStyles } from './MultiSelect.styles';
import { useClickAway } from '../../hooks';

const MultiSelect = <Option extends any>({
  dataTestId,
  getLabel,
  onChange,
  options,
  placeholder,
  value,
}: ComponentProps<Option>) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const classes = useStyles();

  const handleChange = (option: Option) => {
    const newValue = value.includes(option)
      ? value.filter((selectedOption) => selectedOption !== option)
      : [...value, option];

    onChange(newValue);
  };

  const ref = useRef<HTMLDivElement>();

  useClickAway(ref, () => setDropdownOpen(false));

  return (
    <div className={classes.root} ref={ref}>
      <DropdownField
        dataTestId={dataTestId}
        icon="chevronDown"
        iconExpanded="chevronUp"
        isInteractive
        isDropdownOpen={isDropdownOpen}
        setDropdownOpen={setDropdownOpen}
      >
        {value.length === 0 ? (
          <span className={classes.placeholder}>{placeholder}</span>
        ) : (
          <div className={classes.content}>
            {value.length &&
              value.map((option, index) => (
                <PlxsChip
                  key={index}
                  label={getLabel(option)}
                  functionalIcon="cross"
                  onFunctionalIconClick={() => handleChange(option)}
                  tone="brand"
                />
              ))}
          </div>
        )}
      </DropdownField>
      <Dropdown isOpen={isDropdownOpen}>
        <ListBox
          getLabel={getLabel}
          onChange={handleChange}
          options={options}
          value={value}
        />
      </Dropdown>
    </div>
  );
};

export default MultiSelect;
