import { makeStyles } from '@material-ui/core/styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  functionalIcon: (props: StyleProps) => ({
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    display: 'flex',
  }),
}));
