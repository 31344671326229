import React, { useState } from 'react';
import { DocRow, DocSubHeading } from '@dev/components';
import MenuList from '@plexus-ui/components/_internal/MenuList';
import MenuItem from '@plexus-ui/components/_internal/MenuItem';
import { useStyles } from './styles';

const SimpleItems = () => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);

  const exampleData = [
    { label: 'Option 1', value: 1 },
    { label: 'Option 2', value: 2 },
    { label: 'Option 3', value: 3 },
  ];

  return (
    <DocRow margin="sm">
      <DocSubHeading>Simple list items</DocSubHeading>
      <div className={classes.root}>
        <MenuList
          id="menu-list-1"
          dataTestId="menu-list-1"
          onKeyExit={() => {}}
          onSelect={setSelectedValue}
        >
          {exampleData.map((example, index) => (
            <MenuItem
              key={index}
              isHighlighted={selectedValue === example.value}
              value={example.value}
            >
              {example.label}
            </MenuItem>
          ))}
        </MenuList>
      </div>
    </DocRow>
  );
};

export default SimpleItems;
