import { makeStyles } from '@material-ui/core/styles';
import { zIndex } from '../../../styles';
import { StyleProps } from './types';

export const useStyles = makeStyles({
  popover: (props: StyleProps) => ({
    zIndex: zIndex.popover,
    width: props.fullWidth ? '100%' : undefined,
  }),
});
