import React, { FC, useState } from 'react';

import { DocRow } from '@dev/components';
import { PlxsAutoComplete, PlxsText } from '@plexus-ui/index';

const fruits = ['apple', 'kiwi', 'mango', 'pear', 'pineapple'];

const MultiSelectSimple: FC = () => {
  const [selection, setSelection] = useState('');

  const handleSelect = (option: string) => {
    setSelection(JSON.stringify(option));
  };

  const multiSelectFilter = (optionList: string[], suggestion: string) => {
    return optionList.filter((item) => item !== suggestion);
  };

  return (
    <DocRow margin="md">
      <PlxsAutoComplete
        dataTestId="multi-select-simple"
        isMultiSelect={true}
        label="Fruits"
        multiSelectFilter={multiSelectFilter}
        onSelect={handleSelect}
        optionList={fruits}
      />
      <PlxsText>Selected option: {selection}</PlxsText>
    </DocRow>
  );
};

export default MultiSelectSimple;

export const MultiSelectSimpleSnippet = `import React, { FC } from 'react';

import { PlxsAutoComplete } from '@plexus-ui/index';

const fruits = ['apple', 'kiwi', 'mango', 'pear', 'pineapple'];

const MultiSelectSimple: FC = () => {
  const handleSelect = (option: string) => {
    console.log('handleSelect', option);
  };

  const multiSelectFilter = (optionList: string[], suggestion: string) => {
    return optionList.filter((item) => item !== suggestion);
  };

  return (
    <PlxsAutoComplete
      dataTestId="multi-select-simple"
      isMultiSelect={true}
      label="Fruits"
      multiSelectFilter={multiSelectFilter}
      onSelect={handleSelect}
      optionList={fruits}
    />
  );
};

export default MultiSelectSimple;`;
