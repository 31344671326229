import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Basic from './Basic';
import ExternalControl from './ExternalControl';
import Variant from './Variant';
import Plain from './Plain';

const bestPractice = {
  doList: [
    'Use collapse panel to organise between groups of content',
    'Gives the user the capability of having multiple sections open at a time.',
  ],
  doNotList: [],
};

const description =
  'The CollapsePanel is a container that used to show and hide content by clicking the panel header or programatically expand/collapse the panel';

const example = (
  <Fragment>
    <Basic />
    <Variant />
    <ExternalControl />
    <Plain />
  </Fragment>
);

const implementation = `import React, { FC, Fragment } from 'react';
import { PlxsButton, PlxsRow, PlxsText, PlxsCollapsePanel, PlxsButtonGroup } from '@plexus-ui/index';

const SandboxTest: FC = () => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <Fragment>
      <PlxsRow spaceBelow="lg">
        <PlxsText size="md">
          Use this page to test out Plexus UI components.
        </PlxsText>
      </PlxsRow>

      <PlxsRow spaceBelow="lg">
        <PlxsButtonGroup>
          <PlxsButton
            dataTestId="button"
            label="Close panel"
            size="md"
            variant="primary"
            onClick={() => setIsExpanded(false)}
          />
          <PlxsButton
            dataTestId="button"
            label="Open panel"
            size="md"
            variant="primary"
            onClick={() => setIsExpanded(true)}
          />
        </PlxsButtonGroup>
      </PlxsRow>

      <PlxsRow spaceBelow="lg">
        <PlxsCollapsePanel
          dataTestId='test'
          title='Details'
          isExpanded={isExpanded}
          onExpandedChange={setIsExpanded}
        >
          <PlxsRow spaceBelow="lg">
            <PlxsText size="sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet morbi porttitor turpis hendrerit lacinia nunc mi neque ultrices. Fusce ornare senectus tellus sit consectetur. Adipiscing aliquet nibh convallis nunc augue proin consequat eleifend vitae. Diam quis urna vel sed quis ac sed turpis. Vulputate sed est eget et volutpat pellentesque faucibus. Venenatis nibh cras ornare tortor libero, ut pellentesque sem pulvinar. Consequat dolor auctor luctus vel.
            </PlxsText>
          </PlxsRow>

          <PlxsRow spaceBelow="lg">
            <PlxsText size="sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet morbi porttitor turpis hendrerit lacinia nunc mi neque ultrices. Fusce ornare senectus tellus sit consectetur. Adipiscing aliquet nibh convallis nunc augue proin consequat eleifend vitae. Diam quis urna vel sed quis ac sed turpis. Vulputate sed est eget et volutpat pellentesque faucibus. Venenatis nibh cras ornare tortor libero, ut pellentesque sem pulvinar. Consequat dolor auctor luctus vel.
            </PlxsText>
          </PlxsRow>
          
          <PlxsRow spaceBelow="lg">
            <PlxsText size="sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet morbi porttitor turpis hendrerit lacinia nunc mi neque ultrices. Fusce ornare senectus tellus sit consectetur. Adipiscing aliquet nibh convallis nunc augue proin consequat eleifend vitae. Diam quis urna vel sed quis ac sed turpis. Vulputate sed est eget et volutpat pellentesque faucibus. Venenatis nibh cras ornare tortor libero, ut pellentesque sem pulvinar. Consequat dolor auctor luctus vel.
            </PlxsText>
          </PlxsRow>
        </PlxsCollapsePanel>
      </PlxsRow>
    </Fragment>
  );
};

export default SandboxTest;`;

const propList = [
  {
    component: 'CollapsePanel',
    description: 'the content of CollapsePanel',
    isRequired: true,
    prop: 'children',
    type: 'ReactNode',
  },
  {
    component: 'CollapsePanel',
    description:
      'renders as data-testid on the parent div for unique identification',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'CollapsePanel',
    description: 'the text label on the panel header',
    isRequired: true,
    prop: 'title',
    type: 'string',
  },
  {
    component: 'CollapsePanel',
    description:
      'if provided, used as default expanded state when the component is rendered (default: true)',
    isRequired: false,
    prop: 'isExpanded',
    type: 'boolean',
  },
  {
    component: 'CollapsePanel',
    description: 'if provided, style the component with border (default: true)',
    isRequired: false,
    prop: 'hasBorder',
    type: 'boolean',
  },
  {
    component: 'CollapsePanel',
    description:
      'if true, removes the component from the DOM after it has been transition off-screen (default: true)',
    isRequired: false,
    prop: 'unmountOnExit',
    type: 'boolean',
  },
  {
    component: 'CollapsePanel',
    description:
      'Emitted when the expanded state is changed. Use this to update the parent expanded value (see example)',
    isRequired: false,
    prop: 'onExpandedChange',
    type: '(boolean) => void',
  },
  {
    component: 'CollapsePanel',
    description:
      'if provided, style the component with variant (default: default)',
    isRequired: false,
    prop: 'variant',
    type: "'default' | 'tab'",
  },
  {
    component: 'CollapsePanel',
    description:
      'if provided, used as label on the indicator chip (default: null)',
    isRequired: false,
    prop: 'indicatorLabel',
    type: 'string',
  },
  {
    component: 'CollapsePanel',
    description:
      'if provided, used as tone on the indicator chip (default: null)',
    isRequired: false,
    prop: 'indicatorTone',
    type: 'ChipTone',
  },
];

const tsInterface = `interface CollapsePanel {
  children: ReactNode;
  dataTestId: string;
  title: string;
  isExpanded?: boolean;
  hasBorder?: boolean;
  unmountOnExit?: boolean;
  onExpandedChange?: (boolean) => void;
  variant?: 'default' | 'tab';
  indicatorLabel?: string;
  indicatorTone?: ChipTone;
}`;

export default () => {
  return (
    <PageTemplate
      bestPractice={bestPractice}
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
