import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';

import Disabled from './Disabled';
import IconOnly from './IconOnly';
import Loading from './Loading';
import Size from './Size';
import Tone from './Tone';
import Variant from './Variant';
import WithIcon from './WithIcon';
import Active from './Active';

const props = `interface ComponentProps {
  dataTestId: string;
  decorativeIcon?: IconName; // Refer to the Icon Library for a full list of icon names
  onClick?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  onMouseOver?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  functionalIcon?: IconName; // Refer to the Icon Library for a full list of icon names
  id?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isExtraWide?: boolean;
  isFullWidth?: boolean;
  isIconOnly?: boolean;
  isLoading?: boolean;
  isResponsiveWidth?: boolean;
  label: string;
  size?: Size; // 'sm' | 'md' | 'lg'
  tone?: Tone; // 'brand' | 'neutral' | 'positive' | 'urgent' | 'white'
  type?: 'button' | 'submit';
  variant?: Variant; // 'primary' | 'secondary' | 'link';
}`;

export default () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>The Button component.</DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <DocCode>{`<PlxsButton dataTestId="design-system-button" label="Design system button" />`}</DocCode>
        </DocRow>

        <DocRow margin="sm">
          <DocHeading>Examples</DocHeading>
          <DocParagraph>
            The appearance of the button is determined by the props; size, tone,
            variant.
          </DocParagraph>
        </DocRow>

        <Size />

        <Tone />

        <Variant />

        <WithIcon />

        <IconOnly />

        <Disabled />

        <Loading />

        <Active />
      </DocRow>
    </DocRow>
  );
};
