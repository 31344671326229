import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      padding: '0px',
      margin: '0px',
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
});
