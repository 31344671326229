import React, { FC, useRef } from 'react';

import { PlxsRichTextEditor } from '@plexus-ui/index';
import InteractiveFieldWrapper from '@dev/docs/_internal/InteractiveFieldWrapper';

const InteractiveExample: FC = () => {
  const editorRef = useRef(null);

  const handleInit = (event: any, editor: any) => (editorRef.current = editor);

  return (
    <InteractiveFieldWrapper dataTestId="interactive-text-field">
      {({ errorMessage, hasError, isDisabled }) => (
        <PlxsRichTextEditor
          dataTestId="interactive-example-rich-text-editor"
          errorMessage={errorMessage}
          hasError={hasError}
          isDisabled={isDisabled}
          label="Interactive Example Rich Text Editor"
          onInit={handleInit}
          placeholder="Editor placeholder text..."
        />
      )}
    </InteractiveFieldWrapper>
  );
};

export default InteractiveExample;
