import { makeStyles } from '@material-ui/core/styles';

import { brand, neutral } from '../../../styles/color';
import { defaultTextStyle } from '../../../styles/text';
import { inputTransparent, getInputStyle } from '../../../styles/input';

import { StyleProps } from './types';
import { POPOVER_BOTTOM } from '../../_internal/Popover/constants';

const menuStyles = (props: StyleProps) => {
  const { hasError, isActive, isDisabled, hasCustomStyle } = props;
  return hasCustomStyle
    ? {
        color: neutral[100],
        border: `1px solid ${neutral[500]}`,
      }
    : getInputStyle({ hasError, isActive, isDisabled });
};

export const useStyles = makeStyles(() => ({
  root: ({ hasSelection }: StyleProps) => ({
    ...defaultTextStyle,
    position: 'relative',
    color: hasSelection ? brand[100] : neutral[400],
  }),
  selectInput: (props: StyleProps) => {
    const { hasError, isActive, isDisabled } = props;
    return {
      ...getInputStyle({ hasError, isActive, isDisabled }),
      ...inputTransparent,
      paddingRight: '9px',
      userSelect: 'none',
    };
  },
  menuWrapper: (props: StyleProps) => {
    const { placement } = props;
    return {
      ...menuStyles(props),
      margin: 0,
      padding: 0,
      backgroundColor: 'white',
      borderTopWidth: placement === POPOVER_BOTTOM ? 0 : 1,
      borderTopLeftRadius: placement === POPOVER_BOTTOM ? 0 : 3,
      borderTopRightRadius: placement === POPOVER_BOTTOM ? 0 : 3,
      borderBottomWidth: placement === POPOVER_BOTTOM ? 1 : 0,
      borderBottomLeftRadius: placement === POPOVER_BOTTOM ? 3 : 0,
      borderBottomRightRadius: placement === POPOVER_BOTTOM ? 3 : 0,
    };
  },
  menuList: (props: StyleProps) => ({
    width: '100%',
    maxHeight: props.maxMenuListHeight
      ? `${props.maxMenuListHeight}px`
      : 'initial',
    overflow: 'auto',
    padding: '8px 0',
  }),
  menuItem: {
    padding: '8px 16px',
    userSelect: 'none',
    '&:focus': { backgroundColor: brand[900] },
  },
  highlighted: {
    backgroundColor: brand[600],
    '&:focus': {
      backgroundColor: brand[600],
    },
  },
}));
