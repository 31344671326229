import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Examples from './Examples';
import EditableCell from './EditableCell';

const description = `
  This component leverages the material UI Table component with the following styling document below.\n
  This table will be required to import Plexus UI components into it's cells
  `;

const example = (
  <Fragment>
    <Examples />
    <EditableCell />
  </Fragment>
);

const implementation = `import React, { FC } from 'react';
import { PlxsIcon, PlxsTable, PlxsChip } from '@plexus-ui/index';
import { TableColDef, TableCellParams, RowData } from '@plexus-ui/components/Table';

import { useStyles } from './styles';

const rows = [
  { id: 1, username: 'Snow Jon', email: 'Snow@example.com', status: 'Active' },
  {
    id: 2,
    username: 'Lannister Cersei',
    email: 'Lannister@example.com',
    status: 'Active',
  },
  {
    id: 3,
    username: 'Lannister Jaime',
    email: 'Jaime@example.com',
    status: 'Active',
  },
  {
    id: 4,
    username: 'Stark Arya',
    email: 'Stark@example.com',
    status: 'Active',
  },
  {
    id: 5,
    username: 'Targaryen Daenerys',
    email: 'Daenerys@example.com',
    status: 'Active',
  },
  {
    id: 6,
    username: 'Clifford Ferrara',
    email: 'Ferrara@example.com',
    status: 'Active',
  },
  {
    id: 7,
    username: 'Roxie Harvey',
    email: 'Harvey@example.com',
    status: 'Active',
  },
];

const documentRows = [
  {
    id: 1,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 2,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 3,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 4,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 5,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 6,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 7,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
  {
    id: 8,
    documentType: 'App - Independent Contractor Assessment',
    roles: '42',
    documents: '3109',
    users: '253',
  },
];

const TextDocs: FC = () => {
  const classes = useStyles();

  const columns: TableColDef[] = [
    {
      field: 'username',
      headerName: 'User name',
      renderCell: (params: TableCellParams) => (
        <div className={classes.userWrapper}>
          <div className={classes.iconUser}>
            <PlxsIcon name="person" size="lg" tone="brand" />
          </div>
          <div className={classes.labelUser}>
            <span>{params.value}</span>
          </div>
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: TableCellParams) => (
        <PlxsChip
          dataTestId="example"
          label={params.value.toString()}
          onClick={() => {}}
          tone="positive"
        />
      ),
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params: TableCellParams) => (
        <PlxsChip
          dataTestId="example"
          label="Remove"
          decorativeIcon="cross"
          tone="urgent"
        />
      ),
    },
  ];

  return (
    <PlxsTable
      rows={rows}
      columns={columns}
      classes={{ table: classes.table, tableCell: classes.tableCell }}
      isRowMarked
      sortFieldDefault={'username'}
      orderDefault='desc'
      onClickRow={(row, selected) => {
        console.log('[selected]', selected);
      }}
    />
  );
};

export default TextDocs;
`;

const propList = [
  {
    component: 'Table',
    description:
      'Array data object RowData, required field id with number type value.',
    isRequired: true,
    prop: 'rows',
    type: 'RowData[]',
  },
  {
    component: 'Table',
    description:
      'Array object TableColDef for columns definition. Required field id with number type value.',
    isRequired: true,
    prop: 'columns',
    type: 'TableColDef[]',
  },
  {
    component: 'Table',
    description: 'If provided, apply paging to the table',
    isRequired: false,
    prop: 'isPaging',
    type: 'boolean',
  },
  {
    component: 'Table',
    description: 'Show list for row per page. Default [5, 10, 20]',
    isRequired: false,
    prop: 'rowsPerPageOptions',
    type: 'number[]',
  },
  {
    component: 'Table',
    description: 'Show row per page for first load. Default 5',
    isRequired: false,
    prop: 'rowsPerPage',
    type: 'number',
  },
  {
    component: 'Table',
    description: 'Provide handle click row.',
    isRequired: false,
    prop: 'onClickRow',
    type: '(row: TableRowModel, selected: string[]) => void',
  },
  {
    component: 'Table',
    description: 'Row role. Default checkbox.',
    isRequired: false,
    prop: 'rowRole',
    type: "'checkbox' | null",
  },
  {
    component: 'Table',
    description: 'Override style using classes.',
    isRequired: false,
    prop: 'classes',
    type: 'TableClasses',
  },
  {
    component: 'Table',
    description: 'if provided, row will have hover background',
    isRequired: false,
    prop: 'highlightOnHover',
    type: 'boolean',
  },
  {
    component: 'Table',
    description: 'if provided, table will full width 100%',
    isRequired: false,
    prop: 'fullWidth',
    type: 'boolean',
  },
  {
    component: 'Table',
    description:
      'if provided, table row will have background color when onClickRow triggered.',
    isRequired: false,
    prop: 'highlightOnClick',
    type: 'boolean',
  },
  {
    component: 'Table',
    description: "Order sort for table column. Default 'asc'",
    isRequired: false,
    prop: 'orderDefault',
    type: 'string',
  },
  {
    component: 'Table',
    description: "Select field of row for default sort key. Default 'id' key.",
    isRequired: false,
    prop: 'sortFieldDefault',
    type: 'string',
  },
  {
    component: 'Table',
    description: 'Empty state indicator',
    isRequired: false,
    prop: 'emptyIndicator',
    type: 'React.ComponentType',
  },
  {
    component: 'Table',
    description: 'handle rows changes',
    isRequired: false,
    prop: 'onRowsChange',
    type: '(value: RowData[]) => void',
  },
  {
    component: 'Table',
    description: 'if provided, table header will be visually hidden',
    isRequired: false,
    prop: 'isHeaderVisuallyHidden',
    type: 'boolean',
  },
  {
    component: 'Table',
    description:
      'if provided, table row will be expandable and have expandable action button.',
    isRequired: false,
    prop: 'expandable',
    type: 'boolean',
  },
  {
    component: 'Table',
    description: 'This function will be called to render expandable content.',
    isRequired: false,
    prop: 'renderExpandable',
    type: '(row: TableRowModel) => React.ReactNode',
  },
];

const tsInterface = `interface ComponentProps {
  rows: RowData[];
  columns: TableColDef[];
  isPaging?: boolean;
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  onClickRow?: (row: TableRowModel, selected: string[]) => void;
  rowRole?: 'checkbox' | null;
  classes?: TableClasses;
  highlightOnHover?: boolean;
  fullWidth?: boolean;
  highlightOnClick?: boolean;
  orderDefault?: Order;
  sortFieldDefault?: keyof RowData;
  emptyIndicator?: React.ComponentType;
  onRowsChange?: (value: RowData[]) => void;
  isHeaderVisuallyHidden?: boolean;
  expandable?: boolean;
  renderExpandable?: (row: TableRowModel) => React.ReactNode;
}`;

export default () => {
  return (
    <PageTemplate
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
