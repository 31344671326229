import React, { useEffect, useRef, useState } from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import {
  PlxsActionMenu,
  PlxsButton,
  PlxsButtonGroup,
  PlxsCheckbox,
  PlxsColumn,
  PlxsRow,
  PlxsSelect,
  PlxsText,
} from '@plexus-ui/index';
import { useStyles } from './styles';
import { MenuPlacement } from '@plexus-ui/components/_internal/Popover/types';
import { ComponentProps } from '@plexus-ui/components/Button/Button.types';

const DropdownButton = () => {
  const oversizedContentRef = useRef(null);
  const viewportRef = useRef(null);

  const [isViewportLocked, setIsViewportLocked] = useState(false);
  const classes = useStyles({ isViewportLocked });

  const [isCloseOnSelect, setIsCloseOnSelect] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const exampleTriggerProps: ComponentProps[] = [
    {
      dataTestId: 'trigger-1',
      label: 'Choose me',
      size: 'sm',
      tone: 'neutral',
      variant: 'secondary',
    },
    {
      dataTestId: 'trigger-2',
      decorativeIcon: 'person',
      label: 'Choose me',
      size: 'md',
      tone: 'brand',
      variant: 'primary',
    },
  ];

  const exampleData = [
    { label: 'Upload a contract', value: 32 },
    { label: 'Write an autobiography', value: 211 },
    { isDivider: true },
    { label: 'Delete some files', value: 7 },
    { disabled: true, label: 'Share a document for review', value: 990 },
  ];

  const placements = [
    { name: 'bottom-end', displayName: 'BottomRight' },
    { name: 'bottom-start', displayName: 'BottomLeft' },
    { name: 'top-end', displayName: 'TopRight' },
    { name: 'top-start', displayName: 'TopLeft' },
  ];

  const [triggerProps, setTriggerProps] = useState<ComponentProps>(
    exampleTriggerProps[0]
  );
  const [selectedPlacement, setSelectedPlacement] = useState(placements[0]);

  const handlePlacementSelect = (item) => {
    setSelectedPlacement(item);
  };

  const handleMenuItemSelect = (item) => {
    const { label, value } = item;
    setSelectedValue({ label, value });
  };

  const handleTriggerRecenterClick = () => {
    const { offsetHeight, offsetWidth } = oversizedContentRef.current;
    viewportRef.current.scroll(0.3 * offsetWidth, 0.3 * offsetHeight);
  };

  const handleTriggerSelect = (index) =>
    setTriggerProps(exampleTriggerProps[index]);

  useEffect(handleTriggerRecenterClick, []);

  return (
    <DocRow margin="sm">
      <DocSubHeading>Triggered with "dropdown" button</DocSubHeading>
      <DocParagraph>
        Any supported variants of PlxsButton can be passed into this component
        to be the trigger button.
      </DocParagraph>
      <DocParagraph>
        Note: to ensure correct functionality, this component will override the
        following PlxsButton props: functionalIcon, id, isActive, isDisabled and
        onClick. All other PlxsButton props will be preserved
      </DocParagraph>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Placement</div>
          <div className={classes.componentWrapper}>
            <PlxsColumn expand={true}>
              <PlxsSelect
                dataTestId="action-menu-example-dropdown-placements"
                getOptionLabel={(option) => option.displayName}
                onSelect={handlePlacementSelect}
                options={placements}
                placeholder="Select placement..."
                selected={selectedPlacement}
              />
            </PlxsColumn>
          </div>
          <div className={classes.name}>
            <DocCode>{`placement="${selectedPlacement.name}"`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Disabled state</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="action-menu-example-dropdown-disabled-checkbox"
              onChange={() => setIsDisabled(!isDisabled)}
              value={isDisabled}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`isDisabled={${isDisabled ? 'true' : 'false'}}`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Close menu on select</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="action-menu-example-kebab-close-menu-checkbox"
              onChange={() => setIsCloseOnSelect(!isCloseOnSelect)}
              value={isCloseOnSelect}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`isCloseOnSelect={${
              isCloseOnSelect ? 'true' : 'false'
            }}`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Example trigger 1</div>
          <div className={classes.componentWrapper}>
            <PlxsButton
              {...exampleTriggerProps[0]}
              dataTestId="action-menu-dropdown-example-trigger-1"
              onClick={() => handleTriggerSelect(0)}
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {`triggerButton={
  <PlxsButton
    dataTestId="${exampleTriggerProps[0].dataTestId}"
    label="${exampleTriggerProps[0].label}"
    size="${exampleTriggerProps[0].size}"
    tone="${exampleTriggerProps[0].tone}"
    variant="${exampleTriggerProps[0].variant}"
  />
}`}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Example trigger 2</div>
          <div className={classes.componentWrapper}>
            <PlxsButton
              {...exampleTriggerProps[1]}
              dataTestId="action-menu-dropdown-example-trigger-2"
              onClick={() => handleTriggerSelect(1)}
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {`triggerButton={
  <PlxsButton
    dataTestId="${exampleTriggerProps[1].dataTestId}"
    decorativeIcon="${exampleTriggerProps[1].decorativeIcon}"
    label="${exampleTriggerProps[1].label}"
    size="${exampleTriggerProps[1].size}"
    tone="${exampleTriggerProps[1].tone}"
    variant="${exampleTriggerProps[1].variant}"
  />
}`}
            </DocCode>
          </div>
        </li>
      </ul>

      <PlxsRow spaceBelow="xxl">
        <PlxsText>
          Selected: <code>{JSON.stringify(selectedValue)}</code>
        </PlxsText>
      </PlxsRow>

      <PlxsRow spaceBelow="xxl">
        <PlxsButtonGroup>
          <PlxsButton
            dataTestId="action-menu-dropdown-recenter-button"
            label="Re-center trigger"
            onClick={handleTriggerRecenterClick}
          />
          <PlxsButton
            dataTestId="action-menu-dropdown-lock-viewport"
            label={`${isViewportLocked ? 'Unlock' : 'Lock'} viewport`}
            onClick={() => setIsViewportLocked(!isViewportLocked)}
          />
        </PlxsButtonGroup>
      </PlxsRow>

      <div ref={viewportRef} className={classes.viewport}>
        <div ref={oversizedContentRef} className={classes.oversizedContent}>
          <PlxsActionMenu
            dataTestId="action-menu-dropdown-example"
            getOptionState={(option) => ({
              isDisabled: option.disabled,
              isDivider: option.isDivider,
            })}
            isCloseOnSelect={isCloseOnSelect}
            isDisabled={isDisabled}
            onSelect={handleMenuItemSelect}
            options={exampleData}
            placement={selectedPlacement.name as MenuPlacement}
            renderOption={(option) =>
              option.isDivider ? null : (
                <div className={classes.optionWrapper}>
                  <PlxsText element="span" color="inherit" weight="semiBold">
                    {option.label}
                  </PlxsText>
                </div>
              )
            }
            triggerButton={<PlxsButton {...triggerProps} />}
          />
        </div>
      </div>
    </DocRow>
  );
};

export default DropdownButton;
