import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Notifications: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg height="75" width="74" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M22.77 75a6.4 6.4 0 0 0 6.4-6.5h-12.9a6.41 6.41 0 0 0 6.5 6.5Zm21.84-15.13c-1.93-2.14-5.69-5.28-5.69-15.75a16.1 16.1 0 0 0-13-15.74v-2.13a3.2 3.2 0 1 0-6.4 0v2.13a16.09 16.09 0 0 0-13 15.74c0 10.47-3.76 13.61-5.69 15.75A2.6 2.6 0 0 0 0 62a3.14 3.14 0 0 0 3.25 3.25h38.9A3.29 3.29 0 0 0 45.52 62a3.13 3.13 0 0 0-.91-2.13Z"
    />
  </svg>
);

export default Notifications;
