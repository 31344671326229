import { makeStyles } from '@material-ui/core/styles';

import { color } from '../../styles';
import { getTextStyle } from '../../styles/text';

import { StyleProps } from './Tooltip.types';

const variantStyles = (props: StyleProps) => {
  switch (props.variant) {
    case 'contentWrapper':
      return {
        display: 'block',
      };

    case 'iconOnly':
      return {
        display: 'inline-flex',
      };

    default:
      return {
        display: 'inline-block',
      };
  }
};

export const useChildWrapperStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    ...variantStyles(props),
  }),
}));

export const useStyles = makeStyles({
  arrow: {
    color: color.primaryDarker,
  },
  tooltip: {
    borderRadius: '0',
    backgroundColor: color.primaryDarker,
    ...getTextStyle({ size: 'sm', color: 'white' }),
  },
});
