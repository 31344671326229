import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    '& > svg': {
      flexShrink: 0,
      marginRight: '12px',
      position: 'relative',
      top: '2px',
    },
  },
});
