import React, { FC, Fragment, useState } from 'react';
import { DocTextContent } from '@dev/components';
import {
  PlxsButton,
  PlxsButtonGroup,
  PlxsHeading,
  PlxsModalDeprecated,
  PlxsRow,
} from '@plexus-ui/index';
import {
  Variant,
  Width,
} from '@plexus-ui/components/_deprecated/Modal/Modal.types';

export interface ComponentProps {
  dataTestId: string;
  variant?: Variant;
  width?: Width;
}

const DemoModalButton: FC<ComponentProps> = ({
  dataTestId,
  variant,
  width,
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <PlxsButton
        dataTestId={`${dataTestId}-open`}
        onClick={handleOpen}
        label="Open"
      />
      <PlxsModalDeprecated
        dataTestId={dataTestId}
        onClose={handleClose}
        isOpen={isOpen}
        width={width}
        variant={variant}
      >
        <Fragment>
          <PlxsRow alignY="center" spaceBelow="sm">
            <PlxsHeading element="h1">Modal title</PlxsHeading>
          </PlxsRow>

          <PlxsRow spaceBelow="lg">
            <DocTextContent />
          </PlxsRow>

          <PlxsButtonGroup align="right">
            <PlxsButton
              dataTestId="close-button"
              onClick={handleClose}
              label="Close"
              variant="secondary"
            />
            <PlxsButton
              dataTestId="agree-button"
              onClick={handleClose}
              label="Agree"
            />
          </PlxsButtonGroup>
        </Fragment>
      </PlxsModalDeprecated>
    </Fragment>
  );
};

export default DemoModalButton;
