import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

import PlxsIcon from '../../../Icon';
import PlxsText from '../../../Text';
import IndicatorLabel from '../../../_internal/IndicatorLabel';

const PanelHeader: FC<ComponentProps> = ({
  title,
  isExpanded,
  hasBorder,
  onClick,
  variant = 'default',
  indicatorLabel,
  indicatorTone,
}) => {
  const classes = useStyles({ hasBorder, variant });

  return (
    <div className={classes.panelHeader} onClick={onClick}>
      <div className={classes.titleContainer}>
        <PlxsText
          element="span"
          weight="semiBold"
          color={variant === 'tab' ? 'brand' : 'dark'}
        >
          {title}
        </PlxsText>
        <IndicatorLabel
          isActive={true}
          label={indicatorLabel}
          tone={indicatorTone}
        />
        {variant === 'tab' && <span className={classes.panelBorder} />}
      </div>

      <div className={classes.expandIconContainer}>
        <PlxsIcon
          name={isExpanded ? 'chevronUp' : 'chevronDown'}
          tone={variant === 'tab' ? 'brand' : 'neutral'}
        />
      </div>
    </div>
  );
};

export default PanelHeader;
