import React, { FC } from 'react';

import PlxsIcon from '../../Icon';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const MessagePanel: FC<ComponentProps> = ({
  children,
  dataTestId,
  icon,
  onDismiss,
  styleOverrides,
  tone,
}) => {
  const classes = useStyles({ tone, styleOverrides });

  return (
    <div className={classes.messagePanel} data-testid={dataTestId}>
      <div className={classes.icon}>
        <PlxsIcon name={icon} size="lg" tone={tone} />
      </div>
      <div className={classes.content}>{children}</div>
      {Boolean(onDismiss) && (
        <div className={classes.dismiss} onClick={onDismiss}>
          <PlxsIcon name="cross" size="lg" />
        </div>
      )}
    </div>
  );
};

export default MessagePanel;
