import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Route } from '@dev/types';
import AppFooter from '@dev/components/AppFooter';
import color from '@dev/routes/color';
import componentList from '@dev/routes/componentList';
import componentListDeprecated from '@dev/routes/componentListDeprecated';
import componentListInternal from '@dev/routes/componentListInternal';
import componentListToBeUpdated from '@dev/routes/componentListToBeUpdated';
import icons from '@dev/routes/icons';
import overview from '@dev/routes/overview';
import sandbox from '@dev/routes/sandbox';

import { useStyles } from './styles';

export interface ListItem {
  classes: any;
  heading: string;
  list: Route[];
}

const renderSidebarList = (listItem: ListItem) => {
  const { classes, heading, list } = listItem;
  return (
    <Fragment>
      <h2 className={classes.heading}>{heading}</h2>
      <ul className={classes.list}>
        {list.map((route: Route) => (
          <li className={classes.listItem} key={route.name}>
            <Link to={route.path}>
              <button className={classes.button} name={route.name}>
                {route.name}
              </button>
            </Link>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

const Sidebar: FC = () => {
  const classes = useStyles();

  const sidebarList = [
    {
      classes,
      heading: 'OVERVIEW',
      list: overview,
    },
    {
      classes,
      heading: 'COLOR',
      list: color,
    },
    {
      classes,
      heading: 'ICONS',
      list: icons,
    },
    {
      classes,
      heading: 'COMPONENTS',
      list: componentList,
    },
    {
      classes,
      heading: 'COMPONENTS (internal)',
      list: componentListInternal,
    },
    {
      classes,
      heading: 'COMPONENTS (to be updated)',
      list: componentListToBeUpdated,
    },
    {
      classes,
      heading: 'COMPONENTS (deprecated)',
      list: componentListDeprecated,
    },
    {
      classes,
      heading: 'SANDBOX',
      list: sandbox,
    },
  ];

  return (
    <div className={classes.root}>
      {sidebarList.map((listItem, index) => (
        <Fragment key={`sidebar-list-${index}`}>
          {renderSidebarList(listItem)}
        </Fragment>
      ))}
      <AppFooter />
    </div>
  );
};

export default Sidebar;
