import React, { FC, useCallback, useContext, useRef, useState } from 'react';

import { ComponentProps } from './DropdownField.types';
import { useStyles } from './DropdownField.styles';
import PlxsIcon from '../../Icon';
import { FieldContext } from '../../Field/Field.context';
import { getFieldTone } from '../../Field/Field.styles';

const DropdownField: FC<ComponentProps> = ({
  children,
  dataTestId,
  icon,
  iconExpanded,
  isDropdownOpen,
  isInteractive,
  setDropdownOpen,
}) => {
  const [isChildFocused, setChildFocused] = useState(false);
  const isActive = isChildFocused || isDropdownOpen;
  const { hasError } = useContext(FieldContext);
  const classes = useStyles({ hasError, isDropdownOpen, isActive });

  const ref = useRef<HTMLDivElement>();

  const handleFocusCapture = useCallback(() => {
    setChildFocused(true);
  }, [setChildFocused]);

  const handleClick = useCallback(() => {
    if (isInteractive) {
      setDropdownOpen(!isDropdownOpen);
    }
  }, [isDropdownOpen, isInteractive, setDropdownOpen]);

  const handleBlurCapture = useCallback(
    (e: React.FocusEvent) => {
      const { relatedTarget: focusedElement } = e;
      if (!ref.current.contains(focusedElement)) {
        setChildFocused(false);
      }
    },
    [ref, setChildFocused, setDropdownOpen]
  );

  return (
    <div
      ref={ref}
      className={classes.root}
      data-testid={dataTestId}
      tabIndex={isInteractive ? 0 : undefined}
      onClick={handleClick}
      onFocusCapture={handleFocusCapture}
      onBlurCapture={handleBlurCapture}
    >
      {children}
      <PlxsIcon
        name={(isDropdownOpen && iconExpanded) || icon}
        tone={getFieldTone(isActive, hasError)}
      />
    </div>
  );
};

export default DropdownField;
