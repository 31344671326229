import React, { FC, useState } from 'react';
import PlxsText from '../../Text/Text';
import TimeInput from '../TimeInput/TimeInput';
import Counter from '../Counter/Counter';
import { ComponentProps } from './TimeSelector.types';
import { useStyles } from './TimeSelector.styles';
import TimePeriod from '../TimePeriod/TimePeriod';
import { format } from 'date-fns';

const TimeSelector: FC<ComponentProps> = ({
  dataTestId,
  date,
  onChange,
  onTimePeriodChange,
  timePeriod,
}) => {
  const classes = useStyles();

  const hour = date ? format(new Date(date), 'hh') : '';
  const minute = date ? format(new Date(date), 'mm') : '';

  const [inputFocus, setInputFocus] = useState<'HOUR_INPUT' | 'MINUTE_INPUT'>(
    'MINUTE_INPUT'
  );

  const handleMinuteChange = (newMinute: string) => {
    const newDate = new Date(date);
    newDate.setMinutes(Number(newMinute));
    onChange(newDate);
  };

  const handleHourChange = (newHour: string) => {
    const newDate = new Date(date);
    newDate.setHours(Number(newHour));
    onChange(newDate);
  };

  const handleIncrease = (amount: number) => {
    const newDate = new Date(date);
    if (inputFocus === 'HOUR_INPUT') {
      newDate.setHours(date.getHours() + amount);
      onChange(newDate);
    } else {
      newDate.setMinutes(date.getMinutes() + amount);
      onChange(newDate);
    }
  };

  const handleHourFocus = () => {
    setInputFocus('HOUR_INPUT');
  };

  const handleMinuteFocus = () => {
    setInputFocus('MINUTE_INPUT');
  };

  return (
    <div className={classes.root} data-testid={dataTestId}>
      <div className={classes.label}>
        <PlxsText element="span">Time</PlxsText>
      </div>
      <TimeInput
        dataTestId={`${dataTestId}--time-input--hour`}
        minNumber={1}
        maxNumber={12}
        value={hour}
        onChange={handleHourChange}
        onFocus={handleHourFocus}
      />
      <TimeInput
        dataTestId={`${dataTestId}--time-input--minute`}
        value={minute}
        onChange={handleMinuteChange}
        onFocus={handleMinuteFocus}
      />
      <TimePeriod
        dataTestId={`${dataTestId}--time-input--period`}
        value={timePeriod}
        onClick={onTimePeriodChange}
      />
      <Counter
        dataTestId={`${dataTestId}--time-input--counter`}
        onIncrease={handleIncrease}
      />
    </div>
  );
};

export default TimeSelector;
