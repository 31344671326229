import { makeStyles } from '@material-ui/core/styles';
import { StyleProps } from './Checkbox.types';
import { neutral, brand, urgent } from '../../styles/color';
import { spacing } from '../../styles';

const getBorderColor = (props: StyleProps): string => {
  if (props.hasError) {
    return urgent[400];
  } else {
    return neutral[500];
  }
};

const getBorderHoverColor = (props: StyleProps): string => {
  if (props.hasError) {
    return urgent[400];
  } else if (props.isDisabled) {
    return neutral[500];
  } else {
    return neutral[400];
  }
};

const getBorderCheckedColor = (props: StyleProps): string => {
  if (props.hasError) {
    return urgent[400];
  } else if (props.isDisabled) {
    return neutral[500];
  } else {
    return brand[400];
  }
};

const getCheckedColor = (props: StyleProps): string => {
  if (props.isDisabled) {
    return neutral[800];
  } else {
    return brand[400];
  }
};

const getCheckedIconColor = (props: StyleProps): string => {
  if (props.isDisabled) {
    return neutral[400];
  } else {
    return 'white';
  }
};

const getIndeterminateColor = (props: StyleProps): string => {
  if (props.indeterminate && props.isDisabled) {
    return neutral[800];
  } else if (props.indeterminate) {
    return brand[400];
  } else {
    return props.isDisabled ? neutral[800] : 'white';
  }
};

const getIndeterminateIconColor = (props: StyleProps): string => {
  if (props.indeterminate && props.isDisabled) {
    return neutral[400];
  } else if (props.indeterminate) {
    return 'white';
  } else {
    return 'transparent';
  }
};

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: {
    height: '16px',
    marginTop: '8px',
  },
  input: (props: StyleProps) => ({
    width: '24px',
    height: '24px',
    outlineColor: 'transparent',
    opacity: 0,
    margin: '0 !important', // This is needed to override CSS in legal gateway
    '&:focus + span': {
      borderColor: brand[800],
    },
    '& + span > span': {
      borderColor: getBorderColor(props),
      background: getIndeterminateColor(props),
      '& > svg': {
        color: getIndeterminateIconColor(props),
      },
    },
    '&:hover + span > span': {
      borderColor: getBorderHoverColor(props),
    },
    '&:checked + span > span': {
      borderColor: getBorderCheckedColor(props),
      background: getCheckedColor(props),
      '& > svg': {
        color: getCheckedIconColor(props),
      },
    },
  }),
  inputOverlay: ({ isDisabled }: StyleProps) => ({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: isDisabled ? neutral[800] : 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    border: 'solid 1px',
    '& > svg': {
      textShadow: '0px 1px 3px rgb(0 0 0 / 80%)',
    },
  }),
  label: ({ isDisabled, align, padding }: StyleProps) => ({
    display: 'inline-flex',
    flexDirection: align === 'right' ? 'row-reverse' : 'row',
    alignItems: 'center',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    userSelect: 'none',
    padding: padding ? spacing[padding] : undefined,
    // The following section is needed to override bootstrap.css in legal-gateway
    margin: 0,
    '& > :not(:last-child)': {
      margin: align === 'right' ? '0 0 0 16px' : '0 16px 0 0',
    },
  }),
  inputContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
  },
  overlay: {
    width: '35px',
    position: 'absolute',
    top: '-6px',
    right: '-6px',
    bottom: '-6px',
    left: '-6px',
    padding: '3px',
    border: `3px solid transparent`,
    borderRadius: '3px',
  },
}));
