import { makeStyles } from '@material-ui/core/styles';
import { PlxsStyles } from '@plexus-ui/index';

export const useStyles = makeStyles({
  userWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  labelUser: {
    paddingLeft: '16px',
    fontWeight: 600,
  },
  iconUser: {
    position: 'relative',
    width: '40px',
    height: '40px',
    backgroundColor: PlxsStyles.color.brand,
    borderRadius: '50%',
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      '& path': {
        fill: PlxsStyles.color.white,
      },
    },
  },
  table: {
    border: `1px solid ${PlxsStyles.color.neutralShade[800]}`,
  },
  tableCell: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 0px',
    '& a': {
      textDecoration: 'none',
    },
  },
  checkboxHeaderWrapper: {
    display: 'flex',
    verticalAlign: 'middle',
    padding: '0 16px 0 8px',
    '& > div': {
      margin: 0,
    },
    '& input[type="checkbox"]': {
      margin: 0,
    },
  },
  checkboxWrapper: {
    display: 'flex',
    verticalAlign: 'middle',
    padding: '0 0 0 0px',
    '& > div': {
      margin: 0,
    },
    '& input[type="checkbox"]': {
      margin: 0,
    },
  },
  switchHeaderWrapper: {
    display: 'flex',
    padding: '0 16px 0 8px',
  },
  switchWrapper: {
    display: 'flex',
    padding: '0',
  },
  emptyIndicator: {
    padding: '16px 0 0',
  },
  emptySpotlightIcon: {
    marginBottom: '32px',
  },
  emptyHeading: {
    '& h3': {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600,
    },
  },
  settingWrapp: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& ul': {
      paddingLeft: 0,
      marginBottom: 0,
      listStyleType: 'none',
    },
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginBottom: 0,
    },
  },
  tableWrapper: {
    '& p': {
      marginBottom: 0,
    },
  },
});
