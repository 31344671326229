import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';

import { ComponentProps } from './Modal.types';
import { useBackdropStyles, useDialogStyles } from './Modal.styles';

const Modal: FC<ComponentProps> = ({
  ariaLabelledBy,
  ariaDescribedBy,
  children,
  dataTestId,
  onClose,
  width,
  isOpen,
  variant = 'white',
  disableClose,
}) => {
  const backdropClasses = useBackdropStyles();
  const dialogClasses = useDialogStyles({ width, variant });

  const handleClose = (event, reason: string) => {
    if (disableClose && reason === 'backdropClick') {
      return false;
    }

    if (disableClose && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Dialog
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      BackdropProps={{
        className: backdropClasses.backdrop,
      }}
      classes={dialogClasses}
      data-testid={dataTestId}
      fullWidth={true}
      maxWidth={false}
      onClose={handleClose}
      open={isOpen}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
