import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsTextField } from '@plexus-ui/index';

const DefaultActive: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextField
      dataTestId="default-active-text-field"
      autoFocus={true}
      label="Input name to go here"
      isDefaultActive={true}
      onChange={handleChange}
      value={value}
    />
  );
};

export default DefaultActive;
