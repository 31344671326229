import React, { FC, useState } from 'react';
import { DocHeading, DocParagraph, DocRow } from '@dev/components';
import { PlxsButton, PlxsConfetti, PlxsSwitch } from '@plexus-ui/index';
import { FormControlLabel } from '@material-ui/core';

const ConfettiDocs: FC = () => {
  const [enabled, setEnabled] = useState(false);
  const [recycle, setRecycle] = useState(false);
  const handleClick = () => {
    setEnabled(!enabled);
  };

  const handleRecycleChange = () => {
    setRecycle(!recycle);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <DocParagraph>Click to enable/disable</DocParagraph>
        <PlxsButton
          dataTestId="button"
          onClick={handleClick}
          label={enabled ? 'Stop' : 'Start'}
          size="sm"
          variant="primary"
        />
        <PlxsConfetti
          enabled={enabled}
          recycle={recycle}
          onConfettiComplete={handleClick}
        />
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Options</DocHeading>
        <FormControlLabel
          control={
            <PlxsSwitch onChange={handleRecycleChange} checked={recycle} />
          }
          label="Recycle"
          labelPlacement="start"
        />
      </DocRow>
    </DocRow>
  );
};

export default ConfettiDocs;
