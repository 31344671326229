export const ACTION = 'action';
export const ERROR = 'error';
export const ERROR_LIGHT = 'errorLight';
export const SUCCESS = 'success';
export const WARNING = 'warning';

// Tone
export const BRAND = 'brand';
export const CAUTION = 'caution';
export const DARK = 'dark';
export const DISABLED = 'disabled';
export const INHERIT = 'inherit';
export const NEUTRAL = 'neutral';
export const POSITIVE = 'positive';
export const URGENT = 'urgent';
export const WHITE = 'white';

// Locale
export const EN_AU = 'en-AU';
export const EN_US = 'en-US';
