import React, { FC } from 'react';
import { ComponentProps } from './Column.types';
import { useStyles } from './Column.styles';

const Column: FC<ComponentProps> = ({
  align = 'stretch',
  children,
  expand,
  maxWidth,
  spaceBetween,
}) => {
  const classes = useStyles({ align, expand, maxWidth, spaceBetween });
  return <div className={classes.root}>{children}</div>;
};

export default Column;
