import React, { FC, useEffect, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import {
  PlxsIcon,
  PlxsTable,
  PlxsChip,
  PlxsTextLink,
  PlxsButton,
  PlxsStyles,
  PlxsSwitch,
  PlxsRow,
  PlxsColumn,
  PlxsSpotlightIcon,
  PlxsHeading,
  PlxsText,
  PlxsCheckbox,
} from '@plexus-ui/index';
import {
  TableColDef,
  TableCellRenderParams,
  RowData,
  TableColumnHeaderParams,
  TableCellParams,
} from '@plexus-ui/components/Table/Table.types';
import { rowsSort, rows, getBigData, dataDocumentRows } from './data';

import { useStyles } from './styles';

const columnsSort: TableColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
  },
  {
    field: 'lastName',
    headerName: 'Last name',
  },
  {
    field: 'age',
    headerName: 'Age',
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    valueGetter: (params: TableCellParams) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const LoadingIndicator = () => <div>Loading...</div>;

const EmptyIndicator: FC<any> = ({ classes }) => (
  <div className={classes.emptyIndicator}>
    <PlxsRow spaceBelow="md" alignX="center">
      <PlxsColumn align="center" maxWidth={382}>
        <div className={classes.emptySpotlightIcon}>
          <PlxsSpotlightIcon icon="recentlyViewed" tone="brand" />
        </div>
        <div className={classes.emptyHeading}>
          <PlxsHeading element="h3" align="center">
            No document
            <br />
            types available
          </PlxsHeading>
        </div>
        <PlxsText align="center">
          You currently have no document types available. To create a new
          document type to get started.
        </PlxsText>
        <PlxsButton
          dataTestId="example"
          decorativeIcon="add"
          label="Create new document type"
        />
      </PlxsColumn>
    </PlxsRow>
  </div>
);

const TextDocs: FC = () => {
  const classes = useStyles();
  const [loadedRows, setLoadedRows] = useState<RowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [documentRows, setDocumentRows] = useState(dataDocumentRows);

  const disabledDocumentRows = documentRows.filter((row) => !row.select);

  const documentColumns = [
    {
      field: 'select',
      headerName: '',
      hide: false,
      width: 'auto',
      minWidth: 'unset',
      sortable: false,
      renderHeaderControl: (params: TableColumnHeaderParams) => (
        <div className={classes.switchHeaderWrapper}>
          <PlxsSwitch
            dataTestId="example"
            checked={documentRows.every((row) => row.select)}
            indeterminate={documentRows.filter((row) => row.select).length > 0}
            onChange={(e, value) => params.updateAllRows(value)}
          />
        </div>
      ),
      renderCell: (params: TableCellRenderParams) => (
        <div className={classes.switchWrapper}>
          <PlxsSwitch
            dataTestId="example"
            checked={Boolean(params.value)}
            onChange={(e, value) => params.updateRow(value)}
          />
        </div>
      ),
    },
    {
      field: 'documentType',
      headerName: 'Document Type',
      width: 'auto',
      minWidth: 50,
      hide: false,
      responsive: {
        [PlxsStyles.breakpoints.sm]: {
          width: 'auto',
          minWidth: 170,
        },
        [PlxsStyles.breakpoints.md]: {
          width: 'auto',
          minWidth: 170,
        },
        [PlxsStyles.breakpoints.lg]: {
          width: 'auto',
          minWidth: 170,
        },
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      hide: false,
    },
    {
      field: 'documents',
      headerName: 'Documents',
      width: 200,
      hide: false,
    },
    {
      field: 'users',
      headerName: 'Users',
      width: '200px',
      hide: false,
    },
    {
      field: 'send',
      headerName: 'Send',
      hide: false,
      width: 'auto',
      minWidth: 180,
      sortable: false,
      renderHeaderControl: (params: TableColumnHeaderParams) => (
        <div className={classes.checkboxHeaderWrapper}>
          <PlxsCheckbox
            dataTestId="example"
            value={documentRows
              .filter((row) => row.select)
              .every((row) => row.send)}
            indeterminate={documentRows
              .filter((row) => row.select)
              .some((row) => row.send)}
            onChange={(checked) =>
              params.updateAllRows(
                checked,
                (row) =>
                  !disabledDocumentRows.some(
                    (disabledRow) => disabledRow.id === row.id
                  )
              )
            }
          />
        </div>
      ),
      renderCell: (params: TableCellRenderParams) => (
        <div className={classes.checkboxWrapper}>
          <PlxsCheckbox
            dataTestId="example"
            isDisabled={
              disabledDocumentRows.filter(
                (disableRow) => disableRow.id === params.row.id
              ).length > 0
            }
            value={params.row.select && params.row.send}
            onChange={params.updateRow}
          />
        </div>
      ),
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 'auto',
      minWidth: 200,
      hide: false,
      sortable: false,
      renderHeaderControl: (params: TableColumnHeaderParams) => (
        <div className={classes.checkboxHeaderWrapper}>
          <PlxsCheckbox
            dataTestId="example"
            value={documentRows
              .filter((row) => row.select)
              .every((row) => row.active)}
            indeterminate={documentRows
              .filter((row) => row.select)
              .some((row) => row.active)}
            onChange={(checked) =>
              params.updateAllRows(
                checked,
                (row) =>
                  !disabledDocumentRows.some(
                    (disabledRow) => disabledRow.id === row.id
                  )
              )
            }
          />
        </div>
      ),
      renderCell: (params: TableCellRenderParams) => (
        <div className={classes.checkboxWrapper}>
          <PlxsCheckbox
            dataTestId="example"
            isDisabled={
              disabledDocumentRows.filter(
                (disableRow) => disableRow.id === params.row.id
              ).length > 0
            }
            value={params.row.select && params.row.active}
            onChange={params.updateRow}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoadedRows(rows);
      setIsLoading(false);
    }, 5000);
  }, []);

  const columns: TableColDef[] = [
    {
      field: 'username',
      headerName: 'User name',
      renderCell: (params: TableCellRenderParams) => (
        <div className={classes.userWrapper}>
          <div className={classes.iconUser}>
            <PlxsIcon name="person" size="lg" tone="brand" />
          </div>
          <div className={classes.labelUser}>
            <span>{params.value}</span>
          </div>
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: TableCellRenderParams) => (
        <PlxsChip
          dataTestId="example"
          label={params.value.toString()}
          onFunctionalIconClick={() => {}}
          tone="positive"
        />
      ),
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params: TableCellRenderParams) => (
        <PlxsChip
          dataTestId="example"
          label="Remove"
          decorativeIcon="cross"
          tone="urgent"
        />
      ),
    },
  ];

  const bigDataColumns: TableColDef[] = [
    {
      field: 'documentType',
      headerName: 'Document Type',
      renderCell: (params: TableCellRenderParams) => (
        <div className={classes.linkWrapper}>
          <PlxsTextLink>{params.value}</PlxsTextLink>
        </div>
      ),
      width: 'auto',
      minWidth: 50,
      responsive: {
        [PlxsStyles.breakpoints.sm]: {
          width: 'auto',
          minWidth: 170,
        },
        [PlxsStyles.breakpoints.md]: {
          width: 'auto',
          minWidth: 170,
        },
        [PlxsStyles.breakpoints.lg]: {
          width: 'auto',
          minWidth: 170,
        },
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      hide: true,
      responsive: {
        [PlxsStyles.breakpoints.sm]: {
          hide: false,
        },
      },
    },
    {
      field: 'documents',
      headerName: 'Documents',
      width: 200,
    },
    {
      field: 'users',
      headerName: 'Users',
      width: '200px',
    },
  ];

  const handleRetryLoading = () => {
    setIsLoading(true);
    setLoadedRows([]);

    setTimeout(() => {
      setLoadedRows(rows);
      setIsLoading(false);
    }, 1200);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Basic</DocHeading>
        <p>This sample with fullWidth and row marked on click.</p>
        <PlxsTable
          rows={rows}
          columns={columns}
          classes={{ table: classes.table, tableCell: classes.tableCell }}
          highlightOnClick
          sortFieldDefault="username"
          orderDefault="desc"
          onClickRow={(row, selected) => {
            console.log('[row]', row);
            console.log('[selected]', selected);
          }}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Expandable Table</DocHeading>
        <p>This sample with expandable row.</p>
        <PlxsTable
          rows={rows}
          columns={columns}
          classes={{ table: classes.table, tableCell: classes.tableCell }}
          expandable
          renderExpandable={(row) => (
            <div style={{ padding: '16px' }}>
              <PlxsRow spaceBelow="md">
                {row.username} ({row.email})
              </PlxsRow>
              <PlxsRow>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </PlxsRow>
            </div>
          )}
          sortFieldDefault="username"
          orderDefault="desc"
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Responsive Table</DocHeading>
        <p>
          This sample is responsive width and minWidth for documentType field.
        </p>
        <PlxsTable
          sortFieldDefault="documentType"
          rows={documentRows}
          columns={documentColumns}
          onRowsChange={setDocumentRows}
          classes={{ table: classes.table }}
          disabledRows={disabledDocumentRows}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Empty State</DocHeading>
        <PlxsTable
          rows={[]}
          columns={columns}
          classes={{ table: classes.table, tableCell: classes.tableCell }}
          highlightOnHover
          sortFieldDefault="username"
          orderDefault="desc"
          onClickRow={(row, selected) => {
            console.log('[row]', row);
            console.log('[selected]', selected);
          }}
          emptyIndicator={() => <EmptyIndicator classes={classes} />}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Sort Table Case Insensitive</DocHeading>
        <PlxsTable
          rows={rowsSort}
          columns={columnsSort}
          classes={{ table: classes.table, tableCell: classes.tableCell }}
          highlightOnHover
          sortFieldDefault="firstName"
          orderDefault="desc"
          emptyIndicator={() => <EmptyIndicator classes={classes} />}
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Loading</DocHeading>
        <p>
          <PlxsButton
            dataTestId="example"
            decorativeIcon="undo"
            label="Retry"
            onClick={handleRetryLoading}
          />
        </p>
        <PlxsTable
          rows={loadedRows}
          columns={columns}
          classes={{ table: classes.table, tableCell: classes.tableCell }}
          highlightOnHover
          sortFieldDefault="username"
          orderDefault="desc"
          onClickRow={(row, selected) => {
            console.log('[row]', row);
            console.log('[selected]', selected);
          }}
          emptyIndicator={() =>
            isLoading ? (
              <LoadingIndicator />
            ) : (
              <EmptyIndicator classes={classes} />
            )
          }
        />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Pagination</DocHeading>
        <div className={classes.tableWrapper}>
          <PlxsTable
            sortFieldDefault="documentType"
            rows={getBigData()}
            columns={bigDataColumns}
            highlightOnHover
            isPaging
            classes={{ table: classes.table }}
          />
        </div>
      </DocRow>
    </DocRow>
  );
};

export default TextDocs;
