import { makeStyles } from '@material-ui/core/styles';
import { StyleProps, Variant } from './Modal.types';
import { breakpoints } from '../../styles';
import { neutral, brand } from '../../styles/color';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const backgroundColors: Record<Variant, string> = {
  grey: neutral[900],
  white: 'white',
};

function getWidthStyles(fixedWidth?: number): CSSProperties {
  if (fixedWidth === undefined) {
    return {
      maxWidth: '896px',
      width: 'auto',
    };
  } else {
    return {
      width: `${fixedWidth}px`,
    };
  }
}

export const useStyles = makeStyles(() => ({
  backdrop: {
    backgroundColor: fade(brand[100], 0.3),
  },
  content: ({ hasPrimaryButton, removeContentMargin }: StyleProps) => ({
    marginBottom: hasPrimaryButton && !removeContentMargin ? '32px' : '0',
  }),
  contentContainer: ({ enableOverflow, fixedWidth, variant }: StyleProps) => ({
    backgroundColor: backgroundColors[variant],
    borderRadius: '0',
    boxShadow: 'none',
    boxSizing: 'border-box',
    margin: 0,
    maxWidth: '100%',
    outline: 'none',
    overflowY: enableOverflow ? 'visible' : 'auto',
    padding: '32px 16px',
    width: '100%',
    [`@media (min-width:${breakpoints.sm})`]: {
      ...getWidthStyles(fixedWidth),
      borderRadius: '3px',
      margin: '32px',
      padding: '32px',
    },
  }),
}));
