import React, { FC } from 'react';
import cx from 'classnames';
import SwitchMUI from '@material-ui/core/Switch';

import { ComponentProps } from './Switch.types';
import { useStyles } from './Switch.styles';
import PlxsIcon from '../Icon';

const Switch: FC<ComponentProps> = ({
  dataTestId,
  onChange,
  checked,
  isDisabled,
  indeterminate,
}) => {
  const classes = useStyles();

  const props = {};

  if (indeterminate && !checked) {
    props['icon'] = (
      <span className={classes.thumbIndeterminate}>
        <PlxsIcon name="minus" />
      </span>
    );
    props['checkedIcon'] = (
      <span className={classes.thumbIndeterminate}>
        <PlxsIcon name="minus" />
      </span>
    );
  }

  return (
    <SwitchMUI
      classes={{
        root: classes.root,
        switchBase: cx(
          classes.switchBase,
          indeterminate && !checked ? classes.switchIndeterminate : null
        ),
        thumb: classes.thumb,
        track: cx(
          classes.track,
          indeterminate && !checked ? classes.trackIndeterminate : null
        ),
        checked: classes.checked,
      }}
      data-testid={dataTestId}
      onChange={onChange}
      disabled={isDisabled}
      checked={checked}
      {...props}
    />
  );
};

export default Switch;
