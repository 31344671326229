import React from 'react';
import { DocBox, DocHeading, DocRow } from '@dev/components';
import {
  PlxsButton,
  PlxsIcon,
  PlxsRow,
  PlxsText,
  PlxsTooltip,
} from '@plexus-ui/index';

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Default tooltip</DocHeading>
      <PlxsTooltip title="Popup message for the user">
        <PlxsButton dataTestId="hover-here-button" label="Hover here" />
      </PlxsTooltip>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Wrapping a block of content</DocHeading>
      <PlxsTooltip title="Popup message for the user" variant="contentWrapper">
        <DocBox>
          <PlxsText>
            Hovering over this whole content box will display the tooltip.
          </PlxsText>
        </DocBox>
      </PlxsTooltip>
    </DocRow>
    <DocRow margin="lg">
      <DocHeading>Wrapping a icon</DocHeading>
      <PlxsRow>
        <PlxsText element="span">Text with tooltip on inline icon.</PlxsText>
        <PlxsTooltip title="Popup message for the user" variant="iconOnly">
          <PlxsIcon name="infoCircle" />
        </PlxsTooltip>
      </PlxsRow>
    </DocRow>
  </DocRow>
);
