import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginRight: '8px',
    maxWidth: '50px',
    '& input': {
      height: '32px',
      textAlign: 'center',
    },
  },
});
