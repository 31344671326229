import React from 'react';
import { ComponentProps } from './ListBox.types';
import { useStyles } from './ListBox.styles';
import Checkbox from '../Checkbox/Checkbox';

const ListBox = <Option extends any>({
  getDataTestId,
  getLabel,
  onChange,
  options,
  value,
}: ComponentProps<Option>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {options.map((option, index) => (
        <div className={classes.option} key={index}>
          <Checkbox
            dataTestId={getDataTestId ? getDataTestId(option) : ''}
            label={getLabel(option)}
            onChange={(_) => onChange(option)}
            value={value.includes(option)}
          />
        </div>
      ))}
    </div>
  );
};

export default ListBox;
