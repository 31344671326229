import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  inputWrapper: {
    position: 'relative',
    flexGrow: 1,
  },
  marginBottom: {
    marginBottom: '16px',
  },
});
