import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  list: {
    listStyleType: 'disc',
    marginTop: '7px',
    paddingLeft: '27px',
  },
  listItem: {
    paddingLeft: '10px',
  },
});
