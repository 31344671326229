import React, { useState } from 'react';
import { PlxsTextField } from '@plexus-ui/index';
import InteractiveFieldWrapper from '@dev/docs/_internal/InteractiveFieldWrapper';

const InteractiveExample = () => {
  const [value, setValue] = useState('');

  return (
    <InteractiveFieldWrapper dataTestId="interactive-text-field">
      {({ dataTestId, errorMessage, hasError, isDisabled }) => (
        <PlxsTextField
          dataTestId={dataTestId}
          errorMessage={errorMessage}
          hasError={hasError}
          isDisabled={isDisabled}
          label="Input name to go here"
          onChange={(e) => setValue(e.target.value)}
          onReset={() => setValue('')}
          placeholder="Field helper text"
          value={value}
        />
      )}
    </InteractiveFieldWrapper>
  );
};

export default InteractiveExample;
