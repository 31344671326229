import React, { Fragment } from 'react';
import { PlxsTextLink } from '@plexus-ui/index';

export default () => (
  <Fragment>
    An unexpected error occurred. Please try again or{' '}
    <PlxsTextLink href={'mailto:support@plexus.co'}>
      contact support.
    </PlxsTextLink>
  </Fragment>
);
