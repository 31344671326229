import React, { FC } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsHeading } from '@plexus-ui/index';

const HeadingDocs: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Headings as HTML elements</DocHeading>
        <div>
          <PlxsHeading dataTestId="h1-element" element="h1">
            As an h1 element
          </PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h2">As an h2 element</PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h3">As an h3 element</PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h4">As an h4 element</PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h5">As an h5 element</PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h6">As an h6 element</PlxsHeading>
        </div>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Headings with font weight overrides</DocHeading>
        <div>
          <PlxsHeading element="h2" weight="regular">
            H2 element with regular font weight
          </PlxsHeading>
        </div>
      </DocRow>

      <DocRow margin="lg">
        <DocHeading>Headings as styled variants</DocHeading>
        <div>
          <PlxsHeading element="h6" variant="h1">
            Styled as h1
          </PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h6" variant="h2">
            Styled as h2
          </PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h6" variant="h3">
            Styled as h3
          </PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h6" variant="h4">
            Styled as h4
          </PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h6" variant="h5">
            Styled as h5
          </PlxsHeading>
        </div>
        <div>
          <PlxsHeading element="h1" variant="h6">
            Styled as h6
          </PlxsHeading>
        </div>
      </DocRow>
    </DocRow>
  );
};

export default HeadingDocs;
