import React, { FC, useCallback } from 'react';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableCell from '@material-ui/core/TableCell';
import TableCellHead from '../TableCellHead';
import { RowData } from '../Table.types';
import { TableHeadProps } from './types';
import { useStyles } from './styles';

const TableHead: FC<TableHeadProps> = React.memo(
  ({
    order,
    orderBy,
    onRequestSort,
    columns,
    isEmptyState,
    onRowsChange,
    rows,
    isVisuallyHidden,
    expandable = false,
  }) => {
    const classes = useStyles();
    const createSortHandler = useCallback(
      (property: keyof RowData) => (_: React.MouseEvent<unknown>) => {
        onRequestSort(property);
      },
      [onRequestSort]
    );

    return (
      <MuiTableHead className={isVisuallyHidden ? classes.root : ''}>
        <MuiTableRow>
          {columns.map((column, index) => {
            const handleUpdateAllRows = (
              value: any,
              rowFilter?: (row: RowData) => boolean
            ) => {
              if (!column.field) {
                throw Error('Cannot update rows for column with no field.');
              } else if (onRowsChange) {
                const updatedRows = rows.map((row) =>
                  !rowFilter || rowFilter(row)
                    ? { ...row, [column.field]: value }
                    : row
                );
                onRowsChange(updatedRows);
              }
            };

            return (
              <TableCellHead
                key={index}
                col={column}
                orderBy={orderBy}
                order={order}
                createSortHandler={createSortHandler}
                isEmptyState={isEmptyState}
                onUpdateAllRows={handleUpdateAllRows}
                rows={rows}
              />
            );
          })}

          {expandable && (
            <MuiTableCell className={classes.expandableActionColumn} />
          )}
        </MuiTableRow>
      </MuiTableHead>
    );
  }
);

export default TableHead;
