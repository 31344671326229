import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

import {
  defaultTextStyle,
  getTextStyle,
  inheritedTextStyle,
} from '../../styles/text';

import { Size, StyleProps, Overflow } from './Text.types';

const sizeStyles: Record<Size, CSSProperties> = {
  xs: {
    fontSize: '10px',
    lineHeight: '14px',
  },
  sm: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  md: {
    fontSize: '16px',
    lineHeight: '24px',
  },
};

const overflowStyles: Record<Overflow, CSSProperties> = {
  clip: {
    textOverflow: 'clip',
    overflow: 'visible',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

export const useStyles = makeStyles(() => ({
  root: ({ align, color, overflow, size, weight, variant }: StyleProps) => ({
    marginTop: 0,
    marginBottom: 0,
    ...(variant === 'inherit' ? inheritedTextStyle : defaultTextStyle),
    ...sizeStyles[size],
    ...overflowStyles[overflow],
    ...getTextStyle({ color, weight, align }),
  }),
}));
