import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '../../styles';
import { StyleProps } from './ButtonGroup.types';

const base = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
};

export const getAlignment = (props: StyleProps) => {
  const styles = {
    left: {
      '& > *:not(:last-child)': {
        marginRight: spacing.md,
      },
    },
    center: {
      justifyContent: 'center',
      '& > *:not(:last-child)': {
        marginRight: spacing.md,
      },
    },
    right: {
      justifyContent: 'flex-end',
      '& > *:not(:first-child)': {
        marginLeft: spacing.md,
      },
    },
  };
  return styles[props.align];
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    ...base,
    ...getAlignment(props),
  }),
}));
