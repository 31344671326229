import React, { FC } from 'react';

import { BRAND } from '../../../_constants';
import PlxsIcon from '../../Icon';

import { ComponentProps } from './InternalButton.types';
import { useStyles } from './InternalButton.styles';

const InternalButton: FC<ComponentProps> = ({
  dataTestId,
  value,
  label,
  icon,
  tone = BRAND,
  isSelected,
  disabled,
  ...otherProps
}) => {
  const classes = useStyles({ tone, isSelected, disabled });
  return (
    <button
      className={classes.root}
      data-testid={dataTestId}
      value={value}
      disabled={disabled}
      {...otherProps}
    >
      {icon && <PlxsIcon name={icon} />}
      {label}
    </button>
  );
};

export default InternalButton;
