import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Tasks: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg width="80" height="78" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M1.3,39.9l24.2,11c0.7,0.3,1.5,0.3,2.2,0l24.2-11c1.8-0.8,1.8-3.5,0-4.3l-24.2-11c-0.3-0.2-0.7-0.2-1-0.2c-0.4,0-0.8,0-1.1,0.2l-24.2,11C-0.5,36.4-0.5,39.1,1.3,39.9z M52,49.1l-6-2.8l-16.9,7.8c-0.7,0.3-1.6,0.5-2.4,0.5c-0.9,0-1.8-0.2-2.5-0.5L7.3,46.3l-6,2.8c-1.8,0.7-1.8,3.4,0,4.2l24.2,11c0.7,0.3,1.5,0.3,2.2,0l24.2-11C53.8,52.5,53.8,49.9,52,49.1z M52,62.6l-6-2.8l-16.9,7.8c-0.7,0.3-1.6,0.5-2.4,0.5c-0.9,0-1.8-0.2-2.5-0.5L7.3,59.7l-6,2.8c-1.8,0.7-1.8,3.4,0,4.2l24.2,11c0.7,0.3,1.5,0.3,2.2,0l24.2-11C53.8,65.9,53.8,63.3,52,62.6z"
    />
  </svg>
);

export default Tasks;
