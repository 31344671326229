import React, { FC, Fragment } from 'react';

import { ComponentProps } from './types';
import { TimeFormat } from '../../../types';
import { formatDate } from '../../../utils';

const TimeDisplay: FC<ComponentProps> = ({ date, locale }) => {
  const timeFormat: TimeFormat = 'hoursMinutes';
  return <Fragment>{formatDate(date, timeFormat, locale)}</Fragment>;
};

export default TimeDisplay;
