import { makeStyles } from '@material-ui/core/styles';

import { BRAND, POSITIVE, URGENT } from '../../../_constants';
import { brand, neutral, white, positive, urgent } from '../../../styles/color';
import { defaultTextStyle } from '../../../styles/text';

import { StyleProps } from './InternalButton.types';
import { color, focusState } from '../../../styles';
import { tone } from '../../../styles/tone';

const activeStyles = {
  [BRAND]: {
    color: white,
    backgroundColor: brand[400],
    '&:hover': {
      backgroundColor: brand[300],
    },
    '& > svg': {
      marginRight: '8px',
      color: white,
    },
  },
  [POSITIVE]: {
    color: brand[100],
    backgroundColor: positive[900],
    '&:hover': {
      backgroundColor: positive[800],
    },
    '& > svg': {
      marginRight: '8px',
      color: positive[400],
    },
  },
  [URGENT]: {
    color: brand[100],
    backgroundColor: urgent[900],
    '&:hover': {
      backgroundColor: urgent[800],
    },
    '& > svg': {
      marginRight: '8px',
      color: urgent[400],
    },
  },
};

const disabledStyles = (props: StyleProps) => {
  if (props.disabled) {
    return {
      backgroundColor: color.neutralShade[800],
      color: tone.disabled,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: color.neutralShade[800],
      },
      '&[disabled]': {
        cursor: 'not-allowed',
      },
    };
  }
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    '-webkit-font-smoothing': 'inherit',
    '-moz-osx-font-smoothing': 'inherit',
    '-webkit-appearance': 'none',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer',
    margin: 0,
    overflow: 'visible',
    position: 'relative',
    // Shape & layout
    height: '34px',
    borderRadius: '3px',
    padding: '8px 16px',
    display: 'flex',
    '&:not(:first-child)': {
      marginLeft: '8px',
    },
    // Icon
    '& > svg': {
      color: neutral[200],
      marginRight: '8px',
    },
    // Font
    ...defaultTextStyle,
    fontSize: '13px',
    lineHeight: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    // Color
    color: neutral[200],
    '&:focus': focusState,
    '&:hover': {
      backgroundColor: color.neutralShade[600],
    },
    ...(props.isSelected ? activeStyles[props.tone] : {}),
    ...disabledStyles(props),
  }),
}));
