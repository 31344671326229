import React from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import { PlxsChip, PlxsChipGroup } from '@plexus-ui/index';

const usage = `import React, { FC } from 'react';

import { PlxsChip, PlxsChipGroup } from '@plexus-ui/index';

const Example: FC = () => (
  <PlxsChipGroup>
    <PlxsChip
      decorativeIcon="person"
      label="Tomas Michaels"
      tone="brand"
    />
    <PlxsChip
      decorativeIcon="person"
      label="Tomas Michaels"
      tone="brand"
    />
    <PlxsChip
      decorativeIcon="person"
      label="Tomas Michaels"
      tone="brand"
    />
    <PlxsChip
      decorativeIcon="person"
      label="Tomas Michaels"
      tone="brand"
    />
    <PlxsChip
      decorativeIcon="person"
      label="Tomas Michaels"
      tone="brand"
    />
    <PlxsChip
      decorativeIcon="person"
      label="Tomas Michaels"
      tone="brand"
    />
  </PlxsChipGroup>
);

export default Example;`;

export default () => {
  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Chip group</DocSubHeading>
        <DocParagraph>
          The chip group is a fluid wrapper that renders a group of chips with
          equal spacing between each element and row of elements as they stack.
        </DocParagraph>
      </DocRow>
      <PlxsChipGroup>
        <PlxsChip decorativeIcon="person" label="Tomas Michaels" tone="brand" />
        <PlxsChip decorativeIcon="person" label="Tomas Michaels" tone="brand" />
        <PlxsChip decorativeIcon="person" label="Tomas Michaels" tone="brand" />
        <PlxsChip decorativeIcon="person" label="Tomas Michaels" tone="brand" />
        <PlxsChip decorativeIcon="person" label="Tomas Michaels" tone="brand" />
        <PlxsChip decorativeIcon="person" label="Tomas Michaels" tone="brand" />
      </PlxsChipGroup>
      <DocCode>{usage}</DocCode>
    </DocRow>
  );
};
