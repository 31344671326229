import { format, isValid } from 'date-fns';
import { dateFnsStringFormatMap, dateFnsLocaleMap } from './_constants';
import { DateFormat, DateTimeFormat, Locale, TimeFormat } from '../../types';

const formatDate = (
  date: Date,
  stringFormat: DateFormat | TimeFormat | DateTimeFormat,
  locale: Locale
): string | null => {
  if (isValid(date)) {
    const supportedFormat = dateFnsStringFormatMap[stringFormat];

    if (supportedFormat === undefined) {
      throw new RangeError(`Unsupported string format '${stringFormat}'`);
    }

    const supportedLocale = dateFnsLocaleMap[locale];

    if (supportedLocale === undefined) {
      throw new RangeError(`Unsupported locale '${locale}'`);
    }

    return format(date, supportedFormat, { locale: supportedLocale });
  }

  return null;
};

export default formatDate;
