import React, { useEffect, useRef, useState } from 'react';
import { DocCode, DocRow, DocSubHeading } from '@dev/components';
import {
  PlxsActionMenu,
  PlxsButton,
  PlxsButtonGroup,
  PlxsCheckbox,
  PlxsColumn,
  PlxsIcon,
  PlxsRow,
  PlxsSelect,
  PlxsText,
} from '@plexus-ui/index';
import { useStyles } from './styles';
import { MenuPlacement } from '@plexus-ui/components/_internal/Popover/types';

const KebabButton = () => {
  const oversizedContentRef = useRef(null);
  const viewportRef = useRef(null);

  const [isViewportLocked, setIsViewportLocked] = useState(false);
  const classes = useStyles({ isViewportLocked });

  const [isCloseOnSelect, setIsCloseOnSelect] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const exampleData = [
    {
      disabled: false,
      icon: <PlxsIcon name="upload" size="sm" />,
      label: 'Upload a file',
      value: 32,
      dataTestId: 'upload-a-file',
    },
    {
      disabled: false,
      icon: <PlxsIcon name="signature" size="sm" />,
      label: 'Write a book',
      value: 211,
      dataTestId: 'write-a-book',
    },
    {
      disabled: true,
      icon: <PlxsIcon name="gift" size="sm" tone="disabled" />,
      label: 'Send a gift',
      value: 52,
      dataTestId: 'send-a-gift',
    },
    {
      disabled: true,
      icon: <PlxsIcon name="share" size="sm" tone="disabled" />,
      label: 'Share a document',
      value: 990,
      dataTestId: 'share-a-document',
    },
    {
      isDivider: true,
    },
    {
      disabled: false,
      icon: <PlxsIcon name="bin" size="sm" tone="urgent" />,
      label: 'Delete some files',
      value: 7,
      tone: 'urgent',
    },
  ];

  const placements = [
    { name: 'right-start', displayName: 'TopLeft' },
    { name: 'left-start', displayName: 'TopRight' },
    { name: 'right-end', displayName: 'BottomLeft' },
    { name: 'left-end', displayName: 'BottomRight' },
  ];

  const [selectedPlacement, setSelectedPlacement] = useState(placements[0]);

  const handlePlacementSelect = (item) => {
    setSelectedPlacement(item);
  };

  const handleMenuItemSelect = (item) => {
    const { label, value } = item;
    setSelectedValue({ label, value });
  };

  const handleTriggerRecenterClick = () => {
    const { offsetHeight, offsetWidth } = oversizedContentRef.current;
    viewportRef.current.scroll(0.3 * offsetWidth, 0.3 * offsetHeight);
  };

  useEffect(handleTriggerRecenterClick, []);

  return (
    <DocRow margin="sm">
      <DocSubHeading>Triggered with "kebab" button</DocSubHeading>

      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Placement</div>
          <div className={classes.componentWrapper}>
            <PlxsColumn expand={true}>
              <PlxsSelect
                dataTestId="action-menu-example-kebab-placements"
                getOptionLabel={(option) => option.displayName}
                onSelect={handlePlacementSelect}
                options={placements}
                placeholder="Select placement..."
                selected={selectedPlacement}
              />
            </PlxsColumn>
          </div>
          <div className={classes.name}>
            <DocCode>{`placement="${selectedPlacement.name}"`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Disabled state</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="action-menu-example-kebab-disabled-checkbox"
              onChange={() => setIsDisabled(!isDisabled)}
              value={isDisabled}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`isDisabled={${isDisabled ? 'true' : 'false'}}`}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Close menu on select</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="action-menu-example-kebab-close-menu-checkbox"
              onChange={() => setIsCloseOnSelect(!isCloseOnSelect)}
              value={isCloseOnSelect}
            />
          </div>
          <div className={classes.name}>
            <DocCode>{`isCloseOnSelect={${
              isCloseOnSelect ? 'true' : 'false'
            }}`}</DocCode>
          </div>
        </li>
      </ul>

      <PlxsRow spaceBelow="xxl">
        <PlxsText>
          Selected: <code>{JSON.stringify(selectedValue)}</code>
        </PlxsText>
      </PlxsRow>

      <PlxsRow spaceBelow="xxl">
        <PlxsButtonGroup>
          <PlxsButton
            dataTestId="action-menu-kebab-recenter-button"
            label="Re-center trigger"
            onClick={handleTriggerRecenterClick}
          />
          <PlxsButton
            dataTestId="action-menu-kebab-lock-viewport"
            label={`${isViewportLocked ? 'Unlock' : 'Lock'} viewport`}
            onClick={() => setIsViewportLocked(!isViewportLocked)}
          />
        </PlxsButtonGroup>
      </PlxsRow>

      <div ref={viewportRef} className={classes.viewport}>
        <div ref={oversizedContentRef} className={classes.oversizedContent}>
          <PlxsActionMenu
            dataTestId="action-menu-kebab-example"
            getOptionState={(option) => ({
              isDisabled: option.disabled,
              isDivider: option.isDivider,
              tone: option.tone,
              dataTestId: option.dataTestId,
            })}
            isCloseOnSelect={isCloseOnSelect}
            isDisabled={isDisabled}
            onSelect={handleMenuItemSelect}
            options={exampleData}
            placement={selectedPlacement.name as MenuPlacement}
            renderOption={(option) =>
              option.isDivider ? null : (
                <div className={classes.optionWrapper}>
                  <span className={classes.optionIconWrapper}>
                    {option.icon}
                  </span>
                  <PlxsText element="span" color="inherit" weight="semiBold">
                    {option.label}
                  </PlxsText>
                </div>
              )
            }
          />
        </div>
      </div>
    </DocRow>
  );
};

export default KebabButton;
