import classNames from 'classnames';
import React, { FC, useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { PlxsButton } from '@plexus-ui/index';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const ScrollButton: FC<ComponentProps> = ({ position }) => {
  const {
    initComplete,
    isFirstItemVisible,
    isLastItemVisible,
    scrollNext,
    scrollPrev,
  } = useContext(VisibilityContext);

  const classes = useStyles();

  const [isVisible, setVisible] = useState(false);

  const isLeftButton = position === 'left';

  const className = classNames(classes.scrollButton, {
    [classes.scrollButtonLeft]: isLeftButton,
    [classes.scrollButtonRight]: !isLeftButton,
    [classes.scrollButtonShow]: isVisible,
  });
  const functionalIcon = isLeftButton ? 'arrowLeft' : 'arrowRight';

  const handleClick = () => {
    return isLeftButton ? scrollPrev() : scrollNext();
  };

  useEffect(() => {
    if (initComplete) {
      setVisible(isLeftButton ? !isFirstItemVisible : !isLastItemVisible);
    }
  }, [initComplete, isFirstItemVisible, isLastItemVisible, isLeftButton]);

  return (
    <div className={className}>
      {isVisible && (
        <PlxsButton
          dataTestId="scroll-button"
          functionalIcon={functionalIcon}
          isIconOnly={true}
          label=""
          onClick={handleClick}
          size="sm"
          tone="neutral"
          variant="link"
        />
      )}
    </div>
  );
};

export default ScrollButton;
