import React, { FC } from 'react';

import { useStyles } from './ChipGroup.styles';

const ChipGroup: FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default ChipGroup;
