import React, { FC } from 'react';

import PlxsButton from '../../Button';

import { ComponentProps } from './types';

const ResetButton: FC<ComponentProps> = ({ isDisabled, onClick }) => {
  return (
    <PlxsButton
      dataTestId="field-reset-button"
      functionalIcon="cross"
      isDisabled={isDisabled}
      isIconOnly={true}
      label=""
      onClick={onClick}
      size="sm"
      tone="neutral"
      variant="link"
    />
  );
};

export default ResetButton;
