import React, { FC, Fragment } from 'react';

import { useStyles } from './styles';

interface ComponentProps {
  title: string;
  tone: {};
}

const SwatchList: FC<ComponentProps> = ({ title, tone }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.header}>
        <h2 className={classes.heading}>{title}</h2>
      </div>
      <ul className={classes.list}>
        {Object.keys(tone).map((color) => (
          <li className={classes.listItem} key={tone[color]}>
            <div
              className={classes.swatch}
              style={{
                background: tone[color],
              }}
            />
            <div className={classes.shadeValue}>{color}</div>
            <div className={classes.hexValue}>{tone[color]}</div>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default SwatchList;
