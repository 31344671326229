import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const InlineButtonWrapper: FC<ComponentProps> = ({ button, children }) => {
  const classes = useStyles();
  return button ? (
    <div className={classes.wrapper}>
      {children}
      {button}
    </div>
  ) : (
    children
  );
};

export default InlineButtonWrapper;
