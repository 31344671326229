import React, { FC, MouseEvent, useEffect, useState, useMemo } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { ComponentProps } from './TabProvider.types';
import { useStyles } from './TabProvider.styles';
import ScrollButton from './_internal/ScrollButton';
import Tab from './_internal/Tab';
import TabPanel from './_internal/TabPanel';
import PlxsIcon from '../Icon';
import Collapse from '@material-ui/core/Collapse';

const getTabContentId = (tabContentId: string) => `${tabContentId}--content`;

const getTabId = (tabId: string) => `${tabId}--tab`;

const TabProvider: FC<ComponentProps> = ({
  activeTab,
  ariaLabel,
  children,
  dataTestId,
  height,
  hasPadding,
  onClick,
  isCollapsible = false,
}) => {
  const classes = useStyles({ height, isCollapsible });

  // We need to handle falsy children to support stuff like this:
  //
  // <TabProvider>
  //   <TabElement ... />
  //   { condition && (<TabElement ... />) }
  // </TabProvider>
  const childArray = useMemo(() => {
    return (
      Array.isArray(children) ? children : [children]
    ).filter(child => child);
  }, [children]);

  const [activeTabId, setActiveTabId] = useState(childArray[0].props.id);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (activeTab) {
      setActiveTabId(activeTab);
    }
  }, [activeTab]);

  const renderTabPanels = childArray.map((child, index) => {
    const contentId = getTabContentId(child.props.id);
    const tabId = child.props.id;
    const isActive = tabId === activeTabId;

    return (
      <TabPanel
        id={contentId}
        ariaLabelledBy={getTabId(tabId)}
        isActive={isActive}
        key={index}
        hasPadding={hasPadding}
      >
        <Collapse in={isExpanded} timeout="auto" unmountOnExit={true}>
          {child.props.children}
        </Collapse>
      </TabPanel>
    );
  });

  const renderTabs = childArray.map((child, index) => {
    const {
      tabIcon,
      tabIconTone,
      tabIndicatorLabel,
      tabIndicatorTone,
      tabLabel,
    } = child.props;
    const isFirstTab = index === 0;
    const isLastTab = index === childArray.length - 1;
    const contentId = getTabContentId(child.props.id);
    const tabId = child.props.id;
    const isActive = tabId === activeTabId;

    const handleClick = (event: MouseEvent) => {
      onClick ? onClick(tabId) : setActiveTabId(tabId);
      event.stopPropagation();
    };

    return (
      <Tab
        ariaControls={contentId}
        icon={tabIcon}
        iconTone={tabIconTone}
        indicatorLabel={tabIndicatorLabel}
        indicatorTone={tabIndicatorTone}
        isActive={isActive}
        isFirstTab={isFirstTab}
        isLastTab={isLastTab}
        itemId={getTabId(tabId)}
        key={index}
        label={tabLabel}
        onClick={handleClick}
      />
    );
  });

  const onTabPanelClick = () => {
    if (isCollapsible) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div data-testid={dataTestId} className={classes.tabProvider}>
      <div
        aria-label={`${ariaLabel} Tabs`}
        data-testid={`${dataTestId}--header`}
        aria-orientation="horizontal"
        className={classes.tabWrapper}
        role="tablist"
        onClick={onTabPanelClick}
      >
        <ScrollMenu
          LeftArrow={
            <ScrollButton
              dataTestId={`${dataTestId}--scroll-button--left`}
              position="left"
            />
          }
          RightArrow={
            <ScrollButton
              dataTestId={`${dataTestId}--scroll-button--right`}
              position="right"
            />
          }
        >
          {renderTabs}
        </ScrollMenu>
        <div className={classes.expandIconContainer}>
          <PlxsIcon
            name={isExpanded ? 'chevronUp' : 'chevronDown'}
            tone={'brand'}
          />
        </div>
      </div>
      {renderTabPanels}
    </div>
  );
};

export default TabProvider;
