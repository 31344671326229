import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { DocHeading, DocRow, DocParagraph } from '@dev/components';

import { PlxsInput } from '@plexus-ui/index';

const MaxLength: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Fragment>
      <DocRow margin="xxl">
        <DocRow margin="lg">
          <DocHeading>Max Length</DocHeading>
          <DocParagraph>
            The input with a max length character countdown. When the count
            reaches 0 the input value will be truncated. If the character count
            of pasted text exceeds the max length the input value will be
            truncated at the max length value.
          </DocParagraph>
          <PlxsInput
            dataTestId="max-length-input"
            maxLength={60}
            onChange={handleChange}
            value={value}
          />
        </DocRow>
      </DocRow>
    </Fragment>
  );
};

export default MaxLength;
