import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *:not(:last-child)': {
      marginRight: '16px',
    },
  },
});
