import { makeStyles } from '@material-ui/core/styles';
import { color, fontColor } from '../../styles';

interface StyleProps {
  rowCursor: boolean;
}

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableBody: {
    '& .tableRowSelected': {
      backgroundColor: color.brandLightest,
      '&:hover': {
        backgroundColor: color.brandLightest,
      },
    },
    '& .tableRowHover': {
      '&:hover': {
        backgroundColor: color.brandLightest,
      },
    },
  },
  tableRow: ({ rowCursor }: StyleProps) => ({
    cursor: rowCursor ? 'pointer' : 'default',
  }),
  tableRowDisabled: {
    backgroundColor: color.neutralShade[900],
    '&:hover': {
      backgroundColor: color.neutralShade[900],
    },
  },
  tableCell: {
    paddingTop: '11px',
    paddingBottom: '11px',
    borderBottom: `1px solid ${color.neutralShade[900]}`,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: fontColor.default,
  },
  expandableRow: {
    padding: 0,
  },
  expandableActionCell: {
    textAlign: 'center',
  },
  expandableTableCellNoBorder: {
    borderBottom: 0,
  },
  expandableTableCell: {
    borderBottom: `1px solid ${color.neutralShade[900]}`,
  },
});
