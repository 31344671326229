import React, { FC } from 'react';
import { ComponentProps } from './TimePeriod.types';
import { useStyles } from './TimePeriod.styles';

const TimePeriod: FC<ComponentProps> = ({ dataTestId, value, onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    const newTimePeriod = value === 'AM' ? 'PM' : 'AM';
    onClick(newTimePeriod);
  };

  return (
    <button
      data-testid={`${dataTestId}--button`}
      type="button"
      className={classes.button}
      onClick={handleClick}
    >
      {value || ''}
    </button>
  );
};

export default TimePeriod;
