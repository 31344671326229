import React, { FC } from 'react';

import { ComponentProps } from './_types';

const PermissionsFull: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    height="75"
    width="74"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38.6" cy="21.4" r="21.4" fill={circleFill} />
    <path
      fill={iconFill}
      d="m45.2 36.4-8.3-3.3c-.3-.1-.9-.3-1.7 0l-8.3 3.3c-.7.3-1.2 1-1.2 1.7 0 8.1 4.9 13.7 9.5 15.5.7.3 1.3.1 1.7 0 3.7-1.4 9.6-6.4 9.6-15.5 0-.7-.5-1.4-1.3-1.7zm-9.1 13.8V36.6l6.9 2.7c-.5 6.2-4.5 9.7-6.9 10.9zm-19.7-15c5.1 0 9.3-4.1 9.3-9.3 0-5.1-4.2-9.3-9.3-9.3-5.1 0-9.3 4.2-9.3 9.3 0 5.2 4.1 9.3 9.3 9.3zm6.9 2.9c0-.1 0-.3.1-.5-.2 0-.4-.1-.6-.1h-1.2c-1.6.8-3.3 1.2-5.2 1.2s-3.7-.4-5.3-1.2H9.9c-5.4 0-9.7 4.4-9.7 9.8v3c0 2 1.5 3.5 3.5 3.5h25.5c.4 0 .9-.1 1.4-.3-4-3.1-7.3-8.4-7.3-15.4z"
    />
  </svg>
);

export default PermissionsFull;
