import { brand, neutral } from '../../../../styles/color';
import { inputFocused } from '../../../../styles/input';
import { getTextStyle } from '../../../../styles/text';

export const CALENDAR_ELEMENT_MARGIN_BOTTOM = '2px';
export const CALENDAR_BUTTON_HEIGHT = '32px';

export const cellDefault: Record<string, any> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '32px',
  width: '32px',
  borderRadius: '3px',
  border: '1px solid transparent',
  outline: 'none',
};

export const cellDisabled: Record<string, any> = {
  backgroundColor: 'transparent',
  color: neutral[500],
  pointerEvents: 'none',
  '&:hover': { backgroundColor: 'transparent' },
  '&:focus, &:focus:not(:hover)': { color: neutral[500] },
};

export const cellSelectable: Record<string, any> = {
  ...cellDefault,
  cursor: 'pointer',
  '&:hover': { backgroundColor: brand[900] },
  '&:focus:not(:hover)': { ...inputFocused },
};

export const cellSelected: Record<string, any> = {
  ...getTextStyle({ weight: 'semiBold' }),
  backgroundColor: brand[600],
  border: 'none',
  '&:hover': {
    backgroundColor: brand[600],
  },
  '&:focus': {
    border: 'none',
  },
};

export const cellSelectedRange: Record<string, any> = {
  backgroundColor: brand[800],
  '&:hover': {
    backgroundColor: brand[800],
  },
};
