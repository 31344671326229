import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';

import { PlxsText } from '@plexus-ui/index';
import Anchor from '@plexus-ui/components/_internal/Anchor';

const props = `interface ComponentProps {
  children: ReactNode;
  isExternal?: boolean;
  url: string;
}`;

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocRow margin="md">
        <DocParagraph>
          The Anchor component is used to render an unstyled anchor element.
        </DocParagraph>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Props</DocHeading>
        <DocCode>{props}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Default</DocHeading>
        <Anchor url="#">
          <PlxsText>An unstyled link with cursor pointer on hover.</PlxsText>
        </Anchor>
        <DocCode>{`<Anchor url="#">{children}</Anchor>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>External</DocHeading>
        <Anchor isExternal={true} url="#">
          <PlxsText>This link will open in a new tab.</PlxsText>
        </Anchor>
        <DocCode>{`<Anchor isExternal={true} url="#">{children}</Anchor>`}</DocCode>
      </DocRow>
    </DocRow>
  </DocRow>
);
