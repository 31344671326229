import React from 'react';
import { DocRow } from '@dev/components';

const Layout = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <p>
          We are using React Bootstrap to define layouts for our UI. Please
          refer to the documentation{' '}
          <a
            href="https://react-bootstrap-v3.netlify.app/layout/grid/"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </DocRow>
    </DocRow>
  );
};

export default Layout;
