import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import Examples from './Examples';

const bestPractice = {
  doList: [
    'Use spotlight icons to drive emotional connections with users when an action is successful',
    'Use spotlight icons when cards or modals have no content to grab user attention',
    'Use spotlight icons in global navigation to highlight features we wish to drive users to as part of product strategy e.g. inviting, onboarding, upgrading account',
  ],
  doNotList: [
    'Do not use spotlight icons when cards or modals are filled with content as they take up large amounts of space',
    'Do not use spotlight icons in any context where the user will have to click/tap to complete an action - use buttons or links instead',
    'Do not use spotlight icons for input errors, warnings or system instructions - use input error states, toasts or callouts instead',
  ],
};

const description = '';

const example = (
  <Fragment>
    <Examples />
  </Fragment>
);

const implementation = `import React from 'react';
import { PlxsSpotlightIcon } from '@plexus-ui/index';

export default () => (
  <PlxsSpotlightIcon icon="invite" tone="brand" />
);`;

const propList = [
  {
    component: 'SpotlightIcon',
    description: 'renders the icon type',
    isRequired: true,
    prop: 'icon',
    type: '"invite" | "message" | "notifications" | "recentlyViewed" | "share" | "tasks" | "user"',
  },
  {
    component: 'SpotlightIcon',
    description:
      'sets the icon and background circle fill to the appropriate tone',
    isRequired: true,
    prop: 'tone',
    type: '"brand" | "positive" | "urgent"',
  },
  {
    component: 'SpotlightIcon',
    description:
      'if provided, adjusts the icon colors for use on darker backgrounds',
    isRequired: false,
    prop: 'variant',
    type: '"white"',
  },
];

const tsInterface = `interface ComponentProps {
  icon: Icon;
  tone: Tone;
  variant?: Variant;
}`;

export default () => {
  return (
    <PageTemplate
      bestPractice={bestPractice}
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
