import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ComponentProps } from './Toast.types';
import { useStyles } from './Toast.styles';
import MessagePanel from '../_internal/MessagePanel';
import { BRAND } from '../../_constants';
import PlxsButtonGroup from '../ButtonGroup';
import PlxsButton from '../Button';
import { Slide, useMediaQuery } from '@material-ui/core';
import { breakpoints } from '../../styles';
import { TOAST_VISIBILITY_MS } from './Toast.constants';

const Toast: FC<ComponentProps> = ({
  autoDismissVisibleDuration,
  canManuallyDismiss,
  children,
  dataTestId,
  id,
  icon,
  isAutoDismiss = false,
  isVisible,
  onDismiss,
  onTransitionedIn,
  onTransitionedOut,
  onUndo,
  tone = BRAND,
}) => {
  const isSmallScreen = !useMediaQuery(`(min-width:${breakpoints.md})`);
  const classes = useStyles({ tone, isSmallScreen });
  const canUndo = Boolean(onUndo);
  const canAction = canManuallyDismiss || canUndo;

  const autoDismissTimer = useRef(null);
  const [isAutoDismissPaused, setIsAutoDismissPaused] = useState(false);

  const handleUndoButtonClick = (event) => {
    if (canUndo) {
      onUndo(event);
    }
  };

  const handleMouseEnter = () => {
    if (isAutoDismiss) {
      setIsAutoDismissPaused(true);
    }
  };

  const handleMouseLeave = () => {
    if (isAutoDismiss) {
      setIsAutoDismissPaused(false);
    }
  };

  const clearAutoDismissTimer = () => {
    clearTimeout(autoDismissTimer.current);
  };

  const setAutoDismissTimer = useCallback(() => {
    clearAutoDismissTimer();
    autoDismissTimer.current = setTimeout(
      onDismiss,
      autoDismissVisibleDuration || TOAST_VISIBILITY_MS
    );
  }, [onDismiss, autoDismissVisibleDuration]);

  useEffect(() => {
    if (isVisible && isAutoDismiss && !isAutoDismissPaused) {
      setAutoDismissTimer();
    } else {
      clearAutoDismissTimer();
    }

    return clearAutoDismissTimer;
  }, [isVisible, isAutoDismiss, isAutoDismissPaused, setAutoDismissTimer]);

  return (
    <div
      className={classes.root}
      id={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Slide
        direction="up"
        in={isVisible}
        mountOnEnter
        onEntered={onTransitionedIn}
        onExited={onTransitionedOut}
        unmountOnExit
      >
        <div className={classes.contentWrapper}>
          <div className={classes.messagePanelWrapper}>
            <MessagePanel
              dataTestId={`${dataTestId}-messsage`}
              icon={icon}
              tone={tone}
            >
              {children}
            </MessagePanel>
          </div>
          {canAction && (
            <div className={classes.buttonGroupWrapper}>
              <PlxsButtonGroup>
                {canManuallyDismiss && (
                  <PlxsButton
                    dataTestId={`${dataTestId}-dismiss-button`}
                    functionalIcon="cross"
                    label="Dismiss"
                    onClick={onDismiss}
                    tone="neutral"
                    variant="secondary"
                  />
                )}
                {canUndo && (
                  <PlxsButton
                    dataTestId={`${dataTestId}-undo-button`}
                    functionalIcon="undo"
                    label="Undo"
                    onClick={handleUndoButtonClick}
                    tone="urgent"
                    variant="secondary"
                  />
                )}
              </PlxsButtonGroup>
            </div>
          )}
        </div>
      </Slide>
    </div>
  );
};

export default Toast;
