import React, { FC, useEffect, useRef } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import PlxsButton from '../../../Button';
import { Fade } from '@material-ui/core';
import { getDateString, isYearOutOfRange } from '../../_utils';

const YEARS_PER_ROW = 4;
const ROWS_BEFORE_AND_AFTER = 6;

const YearPicker: FC<ComponentProps> = ({
  dataTestId,
  date,
  isOpen,
  maxDate,
  minDate,
  onToggle,
  onYearClick,
}) => {
  const classes = useStyles();

  const yearsWrapperRef = useRef(null);

  const selectedYear = date.getFullYear();
  const rowNumber = Math.floor(selectedYear / YEARS_PER_ROW);
  const firstRow = rowNumber - ROWS_BEFORE_AND_AFTER;
  const lastRow = rowNumber + ROWS_BEFORE_AND_AFTER;

  const rows = [];
  const columns = Array.from(Array(YEARS_PER_ROW).keys());
  for (let i = firstRow; i <= lastRow; i++) {
    rows.push(i);
  }

  const handleYearClick = (year: number, isDisabled: boolean) => () => {
    if (!isDisabled) {
      const newDate = new Date(year, date.getMonth());
      onYearClick(newDate);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const { offsetHeight } = yearsWrapperRef.current;
      yearsWrapperRef.current.scroll(0, 0.3 * offsetHeight);
    }
  }, [isOpen]);

  return (
    <div className={classes.root}>
      <PlxsButton
        dataTestId={`${dataTestId}-year-picker-toggle-button`}
        functionalIcon={isOpen ? 'chevronUp' : 'chevronDown'}
        label={getDateString(date, 'MMMM yyyy')}
        onClick={onToggle}
        size="sm"
        tone="brand"
        variant="link"
      />
      <Fade in={isOpen} mountOnEnter unmountOnExit>
        <div className={`${classes.yearsWrapper}`} ref={yearsWrapperRef}>
          {rows.map((row) => (
            <div className={classes.yearsRowWrapper} key={row}>
              {columns.map((column) => {
                const year = row * YEARS_PER_ROW + column;
                const isDisabled = isYearOutOfRange(year, minDate, maxDate);
                const isSelected = selectedYear === year;
                const classNames = [classes.yearWrapper];
                isSelected && classNames.push(classes.selected);
                isDisabled && classNames.push(classes.disabled);

                return (
                  <div
                    className={classNames.join(' ')}
                    key={year}
                    onClick={handleYearClick(year, isDisabled)}
                  >
                    {year}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default YearPicker;
