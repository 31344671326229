import { makeStyles } from '@material-ui/core/styles';
import { breakpoints } from '@plexus-ui/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const ACTIVE_TEXT = 'rgba(255, 127, 0, 0.3)';
const ACTIVE_BACKGROUND = 'rgba(255, 127, 0, 0.1)';
const INACTIVE_TEXT = 'rgba(255, 0, 0, 0.3)';
const INACTIVE_BACKGROUND = 'rgba(255, 0, 0, 0.1)';

function createBreakpointAreaStyles(
  index: number,
  minWidth: string | number,
  maxWidth: string | number
): CSSProperties {
  const areaCount = 5;
  const areaHeight = 100 / areaCount;

  return {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10em',
    fontSize: '2em',
    left: '0px',
    top: `${index * areaHeight}vh`,
    height: `${areaHeight}vh`,
    pointerEvents: 'none',
    width: maxWidth,
    color: INACTIVE_TEXT,
    backgroundColor: INACTIVE_BACKGROUND,
    [`@media (min-width:${minWidth})`]: {
      color: ACTIVE_TEXT,
      backgroundColor: ACTIVE_BACKGROUND,
    },
    [`@media (min-width:${maxWidth})`]: {
      color: INACTIVE_TEXT,
      backgroundColor: INACTIVE_BACKGROUND,
    },
  };
}

export const useStyles = makeStyles({
  sm: createBreakpointAreaStyles(0, 0, breakpoints.sm),
  md: createBreakpointAreaStyles(1, breakpoints.sm, breakpoints.md),
  lg: createBreakpointAreaStyles(2, breakpoints.md, breakpoints.lg),
  xl: createBreakpointAreaStyles(3, breakpoints.lg, breakpoints.xl),
  xxl: createBreakpointAreaStyles(4, breakpoints.xl, breakpoints.xxl),
});
