import React from 'react';
import {
  DocBox,
  DocCode,
  DocParagraph,
  DocPropGroupHeading,
  DocRow,
  DocSubHeading,
} from '@dev/components';

import IconRightTextField from './IconRight';
import IconRightButtonTextField from './IconRightButton';
import InstructionTextField from './Instruction';
import MaxLengthTextField from './MaxLength';
import ResetTextField from './Reset';
import SearchTextField from './SearchField';
import DefaultActive from './DefaultActive';

export default () => {
  return (
    <DocRow margin="lg">
      <DocPropGroupHeading>Other props</DocPropGroupHeading>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Auto Focus</DocSubHeading>
          <DocParagraph>
            Setting autoFocus to true will focus the TextField on load.
          </DocParagraph>
        </DocRow>
        <DocCode>{`<PlxsTextField
  autoFocus={true}
  dataTestId="auto-focus-text-field"
  label="Input name to go here"
  onChange={handleChange}
  placeholder="Field helper text"
  value={value}
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Instruction text</DocSubHeading>
          <DocParagraph>
            Renders an additional message in small font directly below the
            TextField and above the error message (if displayed).
          </DocParagraph>
        </DocRow>
        <InstructionTextField />
        <DocCode>{`<PlxsTextField
  dataTestId="instruction-text-field"
  instructionText="Additional instructions go here"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Max length</DocSubHeading>
          <DocParagraph>Sets a max character length on the field.</DocParagraph>
        </DocRow>
        <MaxLengthTextField />
        <DocCode>{`<PlxsTextField
  dataTestId="max-length-text-field"
  label="Input name to go here"
  maxLength={20}
  onChange={handleChange}
  placeholder="Field helper text"
  value={value}
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Placeholder text</DocSubHeading>
          <DocParagraph>
            Renders placeholder text inside the TextField. For the dynamic label
            variant the placeholder text will be replaced by the dynamic label.
          </DocParagraph>
        </DocRow>
        <DocCode>{`<PlxsTextField
  dataTestId="placeholder-text-field"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Reset</DocSubHeading>
          <DocParagraph>
            Renders a reset button inside the TextField when the field is not
            disabled and has a value. The parent component is required to handle
            onReset to clear the TextField value.
          </DocParagraph>
        </DocRow>
        <ResetTextField />
        <DocCode>{`<PlxsTextField
  dataTestId="reset-text-field"
  label="Input name to go here"
  onChange={() => {}}
  onReset={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Custom decorative icon on the right</DocSubHeading>
          <DocParagraph>
            Renders a decorative icon component on the right hand side of the
            TextField. The parent component is required to update the tone to
            match the state of the input as required. To fit nicely within the
            text field, the component size must be set to "sm".
          </DocParagraph>
        </DocRow>
        <IconRightTextField />
        <DocCode>{`<PlxsTextField
  iconRight={
    <PlxsIcon
      name="search"
      size="sm"
      tone={tone}
    />
  }
  dataTestId="decorative-icon-text-field"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>
            Custom functional icon button on the right
          </DocSubHeading>
          <DocParagraph>
            Renders a functional icon button component on the right hand side of
            the TextField. The parent component is required to handle the
            onClick/onMouseDown handler, as well as update the tone and whether
            or not it is disabled to match the state of the input as required.
            To fit nicely within the text field, the component size must be set
            to "sm".
          </DocParagraph>
        </DocRow>
        <IconRightButtonTextField />
        <DocCode>{`<PlxsTextField
  iconRight={
    <PlxsButton
      dataTestId="text-field-calendar-button"
      functionalIcon="calendar"
      isIconOnly
      label=""
      size="sm"
      tone={tone}
      variant="link"
    />
  }
  dataTestId="decorative-icon-text-field"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Search Field</DocSubHeading>
          <DocParagraph>Example as search field</DocParagraph>
        </DocRow>
        <DocBox>
          <SearchTextField />
        </DocBox>
        <DocCode>{`<PlxsTextField
  iconRight={searchIcon}
  dataTestId="icon-right-text-field"
  isInline={true}
  onBlur={() => setIsFocused(false)}
  onChange={handleChange}
  onFocus={() => setIsFocused(true)}
  placeholder="Search for something"
  styleOverrides={!isFocused && styleOverrides}
  value={value}
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Active by Default</DocSubHeading>
          <DocParagraph>TextField is active by default</DocParagraph>
        </DocRow>
        <DefaultActive />
        <DocCode>{`<PlxsTextField
  dataTestId="default-active-text-field"
  autoFocus={true}
  label="Input name to go here"
  onChange={handleChange}
  placeholder="Field helper text"
  value={value}
/>`}</DocCode>
      </DocRow>
    </DocRow>
  );
};
