import React, { FC, useEffect, useRef, useState } from 'react';

import InternalFieldWrapper from '../_internal/FieldWrapper';

import { ComponentProps } from './Textarea.types';
import { useStyles } from './Textarea.styles';

const TextField: FC<ComponentProps> = ({
  autoFocus,
  dataTestId,
  errorMessage,
  hasError,
  id,
  inputRef,
  instructionText,
  isAutoResizing,
  isDisabled,
  isInline,
  isResizable,
  label,
  labelVariant = 'static',
  onBlur,
  onChange,
  onFocus,
  padding = 'md',
  placeholder,
  readOnly,
  secondaryLabel,
  secondaryLabelUrl,
  value,
}) => {
  const classes = useStyles({
    isDisabled,
    isResizable,
    padding,
  });

  const [isActive, setIsActive] = useState(false);

  const thisRef = useRef<HTMLTextAreaElement | null>(null);

  const isDynamicLabelMinimised = isActive || Boolean(value);
  const ref = inputRef ? inputRef : thisRef;
  const rows = isAutoResizing ? 1 : 5;
  const styles = isAutoResizing ? { minHeight: 0, overflow: 'hidden' } : {};

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur();
    }
    setIsActive(false);
  };

  const handleFocus = () => {
    if (typeof onFocus === 'function') {
      onFocus();
    }
    setIsActive(true);
  };

  useEffect(() => {
    if (isAutoResizing && thisRef && thisRef.current) {
      const { style } = thisRef.current;
      style.height = '0px';
      const { scrollHeight } = thisRef.current;
      style.height = `${scrollHeight}px`;
    }
  }, [isAutoResizing, value]);

  return (
    <InternalFieldWrapper
      errorMessage={errorMessage}
      hasError={hasError}
      instructionText={instructionText}
      isActive={isActive}
      isDisabled={isDisabled}
      isDynamicLabelMinimised={isDynamicLabelMinimised}
      isInline={isInline}
      label={label}
      labelVariant={labelVariant}
      placeholder={placeholder}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
    >
      <textarea
        autoFocus={autoFocus}
        className={classes.root}
        data-testid={dataTestId}
        disabled={isDisabled}
        id={id}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={ref}
        rows={rows}
        style={styles}
        value={value}
      />
    </InternalFieldWrapper>
  );
};

export default TextField;
