import React, { FC, useState, KeyboardEvent } from 'react';
import { useStyles } from './styles';
import { ComponentProps } from './types';

import { PlxsChip, PlxsSelect } from '@plexus-ui/index';
import LockedCell from './LockedCell';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const DropDownCell: FC<ComponentProps> = ({
  label,
  value,
  editIcon,
  isEditable,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [cellValue, setCellValue] = useState(value);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditMode(false);
    }
  };

  const handleSelection = (option) => {
    setCellValue(option.value);
    setSelectedOption(option);
    setIsEditMode(false);
  };

  const options = [
    {
      label: '1CategorySpamName',
      value: '1CategorySpamName',
    },
    {
      label: 'New Category',
      value: 'New Category',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];

  return (
    <ClickAwayListener onClickAway={() => setIsEditMode(false)}>
      <div className={classes.editableCell} onKeyDown={handleKeyDown}>
        {!isEditable ? (
          <LockedCell value={cellValue} />
        ) : isEditMode ? (
          <PlxsSelect
            dataTestId={label}
            isInline
            getOptionLabel={(option) => option.label}
            isDefaultActive={true}
            onSelect={handleSelection}
            options={options}
            placeholder={cellValue}
            selected={selectedOption}
          />
        ) : (
          <PlxsChip
            label={cellValue}
            hasHover={true}
            functionalIcon={editIcon}
            onFunctionalIconClick={() => {
              setIsEditMode(true);
            }}
            onChipClick={() => {
              setIsEditMode(true);
            }}
            tone="brand"
            variant="plainText"
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DropDownCell;
