import { makeStyles } from '@material-ui/core/styles';

import { color, zIndex } from '../../styles';
import { defaultTextStyle } from '../../styles/text';
import {
  inputActive,
  inputDefault,
  inputDisabled,
  inputError,
  inputReset,
} from '../../styles/input';
import { neutral } from '../../styles/color';

import { StyleProps } from './RichTextEditor.types';

const dropdownFontSize = `${defaultTextStyle.fontSize} !important`;

const getBorderColor = (props: StyleProps) => {
  if (props.isDisabled) {
    return inputDisabled.borderColor;
  }
  if (props.isActive) {
    return inputActive.borderColor;
  }
  if (props.hasError) {
    return inputError.borderColor;
  }
  return inputDefault.borderColor;
};

const statusbarStyles = (props: StyleProps) => {
  const defaultStyles = {
    border: `1px solid ${getBorderColor(props)}`,
    borderRadius: '3px',
  };

  const resizeStyles = {
    ...defaultStyles,
    borderTop: 'none',
    borderRadius: '0 0 3px 3px',
  };

  return props.hasResize ? resizeStyles : defaultStyles;
};

const editAreaStyles = (props: StyleProps) => {
  const defaultStyles = {
    border: `1px solid ${getBorderColor(props)}`,
    borderRadius: '3px',
  };

  const resizeStyles = {
    ...defaultStyles,
    borderBottom: 'none',
    borderRadius: '3px 3px 0 0',
  };

  return props.hasResize ? resizeStyles : defaultStyles;
};

export const useStyles = makeStyles(() => ({
  '@global': {
    '.tox.tox-silver-sink.tox-tinymce-aux': {
      zIndex: zIndex.richTextEditor,
      '& h1': {
        fontSize: dropdownFontSize,
      },
      '& h2': {
        fontSize: dropdownFontSize,
      },
      '& h3': {
        fontSize: dropdownFontSize,
      },
      '& p': {
        fontSize: dropdownFontSize,
      },
    },
  },
  root: (props: StyleProps) => ({
    '& .tox-tinymce': {
      ...inputReset,
      display: 'flex',
      padding: '0',
    },
    '& .tox :not(svg):not(rect)': {
      fontFamily: defaultTextStyle.fontFamily,
      fontSize: defaultTextStyle.fontSize,
    },
    '& .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)': {
      borderRightColor: getBorderColor(props),
    },
    '& .tox .tox-toolbar': {
      background: 0,
    },
    '& .tox .tox-toolbar__group': {
      marginBottom: '4px',
      padding: 0,
    },
    '& .tox .tox-tbtn': {
      border: `1px solid ${neutral[600]}`,
      color: neutral[200],
      margin: '0 4px 4px 0',
    },
    '& .tox .tox-tbtn:hover': {
      background: neutral[600],
      border: `1px solid ${neutral[600]}`,
      color: neutral[200],
    },
    '& .tox-tinymce--disabled .tox-tbtn__select-label': {
      color: neutral[400],
      cursor: 'not-allowed',
    },
    '& .tox .tox-split-button': {
      margin: '0 4px 4px 0',
    },
    '& .tox .tox-split-button:hover': {
      cursor: 'pointer',
    },
    '& .tox .tox-tbtn svg': {
      fill: neutral[200],
    },
    '& .tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg':
      {
        fill: neutral[400],
      },
    '& .tox .tox-sidebar-wrap': {
      cursor: 'text',
    },
    '& .tox .tox-edit-area': {
      ...editAreaStyles(props),
    },
    '& .tox .tox-statusbar': {
      ...statusbarStyles(props),
    },
    '& .tox-tinymce--disabled .tox-statusbar': {
      backgroundColor: neutral[800],
    },
    '& textarea': {
      opacity: 0,
    },
  }),
}));

// Any updates to these styles should also be made to
// RichTextContent/RichTextContent.styles.ts
// useStyles
export const editorStyles = `
body {
  background-color: ${color.white};
  color: ${defaultTextStyle.color};
  font-family: ${defaultTextStyle.fontFamily};
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 18px;
  margin: 10px 15px;
}
h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 4px;
  margin-top: 0;
}
h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px;
  margin-top: 0;
}
h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: 0;
}
h4, h5, h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: 0;
}
ul, ol {
  padding-left: 40px;
  margin-bottom: 16px;
  margin-top: 0;
}
li {
  margin-bottom: 4px;
}
p {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 16px;
  margin-top: 0;
}
body.mce-content-readonly {
  background-color: ${inputDisabled.backgroundColor};
  color: ${inputDisabled.color};
}
body.mce-content-readonly:hover {
  cursor: not-allowed;
}
`;
