import React, { FC } from 'react';
import classNames from 'classnames';

import IndicatorLabel from '../../../_internal/IndicatorLabel';
import TabIcon from '../TabIcon';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Tab: FC<ComponentProps> = ({
  ariaControls,
  icon,
  iconTone,
  indicatorLabel,
  indicatorTone,
  isActive,
  isFirstTab,
  isLastTab,
  itemId,
  label,
  onClick,
}) => {
  const classes = useStyles({ isActive });

  const tabClasses = classNames(classes.tab, {
    [classes.firstTab]: isFirstTab && !isLastTab,
    [classes.lastTab]: isLastTab && !isFirstTab,
    [classes.otherTab]: !isFirstTab && !isLastTab,
  });

  return (
    <div
      data-testid={itemId}
      aria-controls={ariaControls}
      aria-selected={isActive}
      className={tabClasses}
      id={itemId}
      onClick={onClick}
      role="tab"
    >
      <TabIcon isActive={isActive} name={icon} tone={iconTone} />
      <div className={classes.tabLabel}>{label}</div>
      <IndicatorLabel
        isActive={isActive}
        label={indicatorLabel}
        tone={indicatorTone}
      />
      <span className={classes.tabBorder} />
    </div>
  );
};

export default Tab;
