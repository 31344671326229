import { makeStyles } from '@material-ui/core/styles';
import { spacing } from '@plexus-ui/styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    '& > div': {
      marginBottom: spacing[props.margin],
    },
  }),
}));
