import React, { FC, MouseEvent } from 'react';

import { ComponentProps } from './LegacyButton.types';
import { useStyles } from './LegacyButton.styles';

const LegacyButton: FC<ComponentProps> = ({
  children,
  dataTestId,
  handleClick,
  hasCaret,
  id,
  isActive,
  isDisabled,
}) => {
  const classes = useStyles({ isActive, isDisabled });

  const handleOnClickEvent = (event: MouseEvent) => {
    if (typeof handleClick === 'function') {
      return isDisabled ? null : handleClick(event);
    }
    return null;
  };

  return (
    <button
      className={classes.root}
      data-testid={dataTestId}
      id={id}
      onClick={handleOnClickEvent}
      type="button"
    >
      <span>{children}</span>
      {hasCaret && <span className={classes.caret} />}
    </button>
  );
};

export default LegacyButton;
