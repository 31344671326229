import {
  format,
  isValid,
  startOfDay,
  startOfMonth,
  isAfter,
  isBefore,
  parse,
  isMatch,
} from 'date-fns';
import { DateRange } from '../DatePicker.types';
import { TimePeriodType } from '../TimePeriod/TimePeriod.types';

export function getDateString(
  value: Date | DateRange | undefined,
  dateFormat: string
): string {
  if (!value) {
    return '';
  } else if (isDate(value)) {
    return format(value, dateFormat);
  } else {
    return `${format(value.from, dateFormat)} - ${format(
      value.to,
      dateFormat
    )}`;
  }
}

export function getStartOfMonth(date: Date): Date {
  return startOfMonth(date);
}

export function isYearOutOfRange(
  year: number,
  minDate?: Date,
  maxDate?: Date
): boolean {
  const hasMinDate = Boolean(minDate);
  const hasMaxDate = Boolean(maxDate);

  return (
    (hasMinDate && year < minDate.getFullYear()) ||
    (hasMaxDate && year > maxDate.getFullYear())
  );
}

export function isDate(value?: Date | DateRange): value is Date {
  return Boolean(value) && 'getMonth' in value;
}

export function isDateRange(value?: Date | DateRange): value is DateRange {
  return Boolean(value) && !('getMonth' in value);
}

export function isBeforeDate(
  value: Date | DateRange | undefined,
  date?: Date
): boolean {
  if (date && value) {
    if (isDate(value)) {
      return isBefore(startOfDay(value), startOfDay(date));
    } else {
      return (
        isBefore(startOfDay(value.from), startOfDay(date)) ||
        isBefore(startOfDay(value.to), startOfDay(date))
      );
    }
  }
  return false;
}

export function isAfterDate(
  value: Date | DateRange | undefined,
  date?: Date
): boolean {
  if (date && value) {
    if (isDate(value)) {
      return isAfter(startOfDay(value), startOfDay(date));
    } else {
      return (
        isAfter(startOfDay(value.from), startOfDay(date)) ||
        isAfter(startOfDay(value.to), startOfDay(date))
      );
    }
  }
  return false;
}

function getDateTime(dateString: string, dateFormat: string): Date | undefined {
  if (!isMatch(dateString, dateFormat)) {
    return undefined;
  }

  const date = parse(dateString, dateFormat, 0);

  return date;
}

export function parseInputText(
  inputText: string | undefined,
  dateFormat: string,
  isTimeEditable: boolean = false
): Date | DateRange | undefined {
  if (inputText) {
    if (isTimeEditable) {
      return getDateTime(inputText, dateFormat);
    }

    const inputDates: Date[] = inputText.split(' - ').map((inputTextDate) => {
      if (inputTextDate) {
        const date = parse(
          // Replace any empty spaces with something else e.g. underscore, otherwise it can be
          // misinterpreted as a '0' and may result in a valid date - which we don't want.
          inputTextDate.replace(' ', '_'),
          dateFormat,
          0
        );

        return isValid(date) ? date : undefined;
      }
    });

    if (inputDates.length) {
      if (inputDates.length === 1) {
        return inputDates[0];
      } else if (inputDates.every((date) => date)) {
        return {
          from: inputDates[0],
          to: inputDates[1],
        };
      }
    }
  }
}

export function getDateOrDateRange(
  currentValue: Date | DateRange | undefined,
  selectedDate: Date
): Date | DateRange {
  if (
    !currentValue ||
    isDateRange(currentValue) ||
    isBefore(selectedDate, currentValue)
  ) {
    return selectedDate;
  } else {
    return { from: currentValue, to: selectedDate };
  }
}

export function getErrorMessage(
  value?: Date | DateRange,
  dateFormat?: string,
  minimumDate?: Date,
  maximumDate?: Date
): string | undefined {
  if (!value) {
    return 'Please enter a valid date';
  } else if (isDateRange(value) && isAfter(value.from, value.to)) {
    return 'Start date must be before end date';
  } else if (isBeforeDate(value, minimumDate)) {
    return `Please enter a date after ${getDateString(
      minimumDate,
      dateFormat
    )}`;
  } else if (isAfterDate(value, maximumDate)) {
    return `Please enter a date before ${getDateString(
      maximumDate,
      dateFormat
    )}`;
  }
}

export const formatValue = (value: string, maxNumber: number) => {
  if (value.length === 1) {
    return `0${value}`;
  }

  return Number(value) > maxNumber ? String(maxNumber) : value;
};

export const isNumber = (value: string) => {
  return !isNaN(Number(value));
};

export const increaseValue = (value: string, maxNumber: number) => {
  if (Number(value) < maxNumber) {
    return String(Number(value) + 1);
  }

  return value;
};

export const decreaseValue = (value: string, minNumber: number) => {
  if (Number(value) > minNumber) {
    return String(Number(value) - 1);
  }

  return value;
};

export const formatTime = 'hh:mm a';
