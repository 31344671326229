import React, { FC } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsLegacyButton } from '@plexus-ui/index';

const LegacyButtonDocs: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <PlxsLegacyButton dataTestId="edit-document-button">
          Edit Document
        </PlxsLegacyButton>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>With Caret</DocHeading>
        <PlxsLegacyButton dataTestId="edit-document-button" hasCaret={true}>
          Versions
        </PlxsLegacyButton>
      </DocRow>
    </DocRow>
  );
};

export default LegacyButtonDocs;
