import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle } from '../../styles/text';
import { getTextInputStyles } from '../../styles/input';
import { toneBg } from '../../styles/tone';

import { StyleProps } from './AutoComplete.types';

// The CSS properties below are applied directly to the react-autosuggest component.
export const useStyles = makeStyles(() => ({
  container: {},
  containerOpen: {},
  input: (props: StyleProps) => ({
    ...getTextInputStyles({ isDisabled: props.isDisabled }),
    paddingRight: '55px',
    position: 'relative',
  }),
  inputOpen: {},
  inputFocused: {},
  suggestionsContainer: {},
  suggestionsContainerOpen: {},
  suggestionsList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  suggestion: {
    ...defaultTextStyle,
    cursor: 'pointer',
    padding: '8px 16px',
  },
  suggestionFirst: {},
  suggestionHighlighted: {
    backgroundColor: toneBg.brand,
  },
  sectionContainer: {},
  sectionContainerFirst: {},
  sectionTitle: {},
}));
