import React, { FC } from 'react';
import { DocHeading, DocPlaceholderText, DocRow } from '@dev/components';
import { PlxsAlert, PlxsRow, PlxsText } from '@plexus-ui/index';

const Alert: FC = () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocHeading>Success</DocHeading>
      <PlxsRow>
        <PlxsAlert variant="success">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>With outline</DocHeading>
      <PlxsRow>
        <PlxsAlert hasOutline variant="success">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>Warning</DocHeading>
      <PlxsRow>
        <PlxsAlert variant="warning">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>With outline</DocHeading>
      <PlxsRow>
        <PlxsAlert hasOutline variant="warning">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>Error</DocHeading>
      <PlxsRow>
        <PlxsAlert variant="error">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>With outline</DocHeading>
      <PlxsRow>
        <PlxsAlert hasOutline variant="error">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>Full width</DocHeading>
      <PlxsRow>
        <PlxsAlert isFullWidth variant="success">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>

    <DocRow margin="lg">
      <DocHeading>Full width, content centered</DocHeading>
      <PlxsRow>
        <PlxsAlert hasTextAlignCenter isFullWidth variant="success">
          <PlxsText>
            <DocPlaceholderText length="short" />
          </PlxsText>
        </PlxsAlert>
      </PlxsRow>
    </DocRow>
  </DocRow>
);

export default Alert;
