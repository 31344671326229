import { makeStyles } from '@material-ui/core/styles';

import { tone } from '../../styles/tone';
import { zIndex } from '../../styles';

import { StyleProps } from './Toast.types';

export const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: '32px',
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
    maxWidth: '896px',
    width: '100%',
    zIndex: zIndex.toast,
  },
  contentWrapper: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: props.isSmallScreen ? 'wrap' : 'initial',
    padding: '8px 8px',
    background: 'white',
    border: '1px solid transparent',
    borderColor: tone[props.tone],
    borderRadius: '3px',
    boxShadow: '0 4px 12px rgba(29, 58, 88, 0.08)',
  }),
  buttonGroupWrapper: (props: StyleProps) => ({
    padding: props.isSmallScreen ? '8px' : '8px 24px',
  }),
  messagePanelWrapper: (props: StyleProps) => ({
    flexBasis: props.isSmallScreen ? '100%' : 'initial',
    flexGrow: 1,
    padding: '8px',
  }),
});
