import { Route as RouteInterface } from '@dev/types';
import color from '@dev/routes/color';
import componentList from '@dev/routes/componentList';
import componentListDeprecated from '@dev/routes/componentListDeprecated';
import componentListInternal from '@dev/routes/componentListInternal';
import componentListToBeUpdated from '@dev/routes/componentListToBeUpdated';
import icons from '@dev/routes/icons';
import overview from '@dev/routes/overview';
import sandbox from '@dev/routes/sandbox';

import DesignSystem from '../AppContent/DesignSystem';

const components: RouteInterface[] = [
  ...overview,
  ...color,
  ...componentList,
  ...componentListDeprecated,
  ...componentListInternal,
  ...componentListToBeUpdated,
  ...icons,
  ...sandbox,
  {
    component: DesignSystem,
    path: '/',
    name: 'Plexus Design System',
  },
];

export default components;
