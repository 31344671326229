import {
  BRAND,
  CAUTION,
  DARK,
  DISABLED,
  NEUTRAL,
  POSITIVE,
  URGENT,
  WHITE,
} from '../_constants';

import { brand, caution, neutral, positive, urgent, white } from './color';

// Tone as foreground color
type Tone =
  | typeof BRAND
  | typeof CAUTION
  | typeof DARK
  | typeof DISABLED
  | typeof NEUTRAL
  | typeof POSITIVE
  | typeof URGENT
  | typeof WHITE;

export const tone: Record<Tone, string> = {
  brand: brand[400],
  caution: caution[400],
  dark: brand[100],
  disabled: neutral[400],
  neutral: neutral[200],
  positive: positive[400],
  urgent: urgent[400],
  white,
};

export const toneHover: Record<Tone, string> = {
  brand: brand[300],
  caution: caution[300],
  dark: brand[100], // No change
  disabled: neutral[400], // No change
  neutral: neutral[200], // No change
  positive: positive[300],
  urgent: urgent[300],
  white, // No change
};

// Tone as background color
type ToneBg =
  | typeof BRAND
  | typeof CAUTION
  | typeof DISABLED
  | typeof NEUTRAL
  | typeof POSITIVE
  | typeof URGENT;

export const toneBg: Record<ToneBg, string> = {
  brand: brand[900],
  caution: caution[900],
  disabled: neutral[800],
  neutral: neutral[800],
  positive: positive[900],
  urgent: urgent[900],
};

export const toneBgHover: Record<ToneBg, string> = {
  brand: brand[800],
  caution: caution[800],
  disabled: neutral[800], // No change
  neutral: neutral[500],
  positive: positive[800],
  urgent: urgent[800],
};
