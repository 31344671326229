import React, { FC } from 'react';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const Paragraph: FC<ComponentProps> = ({ children, margin }) => {
  const classes = useStyles({ margin });
  return <p className={classes.root}>{children}</p>;
};

export default Paragraph;
