import { makeStyles } from '@material-ui/core/styles';
import { color, fontColor } from '../../../styles';

export const useStyles = makeStyles({
  tableHeadLabel: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '18px',
    color: fontColor.default,
  },
  tableCellHead: {
    padding: '11px 8px',
    height: '56px',
    lineHeight: '100%',
    borderBottom: `2px solid ${color.neutralShade[800]}`,
    width: 'auto',
    minWidth: '50px',
    '& .MuiTableSortLabel-root': {
      padding: '4px 0 4px 8px',
      borderRadius: '3px',

      '& .MuiTableSortLabel-icon': {
        padding: '0 8px',
        margin: 0,
        lineHeight: 0,

        '& > svg': {
          color: color.neutralShade[200],
        },
      },
      '&:hover': {
        color: fontColor.default,
        backgroundColor: color.neutralShade[900],

        '& .MuiTableSortLabel-icon': {
          opacity: 1,

          '& > svg': {
            color: color.neutralShade[200],
          },
        },
      },
    },
  },
  cellWrapp: {
    display: 'flex',
    alignItems: 'center',
  },
  controlHeader: {
    display: 'flex',
  },
  textHeader: {},
});
