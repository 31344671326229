import React, { FC, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import { PlxsText, PlxsTextLink } from '@plexus-ui/index';

const Examples: FC = () => {
  const [handleOnClickCount, setHandleOnClickCount] = useState(0);

  const handleOnClick = () => {
    setHandleOnClickCount(handleOnClickCount + 1);
  };

  return (
    <DocRow margin="lg">
      <DocRow margin="lg">
        <DocHeading>Basic</DocHeading>
        <PlxsText>
          Paragraph text with <PlxsTextLink>text link</PlxsTextLink>.
        </PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Link with icon</DocHeading>
        <PlxsText>
          Paragraph text with{' '}
          <PlxsTextLink iconName="externalLink">external link</PlxsTextLink>.
        </PlxsText>
        <PlxsText>
          Paragraph text with{' '}
          <PlxsTextLink iconName="download">download link</PlxsTextLink>.
        </PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Link with click event handler</DocHeading>
        <PlxsText>
          This{' '}
          <PlxsTextLink handleOnClick={handleOnClick}>text link</PlxsTextLink>{' '}
          has been clicked {handleOnClickCount} times.
        </PlxsText>
      </DocRow>
    </DocRow>
  );
};

export default Examples;
