import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import VariantPaddingNone from './VariantPaddingNone';
import WithButtons from './WithButtons';
import WithSwitches from './WithSwitches';

const description = [
  'The Popover component provides a basic popup box implementation, positioned relative to the anchor or trigger element.',
  'The implementation is flexible so the content area can be customised by the consuming component.',
  'Best used as a solution for menu’s where the ActionMenu does not fit the requirements.',
];

const example = (
  <Fragment>
    <WithButtons />
    <WithSwitches />
    <VariantPaddingNone />
  </Fragment>
);

const implementation = `import React, { FC, Fragment, MouseEvent, useState } from 'react';
import {
  PlxsButton,
  PlxsHeading,
  PlxsPopover,
  PlxsRow,
  PlxsText,
} from '@plexus-ui/index';

const Popover: FC = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const isOpen = Boolean(anchorElement);

  return (
    <Fragment>
      <PlxsButton
        dataTestId="default-menu--button"
        onClick={handleOpen}
        id="default-menu-button"
        isActive={isOpen}
        label="Open"
      />
      <PlxsPopover
        anchorElement={anchorElement}
        dataTestId="default-menu"
        isOpen={isOpen}
        onClickAway={handleClose}
      >
        <Fragment>
          <PlxsRow spaceBelow="md">
            <PlxsHeading element="h2">Heading text</PlxsHeading>
          </PlxsRow>
          <PlxsRow spaceBelow="md">
            <PlxsText element="span">Sub heading text</PlxsText>
          </PlxsRow>
          <PlxsRow spaceBelow="md">
            <PlxsButton
              dataTestId="cta-one--button"
              label="Call to action"
              onClick={() => {}}
            />
          </PlxsRow>
          <PlxsRow spaceBelow="md">
            <PlxsButton
              dataTestId="cta-two--button"
              label="Call to action"
              onClick={() => {}}
              variant="secondary"
            />
          </PlxsRow>
          <PlxsRow>
            <PlxsButton
              dataTestId="cta-three--button"
              label="Call to action"
              onClick={() => {}}
              tone="urgent"
            />
          </PlxsRow>
        </Fragment>
      </PlxsPopover>
    </Fragment>
  );
};

export default Popover;
`;

const propList = [
  {
    component: 'Popover',
    description: 'the HTML element that anchors the positioning of the popover',
    isRequired: true,
    prop: 'anchorElement',
    type: 'HTMLElement',
  },
  {
    component: 'Popover',
    description: 'the internal content of the popup box',
    isRequired: true,
    prop: 'children',
    type: 'ReactNode',
  },
  {
    component: 'Popover',
    description: 'renders as data-testid on the parent div',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'Popover',
    description: 'the value used to open and close the popover',
    isRequired: true,
    prop: 'isOpen',
    type: 'boolean',
  },
  {
    component: 'Popover',
    description:
      'defines if the component remains mounted after transistioned out',
    isRequired: false,
    prop: 'isUnmountOnTransitionedOut',
    type: 'boolean',
  },
  {
    component: 'Popover',
    description:
      'function to handle closing the popover on clicking outside the content area',
    isRequired: true,
    prop: 'onClickAway',
    type: 'function',
  },
  {
    component: 'Popover',
    description: 'sets the position relative to the anchor element',
    isRequired: false,
    prop: 'placement',
    type: 'MenuPlacement: See _internal/Popover/types',
  },
  {
    component: 'Popover',
    description: 'provides component variants',
    isRequired: false,
    prop: 'variant',
    type: 'Variant: "paddingNone"',
  },
];

const tsInterface = `interface ComponentProps {
  anchorElement: HTMLElement;
  children: ReactNode;
  dataTestId: string;
  isOpen: boolean;
  isUnmountOnTransitionedOut?: boolean;
  onClickAway?: () => void;
  placement?: MenuPlacement;
  variant?: Variant;
}
`;

export default () => {
  return (
    <PageTemplate
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
