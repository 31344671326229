import React from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ButtonActive = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Active button</DocSubHeading>
        <DocParagraph>
          A button does not normally get marked as "active" by itself. This prop
          however can be useful in situations where the button is part of a
          complex component that is currently in focus to the user. An example
          of where this is used is in the ActionMenu where we indicate the
          button is "active" when the MenuList is visible on the screen. An
          active button is styled exactly the same as when the "hover" state.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Primary active</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-active-button"
              isActive={true}
              label="Primary button"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isActive={true}'}</DocCode>
          </div>
        </li>
        <li className={`${classes.listItem}`}>
          <div className={classes.displayName}>Primary white active</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-white-active-button"
              isActive={true}
              label="Primary button"
              tone="white"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isActive={true} tone="white"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Secondary active</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="secondary-active-button"
              isActive={true}
              label="Secondary button"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isActive={true} variant="secondary"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Secondary neutral active</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="secondary-active-button"
              isActive={true}
              label="Secondary button"
              tone="neutral"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'isActive={true} tone="neutral" variant="secondary"'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Link active</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="link-active-button"
              isActive={true}
              label="Link button"
              variant="link"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isActive={true} variant="link"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Icon only active</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="icon-only-active-button"
              functionalIcon="signature"
              isActive={true}
              isIconOnly={true}
              label="Icon only button"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isActive={true} isIconOnly={true}'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ButtonActive;
