import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f4',
    borderRight: '1px solid #e0e0e3',
    height: '100%',
    padding: '20px 20px 80px',
    position: 'relative',
  },
  heading: {
    fontSize: '13px',
    marginBottom: '20px',
  },
  list: {
    marginBottom: '33px',
  },
  listItem: {
    marginBottom: '7px',
  },
  button: {
    color: '#808090',
    fontSize: '13px',
    fontWeight: 500,
  },
});
