import { makeStyles } from '@material-ui/core/styles';
import { brand, neutral, urgent } from '../../../styles/color';
import { StyleProps } from './types';
import { URGENT } from '../../../_constants';

const getColor = (props: StyleProps) => {
  const { isDisabled, isDivider, tone } = props;

  if (isDivider) {
    return brand[100];
  }

  switch (tone) {
    case URGENT:
      return isDisabled ? urgent[800] : urgent[400];
    default:
      return isDisabled ? neutral[400] : brand[100];
  }
};

const getHighlightColor = (props: StyleProps) => {
  const { isDisabled, isDivider, isHighlighted, tone } = props;

  if (isDisabled || isDivider) {
    return 'transparent';
  }

  return tone === URGENT
    ? urgent[900]
    : isHighlighted
    ? brand[600]
    : brand[900];
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    color: getColor(props),
    cursor: props.isDisabled || props.isDivider ? 'not-allowed' : 'pointer',
    padding: `8px ${props.isDivider ? '0' : '16px'}`,
    userSelect: 'none',
    backgroundColor: props.isHighlighted
      ? getHighlightColor(props)
      : 'transparent',
    '&:focus': {
      outline: 'none',
      backgroundColor: getHighlightColor(props),
    },
    borderRadius: props.hasBorderRadius ? '3px' : 0,
  }),
  divider: {
    margin: 0,
    padding: 0,
  },
}));
