import React, { FC, Fragment } from 'react';
import { PlxsCallout, PlxsText, PlxsTextLink } from '@plexus-ui/index';

import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocPropGroupHeading,
  DocRow,
} from '@dev/components';

import CustomCallout, { usage } from './UsageExample';

const props = `interface ComponentProps {
  dataTestId: string;
  fitContentWidth?: boolean;
  icon?: IconName;
  message: ReactNode;
  onDismiss?: (event: MouseEvent) => void;
  supportingCopy?: ReactNode;
  tone?: Tone;
}`;

const Callout: FC = () => {
  const commonMessage =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

  const commonSupportingCopy = `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Vivamus eget lacus congue, tristique mi vel, rutrum orci. Maecenas quis libero pulvinar
    risus accumsan luctus et a ligula. Curabitur eu massa arcu. Aenean aliquam, turpis at porttitor
    eleifend, mauris erat scelerisque purus, eu vehicula enim metus blandit enim. Donec feugiat sem et
    semper faucibus. Sed interdum lobortis accumsan. Sed pharetra sit amet justo eu dictum.`;

  return (
    <Fragment>
      <DocRow margin="md">
        <DocRow margin="md">
          <DocHeading>Design guidelines</DocHeading>
          <DocParagraph>
            Use callouts when a page or modal requires persistent messaging to
            clarify how a feature works or give additional context to page
            content.
          </DocParagraph>
          <DocParagraph>
            Use tones to convey if page/modal content is locked, suspended, new,
            potentially overwritable or requires the user to acknowledge
            additional instructions in order to properly interact with it.
          </DocParagraph>
          <DocParagraph>
            Display callouts above page sections or directly below page
            headings, not in between inputs or pieces of body content.
          </DocParagraph>
          <DocParagraph>
            Keep callout content brief. As callouts have a colourful background,
            lengthy text inside them will distract users from their key
            objective. If lengthy instructions are required, use paragraph text.
          </DocParagraph>
          <DocParagraph>
            Limit bolded messages to one sentence to give callout information
            hierarchy.
          </DocParagraph>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Props</DocHeading>
          <DocCode>{props}</DocCode>
        </DocRow>

        <DocRow margin="md">
          <DocHeading>Usage</DocHeading>
          <CustomCallout />
          <DocCode>{usage}</DocCode>
        </DocRow>
      </DocRow>

      <DocPropGroupHeading>Message types</DocPropGroupHeading>

      <DocRow margin="sm">
        <DocHeading>Message</DocHeading>
        <PlxsCallout message="Message" dataTestId="callout-brand" />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Message with supporting copy</DocHeading>
        <PlxsCallout
          message="Message"
          dataTestId="callout-brand-with-supporting-copy"
          supportingCopy="Supporting copy"
        />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Message with supporting copy and links</DocHeading>
        <PlxsCallout
          message={
            <PlxsText weight="semiBold">
              Please check <PlxsTextLink>link</PlxsTextLink>.
            </PlxsText>
          }
          dataTestId="callout-brand-with-supporting-copy-and-links"
          supportingCopy={
            <PlxsText>
              For more information, check <PlxsTextLink>link</PlxsTextLink>.
            </PlxsText>
          }
        />
      </DocRow>

      <DocPropGroupHeading>Tones</DocPropGroupHeading>

      <DocRow margin="sm">
        <DocHeading>Brand (default)</DocHeading>
        <PlxsCallout message={commonMessage} dataTestId="callout-neutral" />
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-neutral-with-supporting-copy"
          supportingCopy={commonSupportingCopy}
        />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Caution</DocHeading>
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-caution"
          tone="caution"
        />
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-caution-with-supporting-copy"
          supportingCopy={commonSupportingCopy}
          tone="caution"
        />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Urgent</DocHeading>
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-urgent"
          tone="urgent"
        />
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-urgent-with-supporting-copy"
          supportingCopy={commonSupportingCopy}
          tone="urgent"
        />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Positive</DocHeading>
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-positive"
          tone="positive"
        />
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-positive-with-supporting-copy"
          supportingCopy={commonSupportingCopy}
          tone="positive"
        />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Neutral</DocHeading>
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-neutral"
          tone="neutral"
        />
        <PlxsCallout
          message={commonMessage}
          dataTestId="callout-neutral-with-supporting-copy"
          supportingCopy={commonSupportingCopy}
          tone="neutral"
        />
      </DocRow>

      <DocPropGroupHeading>Other props</DocPropGroupHeading>

      <DocRow margin="sm">
        <DocHeading>Fit content width</DocHeading>
        <PlxsCallout
          message="Short"
          dataTestId="callout-fit-content-width-short"
          supportingCopy="Lorem ipsum dolor."
          fitContentWidth
        />
        <PlxsCallout
          message="Long"
          dataTestId="callout-fit-content-width-long"
          supportingCopy={`Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Pellentesque eget massa pulvinar, facilisis mi eu, facilisis sapien.
          Donec luctus non tortor eget accumsan. Morbi auctor arcu ex, ac ultricies
          tellus semper pulvinar. Pellentesque egestas non tortor in maximus.
          Duis pellentesque magna ex, eu malesuada ipsum imperdiet eget. Ut consequat sollicitudin.`}
          fitContentWidth
        />
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Dismissable callout</DocHeading>
        <PlxsCallout
          dataTestId="callout-dismissable"
          message={commonMessage}
          onDismiss={() => {}}
          tone="brand"
        />
        <PlxsCallout
          dataTestId="callout-dismissable"
          message={commonMessage}
          onDismiss={() => {}}
          supportingCopy={commonSupportingCopy}
          tone="brand"
        />
      </DocRow>
    </Fragment>
  );
};

export default Callout;
