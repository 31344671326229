import { makeStyles } from '@material-ui/core/styles';

import { focusState } from '../../../styles';
import { getTextStyle } from '../../../styles/text';
import {
  INPUT_BORDER_RADIUS,
  INPUT_BORDER_WIDTH,
  INPUT_PADDING,
} from '../_styles';
import { neutral } from '../../../styles/color';

import {
  CALENDAR_BUTTON_HEIGHT,
  CALENDAR_ELEMENT_MARGIN_BOTTOM,
  cellDefault,
  cellDisabled,
  cellSelectable,
  cellSelected,
  cellSelectedRange,
} from './_styles';

interface StyleProps {
  mode?: 'single' | 'range';
}

export const useStyles = makeStyles({
  '@keyframes slideLeft': {
    from: { transform: 'translateX(75%)' },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes slideRight': {
    from: { transform: 'translateX(-75%)' },
    to: { transform: 'translateX(0)' },
  },
  daySlideLeft: { animation: '$slideLeft 200ms' },
  daySlideRight: { animation: '$slideRight 200ms' },
  todayButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  // Below are overridable classNames for the react-day-picker lib. All class names below must be present otherwise we
  // will get TS error. See: types/ClassNames.d.ts.
  container: {
    height: `calc(100% - ${CALENDAR_BUTTON_HEIGHT} - ${INPUT_BORDER_WIDTH})`,
    marginBottom: CALENDAR_ELEMENT_MARGIN_BOTTOM,
    userSelect: 'none',
  },
  wrapper: {
    height: '100%',

    '&:focus': {
      borderRadius: INPUT_BORDER_RADIUS,
      ...focusState,
    },
    '&:focus:not(:focus-visible)': {
      boxShadow: 'unset',
      outline: 'none',
    },
    '&:focus-visible': {
      borderRadius: INPUT_BORDER_RADIUS,
      ...focusState,
    },
  },
  interactionDisabled: {},

  // These are for the navigating forward/backwards in months. No need to define the styles here as we're doing that in
  // MonthNavigation styles.ts
  navBar: {},
  navButtonPrev: {},
  navButtonNext: {},
  navButtonInteractionDisabled: {},

  months: {
    display: 'flex',
    height: '100%',
  },
  month: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  caption: {},
  weekdays: {
    marginBottom: CALENDAR_ELEMENT_MARGIN_BOTTOM,
  },
  weekdaysRow: {
    display: 'flex',
    justifyContent: 'space-between',
    ...getTextStyle({ weight: 'semiBold' }),
  },
  weekday: {
    ...cellDefault,
    '& > abbr': {
      textDecoration: 'none',
      cursor: 'initial',
    },
  },
  weekNumber: {},

  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },

  week: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  day: { ...cellSelectable },

  // The footer & today button's styling. We're not using these because in the current version, we can't provide a
  // custom button component. We could copy the styling of `PlxsButton` here but why. I believe the next major version
  // allows this.
  footer: {},
  todayButton: {},

  today: {
    ...cellSelectable,
    borderColor: neutral[500],
  },
  selected: ({ mode }: StyleProps) => ({
    ...cellSelected,
    ...(mode === 'range' ? cellSelectedRange : {}),
  }),
  from: () => ({ ...cellSelected }),
  to: () => ({ ...cellSelected }),
  disabled: { ...cellDisabled },
  // This is for styling dates outside of the current month that may be visible in the calendar
  outside: { ...cellDisabled },
  timeSelector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: '12px',
    marginBottom: '12px',
  },
});
