import React, { FC, useState } from 'react';
import { DocHeading, DocRow } from '@dev/components';
import {
  PlxsButton,
  PlxsRow,
  PlxsText,
  PlxsCollapsePanel,
  PlxsButtonGroup,
} from '@plexus-ui/index';

const ExternalControl: FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>External Control</DocHeading>
        <PlxsRow spaceBelow="lg">
          <PlxsButtonGroup>
            <PlxsButton
              dataTestId="button"
              label="Close panel"
              size="md"
              variant="primary"
              onClick={() => setIsExpanded(false)}
            />
            <PlxsButton
              dataTestId="button"
              label="Open panel"
              size="md"
              variant="primary"
              onClick={() => setIsExpanded(true)}
            />
          </PlxsButtonGroup>
        </PlxsRow>

        <PlxsRow spaceBelow="lg">
          <PlxsCollapsePanel
            dataTestId="test"
            title="Details"
            isExpanded={isExpanded}
            onExpandedChange={setIsExpanded}
          >
            <PlxsRow spaceBelow="lg">
              <PlxsText size="sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                morbi porttitor turpis hendrerit lacinia nunc mi neque ultrices.
                Fusce ornare senectus tellus sit consectetur. Adipiscing aliquet
                nibh convallis nunc augue proin consequat eleifend vitae. Diam
                quis urna vel sed quis ac sed turpis. Vulputate sed est eget et
                volutpat pellentesque faucibus. Venenatis nibh cras ornare
                tortor libero, ut pellentesque sem pulvinar. Consequat dolor
                auctor luctus vel.
              </PlxsText>
            </PlxsRow>

            <PlxsRow spaceBelow="lg">
              <PlxsText size="sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                morbi porttitor turpis hendrerit lacinia nunc mi neque ultrices.
                Fusce ornare senectus tellus sit consectetur. Adipiscing aliquet
                nibh convallis nunc augue proin consequat eleifend vitae. Diam
                quis urna vel sed quis ac sed turpis. Vulputate sed est eget et
                volutpat pellentesque faucibus. Venenatis nibh cras ornare
                tortor libero, ut pellentesque sem pulvinar. Consequat dolor
                auctor luctus vel.
              </PlxsText>
            </PlxsRow>

            <PlxsRow spaceBelow="lg">
              <PlxsText size="sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                morbi porttitor turpis hendrerit lacinia nunc mi neque ultrices.
                Fusce ornare senectus tellus sit consectetur. Adipiscing aliquet
                nibh convallis nunc augue proin consequat eleifend vitae. Diam
                quis urna vel sed quis ac sed turpis. Vulputate sed est eget et
                volutpat pellentesque faucibus. Venenatis nibh cras ornare
                tortor libero, ut pellentesque sem pulvinar. Consequat dolor
                auctor luctus vel.
              </PlxsText>
            </PlxsRow>
          </PlxsCollapsePanel>
        </PlxsRow>
      </DocRow>
    </DocRow>
  );
};

export default ExternalControl;
