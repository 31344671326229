import React, { FC } from 'react';
import { ComponentProps } from './Row.types';
import { useStyles } from './Row.styles';

const Row: FC<ComponentProps> = ({
  alignX = 'left',
  alignY = 'top',
  children,
  isFlex = true,
  spaceBelow,
  spaceBetween,
}) => {
  const classes = useStyles({
    alignX,
    alignY,
    isFlex,
    spaceBelow,
    spaceBetween,
  });
  const hasStyles = isFlex || spaceBelow || spaceBetween;
  return <div className={hasStyles ? classes.root : null}>{children}</div>;
};

export default Row;
