import { makeStyles } from '@material-ui/core/styles';

import { breakpoints, spacing } from '../../styles';
import { defaultTextStyle } from '../../styles/text';
import { tone } from '../../styles/tone';

import { CALENDAR_BOTTOM } from './_constants';
import {
  INPUT_BORDER_RADIUS,
  INPUT_BORDER_WIDTH,
  INPUT_PADDING,
} from './_styles';
import { StyleProps } from './DatePicker.types';

const INPUT_WIDTH = '262px';
const INPUT_HEIGHT = '328px';

const getBorderOverrides = (props) => {
  const { calendarPlacement } = props;

  if (calendarPlacement === CALENDAR_BOTTOM) {
    return {
      marginTop: '-1px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    };
  } else {
    return {
      marginBottom: '-1px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    };
  }
};

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width:calc(${breakpoints.sm} - 1px))`]: {
      flexWrap: 'wrap',
    },
  },
  textInputWrapper: {
    width: INPUT_WIDTH,
  },
  calendarWrapper: (props: StyleProps) => ({
    background: 'white',
    border: `solid ${tone.brand}`,
    borderTopWidth: INPUT_BORDER_WIDTH,
    borderRightWidth: INPUT_BORDER_WIDTH,
    borderBottomWidth: INPUT_BORDER_WIDTH,
    borderLeftWidth: INPUT_BORDER_WIDTH,
    borderBottomLeftRadius: INPUT_BORDER_RADIUS,
    borderBottomRightRadius: INPUT_BORDER_RADIUS,
    borderTopLeftRadius: INPUT_BORDER_RADIUS,
    borderTopRightRadius: INPUT_BORDER_RADIUS,
    ...defaultTextStyle,
    width: INPUT_WIDTH,
    padding: INPUT_PADDING,
    ...getBorderOverrides(props),
  }),
});
