import React, { FC, useState, KeyboardEvent } from 'react';
import { useStyles } from './styles';
import { ComponentProps } from './types';

import { PlxsChip, PlxsDatePicker } from '@plexus-ui/index';
import LockedCell from './LockedCell';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const DatePickerCell: FC<ComponentProps> = ({
  label,
  value,
  editIcon,
  isEditable,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [cellValue, setCellValue] = useState<Date>(new Date(value));
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditMode(false);
    }
  };
  const handleSelect = (option) => {
    setCellValue(option);
    setIsEditMode(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsEditMode(false)}>
      <div className={classes.editableCell} onKeyDown={handleKeyDown}>
        {!isEditable ? (
          <LockedCell value={cellValue.toDateString()} />
        ) : isEditMode ? (
          <PlxsDatePicker
            dataTestId={label}
            isInline
            isDefaultActive={true}
            onSelect={handleSelect}
            selected={cellValue}
          />
        ) : (
          <PlxsChip
            label={cellValue.toDateString()}
            hasHover={true}
            functionalIcon={editIcon}
            onFunctionalIconClick={() => {
              setIsEditMode(true);
            }}
            onChipClick={() => {
              setIsEditMode(true);
            }}
            tone="brand"
            variant="plainText"
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DatePickerCell;
