import React, { FC, Fragment } from 'react';

import PlxsChip from '../../Chip';
import Tooltip from '../Tooltip';

import { ComponentProps } from './types';
import { useStyles } from './styles';

const MultSelectList: FC<ComponentProps> = ({
  dataTestId,
  getOptionValue,
  isDisabled,
  isFocused,
  onRemove,
  selectedList,
  tooltipTextKey,
}) => {
  const classes = useStyles();

  const displayList = isFocused
    ? selectedList
    : selectedList.filter((item, index) => index < 6);
  const hiddenItems = selectedList.length - displayList.length;
  const hasHiddenItems = !isFocused && hiddenItems > 0;
  const hiddenItemLabel = `${hiddenItems} more`;

  return (
    <Fragment>
      {displayList.map((item, index) => {
        const chipLabel = getOptionValue ? getOptionValue(item) : item;
        const tooltipTitle = tooltipTextKey ? item[tooltipTextKey] : null;

        const chip = (
          <PlxsChip
            dataTestId={dataTestId}
            functionalIcon={!isDisabled ? 'cross' : null}
            isDisabled={isDisabled}
            label={chipLabel}
            onFunctionalIconClick={() => onRemove(item)}
            tone="brand"
          />
        );

        return tooltipTitle ? (
          <Tooltip dataTestId={dataTestId} key={index} title={tooltipTitle}>
            <div className={classes.root}>{chip}</div>
          </Tooltip>
        ) : (
          <div className={classes.root} key={index}>
            {chip}
          </div>
        );
      })}

      {hasHiddenItems && (
        <PlxsChip isDisabled={isDisabled} label={hiddenItemLabel} />
      )}
    </Fragment>
  );
};

export default MultSelectList;
