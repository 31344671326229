import React, { FC } from 'react';

import { ComponentProps } from './TextEditor.types';
import { useStyles } from './TextEditor.styles';

const TextEditor: FC<ComponentProps> = ({
  dataTestId,
  onClick,
  padding = 'md',
  value,
}) => {
  const classes = useStyles({
    padding,
  });

  return (
    <div className={classes.root} data-testid={dataTestId} onClick={onClick}>
      {value}
    </div>
  );
};

export default TextEditor;
