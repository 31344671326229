import { makeStyles } from '@material-ui/core/styles';
import { PlxsStyles } from '@plexus-ui/index';

export const useStyles = makeStyles({
  table: {
    border: `1px solid ${PlxsStyles.color.neutralShade[800]}`,
  },
  tableCell: {
    minHeight: '30px',
    minWidth: '100px',
  },
  editableCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '300px',
    height: '40px',
    padding: '4px',
  },
  lockedCell: {
    paddingLeft: '8px',
  },
});
