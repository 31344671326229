import React, { FC } from 'react';

import { ComponentProps } from './types';
import PlxsButton from '../../../Button';
import { useStyles } from './styles';
import { getStartOfMonth, isAfterDate, isBeforeDate } from '../../_utils';

const MonthNavigationButtons: FC<ComponentProps> = ({
  dataTestId,
  maxDate,
  minDate,
  nextMonth,
  onNextMonthClick,
  onPreviousMonthClick,
  previousMonth,
}) => {
  const classes = useStyles();

  const hasMaxDate = Boolean(maxDate);
  const hasMinDate = Boolean(minDate);

  return (
    <div className={classes.root}>
      <PlxsButton
        dataTestId={`${dataTestId}-month-back-button`}
        functionalIcon="chevronLeft"
        isDisabled={
          hasMinDate && isBeforeDate(previousMonth, getStartOfMonth(minDate))
        }
        isIconOnly
        label=""
        onClick={onPreviousMonthClick}
        size="sm"
        tone="brand"
        variant="link"
      />
      <PlxsButton
        dataTestId={`${dataTestId}-month-next-button`}
        functionalIcon="chevronRight"
        isDisabled={
          hasMaxDate && isAfterDate(nextMonth, getStartOfMonth(maxDate))
        }
        isIconOnly
        label=""
        onClick={onNextMonthClick}
        size="sm"
        tone="brand"
        variant="link"
      />
    </div>
  );
};

export default MonthNavigationButtons;
