import React, { FC, MouseEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DocRow } from '@dev/components';
import { PlxsAutoComplete, PlxsButton, PlxsText } from '@plexus-ui/index';
import { users } from '@dev/mocks/users';

interface User {
  email: string;
  id?: number;
  name: string;
}

const useStyles = makeStyles(() => ({
  button: {
    '& button': {
      borderRadius: '0 0 3px 3px',
      justifyContent: 'flex-start',
    },
  },
}));

const DropdownFooter: FC = () => {
  const classes = useStyles();

  const [selection, setSelection] = useState('');

  const getOptionValue = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  const handleNewContactButton = (event: MouseEvent) => {
    console.log('handleNewContactButton clicked');
  };

  const handleSelect = (option: User) => {
    setSelection(JSON.stringify(option));
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return `${name} (${id ? email : 'External'})`;
  };

  const dropdownFooter = (
    <div className={classes.button}>
      <PlxsButton
        dataTestId="new-contact-button"
        decorativeIcon="add"
        isFullWidth={true}
        label="New contact"
        onMouseDown={handleNewContactButton}
        variant="link"
      />
    </div>
  );

  return (
    <DocRow margin="md">
      <PlxsAutoComplete
        dataTestId="single-select-complex"
        dropdownFooter={dropdownFooter}
        getOptionValue={getOptionValue}
        label="Recipients"
        matchKeys={['email', 'name']}
        onSelect={handleSelect}
        optionList={users}
        renderOption={renderOption}
      />
      <PlxsText>Selected option: {selection}</PlxsText>
    </DocRow>
  );
};

export default DropdownFooter;

export const DropdownFooterSnippet = `import React, { FC, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PlxsAutoComplete, PlxsButton } from '@plexus-ui/index';

interface User {
  ...
}

const users = [
  ...
];

const useStyles = makeStyles(() => ({
  button: {
    '& button': {
      borderRadius: '0 0 3px 3px',
      justifyContent: 'flex-start',
    },
  },
}));

const DropdownFooter: FC = () => {
  const classes = useStyles();

  const getOptionValue = (option: User) => {
    const { email, id, name } = option;
    return \`\${name} (\${id ? email : 'External'})\`;
  };

  const handleNewContactButton = (event: MouseEvent) => {
    console.log('handleNewContactButton clicked');
  };

  const handleSelect = (option: User) => {
    console.log('handleSelect', JSON.stringify(option));
  };

  const renderOption = (option: User) => {
    const { email, id, name } = option;
    return \`\${name} (\${id ? email : 'External'})\`;
  };

  const dropdownFooter = (
    <div className={classes.button}>
      <PlxsButton
        dataTestId="new-contact-button"
        decorativeIcon="add"
        isFullWidth={true}
        label="New contact"
        onMouseDown={handleNewContactButton}
        variant="link"
      />
    </div>
  );

  return (
    <PlxsAutoComplete
      dataTestId="single-select-complex"
      dropdownFooter={dropdownFooter}
      getOptionValue={getOptionValue}
      label="Recipients"
      matchKeys={['email', 'name']}
      onSelect={handleSelect}
      optionList={users}
      renderOption={renderOption}
    />
  );
};

export default DropdownFooter;`;
