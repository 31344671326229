import { enAU, enUS } from 'date-fns/locale';
import { EN_AU, EN_US } from '../../../_constants';

export const dateFnsLocaleMap = {
  [EN_AU]: enAU,
  [EN_US]: enUS,
};

export const dateFnsStringFormatMap = {
  digits: 'P', // localized 04/29/1453
  shortMonth: 'PP', // localized Apr 29, 1453
  hoursMinutes: 'hh:mm a', // can't use a 'p' as the hours isn't padded
  digitsHoursMinutes: 'P, hh:mm a', // localized 04/29/1453 12:00 am
  shortMonthHoursMinutes: 'PP, hh:mm a', // localized Apr 29, 1453 12:00 am
};
