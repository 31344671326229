import { makeStyles } from '@material-ui/core/styles';

import { StyleProps } from './types';

export const useStyles = makeStyles({
  wrapper: (props: StyleProps) => ({
    bottom: '3px',
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    position: 'absolute',
    right: '3px',
    top: '3px',
    '& > svg': {
      margin: '8px 7px',
    },
  }),
});
