import React, { FC } from 'react';

import InternalButton from './_internal/InternalButton';

import { useStyles } from './ToggleButton.styles';
import { ComponentProps } from './ToggleButton.types';

const ToggleButton: FC<ComponentProps> = ({
  buttons,
  value,
  isDisabled,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {buttons.map((button) => {
        return (
          <InternalButton
            dataTestId={button.dataTestId}
            key={button.value}
            value={button.value}
            label={button.label}
            tone={button.tone}
            disabled={isDisabled}
            icon={button.icon}
            isSelected={value === button.value}
            onClick={isDisabled ? null : () => onChange(button.value)}
          />
        );
      })}
    </div>
  );
};

export default ToggleButton;
