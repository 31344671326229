import { makeStyles } from '@material-ui/core/styles';
import { StyleProps } from './DropdownField.types';
import { getFieldColor } from '../../Field/Field.styles';

export const useStyles = makeStyles({
  root: ({ hasError, isDropdownOpen, isActive }: StyleProps) => ({
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid',
    minHeight: '40px',
    boxSizing: 'border-box',
    borderColor: getFieldColor(isActive, hasError),
    borderRadius: isDropdownOpen ? '3px 3px 0 0' : '3px',
    outline: 'none',
    '& > *:last-child': {
      margin: '10px',
      cursor: 'pointer',
    },
  }),
});
