import React from 'react';
import {
  DocCode,
  DocHeading,
  DocParagraph,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsIcon } from '@plexus-ui/index';

const props = `interface ComponentProps {
  background?: string; // Color hex code applied to background css property
  customSize?: number; // Overrides size prop, sets the icon size
  name: Name; // Refer to the Icon Library for a full list of icon names
  size?: Size; // 'sm' | 'lg'
  tone?: Tone; // 'brand' | 'caution' | 'dark' | 'disabled' | 'neutral' | 'positive' | 'urgent' | 'white'
}`;

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocRow margin="md">
        <DocParagraph>
          The Icon component is used to render icons from the Plexus UI Icon
          Library.
        </DocParagraph>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Props</DocHeading>
        <DocCode>{props}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Usage</DocHeading>
        <DocCode>{`<PlxsIcon name="image" size="sm" tone="brand" />`}</DocCode>
      </DocRow>

      <DocRow margin="sm">
        <DocHeading>Examples</DocHeading>
        <DocParagraph>
          The Image icon is displayed below using size and tone prop variants.
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocSubHeading>Size</DocSubHeading>
        <DocParagraph>
          <PlxsIcon name="image" size="sm" /> Small (default)
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" /> Large
        </DocParagraph>
      </DocRow>

      <DocRow margin="sm">
        <DocSubHeading>Tone</DocSubHeading>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="brand" /> Brand
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="caution" /> Caution
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="dark" /> Dark
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="disabled" /> Disabled
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="neutral" /> Neutral (default)
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="positive" /> Positive
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon name="image" size="lg" tone="urgent" /> Urgent
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon background="#e6e6e6" name="image" size="lg" tone="white" />{' '}
          White
        </DocParagraph>
      </DocRow>
      <DocRow margin="sm">
        <DocSubHeading>Custom Size</DocSubHeading>
        <DocCode>{`<PlxsIcon customSize={32} name="image" />`}</DocCode>
        <DocParagraph>
          This custom size prop is used as a legacy solution to replace icons
          that are yet to be updated to use our standard small or large size.
          Defining a custom size will override the size prop and render the crop
          SVG file. i.e. The SVG was set on an artboard without padding.
        </DocParagraph>
        <DocParagraph>
          <PlxsIcon customSize={32} name="image" />
        </DocParagraph>
      </DocRow>
    </DocRow>
  </DocRow>
);
