import React from 'react';
import cx from 'classnames';

import { DocCode, DocHeading, DocRow } from '@dev/components';
import { Icon } from '@plexus-ui/components/SpotlightIcon/SpotlightIcon.types';
import { PlxsSpotlightIcon } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';
import { IconName } from '@plexus-ui/icons/types';

const ICONS: (Icon | IconName)[] = [
  'ai',
  'approvalDeclined',
  'approvalSuccess',
  'ghost',
  'invite',
  'message',
  'notifications',
  'permissionsBasic',
  'permissionsFull',
  'permissionsCustom',
  'permissionsEmpty',
  'recentlyViewed',
  'signatureDeclined',
  'signatureSuccess',
  'share',
  'tasks',
  'user',
];

export default () => {
  const classes = useStyles();

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Special Icons</DocHeading>
        <ul className={classes.list}>
          {ICONS.map((icon, index) => (
            <li className={classes.listItem} key={index}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon icon={icon} tone="brand" />
              </div>
              <div className={classes.name}>
                <DocCode>{`icon="${icon}"`}</DocCode>
              </div>
            </li>
          ))}
        </ul>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Tone and variants</DocHeading>
        <DocRow margin="sm">
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon icon="invite" tone="brand" />
              </div>
              <div className={classes.name}>
                <DocCode>{'tone="brand"'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon icon="invite" tone="positive" />
              </div>
              <div className={classes.name}>
                <DocCode>{'tone="positive"'}</DocCode>
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon icon="invite" tone="urgent" />
              </div>
              <div className={classes.name}>
                <DocCode>{'tone="urgent"'}</DocCode>
              </div>
            </li>
          </ul>
        </DocRow>
        <DocRow margin="md">
          <ul className={classes.list}>
            <li className={cx(classes.listItem, classes.listItemDark)}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon icon="invite" tone="brand" variant="white" />
              </div>
              <div className={classes.name}>
                <DocCode>{'tone="brand" variant="white"'}</DocCode>
              </div>
            </li>
            <li className={cx(classes.listItem, classes.listItemDark)}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon
                  icon="invite"
                  tone="positive"
                  variant="white"
                />
              </div>
              <div className={classes.name}>
                <DocCode>{'tone="positive" variant="white"'}</DocCode>
              </div>
            </li>
            <li className={cx(classes.listItem, classes.listItemDark)}>
              <div className={classes.icons}>
                <PlxsSpotlightIcon
                  icon="invite"
                  tone="urgent"
                  variant="white"
                />
              </div>
              <div className={classes.name}>
                <DocCode>{'tone="urgent" variant="white"'}</DocCode>
              </div>
            </li>
          </ul>
        </DocRow>
      </DocRow>
    </DocRow>
  );
};
