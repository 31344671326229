import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';

import { brand } from '../../styles/color';
import { fieldsetReset } from '../../styles/input';

import { StyleProps } from './RadioButtonGroup.types';

function getEnabledTileLabelStyle(isChecked: boolean): CSSProperties {
  return isChecked
    ? {
        background: brand[600],
        '&:hover': {
          background: brand[500],
        },
      }
    : {
        '&:hover': {
          background: brand[900],
        },
      };
}

function getLabelVariantStyle(
  { variant, isDisabled }: StyleProps,
  isChecked: boolean
): CSSProperties {
  if (variant === 'tile') {
    return {
      flexGrow: 1,
      borderRadius: 3,
      flexDirection: 'row-reverse',
      padding: '24px 32px 24px 32px',
      ...(isDisabled ? {} : getEnabledTileLabelStyle(isChecked)),
      '& > *:not(:first-child)': {
        marginRight: 16,
      },
    };
  } else {
    return {
      flexWrap: 'nowrap',
      flexShrink: 0,
      '& > *:not(:first-child)': {
        marginLeft: 16,
      },
    };
  }
}

const baseLabelStyles = {
  alignItems: 'center',
  display: 'flex',
};

export const useStyles = makeStyles(() => ({
  fieldset: ({ variant }: StyleProps) => ({
    ...fieldsetReset,
    position: 'relative',
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    flexDirection: variant === 'vertical' ? 'column' : 'row',
    userSelect: 'none',
  }),
  uncheckedLabel: (props: StyleProps) => ({
    ...baseLabelStyles,
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    ...getLabelVariantStyle(props, false),
  }),
  checkedLabel: (props: StyleProps) => ({
    ...baseLabelStyles,
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    ...getLabelVariantStyle(props, true),
  }),
  labelText: {
    flexGrow: 1,
  },
}));
