import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '../../styles/color';

import { StyleProps } from './TabProvider.types';

export const useStyles = makeStyles(() => ({
  tabProvider: ({ height }: StyleProps) => ({
    height: height ? `${height}px` : undefined,
    // Button and tab wrapper
    '& .react-horizontal-scrolling-menu--wrapper': {
      alignItems: 'flex-end',
      overflowX: 'auto',
    },
    // Tab wrapper
    '& .react-horizontal-scrolling-menu--scroll-container': {
      overflow: 'hidden',
    },
  }),
  tabWrapper: ({ isCollapsible }: StyleProps) => ({
    borderBottom: `1px solid ${neutral[600]}`,
    marginBottom: '16px',
    minHeight: '39px', // 40px including border
    display: isCollapsible ? 'flex' : 'block',
    justifyContent: isCollapsible ? 'space-between' : undefined,
    alignItems: isCollapsible ? 'center' : undefined,
  }),
  expandIconContainer: ({ isCollapsible }: StyleProps) => ({
    display: isCollapsible ? 'flex' : 'none',
    width: '40px',
    minWidth: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    borderLeft: `1px solid ${neutral[600]}`,
  }),
}));
