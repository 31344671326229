import React from 'react';
import { DocCode, DocRow } from '@dev/components';
import { useStyles } from './styles';
import { breakpoints } from '@plexus-ui/styles';

const Breakpoints = () => {
  const classes = useStyles();
  return (
    <DocRow margin="xxl">
      <p>
        The overlays show our different breakpoints. Breakpoints are used like
        this in CSS:
      </p>
      <DocCode>
        {/* eslint-disable-next-line no-template-curly-in-string */}
        {'[`@media (min-width:${breakpoints.sm})`]: {\n' +
          '  ...STYLES,\n' +
          '}'}
      </DocCode>
      <div className={classes.sm}>sm (&lt; {breakpoints.sm})</div>
      <div className={classes.md}>md (&lt; {breakpoints.md})</div>
      <div className={classes.lg}>lg (&lt; {breakpoints.lg})</div>
      <div className={classes.xl}>xl (&lt; {breakpoints.xl})</div>
      <div className={classes.xxl}>xxl (&lt; {breakpoints.xxl})</div>
    </DocRow>
  );
};

export default Breakpoints;
