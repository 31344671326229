import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle, getTextStyle } from '../../styles/text';
import { fontColor } from '../../styles';

import { StyleProps } from './Chip.types';

const variantStyles = (props: StyleProps) => ({
  '& > div': {
    backgroundColor: 'transparent',
  },
  '& $functionalIcon': {
    display: 'none',
  },
  '&:hover $functionalIcon': {
    display: props.hasHover ? 'inline-block' : undefined,
  },
});

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    borderRadius: 2,
    display: 'inline-block',
    overflow: 'hidden',
    '&:hover': {
      cursor: props.onChipClick && !props.isDisabled ? 'pointer' : undefined,
    },
  }),
  innerWrapper: {
    alignItems: 'center',
    display: 'flex',
    padding: '3px 8px',
  },
  label: (props: StyleProps) => ({
    ...defaultTextStyle,
    ...getTextStyle({ weight: 'semiBold' }),
    color: props.isDisabled ? fontColor.disabled : fontColor.default,
    overflow: 'hidden',
    paddingLeft: props.hasDecorativeIcon ? '8px' : undefined,
    paddingRight: props.hasFunctionalIcon ? '8px' : undefined,
    textOverflow: 'ellipsis',
  }),
  functionalIcon: () => ({}),
  plainText: (props: StyleProps) => ({
    ...variantStyles(props),
  }),
  muted: (props: StyleProps) => ({
    ...variantStyles(props),
    '& div > span': {
      ...getTextStyle({
        color: 'disabled',
      }),
    },
  }),
}));
