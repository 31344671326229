import React from 'react';
import { DocCode, DocParagraph, DocRow, DocSubHeading } from '@dev/components';
import { PlxsButton } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ButtonLoading = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Loading button</DocSubHeading>
        <DocParagraph>
          A universal loading style is applied to all button variants.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Primary loading</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-loading-button"
              isLoading={true}
              label="Primary loading button"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isLoading={true}'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Primary positive loading</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-loading-button"
              isLoading={true}
              label="Primary loading button"
              tone="positive"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isLoading={true} tone="positive"'}</DocCode>
          </div>
        </li>
        <li className={`${classes.listItem} ${classes.listItemBackground}`}>
          <div className={classes.displayName}>Primary white loading</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="primary-white-loading-button"
              isLoading={true}
              label="Primary loading button"
              tone="white"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isLoading={true} tone="white"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Secondary loading</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="secondary-loading-button"
              isLoading={true}
              label="Secondary loading button"
              variant="secondary"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isLoading={true} variant="secondary"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Link loading</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="link-loading-button"
              isLoading={true}
              label="Link loading button"
              variant="link"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isLoading={true} variant="link"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Icon loading</div>
          <div className={classes.icons}>
            <PlxsButton
              dataTestId="icon-only-loading-button"
              functionalIcon="signature"
              isIconOnly={true}
              isLoading={true}
              label="Icon only loading button"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'isIconOnly={true} isLoading={true}'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ButtonLoading;
