import React, { FC, MouseEvent, useState } from 'react';
import { PlxsCallout } from '@plexus-ui/index';

const CustomCallout: FC = () => {
  const [hasCallout, setHasCallout] = useState(true);

  const handleDismiss = (event: MouseEvent) => {
    setHasCallout(false);
  };

  return hasCallout ? (
    <PlxsCallout
      dataTestId="callout"
      fitContentWidth={true}
      icon="checklist"
      message="Custom callout"
      onDismiss={handleDismiss}
      supportingCopy="Featuring; fit content width, custom icon, message, dismissable, supporting copy and positive tone."
      tone="positive"
    />
  ) : null;
};

export default CustomCallout;

export const usage = `import React, { FC, MouseEvent, useState } from 'react';
import { PlxsCallout } from '@plexus-ui/index';

const CustomCallout: FC = () => {
  const [hasCallout, setHasCallout] = useState(true);

  const handleDismiss = (event: MouseEvent) => {
    setHasCallout(false);
  };

  return hasCallout ? (
    <PlxsCallout
      dataTestId="callout"
      fitContentWidth={true}
      icon="checklist"
      message="Custom callout"
      onDismiss={handleDismiss}
      supportingCopy="Featuring; fit content width, custom icon, message, dismissable, supporting copy and positive tone."
      tone="positive"
    />
  ) : null;
};

export default CustomCallout;`;
