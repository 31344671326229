import React, { FC } from 'react';

import { ComponentProps } from './_types';

const SignatureSuccess: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    width="76"
    height="75"
    viewBox="0 0 76 75"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="46" cy="29.9998" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M64.2734 42.9998C58.9922 43.406 51.4766 48.5857 47.7188 50.3123C44.7734 51.6326 42.1328 52.7498 40 52.7498C37.6641 52.7498 37.3594 51.1248 37.7656 47.4685C37.8672 46.656 38.9844 39.4451 33.5 39.7498C30.8594 39.9529 26.8984 42.2888 16.2344 52.5466L20.5 42.0857C23.5469 34.3669 15.0156 26.6482 7.29688 31.6248L1.71094 35.3826C1 35.7888 0.695312 36.8044 1.20312 37.6169L2.92969 40.3591C3.4375 41.0701 4.45312 41.3748 5.16406 40.8669L11.0547 36.906C12.9844 35.6873 15.2188 37.6169 14.4062 39.6482L4.45312 64.6326C3.74219 66.2576 4.75781 68.9998 7.5 68.9998C8.3125 68.9998 9.125 68.6951 9.73438 68.0857C14 63.8201 25.4766 52.7498 31.1641 48.1794C30.9609 51.1248 30.9609 54.1716 33.2969 56.7107C34.8203 58.4373 37.0547 59.2498 39.8984 59.2498C43.5547 59.2498 46.8047 57.8279 50.3594 56.2029C53.7109 54.781 60.4141 49.906 64.375 49.6013C65.2891 49.4998 65.8984 48.7888 65.8984 47.9763V44.7263C66 43.8123 65.1875 42.9998 64.2734 42.9998Z"
    />
  </svg>
);

export default SignatureSuccess;
