import React, { FC, useRef, useState } from 'react';

import InternalFieldWrapper from '../_internal/FieldWrapper';
import PlxsChip from '../Chip';

import { ComponentProps } from './ChipSelectField.types';
import { useStyles } from './ChipSelectField.styles';

const DELIMITER_REGEX = /[\s|,]/;

function defaultItemValidity(_: string) {
  return true;
}

const ChipSelectField: FC<ComponentProps> = (props) => {
  const {
    dataTestId,
    autofocus,
    items,
    onItemsChanged,
    itemValidity = defaultItemValidity,
    rows,
    lowResolutionRows,
    errorMessage,
    hasError,
    instructionText,
    label,
    placeholder,
    secondaryLabel,
    secondaryLabelUrl,
  } = props;

  const styles = useStyles({ rows, lowResolutionRows });
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState(false);

  const hasItems = Boolean(items && items.length);

  const handleItemRemoved = (removedItem: string) => {
    const newItems = items.filter((item) => item !== removedItem);
    onItemsChanged(newItems);
  };

  const handleItemsAdded = (value: string) => {
    const addedItems = Array.from(new Set(value.split(DELIMITER_REGEX))).filter(
      (item) => item
    );
    const newItems = items
      .filter((item) => !addedItems.includes(item))
      .concat(addedItems);
    onItemsChanged(newItems);
    setInputValue('');
  };

  const handleInputValueChanged = (value: string) => {
    if (value.match(DELIMITER_REGEX)) {
      handleItemsAdded(value);
    } else {
      setInputValue(value);
    }
  };

  const handleInputKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleItemsAdded(inputValue);
    } else if (key === 'Backspace' && !inputValue && items) {
      const removedItem = items[items.length - 1];
      handleItemRemoved(removedItem);
    }
  };

  const handleInputBlur = () => {
    handleItemsAdded(inputValue);
    setIsInputFocused(false);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleClick = (e) => {
    if (!e.isDefaultPrevented()) {
      inputRef.current.focus();
    }
  };

  return (
    <InternalFieldWrapper
      isActive={isInputFocused}
      errorMessage={errorMessage}
      hasError={hasError}
      instructionText={instructionText}
      label={label}
      secondaryLabel={secondaryLabel}
      secondaryLabelUrl={secondaryLabelUrl}
    >
      <div
        className={styles.root}
        data-testid={dataTestId}
        onClick={handleClick}
      >
        <div className={styles.itemContainerScrollView}>
          <div className={styles.itemContainer}>
            {items &&
              items.map((item, index) => (
                <PlxsChip
                  key={index}
                  label={item}
                  functionalIcon="cross"
                  tone={itemValidity(item) ? 'brand' : 'urgent'}
                  onFunctionalIconClick={() => handleItemRemoved(item)}
                  hasHover
                />
              ))}
            <input
              autoFocus={autofocus}
              type="text"
              className={styles.input}
              data-testid={`${dataTestId}--input`}
              placeholder={
                !hasItems && !isInputFocused ? placeholder : undefined
              }
              value={inputValue}
              onChange={(e) => handleInputValueChanged(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onKeyDown={(e) => handleInputKeyDown(e.key)}
              ref={inputRef}
            />
          </div>
        </div>
      </div>
    </InternalFieldWrapper>
  );
};

export default ChipSelectField;
