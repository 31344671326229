import React, { FC, useState } from 'react';
import { DocRow } from '@dev/components';
import { PlxsButton, PlxsMultiSelect, PlxsText } from '@plexus-ui/index';

const OPTIONS: string[] = [
  'Alfa',
  'Bravo',
  'Charlie',
  'Delta',
  'Echo',
  'Foxtrot',
  'Golf',
  'Hotel',
  'India',
  'Juliett',
  'Kilo',
  'Lima',
  'Mike',
  'November',
  'Oscar',
  'Papa',
  'Quebec',
  'Romeo',
  'Sierra',
  'Tango',
  'Uniform',
  'Victor',
  'Whiskey',
  'X-ray',
  'Yankee',
  'Zulu',
];

const MultiSelectDocs: FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <PlxsText>
          This component for selection of multiple options is a work in
          progress.
        </PlxsText>

        <pre>
          Selected: <strong>{JSON.stringify(selectedOptions)}</strong>
        </pre>
      </DocRow>

      <DocRow margin="lg">
        <PlxsMultiSelect
          dataTestId="placeholder"
          onChange={setSelectedOptions}
          options={OPTIONS}
          getLabel={(o) => o}
          placeholder="Placeholder text goes here..."
          value={selectedOptions}
        />
        <PlxsButton
          dataTestId="clear-button"
          onClick={() => setSelectedOptions([])}
          label="Clear"
        />
      </DocRow>
    </DocRow>
  );
};

export default MultiSelectDocs;
