import React, { FC, Fragment } from 'react';
import { PlxsRow } from '@plexus-ui/index';

import { ComponentProps } from './types';

const Description: FC<ComponentProps> = ({ children }) => {
  if (!children) {
    return null;
  }

  const renderChildren = Array.isArray(children) ? (
    <Fragment>
      {children.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </Fragment>
  ) : (
    <p>{children}</p>
  );

  return (
    <PlxsRow isFlex={false} spaceBelow="md">
      {renderChildren}
    </PlxsRow>
  );
};

export default Description;
