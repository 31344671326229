import React, { FC, Fragment, useRef, useEffect, useState } from 'react';

import { DocCode, DocRow, DocSubHeading } from '@dev/components';
import { PlxsRichTextEditor } from '@plexus-ui/index';

const Controlled: FC = () => {
  const initialValue =
    '<h1>Example</h1><p>This example shows the editor initalised with an initial value.</p>';

  const [value, setValue] = useState(initialValue ?? '');

  const editorRef = useRef(null);

  const handleInit = (event: any, editor: any) => (editorRef.current = editor);

  const handleOnChange = (value: string, editor: any) => {
    setValue(value);
  };

  useEffect(() => setValue(initialValue ?? ''), [initialValue]);

  return (
    <Fragment>
      <DocRow margin="sm">
        <PlxsRichTextEditor
          dataTestId="controlled-rich-text-editor"
          label="Controlled Rich Text Editor"
          onEditorChange={handleOnChange}
          onInit={handleInit}
          placeholder="Editor placeholder text..."
          value={value}
        />
      </DocRow>

      <DocRow margin="sm">
        <DocSubHeading>Value</DocSubHeading>
        <DocCode>{value}</DocCode>
      </DocRow>
    </Fragment>
  );
};

export default Controlled;

export const controlledExample = `import React, { FC, Fragment, useRef, useEffect, useState } from 'react';

import { PlxsRichTextEditor } from '@plexus-ui/index';

const ControlledExample: FC = ({ initialValue }) => {
  const [value, setValue] = useState(initialValue ?? '');

  const editorRef = useRef(null);

  const handleInit = (event: any, editor: any) => (editorRef.current = editor);

  const handleOnChange = (value: string, editor: any) => {
    setValue(value);
  };

  useEffect(() => setValue(initialValue ?? ''), [initialValue]);

  return (
    <PlxsRichTextEditor
      dataTestId="controlled-rich-text-editor"
      label="Controlled Rich Text Editor"
      onEditorChange={handleOnChange}
      onInit={handleInit}
      placeholder="Editor placeholder text..."
      value={value}
    />
  );
};

export default ControlledExample;`;
