import React from 'react';
import {
  DocCode,
  DocParagraph,
  DocProp,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsChip } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ChipTone = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Tone</DocSubHeading>
        <DocParagraph>
          The <DocProp>tone</DocProp> prop renders chips in colour.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Brand</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              tone="brand"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'tone="brand"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Positive</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              tone="positive"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'tone="positive"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Caution</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              tone="caution"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'tone="caution"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Urgent</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              tone="urgent"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'tone="urgent"'}</DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Neutral</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              label="Tomas Michaels"
              tone="neutral"
            />
          </div>
          <div className={classes.name}>
            <DocCode>{'tone="neutral"'}</DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ChipTone;
