import React from 'react';
import { DocCode, DocHeading, DocParagraph, DocRow } from '@dev/components';

const props = `interface ComponentProps {
  children: ReactNode;
  errorMessage?: string;
  hasError?: boolean;
  instructionText?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isFilled?: boolean;
  isResizable?: boolean;
  label: string;
  labelVariant: 'dynamic' | 'hidden' | 'static';
  onReset?: () => void;
  placeholder?: string;
  secondaryLabel?: string;
  secondaryLabelUrl?: string;
}`;

export default () => (
  <DocRow margin="xxl">
    <DocRow margin="lg">
      <DocRow margin="md">
        <DocParagraph>
          The FieldWrapper component provides a standard layout for the
          TextField and Textarea components. The wrapper renders field labels,
          an input overlay style, reset button, instruction text and error
          messaging.
        </DocParagraph>
      </DocRow>

      <DocRow margin="md">
        <DocHeading>Props</DocHeading>
        <DocCode>{props}</DocCode>
      </DocRow>
    </DocRow>

    <DocRow margin="md">
      <DocHeading>Usage</DocHeading>
      <DocParagraph>
        Individual props and examples are documented under components/Textarea
        and components/TextField.
      </DocParagraph>
    </DocRow>
  </DocRow>
);
