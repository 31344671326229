import React from 'react';
import PageTemplate from '@dev/components/PageTemplate';

const bestPractice = {
  doList: [
    'Use token provider to inject auth token to component that requires to make authenticated API calls',
  ],
  doNotList: [],
};

const description =
  'The TokenProvider is a component that will fetch access token from a provided endpoint. ' +
  'It will make the token available to the child component. ' +
  'it is available through the HOC `withPlxsTokenProvider`.';

const example = <div>See "implementation" section</div>;

const implementation = `import React, { FC, Fragment, useState } from 'react';
import { withPlxsTokenProvider } from '@plexus-ui/index';
import { getUserFromBackend } from './api';

const WidgetA: FC = ({accessToken, userId}) => {
  const [name, setName] = useState<string>('');

  useEffect(() => {
    const response = await getUserFromBackend(accessToken, userId);
    setName(response.name);
  }, [accessToken, userId]);

  return <div>Contact: {name}</div>;
};

const AuthenticatedWidgetA = withPlxsTokenProvider(
  WidgetA,
  ['https://address.to/my-service'],
  'https://address.to/token-provider',
  { 'X-SOME-HEADER-KEY': 'value' }
)

const DashboardView: FC = () => (
  <Fragment>
    <div>Welcome</div>
    <AuthenticatedWidgetA userId="453" />
  </Fragment>
);`;

const propList = [
  {
    component: 'TokenProvider',
    description:
      'the component that will be enriched with the access token retrieved from the supplied endpoint',
    isRequired: true,
    prop: 'children',
    type: 'ReactNode',
  },
  {
    component: 'TokenProvider',
    description:
      'the URI(s) of the target service(s) where the child component intends to use the requested access token',
    isRequired: true,
    prop: 'resource',
    type: 'string[]',
  },
  {
    component: 'TokenProvider',
    description: 'the endpoint to request the access token',
    isRequired: true,
    prop: 'tokenUrl',
    type: 'string',
  },
  {
    component: 'TokenProvider',
    description:
      'if provided, used as the headers for requesting the access token (defaults to `{}`)',
    isRequired: false,
    prop: 'tokenRequestHeaders',
    type: 'Record<string, string>',
  },
];

const tsInterface = `interface TokenProviderProps {
  children: ReactNode;
  resource: string[];
  tokenRequestHeaders?: Record<string, string>;
  tokenUrl: string;
}`;

export default () => {
  return (
    <PageTemplate
      bestPractice={bestPractice}
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
