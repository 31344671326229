import React, { Fragment, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import AppContent from '@dev/components/AppContent';
import AppSidebar from '@dev/components/AppSidebar';

import { useStyles } from './styles';
import components from './components';

const App = () => {
  const classes = useStyles();

  const [selectedComponentName, setSelectedComponentName] = useState('');

  const location = useLocation();
  const path = location ? location.pathname : '';

  useEffect(() => {
    let comp = components.find((c) => c.path === path);
    setSelectedComponentName(comp ? comp.name : '');
    window.scrollTo(0, 0);
  }, [path]);

  return (
    <Fragment>
      <div className="row g-0">
        <div className="col">
          <header className={classes.header}>
            <span className={classes.heading}>Plexus UI</span>
          </header>
        </div>
      </div>

      <div className="row flex-grow-1 g-0">
        <div className="col-md-3 col-xl-2">
          <AppSidebar />
        </div>

        <div className="col-md-9 col-xl-10 content-wrapper">
          <AppContent heading={selectedComponentName}>
            <Switch>
              {components.map((component, index: number) => {
                return (
                  <Route
                    key={index}
                    path={component.path}
                    render={() => <component.component />}
                  />
                );
              })}
            </Switch>
          </AppContent>
        </div>
      </div>
    </Fragment>
  );
};

export default App;
