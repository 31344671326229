import React, { useState } from 'react';
import { DocCode, DocRow, DocSubHeading } from '@dev/components';
import {
  PlxsCheckbox,
  PlxsColumn,
  PlxsDatePicker,
  PlxsSelect,
  PlxsButton,
} from '@plexus-ui/index';
import { useStyles } from './styles';
import { getDateString } from '@plexus-ui/components/DatePicker/_utils';
import { DATE_FORMAT } from '@plexus-ui/components/DatePicker/DatePicker.constants';
import { add, sub, format } from 'date-fns';
import { DateRange } from '@plexus-ui/components/DatePicker/DatePicker.types';

const SingleDate = () => {
  const classes = useStyles();
  const labelVariantOptions = ['hidden', 'dynamic', 'static'];

  // TextField related props
  const [hasInstructionText, setHasInstructionText] = useState(false);
  const [hasLabel, setHasLabel] = useState(false);
  const [hasSecondaryLabel, setHasSecondaryLabel] = useState(false);
  const [isForceError, setIsForceError] = useState(false);
  const [isDefaultActive, setIsDefaultActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [labelVariant, setLabelVariant] = useState('static');
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(new Date());

  // DatePicker props
  const today = new Date();
  const minDate = sub(today, { months: 3 });
  const maxDate = add(today, { years: 1 });
  const [hasMinDate, setHasMinDate] = useState(false);
  const [hasMaxDate, setHasMaxDate] = useState(false);
  const [selectedDates, setSelectedDates] = useState<DateRange>();

  const handleSingleDateSelect = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleDateTimeSelect = (date: Date | null) => {
    setSelectedDateTime(date);
  };

  const handleReset = () => {
    setSelectedDates(undefined);
  };

  return (
    <DocRow margin="sm">
      <DocSubHeading>
        Relevant TextField props. See TextField section for prop details.
      </DocSubHeading>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Disabled state</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-disabled-checkbox"
              onChange={() => setIsDisabled(!isDisabled)}
              value={isDisabled}
            />
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Error state</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-force-error-checkbox"
              onChange={() => setIsForceError(!isForceError)}
              value={isForceError}
            />
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Instruction text</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-instruction-text-checkbox"
              onChange={() => setHasInstructionText(!hasInstructionText)}
              value={hasInstructionText}
            />
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Label</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-label-checkbox"
              onChange={() => setHasLabel(!hasLabel)}
              value={hasLabel}
            />
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Label variant</div>
          <div className={classes.componentWrapper}>
            <PlxsColumn expand={true}>
              <PlxsSelect
                dataTestId="date-picker-label-variant-select"
                getOptionLabel={(option) => option}
                getOptionKey={(option) => option}
                onSelect={setLabelVariant}
                options={labelVariantOptions}
                renderOption={(option) => option}
                selected={labelVariant}
              />
            </PlxsColumn>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Secondary label</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-secondary-label-checkbox"
              onChange={() => {
                setHasSecondaryLabel(!hasSecondaryLabel);
                !hasLabel && setHasLabel(true);
              }}
              value={hasSecondaryLabel}
            />
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Default Active</div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-is-default-active-checkbox"
              onChange={() => setIsDefaultActive(!isDefaultActive)}
              value={isDefaultActive}
            />
          </div>
        </li>
      </ul>

      <DocSubHeading>Date specific props.</DocSubHeading>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>
            Set min date (e.g. 3 months before today)
          </div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-min-date-checkbox"
              onChange={() => setHasMinDate(!hasMinDate)}
              value={hasMinDate}
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {hasMinDate
                ? `minDate={new Date(${minDate.getFullYear()}, ${minDate.getMonth()}, ${minDate.getDate()})}`
                : ' '}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>
            Set max date (e.g. 1 year after today)
          </div>
          <div className={classes.componentWrapper}>
            <PlxsCheckbox
              dataTestId="date-picker-max-date-checkbox"
              onChange={() => setHasMaxDate(!hasMaxDate)}
              value={hasMaxDate}
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {hasMaxDate
                ? `maxDate={new Date(${maxDate.getFullYear()}, ${maxDate.getMonth()}, ${maxDate.getDate()})}`
                : ' '}
            </DocCode>
          </div>
        </li>
      </ul>

      <DocSubHeading>
        Selected date: {getDateString(selectedDate, DATE_FORMAT)}
      </DocSubHeading>

      <PlxsDatePicker
        dataTestId="datepicker-single"
        hasError={isForceError}
        errorMessage={isForceError ? 'Error text to go here' : ''}
        instructionText={
          hasInstructionText
            ? 'Click on the calendar button or start typing'
            : ''
        }
        isDefaultActive={isDefaultActive}
        isDisabled={isDisabled}
        label={hasLabel ? 'Select a date' : ''}
        // @ts-ignore
        labelVariant={labelVariant}
        secondaryLabel={hasSecondaryLabel ? 'Help' : ''}
        secondaryLabelUrl={hasSecondaryLabel ? '#' : ''}
        minDate={hasMinDate ? minDate : null}
        maxDate={hasMaxDate ? maxDate : null}
        onSelect={handleSingleDateSelect}
        selected={selectedDate}
      />

      <DocSubHeading>Example with en-US locale</DocSubHeading>

      <PlxsDatePicker
        dataTestId="datepicker-single"
        hasError={isForceError}
        errorMessage={isForceError ? 'Error text to go here' : ''}
        instructionText={
          hasInstructionText
            ? 'Click on the calendar button or start typing'
            : ''
        }
        isDefaultActive={isDefaultActive}
        isDisabled={isDisabled}
        label={hasLabel ? 'Select a date' : ''}
        // @ts-ignore
        labelVariant={labelVariant}
        secondaryLabel={hasSecondaryLabel ? 'Help' : ''}
        secondaryLabelUrl={hasSecondaryLabel ? '#' : ''}
        minDate={hasMinDate ? minDate : null}
        maxDate={hasMaxDate ? maxDate : null}
        onSelect={handleSingleDateSelect}
        selected={selectedDate}
        locale="en-US"
      />

      <DocSubHeading>
        Date range example with range: {JSON.stringify(selectedDates)}
      </DocSubHeading>

      <DocSubHeading>
        <PlxsButton
          dataTestId=""
          label="Reset"
          variant="secondary"
          onClick={handleReset}
        />
      </DocSubHeading>

      <PlxsDatePicker
        dataTestId="datepicker-single"
        hasError={isForceError}
        errorMessage={isForceError ? 'Error text to go here' : ''}
        instructionText={
          hasInstructionText
            ? 'Click on the calendar button or start typing'
            : ''
        }
        isDefaultActive={isDefaultActive}
        isDisabled={isDisabled}
        label={hasLabel ? 'Select a date' : ''}
        // @ts-ignore
        labelVariant={labelVariant}
        secondaryLabel={hasSecondaryLabel ? 'Help' : ''}
        secondaryLabelUrl={hasSecondaryLabel ? '#' : ''}
        minDate={hasMinDate ? minDate : null}
        maxDate={hasMaxDate ? maxDate : null}
        onSelectRange={setSelectedDates}
        selectedRange={selectedDates}
        mode="range"
      />

      <DocSubHeading>
        Date range example with en-US locale: {JSON.stringify(selectedDates)}
      </DocSubHeading>

      <PlxsDatePicker
        dataTestId="datepicker-single"
        hasError={isForceError}
        errorMessage={isForceError ? 'Error text to go here' : ''}
        instructionText={
          hasInstructionText
            ? 'Click on the calendar button or start typing'
            : ''
        }
        isDefaultActive={isDefaultActive}
        isDisabled={isDisabled}
        label={hasLabel ? 'Select a date' : ''}
        // @ts-ignore
        labelVariant={labelVariant}
        secondaryLabel={hasSecondaryLabel ? 'Help' : ''}
        secondaryLabelUrl={hasSecondaryLabel ? '#' : ''}
        minDate={hasMinDate ? minDate : null}
        maxDate={hasMaxDate ? maxDate : null}
        onSelectRange={setSelectedDates}
        selectedRange={selectedDates}
        mode="range"
        locale="en-US"
      />

      <DocSubHeading>
        Date with time{' '}
        {selectedDateTime && format(selectedDateTime, 'dd/MM/yyyy hh:mm a')}
      </DocSubHeading>

      <PlxsDatePicker
        dataTestId="datepicker-single"
        hasError={isForceError}
        errorMessage={isForceError ? 'Error text to go here' : ''}
        instructionText={
          hasInstructionText
            ? 'Click on the calendar button or start typing'
            : ''
        }
        isDefaultActive={isDefaultActive}
        isDisabled={isDisabled}
        label={hasLabel ? 'Select a date' : ''}
        // @ts-ignore
        labelVariant={labelVariant}
        secondaryLabel={hasSecondaryLabel ? 'Help' : ''}
        secondaryLabelUrl={hasSecondaryLabel ? '#' : ''}
        minDate={hasMinDate ? minDate : null}
        maxDate={hasMaxDate ? maxDate : null}
        onSelect={handleDateTimeSelect}
        selected={selectedDateTime}
        isTimeEditable
      />

      <DocSubHeading>
        Date Time Example with en-US locale{' '}
        {selectedDateTime && format(selectedDateTime, 'MM/dd/yyyy hh:mm a')}
      </DocSubHeading>

      <PlxsDatePicker
        dataTestId="datepicker-single"
        hasError={isForceError}
        errorMessage={isForceError ? 'Error text to go here' : ''}
        instructionText={
          hasInstructionText
            ? 'Click on the calendar button or start typing'
            : ''
        }
        isDefaultActive={isDefaultActive}
        isDisabled={isDisabled}
        label={hasLabel ? 'Select a date' : ''}
        // @ts-ignore
        labelVariant={labelVariant}
        secondaryLabel={hasSecondaryLabel ? 'Help' : ''}
        secondaryLabelUrl={hasSecondaryLabel ? '#' : ''}
        minDate={hasMinDate ? minDate : null}
        maxDate={hasMaxDate ? maxDate : null}
        onSelect={handleDateTimeSelect}
        selected={selectedDateTime}
        locale="en-US"
        isTimeEditable
      />
    </DocRow>
  );
};

export default SingleDate;
