import React, {
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getDropdownLabel, getSelectedIndex, isSelection } from '../_internal';
import Icon from '../../Icon';
import MenuList from '../../_internal/MenuList';
import CustomMenuItem from './CustomMenuItem';
import SelectFieldWrapper from '../_internal/SelectFieldWrapper';

import { ComponentProps } from './types';
import { useStyles } from './styles';
import cx from 'classnames';

import Popover from '../../_internal/Popover';
import { POPOVER_BOTTOM, POPOVER_TOP } from '../../_internal/Popover/constants';

const CustomSelect = <Option extends any>(props: ComponentProps<Option>) => {
  const {
    dataTestId,
    getOptionLabel,
    hasError,
    id,
    isDisabled,
    isDefaultActive = false,
    maxMenuListHeight,
    onIndexSelected,
    options,
    renderOption,
    selected,
    autoFocus,
    hasCustomStyle,
    autoPlacement,
  } = props;

  const [anchorElement, setAnchorElement] = useState<HTMLInputElement | null>(
    null
  );
  const [placement, setPlacement] = useState<
    typeof POPOVER_TOP | typeof POPOVER_BOTTOM
  >(POPOVER_BOTTOM);

  const isMenuOpen = Boolean(anchorElement);
  const inputRef = useRef(null);

  const hasSelection = isSelection(selected);
  const selectedIndex = getSelectedIndex(props);

  const classes = useStyles({
    hasSelection,
    hasError,
    isActive: isMenuOpen,
    isDisabled,
    maxMenuListHeight,
    hasCustomStyle,
    placement,
  });

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  });

  useEffect(() => {
    if (isDefaultActive) {
      setAnchorElement(inputRef.current);
    }
  }, [inputRef, isDefaultActive]);

  const handleClickAway = () => setAnchorElement(null);

  const handlePopoverFlip = (_, placement) => setPlacement(placement);

  const handleKeyExit = () => {
    setAnchorElement(null);
    inputRef.current.focus();
  };

  const handleInputClick = (_: MouseEvent) => {
    if (isDisabled) {
      return;
    }

    setAnchorElement(isMenuOpen ? null : inputRef.current);
  };

  const handleInputKeyDown = (event: KeyboardEvent) => {
    if (isDisabled) {
      return;
    }

    const { key } = event;

    if ([' ', 'ArrowDown', 'ArrowUp', 'Enter'].includes(key)) {
      event.preventDefault();
      setAnchorElement(inputRef.current);
    }
  };

  const handleItemSelect = (value: number) => {
    onIndexSelected(value);
    setAnchorElement(null);
    inputRef.current.focus();
  };

  const newItems = options.map((option, index) => (
    <CustomMenuItem
      key={index}
      value={index}
      datatest-id={`${dataTestId}--item`}
      className={cx(
        classes.menuItem,
        index === selectedIndex && classes.highlighted
      )}
    >
      {renderOption ? renderOption(option) : getOptionLabel(option)}
    </CustomMenuItem>
  ));

  return (
    <SelectFieldWrapper
      {...props}
      isActive={isMenuOpen}
      styleOverrides={
        isMenuOpen
          ? {
              css: {
                borderBottomLeftRadius: placement === POPOVER_BOTTOM ? 0 : 3,
                borderBottomRightRadius: placement === POPOVER_BOTTOM ? 0 : 3,
                borderTopLeftRadius: placement === POPOVER_BOTTOM ? 3 : 0,
                borderTopRightRadius: placement === POPOVER_BOTTOM ? 3 : 0,
              },
            }
          : {}
      }
    >
      <div className={classes.root}>
        <div
          aria-disabled={isDisabled}
          className={classes.selectInput}
          data-testid={dataTestId}
          id={id}
          onClick={handleInputClick}
          onKeyDown={handleInputKeyDown}
          ref={inputRef}
          tabIndex={isDisabled ? undefined : 0}
        >
          <span>{getDropdownLabel(props)}</span>
          <Icon name={isMenuOpen ? 'chevronUp' : 'chevronDown'} />
        </div>
        <Popover
          anchorElement={anchorElement}
          dataTestId={`${dataTestId}-popover`}
          isOpen={isMenuOpen}
          onClickAway={handleClickAway}
          onFlip={autoPlacement ? handlePopoverFlip : undefined}
          placement={POPOVER_BOTTOM}
          placementOffset={[0, 0]}
          transitionModifiers={{ in: isMenuOpen, timeout: { enter: 300 } }}
          fullWidth={true}
        >
          <div className={classes.menuWrapper}>
            <div className={classes.menuList}>
              <MenuList
                autoFocus
                onKeyExit={handleKeyExit}
                onSelect={handleItemSelect}
              >
                {newItems}
              </MenuList>
            </div>
          </div>
        </Popover>
      </div>
    </SelectFieldWrapper>
  );
};

export default CustomSelect;
