import AddingNewIcons from '@dev/docs/icons/AddingNewIcons';
import IconDesign from '@dev/docs/icons/IconDesign';
import IconLibrary from '@dev/docs/icons/IconLibrary';

const icons = [
  {
    component: IconDesign,
    name: 'Icon Design',
    path: '/iconDesign',
  },
  {
    component: AddingNewIcons,
    name: 'Adding New Icons ',
    path: '/addingNewIcons',
  },
  {
    component: IconLibrary,
    name: 'Icon Library',
    path: '/iconLibrary',
  },
];

export default icons;
