import React, { FC } from 'react';

import { ComponentProps } from './_types';

const ApprovalDeclined: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg
    width="74"
    height="76"
    viewBox="0 0 74 76"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="44" cy="29.9998" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M25.75 37.3123V23.4998H5.4375C4.01562 23.4998 3 24.617 3 25.9373V73.0623C3 74.4842 4.01562 75.4998 5.4375 75.4998H39.5625C40.8828 75.4998 42 74.4842 42 73.0623V39.7498H28.1875C26.7656 39.7498 25.75 38.7342 25.75 37.3123ZM32.25 61.2811C32.25 61.992 31.6406 62.4998 31.0312 62.4998H13.9688C13.2578 62.4998 12.75 61.992 12.75 61.2811V60.4686C12.75 59.8592 13.2578 59.2498 13.9688 59.2498H31.0312C31.6406 59.2498 32.25 59.8592 32.25 60.4686V61.2811ZM32.25 54.7811C32.25 55.492 31.6406 55.9998 31.0312 55.9998H13.9688C13.2578 55.9998 12.75 55.492 12.75 54.7811V53.9686C12.75 53.3592 13.2578 52.7498 13.9688 52.7498H31.0312C31.6406 52.7498 32.25 53.3592 32.25 53.9686V54.7811ZM32.25 47.4686V48.2811C32.25 48.992 31.6406 49.4998 31.0312 49.4998H13.9688C13.2578 49.4998 12.75 48.992 12.75 48.2811V47.4686C12.75 46.8592 13.2578 46.2498 13.9688 46.2498H31.0312C31.6406 46.2498 32.25 46.8592 32.25 47.4686ZM42 35.8904C42 35.2811 41.6953 34.6717 41.2891 34.1639L31.3359 24.2108C30.8281 23.8045 30.2188 23.4998 29.6094 23.4998H29V36.4998H42V35.8904Z"
    />
  </svg>
);

export default ApprovalDeclined;
