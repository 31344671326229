import React, { FC } from 'react';

import { ComponentProps } from './_types';

const User: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg height="75" width="74" xmlns="http://www.w3.org/2000/svg">
    <circle cx="44" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M22.75 49a13 13 0 1 0-13-13 13 13 0 0 0 13 13Zm9 3.25h-1.69a16 16 0 0 1-7.31 1.63 16.45 16.45 0 0 1-7.41-1.63h-1.73A13.7 13.7 0 0 0 0 66v4.16A4.81 4.81 0 0 0 4.88 75h35.74a4.87 4.87 0 0 0 4.88-4.88V66a13.79 13.79 0 0 0-13.71-13.75Z"
    />
  </svg>
);

export default User;
