import React, { FC, useState } from 'react';

import { ComponentProps } from './types';
import PlxsButton from '../../Button/Button';
import PlxsTextField from '../../TextField/TextField';
import { ComponentProps as PlxsTextFieldProps } from '../../TextField/TextField.types';
import { CALENDAR_BOTTOM } from '../_constants';
import NumberFormat from 'react-number-format';
import { useClickAway } from '../../../hooks';
import { BRAND, NEUTRAL, URGENT } from '../../../_constants';
import { FieldStyleOverrides } from '../../_internal/FieldWrapper/types';

/**
 * This rather awkward component is required because react-number-format's
 * NumberFormat dumps all its props directly into any "custom input" component
 * we tell it to render. We want to specify a placeholder on our custom input,
 * but this has the same name as an existing prop on the NumberFormat which we
 * do not want to set. Therefore, we use this component to effectively bypass
 * the name collision.
 *
 * It would be good to abandon react-number-format entirely, as its practise of
 * blindly dumping props into components is extremely risky. If we ever add a
 * prop to PlxsTextField which has the same name as a prop on NumberFormat,
 * defects may result which engineers and QA are extremely unlikely to notice,
 * and may thus be deployed to production.
 */
const PlxsTextFieldWithRenamedPlaceholderProp: FC<
  PlxsTextFieldProps & {
    plxsPlaceholder: string;
  }
> = (props) => {
  return <PlxsTextField {...props} placeholder={props.plxsPlaceholder} />;
};

const TextInput: FC<ComponentProps> = ({
  calendarPlacement,
  dataTestId,
  dateFormat,
  hasError,
  inputRef,
  isCalendarOpen,
  isDisabled,
  onCalendarButtonClick,
  onTextInputChange,
  mode,
  timePeriod,
  isTimeEditable = false,
  ...otherTextFieldProps
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  let styleOverrides: FieldStyleOverrides = { hidePlaceholderEllipsis: true };
  if (isCalendarOpen) {
    styleOverrides.state = { isActive: true };
    styleOverrides.css =
      calendarPlacement === CALENDAR_BOTTOM
        ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
        : { borderTopLeftRadius: 0, borderTopRightRadius: 0 };
  }

  const isActive = isCalendarOpen || isInputFocused;
  const calendarButtonTone =
    (isActive && BRAND) || (hasError && URGENT) || NEUTRAL;

  const handleInputFocus = () => setIsInputFocused(true);

  // Can't use Blur here as the event will conflict with the iconRight's
  // onClick listener, meaning that button would need to be clicked twice
  // before triggering. Can't use `MouseDown` too as it would interfere with
  // the Popover's clickaway listener... So add another click away on the input.
  useClickAway(inputRef, () => setIsInputFocused(false));

  let format = mode === 'single' ? dateFormat : `${dateFormat} - ${dateFormat}`;

  // Replace all letters with #, except for the last two which are replaced with
  // the time period. This is because react-number-format does not support
  // placeholders with letters in them, but we want to show the user the
  // time period in the placeholder.
  let formatPlaceholder = format.replace(/[a-zA-Z]/g, '#');
  if (isTimeEditable) {
    formatPlaceholder = formatPlaceholder.replace(/([#]{2})$/, timePeriod);
    format = format.replace('aa', 'am/pm');
  }

  return (
    <NumberFormat
      customInput={PlxsTextFieldWithRenamedPlaceholderProp}
      format={formatPlaceholder}
      mask=" "
      {...otherTextFieldProps}
      dataTestId={`${dataTestId}-input`}
      hasError={hasError}
      iconRight={
        <PlxsButton
          dataTestId={`${dataTestId}-toggle`}
          functionalIcon="calendar"
          isDisabled={isDisabled}
          isIconOnly
          label=""
          onClick={onCalendarButtonClick}
          size="sm"
          tone={calendarButtonTone}
          variant="link"
        />
      }
      inputRef={inputRef}
      isDisabled={isDisabled}
      onChange={onTextInputChange}
      onFocus={handleInputFocus}
      plxsPlaceholder={format.toUpperCase()}
      styleOverrides={styleOverrides}
    />
  );
};

export default TextInput;
