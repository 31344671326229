import React, { FC } from 'react';
import { ComponentProps } from './Heading.types';
import { useStyles } from './Heading.styles';

const Heading: FC<ComponentProps> = ({
  align = 'left',
  children,
  color = 'dark',
  dataTestId,
  element,
  variant = element,
  weight,
}) => {
  const classes = useStyles({ align, color, variant, weight });
  switch (element) {
    case 'h6': {
      return (
        <h6 className={classes.root} data-testid={dataTestId}>
          {children}
        </h6>
      );
    }
    case 'h5': {
      return (
        <h5 className={classes.root} data-testid={dataTestId}>
          {children}
        </h5>
      );
    }
    case 'h4': {
      return (
        <h4 className={classes.root} data-testid={dataTestId}>
          {children}
        </h4>
      );
    }
    case 'h3': {
      return (
        <h3 className={classes.root} data-testid={dataTestId}>
          {children}
        </h3>
      );
    }
    case 'h2': {
      return (
        <h2 className={classes.root} data-testid={dataTestId}>
          {children}
        </h2>
      );
    }
    default: {
      return (
        <h1 className={classes.root} data-testid={dataTestId}>
          {children}
        </h1>
      );
    }
  }
};

export default Heading;
