import React, { Fragment } from 'react';

import PageTemplate from '@dev/components/PageTemplate';

import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';

const description = `
  Refer to Plexus UI Figma Design Sytem, selector component.
`;

const example = (
  <Fragment>
    <SingleSelect />
    <MultiSelect />
  </Fragment>
);

const implementation = `
import React, { useState } from 'react';
import {
  PlxsTileSelector
} from '@plexus-ui/index';
import { makeStyles } from '@material-ui/core/styles';
import { PlxsStyles } from '@plexus-ui/index';

export const useStyles = makeStyles({
  root: {
    '& h6': {
      marginBottom: 0,
      fontWeight: 600
    }
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '24px',
    gridAutoRows: '1fr',
    [\`@media (min-width:\${PlxsStyles.breakpoints.md})\`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [\`@media (min-width:\${PlxsStyles.breakpoints.lg})\`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  }
});

const data = [
  {
    id: 1,
    icon: PLACEHOLDER_ICON,
    name: 'Approve and eSign',
  },
  {
    id: 2,
    icon: PLACEHOLDER_ICON,
    name: 'Advertising Compliance Review',
  },
  {
    id: 3,
    icon: PLACEHOLDER_ICON,
    name: 'Automation framework',
  },
  {
    id: 4,
    icon: PLACEHOLDER_ICON,
    name: 'Plexus order form',
  },
  {
    id: 5,
    icon: PLACEHOLDER_ICON,
    name: 'Request legal support',
  },
  {
    id: 6,
    icon: PLACEHOLDER_ICON,
    name: 'Create and NDA',
  }
];

const Examples = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState<any[]>([1, 2]);

  const handleClick = (app: any) => {
    const isSelected = selected.includes(app.id);
    let items = [...selected];
    if (isSelected) {
      items = selected.filter(item => item !== app.id)
    } else {
      items.push(app.id);
    }

    setSelected(items);
  };

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Multi Selector</DocHeading>
        <div className={classes.root}>
          <div className={classes.grid}>
            {data.map((item, key) => (
              <div key={key}>
                <PlxsTileSelector
                  icon={item.icon}
                  name={item.name}
                  dataTestId="test"
                  onChange={() => handleClick(item)}
                  value={selected.indexOf(item.id) > -1}
                />
              </div>
            ))}
          </div>
          <br />
          <div>
            <h6>Value:</h6>
            <DocCode>{JSON.stringify(selected, undefined, 2)}</DocCode>
          </div>
        </div>
      </DocRow>
    </DocRow>
  );
};

export default Examples;
`;

const propList = [
  {
    component: 'TileSelector',
    description:
      'Icon url string to show image. Required field icon with string type value.',
    isRequired: true,
    prop: 'icon',
    type: 'string',
  },
  {
    component: 'TileSelector',
    description:
      'Name of tile selector. Required field name with string type value.',
    isRequired: true,
    prop: 'name',
    type: 'string',
  },
  {
    component: 'TileSelector',
    description:
      'dataTestId definition. Required field dataTestId with string type value.',
    isRequired: true,
    prop: 'dataTestId',
    type: 'string',
  },
  {
    component: 'TileSelector',
    description: 'Required field dataTestId with function type.',
    isRequired: false,
    prop: 'onChange',
    type: '(value: boolean) => void',
  },
  {
    component: 'TileSelector',
    description: 'Required field selected with boolean type value.',
    isRequired: false,
    prop: 'value',
    type: 'boolean',
  },
];

const tsInterface = `interface ComponentProps {
  icon: string;
  name: string;
  dataTestId: string;
  onClick: () => void;
  selected: boolean;
}`;

export default () => {
  return (
    <PageTemplate
      description={description}
      example={example}
      implementation={implementation}
      propList={propList}
      tsInterface={tsInterface}
    />
  );
};
