import { makeStyles } from '@material-ui/core/styles';
import { StyleProps } from './Dropdown.types';
import { getFieldColor } from '../../Field/Field.styles';
import { zIndex } from '../../../styles';

export const useStyles = makeStyles({
  root: ({ hasError, isOpen }: StyleProps) => ({
    display: 'flex',
    visibility: isOpen ? 'visible' : 'hidden',
    transform: isOpen ? 'scaleY(1)' : 'scaleY(0.5)',
    transformOrigin: 'top',
    position: 'absolute',
    zIndex: isOpen ? zIndex.popover : -1,
    top: '100%',
    left: '0px',
    right: '0px',
    maxHeight: '300px',
    overflow: 'auto',
    background: 'white',
    border: `1px solid ${getFieldColor(true, hasError)}`,
    borderTop: 'none',
    borderRadius: '0 0 3px 3px',
    transition: 'transform 200ms',
    opacity: isOpen ? 1 : 0,
  }),
});
