import { makeStyles } from '@material-ui/core/styles';

import { brand } from '../../styles/color';
import { color } from '../../styles';

import { StyleProps } from './TileSelector.types';

export const useStyles = makeStyles({
  root: ({ value }: StyleProps) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    padding: '24px 32px',
    borderRadius: '3px',
    border: '1px solid',
    backgroundColor: value ? brand[600] : undefined,
    borderColor: value ? brand[600] : color.neutralShade[800],
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: value ? brand[500] : color.brandLightest,
      borderColor: value ? brand[500] : color.brandLightest,
    },
  }),
  clickArea: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  image: {
    width: '42px',
    height: '40px',
  },
  label: {
    flexGrow: 1,
    marginLeft: '16px',
    marginRight: '16px',
    '& > h6': {
      wordWrap: 'break-word',
    },
  },
});
