import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { brand, neutral } from './color';
import { color } from './index';
import { defaultTextStyle } from './text';
import { tone } from './tone';

export interface InputStateProps {
  hasError?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
}

export const inputActive = {
  borderColor: tone.brand,
  color: brand[100],
  '& svg': {
    color: tone.brand,
  },
};

export const inputDefault = {
  borderColor: neutral[500],
  color: neutral[100],
  '& svg': {
    color: 'inherit',
  },
};

export const inputDisabled = {
  backgroundColor: neutral[800],
  borderColor: neutral[500],
  color: tone.disabled,
  cursor: 'not-allowed',
  '& svg': {
    color: tone.disabled,
  },
};

export const inputError = {
  borderColor: tone.urgent,
  '& svg': {
    color: tone.urgent,
  },
};

export const inputFocused = {
  ...inputActive,
  outline: 'none',
};

export const inputReset = {
  '-webkit-tap-highlight-color': 'transparent',
  background: 'none',
  border: '1px solid transparent',
  color: 'currentColor',
  display: 'block',
  font: 'inherit',
  height: 'auto',
  letterSpacing: 'inherit',
  margin: 0,
  minWidth: 0,
  outline: 'none',
  padding: 0,
  width: '100%',
};

export const fieldsetReset = {
  margin: 0,
  padding: 0,
  paddingBlock: 0,
  marginInline: 0,
  paddingInline: 0,
  minInlineSize: 0,
};

export const inputTransparent = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
};

export const getInputStyle = (state: InputStateProps) => ({
  ...inputReset,
  backgroundColor: color.white,
  borderBottomLeftRadius: '3px',
  borderBottomRightRadius: '3px',
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: '10px 15px',
  '&:focus': {
    ...inputFocused,
  },
  ...inputDefault,
  ...(state.hasError ? inputError : {}),
  ...(state.isActive ? inputActive : {}),
  ...(state.isDisabled ? inputDisabled : {}),
});

const disabledTextInputStyles = (isDisabled: boolean | undefined) => {
  const disabledStyles = {
    color: tone.disabled,
    cursor: 'not-allowed',
  };
  return isDisabled ? disabledStyles : {};
};

export const getTextInputStyles = (state: InputStateProps): CSSProperties => ({
  ...inputReset,
  ...defaultTextStyle,
  ...disabledTextInputStyles(state.isDisabled),
  padding: '10px 15px',
});
