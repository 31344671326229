import { makeStyles } from '@material-ui/core/styles';

import { getInputStyle } from '../../../../styles/input';
import { tone } from '../../../../styles/tone';
import { zIndex } from '../../../../styles';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  '@keyframes dropDownMenuIn': {
    '0%': {
      transform: 'scaleY(0)',
      transformOrigin: 'top',
    },
    '100%': {
      transform: 'scaleY(1)',
      transformOrigin: 'top',
    },
  },
  dropdownFooterBorder: {
    borderTop: `1px solid ${tone.brand}`,
    marginLeft: '16px',
    marginRight: '16px',
  },
  dropdownFooterWrapper: {
    backgroundColor: tone.white,
    paddingTop: '16px',
  },
  dropDownMenu: (props: StyleProps) => {
    const { hasError, isActive, isDisabled, isMultiSelect } = props;
    return {
      ...getInputStyle({ hasError, isActive, isDisabled }),
      backgroundColor: tone.white,
      borderTop: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      cursor: 'auto',
      display: 'block',
      left: 0,
      margin: 0,
      padding: 0,
      position: 'absolute',
      right: 0,
      top: isMultiSelect ? '100%' : 'auto',
      zIndex: zIndex.menu,
    };
  },
  dropDownMenuAnimation: {
    animation: '$dropDownMenuIn 300ms',
  },
  menuList: (props: StyleProps) => ({
    width: '100%',
    maxHeight: props.maxMenuListHeight
      ? `${props.maxMenuListHeight}px`
      : 'initial',
    overflow: 'auto',
    padding: '8px 0',
  }),
}));
