export const AI = 'ai';
export const APPROVAL_DECLINED = 'approvalDeclined';
export const APPROVAL_SUCCESS = 'approvalSuccess';
export const GHOST = 'ghost';
export const INVITE = 'invite';
export const MESSAGE = 'message';
export const NOTIFICATIONS = 'notifications';
export const PERMISSIONS_BASIC = 'permissionsBasic';
export const PERMISSIONS_FULL = 'permissionsFull';
export const PERMISSIONS_CUSTOM = 'permissionsCustom';
export const PERMISSIONS_EMPTY = 'permissionsEmpty';
export const RECENTLY_VIEWED = 'recentlyViewed';
export const SHARE = 'share';
export const SIGNATURE_DECLINED = 'signatureDeclined';
export const SIGNATURE_SUCCESS = 'signatureSuccess';
export const TASKS = 'tasks';
export const USER = 'user';

export const VARIANT_WHITE = 'white';
