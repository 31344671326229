import { makeStyles } from '@material-ui/core/styles';
import { getTextStyle } from '../../styles/text';

// Any updates to these styles should also be made to
// RichTextEditor/RichTextEditor.styles.ts
// editorStyles
export const useStyles = makeStyles({
  root: {
    ...getTextStyle({ color: 'dark', weight: 'regular' }),
    fontSize: '13px',
    lineHeight: '18px',
    '& h1': {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400,
      marginTop: '0',
      marginBottom: '4px',
    },
    '& h2': {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 600,
      marginTop: '0',
      marginBottom: '4px',
    },
    '& h3': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
      marginTop: '0',
      marginBottom: '4px',
    },
    '& h4, & h5, & h6': {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      marginTop: '0',
      marginBottom: '4px',
    },
    '& ol, & ul': {
      paddingLeft: '40px',
      marginTop: '0',
      marginBottom: '16px',
    },
    '& ul': {
      listStyleType: 'disc',
    },
    '& li': {
      marginBottom: '4px',
    },
    '& p': {
      fontSize: '13px',
      lineHeight: '18px',
      marginBottom: '16px',
    },
    '& p:last-child': {
      marginBottom: '0',
    },
  },
});
