import React, { FC } from 'react';

import { ComponentProps } from './_types';

const Message: FC<ComponentProps> = ({ circleFill, iconFill }) => (
  <svg height="70" width="85" xmlns="http://www.w3.org/2000/svg">
    <circle cx="55" cy="30" r="30" fill={circleFill} />
    <path
      fill={iconFill}
      d="M51.48 43.9c-2.33 1.83-5.28 4.1-15.64 11.58-2 1.52-5.79 4.87-9.34 4.87s-7.31-3.35-9.45-4.87C6.7 48 3.75 45.73 1.41 43.9a.57.57 0 00-.91.51v20.71A4.81 4.81 0 005.38 70h42.24a4.87 4.87 0 004.88-4.88V44.41a.64.64 0 00-1.02-.51zM26.5 57c2.34.1 5.69-3 7.41-4.16 13.51-9.75 14.53-10.67 17.57-13.11a2.3 2.3 0 001-1.93v-1.92A4.94 4.94 0 0047.62 31H5.38A4.87 4.87 0 00.5 35.88v1.92a2.21 2.21 0 00.91 1.93C4.46 42.17 5.48 43.09 19 52.84c1.71 1.21 5.06 4.26 7.5 4.16z"
    />
  </svg>
);

export default Message;
