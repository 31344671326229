import React, { FC } from 'react';

import { useStyles } from './styles';

const Prop: FC = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.root}>{children}</span>;
};

export default Prop;
