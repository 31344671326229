import React from 'react';
import {
  DocCode,
  DocPropGroupHeading,
  DocRow,
  DocSubHeading,
} from '@dev/components';

import DefaultTextarea from './Default';
import DisabledTextarea from './Disabled';
import ErrorTextarea from './Error';

export default () => {
  return (
    <DocRow margin="lg">
      <DocPropGroupHeading>States</DocPropGroupHeading>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Default</DocSubHeading>
        </DocRow>
        <DefaultTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="default-textarea"
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Disabled</DocSubHeading>
        </DocRow>
        <DisabledTextarea />
        <DocCode>{`<PlxsTextarea
  dataTestId="disabled-textarea"
  isDisabled={true}
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>

      <DocRow margin="md">
        <DocRow margin="sm">
          <DocSubHeading>Error</DocSubHeading>
        </DocRow>
        <ErrorTextarea />
        <DocCode>{`<PlxsTextField
  dataTestId="error-textarea"
  errorMessage="Error text to go here"
  hasError={true}
  label="Input name to go here"
  onChange={() => {}}
  placeholder="Field helper text"
/>`}</DocCode>
      </DocRow>
    </DocRow>
  );
};
