import { makeStyles } from '@material-ui/core/styles';

import { color, fontColor } from '../../../../../styles';
import { defaultTextStyle } from '../../../../../styles/text';
import { tone, toneBg } from '../../../../../styles/tone';

import { StyleProps } from './types';

export const useStyles = makeStyles(() => ({
  '@keyframes labelAnimation': {
    '0%': {},
    '100%': {
      color: fontColor.default,
      fontSize: '10px',
      top: '-10px',
    },
  },
  '@keyframes labelDisabledAnimation': {
    '0%': {},
    '100%': {
      color: tone.disabled,
      fontSize: '10px',
      top: '-10px',
    },
  },
  label: (props: StyleProps) => ({
    ...defaultTextStyle,
    backgroundColor: props.isDisabled ? toneBg.disabled : color.white,
    borderRadius: 3,
    color: tone.disabled,
    padding: '0 8px',
    position: 'absolute',
    top: '11px',
    left: '8px',
  }),
  labelAnimation: {
    animation: '$labelAnimation 250ms',
    'animation-fill-mode': 'forwards',
  },
  labelDisabledAnimation: {
    animation: '$labelDisabledAnimation 250ms',
    'animation-fill-mode': 'forwards',
  },
}));
