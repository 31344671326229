import React, { FC } from 'react';
import { DocHeading, DocRow, DocText } from '@dev/components';
import { PlxsProgressBar } from '@plexus-ui/index';

const ProgressBarDocs: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>Default</DocHeading>
        <PlxsProgressBar progress={0.5} />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Progress variants</DocHeading>
        <PlxsProgressBar progress={0} label="0" />
        <PlxsProgressBar progress={0.00001} label="0.00001" />
        <PlxsProgressBar progress={0.5} label="0.5" />
        <PlxsProgressBar progress={1} label="1" />
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Tone variants</DocHeading>
        <DocText>Fixed label widths set for consistent layout.</DocText>
        <PlxsProgressBar
          progress={0.2}
          tone="brand"
          label="brand"
          labelWidth={50}
        />
        <PlxsProgressBar
          progress={0.4}
          tone="positive"
          label="positive"
          labelWidth={50}
        />
        <PlxsProgressBar
          progress={0.6}
          tone="caution"
          label="caution"
          labelWidth={50}
        />
        <PlxsProgressBar
          progress={0.8}
          tone="urgent"
          label="urgent"
          labelWidth={50}
        />
      </DocRow>
    </DocRow>
  );
};

export default ProgressBarDocs;
