import { makeStyles } from '@material-ui/core/styles';
import { color, spacing } from '../../styles';

const switchHeight = 25;
const thumbBorderSize = Math.round(switchHeight / 10);
const thumbSpacing = 2;
const switchWidth = switchHeight * 1.8 + thumbBorderSize * 2;
const trackBorderRadius = switchHeight / 2;
const thumbDimension = switchHeight - thumbSpacing * 2;
const thumbTravelDistance = switchWidth - thumbDimension - thumbSpacing * 2;
const trackActiveColor = color.brand;
const trackInactiveColor = color.neutralShade[600];

export const useStyles = makeStyles(() => ({
  root: {
    width: switchWidth,
    height: switchHeight,
    padding: 0,
    margin: spacing.xs,
    overflow: 'inherit',
    '& .MuiIconButton-root': {
      padding: thumbSpacing,
      position: 'absolute',
    },
  },
  switchBase: {
    padding: thumbSpacing,
    '&$checked': {
      transform: `translateX(${thumbTravelDistance}px)`,
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: trackActiveColor,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: 'white',
      },
    },
    '&$focusVisible $thumb': {
      border: `${thumbBorderSize}px solid #fff`,
    },
  },
  switchIndeterminate: {
    top: 1,
    transform: 'translateX(14px)',
  },
  thumb: {
    width: thumbDimension,
    height: thumbDimension,
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    border: `${thumbBorderSize}px solid #fff`,
  },
  thumbIndeterminate: {
    display: 'flex',
    '& svg': {
      color: 'white',
    },
  },
  track: {
    borderRadius: trackBorderRadius,
    backgroundColor: trackInactiveColor,
    opacity: 1,
  },
  trackIndeterminate: {
    backgroundColor: trackActiveColor,
  },
  checked: {},
  focusVisible: {},
}));
