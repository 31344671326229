import React, { FC } from 'react';
import { ComponentProps } from './ButtonGroup.types';
import { useStyles } from './ButtonGroup.styles';

const ButtonGroup: FC<ComponentProps> = ({ align = 'left', children }) => {
  const classes = useStyles({ align });
  return <div className={classes.root}>{children}</div>;
};

export default ButtonGroup;
