import React from 'react';
import {
  DocCode,
  DocParagraph,
  DocProp,
  DocRow,
  DocSubHeading,
} from '@dev/components';
import { PlxsChip } from '@plexus-ui/index';

import { useStyles } from '../../styles/panelList';

const ChipDisabled = () => {
  const classes = useStyles();

  return (
    <DocRow margin="sm">
      <DocRow margin="sm">
        <DocSubHeading>Disabled</DocSubHeading>
        <DocParagraph>
          The <DocProp>isDisabled</DocProp> prop renders chips in the disabled
          style and disables the hover state and onClick functionality.
        </DocParagraph>
      </DocRow>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Brand</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              hasHover={true}
              isDisabled={true}
              label="Tomas Michaels"
              tone="brand"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'hasHover={true} tone="brand" isDisabled={true}'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Positive</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              hasHover={true}
              isDisabled={true}
              label="Tomas Michaels"
              tone="positive"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'hasHover={true} tone="positive" isDisabled={true}'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Caution</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              hasHover={true}
              isDisabled={true}
              label="Tomas Michaels"
              tone="caution"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'hasHover={true} tone="caution" isDisabled={true}'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Urgent</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              hasHover={true}
              isDisabled={true}
              label="Tomas Michaels"
              tone="urgent"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'hasHover={true} tone="urgent" isDisabled={true}'}
            </DocCode>
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.displayName}>Neutral</div>
          <div className={classes.icons}>
            <PlxsChip
              decorativeIcon="person"
              functionalIcon="cross"
              hasHover={true}
              isDisabled={true}
              label="Tomas Michaels"
              tone="neutral"
            />
          </div>
          <div className={classes.name}>
            <DocCode>
              {'hasHover={true} tone="neutral" isDisabled={true}'}
            </DocCode>
          </div>
        </li>
      </ul>
    </DocRow>
  );
};

export default ChipDisabled;
