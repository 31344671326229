import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsButton, PlxsTextField } from '@plexus-ui/index';

const IconRightButtonTextField: FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // In the example below where we're re-rendering the button (because of tone)
  // when the input is focused/blurred: if we use the normal `onClick` event,
  // we'd have to click the button twice to get that event triggered. This
  // article provides good explanation why (and why we use `onMouseDown` here):
  // TL;DR: It is to do with `onBlur` and `onClick` trigger order + re-renders:
  // https://www.eventbrite.com/engineering/a-story-of-a-react-re-rendering-bug/
  //
  // If we weren't re-rendering the button i.e. in the example below, we weren't
  // updating the tone, then `onClick` would work as expected.

  return (
    <PlxsTextField
      iconRight={
        <PlxsButton
          dataTestId="text-field-calendar-button"
          functionalIcon="calendar"
          isIconOnly
          label=""
          onMouseDown={() => setValue('iconRight button clicked!')}
          size="sm"
          tone={isFocused ? 'brand' : 'neutral'}
          variant="link"
        />
      }
      dataTestId="icon-right-button-text-field"
      label="Input name to go here"
      onBlur={() => setIsFocused(false)}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default IconRightButtonTextField;
