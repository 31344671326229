import React, { ChangeEvent, FC, useState } from 'react';

import { PlxsTextField } from '@plexus-ui/index';

const InstructionTextField: FC = () => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <PlxsTextField
      dataTestId="instruction-text-field"
      instructionText="Additional instructions go here"
      label="Input name to go here"
      onChange={handleChange}
      placeholder="Field helper text"
      value={value}
    />
  );
};

export default InstructionTextField;
