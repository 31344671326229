import React, { FC } from 'react';
import { DocBox, DocHeading, DocRow } from '@dev/components';
import { PlxsText } from '@plexus-ui/index';

const TextDocs: FC = () => {
  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocHeading>XS Text</DocHeading>
        <PlxsText dataTestId="p-text-xs" size="xs">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          pharetra, dui hendrerit euismod interdum, dolor quam bibendum arcu,
          nec pellentesque tellus velit vitae arcu.
        </PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>SM Text (default)</DocHeading>
        <PlxsText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          pharetra, dui hendrerit euismod interdum, dolor quam bibendum arcu,
          nec pellentesque tellus velit vitae arcu.
        </PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>Bold Text</DocHeading>
        <PlxsText weight="bold">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          pharetra, dui hendrerit euismod interdum, dolor quam bibendum arcu,
          nec pellentesque tellus velit vitae arcu.
        </PlxsText>
      </DocRow>
      <DocRow margin="lg">
        <DocHeading>White Text</DocHeading>
        <DocBox hasDarkBg={true}>
          <PlxsText color="white">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            pharetra, dui hendrerit euismod interdum, dolor quam bibendum arcu,
            nec pellentesque tellus velit vitae arcu.
          </PlxsText>
        </DocBox>
      </DocRow>
    </DocRow>
  );
};

export default TextDocs;
