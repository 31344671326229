import { makeStyles } from '@material-ui/core/styles';

import { neutral } from '../../styles/color';

import { StyleProps } from './Popover.types';

const padding = (props: StyleProps) => {
  return props.variant === 'paddingNone'
    ? {}
    : {
        padding: '16px 24px',
      };
};

export const useStyles = makeStyles({
  background: (props: StyleProps) => ({
    background: '#ffffff',
    border: `1px solid ${neutral[800]}`,
    borderRadius: '3px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    minWidth: '160px',
    ...padding(props),
  }),
});
