import React, { FC, MouseEvent } from 'react';
import { PlxsButton } from '@plexus-ui/index';

interface ComponentProps {
  onClick: (event: MouseEvent) => void;
}

const PrimaryButton: FC<ComponentProps> = ({ onClick }) => {
  return (
    <PlxsButton
      dataTestId="primary-action--button"
      functionalIcon="add"
      onClick={onClick}
      label="Primary action button"
    />
  );
};

export default PrimaryButton;
